import { getNumberOrNull } from "../../typeUtilities/getNumber";
import { ILineItem } from "../components/BillableFormLineItem";
import { IBillableItemForFormLineItem } from "../types/IBillableItemForFormLineItem";
import { IInvoiceItem } from "../types/IInvoiceItem";
import { getLineItemName } from "./getLineItemName";

export function mapLineItemForSave(
  lineItem: ILineItem,
  invoiceItems: IInvoiceItem[]
): IBillableItemForFormLineItem {
  return {
    id: lineItem.id,
    description: lineItem.description,
    itemId: lineItem.itemId,
    name: getLineItemName(lineItem.name, lineItem.itemId, invoiceItems),
    amount: getNumberOrNull(lineItem.amountPerItem),
    quantity: getNumberOrNull(lineItem.quantity),
    taxable: lineItem.taxable ?? false,
    hide: lineItem.hide ?? false,
    optional: lineItem.optional ?? false,
    selected: lineItem.selected ?? false,
  };
}
