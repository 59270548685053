import LocalizedStrings, { LocalizedStringsMethods } from "react-localization";
import {
  getSetting,
  setSetting,
  SettingsType,
} from "./services/settingService";

export type Language = "en" | "es";

export interface IStrings extends LocalizedStringsMethods {
  latitude: string;
  longitude: string;
  crewMembers: string;
  notes: string;
  start: string;
  stop: string;
  stopClock: string;
  complete: string;
  completed: string;
  pause: string;
  resume: string;
  uploading: string;
  addCaption: string;
  startTime: string;
  stopTime: string;
  resetJobTimes: string;
  areYouSureResetTimes: string;
  no: string;
  deletePhoto: string;
  delete: string;
  cancel: string;
  save: string;
  estimatedTime: string;
  hours: string;
  minutes: string;
  hoursAndMinutes: string;
  uploadFailed: string;
  deleteFailed: string;
  checkInternetConnection: string;
  retry: string;
  checklist: string;
  leavingShop: string;
  returnToShop: string;
  skipJob: string;
  unskipJob: string;
  skipped: string;
  lastVisitDate: string;
  nextDay: string;
  futureDay: string;
  pastDay: string;
  estimatedArrival: string;
  estimatedComplete: string;
  actualArrival: string;
  actualComplete: string;
  clockIn: string;
  clockOut: string;
  clockInUpper: string;
  clockOutUpper: string;
  chooseEmployees: string;
  search: string;
  noAvailableCrewMembers: string;
  errorLoadingCrewMembers: string;
  errorClockingIn: string;
  errorClockingOut: string;
  saveFailed: string;
  mustSelectCrewMember: string;
  back: string;
  invoice: string;
  paid: string;
  upload: string;
  estimate: string;
  customerEmailAddresses: string;
  addEmailAddress: string;
  template: string;
  numberOfDaysValid: string;
  lineItems: string;
  lineItem: string;
  addLineItem: string;
  description: string;
  quantity: string;
  amountPerItem: string;
  taxable: string;
  subtotal: string;
  discountValue: string;
  discountPercent: string;
  taxRate: string;
  total: string;
  addFile: string;
  summary: string;
  present: string;
  productService: string;
  signatureExplanationInvoice: string;
  signatureExplanationEstimate: string;
  captureSignature: string;
  discount: string;
  taxes: string;
  email: string;
  confirm: string;
  pay: string;
  paymentAmount: string;
  creditCard: string;
  bankTransfer: string;
  cash: string;
  check: string;
  other: string;
  referenceInformation: string;
  name: string;
  voidAcceptanceWarning: string;
  unsavedChangesWarning: string;
  unitPrice: string;
  unlock: string;
  messageSent: string;
  bankAccountType: string;
  routingNumber: string;
  accountNumber: string;
  confirmAccountNumber: string;
  selectBankAccountType: string;
  businessChecking: string;
  personalChecking: string;
  accountNumbersDoNotMatch: string;
  accountNumberExampleLabel: string;
  routingNumberExampleLabel: string;
  accepted: string;
  on: string;
  invoicePaid: string;
  voidInvoiceWarning: string;
  viewJobHistory: string;
  jobHistory: string;
  files: string;
  jobNotes: string;
  crewNotes: string;
  viewFullImage: string;
  validUntil: string;
  estimateDate: string;
  deleteFile: string;
  attachments: string;
  images: string;
  newLineItem: string;
  editLineItem: string;
  formIsNotValid: string;
  showFieldToFix: string;
  wouldYouLikeToApplyEstimateToCurrentJob: string;
  yesApplyToCurrentJob: string;
  noWorkWillBeDoneLater: string;
  deleteLineItem: string;
  pleaseAddLineItem: string;
  noLineItemsSet: string;
  notifyOnTheWay: string;
  sendOnTheWayNotification: string;
  send: string;
  myName: string;
  myPhoneNumber: string;
  arrivalTime: string;
  none: string;
  dueDate: string;
  edit: string;
  customerIsTaxExempt: string;
  add: string;
  update: string;
  optional: string;
  included: string;
  notIncluded: string;
  copyJobFiles: string;
  print: string;
  text: string;
  customerPhoneNumber: string;
  customerPhoneNumberOptedIn: string;
  messageDataRatesMayApply: string;
  messagesMaySendEveryDay: string;
  canReplyWithHelpStop: string;
  thisJobHasNotBeenCompleted: string;
  invoicingJobNotCompleted: string;
  wouldYouLikeToCompleteIt: string;
  errorCompletingJob: string;
  jobCannotBeCompletedUntilFollowingItemsDone: string;
  skip: string;
  yesJobCompleted: string;
  logout: string;
  initialDeposit: string;
  balanceDueAtCompletion: string;
  yes: string;
  areYouSureYouWantToLogout: string;
  worksheets: string;
  ok: string;
  worksheetCannotBeCompleted: string;
}

export const strings: IStrings = new LocalizedStrings({
  en: {
    latitude: "Latitude",
    longitude: "Longitude",
    crewMembers: "Crew members",
    notes: "Notes",
    start: "Start",
    stop: "Stop",
    stopClock: "Stop Clock",
    complete: "Complete",
    completed: "Completed",
    pause: "Pause",
    resume: "Resume",
    uploading: "Uploading...",
    addCaption: "Add caption...",
    startTime: "Start time",
    stopTime: "Stop time",
    resetJobTimes: "Reset job times",
    areYouSureResetTimes: "Are you sure you want to reset times?",
    no: "No",
    deletePhoto: "Delete photo?",
    delete: "Delete",
    cancel: "Cancel",
    save: "Save",
    estimatedTime: "Estimated time",
    hours: "{0} hours",
    minutes: "{0} minutes",
    hoursAndMinutes: "{0} hours, {1} minutes",
    uploadFailed: "Upload failed!",
    deleteFailed: " Delete failed!",
    checklist: "Checklist",
    checkInternetConnection:
      "Please make sure you still have an internet connection.",
    retry: "Retry.",
    leavingShop: "Leaving shop",
    returnToShop: "Return to shop",
    skipJob: "Skip job?",
    unskipJob: "Unskip job?",
    skipped: "Skipped",
    lastVisitDate: "Last visit date",
    nextDay: "Next Day",
    futureDay: "Future Day",
    pastDay: "Past Day",
    estimatedArrival: "Estimated arrival",
    estimatedComplete: "Estimated complete",
    actualArrival: "Actual arrival",
    actualComplete: "Actual complete",
    clockIn: "Clock in",
    clockOut: "Clock out",
    clockInUpper: "Clock In",
    clockOutUpper: "Clock Out",
    chooseEmployees: "Choose employee(s)",
    search: "Search",
    noAvailableCrewMembers: "No available crew members",
    errorLoadingCrewMembers:
      "There was an error loading crew members. Please ensure you have an Internet connection and re-open this window.",
    errorClockingOut:
      "There was an error clocking out. Please ensure you have an Internet connection and try again.",
    errorClockingIn:
      "There was an error clocking in. Please ensure you have an Internet connection and try again.",
    saveFailed:
      "Save failed!  Please ensure you have an internet connection and try again.",
    mustSelectCrewMember: "You must select at least one crew member.",
    back: "Back",
    invoice: "Invoice",
    paid: "Paid",
    upload: "Upload",
    estimate: "Estimate",
    customerEmailAddresses: "Customer email addresses",
    addEmailAddress: "Add email address",
    template: "Template",
    numberOfDaysValid: "Number of days valid",
    lineItems: "Line Items",
    lineItem: "Line item",
    addLineItem: "Add Line Item",
    description: "Description",
    quantity: "Quantity",
    amountPerItem: "Amount per item",
    taxable: "Taxable",
    subtotal: "Subtotal",
    discountValue: "Discount value",
    discountPercent: "Discount percent",
    taxRate: "Tax rate",
    total: "Total",
    addFile: "Add file",
    summary: "Job summary",
    present: "Present",
    productService: "Product / Service",
    signatureExplanationInvoice:
      "By signing below, I agree the services provided are to my expectation and agree to pay for services rendered under the terms and conditions below",
    signatureExplanationEstimate:
      "By providing a signature below, I accept the proposal for the work to be performed under the terms and conditions attached to this proposal",
    captureSignature: "Capture Signature",
    discount: "Discount",
    taxes: "Taxes",
    email: "Email",
    confirm: "Confirm",
    pay: "Pay",
    paymentAmount: "Payment amount",
    creditCard: "Credit card",
    bankTransfer: "Bank transfer",
    cash: "Cash",
    check: "Check",
    other: "Other",
    referenceInformation: "Reference information",
    name: "Name",
    voidAcceptanceWarning:
      "Editing this estimate will void the acceptance captured. Are you sure you want to proceed?",
    unsavedChangesWarning:
      "You have unsaved changes. Are you sure you want to leave this page?",
    unitPrice: "Unit price",
    unlock: "Unlock",
    messageSent: "Message sent!",
    bankAccountType: "Bank account type",
    routingNumber: "Routing number",
    accountNumber: "Account number",
    confirmAccountNumber: "Confirm account number",
    selectBankAccountType: "Select bank account type",
    businessChecking: "Business checking",
    personalChecking: "Personal checking",
    accountNumbersDoNotMatch: "The account numbers do not match",
    accountNumberExampleLabel: "Account",
    routingNumberExampleLabel: "Routing",
    accepted: "Accepted",
    on: "on",
    invoicePaid: "Invoice Paid!",
    voidInvoiceWarning:
      "Editing this invoice will void the signature captured. Are you sure you want to proceed?",
    viewJobHistory: "Job history",
    jobHistory: "Job History",
    files: "Files",
    jobNotes: "Job Notes",
    crewNotes: "Crew Notes",
    viewFullImage: "View Full Image",
    estimateDate: "Estimate date",
    validUntil: "Valid until",
    deleteFile: "Delete file?",
    attachments: "Attachments",
    newLineItem: "New Line Item",
    editLineItem: "Edit Line Item",
    formIsNotValid: "Form is not valid!",
    showFieldToFix: "Show field to fix.",
    wouldYouLikeToApplyEstimateToCurrentJob:
      "Would you like to apply this estimate to the current job?",
    yesApplyToCurrentJob: "Yes, apply to current job",
    noWorkWillBeDoneLater: "No, work will be done later.",
    deleteLineItem: "Delete line item?",
    pleaseAddLineItem: "Please add a line item",
    noLineItemsSet: "No line items are set",
    notifyOnTheWay: "On-the-way",
    sendOnTheWayNotification: "Send On-The-Way Message",
    send: "Send",
    myName: "My name",
    myPhoneNumber: "My phone number",
    arrivalTime: "Arrival time",
    none: "None",
    dueDate: "Due:",
    edit: "Edit",
    customerIsTaxExempt: "Customer is tax-exempt",
    add: "Add",
    update: "Update",
    optional: "Optional",
    included: "Included",
    notIncluded: "Not included",
    copyJobFiles: "Copy job files",
    print: "Print",
    text: "Text",
    customerPhoneNumber: "Customer phone number",
    customerPhoneNumberOptedIn:
      "Owner of this phone number has opted-in to receive texts and been informed:",
    messageDataRatesMayApply: "Message and data rates may apply",
    messagesMaySendEveryDay: "Messages may be sent every day",
    canReplyWithHelpStop:
      "Can reply to messages with HELP for help or STOP to cancel",
    thisJobHasNotBeenCompleted: "This job hasn't been completed!",
    invoicingJobNotCompleted:
      "You're invoicing a job that hasn't been completed.",
    wouldYouLikeToCompleteIt: "Would you like to complete it now?",
    errorCompletingJob:
      "There was an error completing the job. Please try again.",
    jobCannotBeCompletedUntilFollowingItemsDone:
      "The job cannot be completed until the following items are done:",
    yesJobCompleted: "Yes, the job is completed",
    skip: "Skip",
    images: "Images",
    logout: "Logout",
    initialDeposit: "Initial deposit",
    balanceDueAtCompletion: "Balance due at completion",
    yes: "Yes",
    areYouSureYouWantToLogout: "Are you sure you want to logout?",
    worksheets: "Worksheets",
    ok: "OK",
    worksheetCannotBeCompleted: "Worksheet Cannot Be Completed",
  },
  es: {
    latitude: "Latitud",
    longitude: "Longitud",
    crewMembers: "Miembros de equipo",
    notes: "Notas",
    start: "Comienzo",
    stop: "Parar",
    stopClock: "Parar reloj",
    complete: "Terminar",
    completed: "Terminado",
    pause: "Pausa",
    resume: "Continuar",
    uploading: "Cargando...",
    addCaption: "Agregar subtitulo...",
    startTime: "Hora de inicio",
    stopTime: "Parar tiempo",
    resetJobTimes: "Restablecer tiempos de trabajo",
    areYouSureResetTimes:
      "Esta seguro de querer restablecer los tiempos de trabajo?",
    no: "No",
    deletePhoto: "¿Borrar foto?",
    delete: "Borrar",
    cancel: "Cancelar",
    save: "Salvar",
    estimatedTime: "Tiempo estimado",
    hours: "{0} horas",
    minutes: "{0} minutos",
    hoursAndMinutes: "{0} horas, {1} minutos",
    uploadFailed: "Subida fallida!",
    deleteFailed: " Borrar fallido!",
    checklist: "Lista de verificacion",
    checkInternetConnection:
      "Asegurse de que todavia tiene una conexion al Internet.",
    retry: "Intentar de nuevo.",
    leavingShop: "Salir del taller",
    returnToShop: "Volver al taller",
    skipJob: "¿Omitir trabajo?",
    unskipJob: "Unskip job?",
    skipped: "Omitido",
    lastVisitDate: "Fecha de la última visita",
    nextDay: "Dia Siguiente",
    futureDay: "Dia Futuro",
    pastDay: "Dia Pasado",
    estimatedArrival: "Llegada estimada",
    estimatedComplete: "Completo estimado",
    actualArrival: "Llegada real",
    actualComplete: "Real completo",
    clockIn: "Registrar entrada",
    clockOut: "Registrar salida",
    clockInUpper: "Registrar Entrada",
    clockOutUpper: "Registrar Salida",
    chooseEmployees: "Escoja los empleados",
    search: "Buscar",
    noAvailableCrewMembers: "No ai miembros disponcible",
    errorLoadingCrewMembers:
      "Habia un eror regargando los miembros por el internet.",
    errorClockingOut:
      "Habia un eror con el registrar salida, por favor de asegurarse que la conecion del internet esta bien.",
    errorClockingIn:
      "Habia un eror con el registrar entrada, por favor de asegurarse que la conecion del internet esta bien.",
    saveFailed:
      "Error al guardar, por favor de asegurarse que la conecion del internet esta bien.",
    mustSelectCrewMember:
      "Debes seleccionar al menos un miembro de la tripulación.",
    back: "Volver",
    invoice: "Factura",
    paid: "Pagado",
    upload: "Cargar",
    estimate: "Estimado",
    customerEmailAddresses: "Direcciones de correo electrónico de clientes",
    template: "Plantilla",
    numberOfDaysValid: "Numero de dias valido",
    lineItems: "Partidas",
    lineItem: "Partida",
    addLineItem: "Añadir Partida",
    description: "Descripción",
    quantity: "Cantidad",
    amountPerItem: "Importe por articulo",
    taxable: "Imponible",
    subtotal: "Subtotal",
    discountValue: "Importe del descuento",
    discountPercent: "Porcentaje de descuento",
    taxRate: "Tipo impositivo",
    total: "Total",
    addFile: "Añadir archivo",
    summary: "Resumen del trabajo",
    present: "Presente",
    productService: "Producto / Servicio",
    signatureExplanationInvoice:
      "Al firmar a continuanción están a la altura de mis expectativas y estoy de acuerdo con los servicios prestados al pacienteen los téminos y condiciones siguientes.",
    signatureExplanationEstimate:
      "Al proporcionar mi firma a continuación, acepto la propuesta para que el trabajo se realice bajo los términos y condiciones adjuntos a esta propuesta.",
    captureSignature: "Capturar firma",
    discount: "Descuento",
    email: "Correo electrónico",
    confirm: "Confirme",
    pay: "Pagar",
    paymentAmount: "Importe del pago",
    creditCard: "Tarjeta de Crédito",
    bankTransfer: "Transferencia bancaria",
    cash: "Efectivo",
    check: "Cheque",
    other: "Otro",
    referenceInformation: "Información de referencia",
    name: "Nombre",
    voidAcceptanceWarning:
      "Editar este presupuesto anulará la aceptación capturada¿ Está seguro deque desea continuar?",
    messageSent: "Mensaje enviado",
    addEmailAddress: "Anadar direccion de correo electrónico",
    taxes: "Impuestos",
    unsavedChangesWarning:
      "Tienes cambios sin guardar. ¿Seguo que quieres salir de esta página?",
    unitPrice: "Precio unitario",
    unlock: "Debloquar",
    bankAccountType: "Tipo de cuenta bancaria",
    routingNumber: "Número de ruta",
    accountNumber: "Número de cuenta",
    confirmAccountNumber: "Confirmar nú de cuenta",
    selectBankAccountType: "Seleccione el tipo de cuenta bancaria",
    businessChecking: "Comprobación comercial",
    personalChecking: "Banca personal",
    accountNumbersDoNotMatch: "Los números de cuenta no coinciden",
    accountNumberExampleLabel: "Número de cuenta",
    routingNumberExampleLabel: "Número de ruta",
    accepted: "Aceptada",
    on: "el",
    invoicePaid: "Factura pagada!",
    voidInvoiceWarning:
      "La edicion de esta factura anulará la firma capturada. ¿Está seguro de que desea continuar?",
    viewJobHistory: "Ver historial de empleo",
    jobHistory: "Historial Laboral",
    files: "Archivos",
    jobNotes: "Notas de trajabo",
    crewNotes: "Notas de la tripulación",
    viewFullImage: "Ver imagen completa",
    estimateDate: "Fecha de la estimado",
    validUntil: "Válido hasta el",
    deleteFile: "Borrar archivo?",
    attachments: "Archivos adjuntos",
    newLineItem: "Nueva partida",
    editLineItem: "Editar partida",
    formIsNotValid: "El formulario no es válido!",
    showFieldToFix: "Mostrar campo para corregir.",
    wouldYouLikeToApplyEstimateToCurrentJob:
      "¿Te gustaría aplicar esta estimación al trabajo actual?",
    yesApplyToCurrentJob: "Sí, aplicar al trabajo actual.",
    noWorkWillBeDoneLater: "No, el trabajo se realizará más tarde.",
    deleteLineItem: "¿Borrar partida?",
    pleaseAddLineItem: "Agregue una línea de pedido",
    noLineItemsSet: "No se han establecido elementos de línea",
    notifyOnTheWay: "En camino",
    sendOnTheWayNotification: "Avisar en el camino",
    send: "Enviar",
    myName: "Mi nombre",
    myPhoneNumber: "Mi número de teléfono",
    arrivalTime: "Hora de llegada",
    none: "No especificado",
    dueDate: "Fecha de vencimiento:",
    edit: "Editar",
    customerIsTaxExempt: "El cliente está exento de impuestos",
    add: "Agregar",
    update: "Actualizar",
    optional: "Opcional",
    included: "Incluido",
    notIncluded: "No incluido",
    copyJobFiles: "Copiar archivos de trabajo",
    print: "Imprimir",
    text: "Texto",
    customerPhoneNumber: "Número de teléfono del cliente",
    customerPhoneNumberOptedIn:
      "El propietario de este número de teléfono ha optado por recibir mensajes de texto y ha sido informado:",
    messageDataRatesMayApply: "Se pueden aplicar tarifas por mensajes y datos",
    messagesMaySendEveryDay: "Se pueden enviar mensajes todos los días",
    canReplyWithHelpStop:
      "Puede responder mensajes con AYUDA para obtener ayuda o DETENER para cancelar",
    thisJobHasNotBeenCompleted: "¡Este trabajo no ha sido completado!",
    invoicingJobNotCompleted:
      "Estás facturando un trabajo que no se ha completado.",
    wouldYouLikeToCompleteIt: "¿Quieres completarlo ahora?",
    errorCompletingJob:
      "Se produjo un error al completar el trabajo. Inténtalo de nuevo.",
    jobCannotBeCompletedUntilFollowingItemsDone:
      "El trabajo no se puede completar hasta que se realicen los siguientes elementos:",
    yesJobCompleted: "Sí, el trabajo está completo.",
    skip: "¿Omitir?",
    images: "Imágenes",
    logout: "Cerrar sesión",
    initialDeposit: "Depósito inicial",
    balanceDueAtCompletion: "Saldo adeudado al finalizar",
    yes: "Sí",
    areYouSureYouWantToLogout: "¿Estás seguro de que quieres cerrar sesión?",
    worksheets: "Hojas de trabajo",
    ok: "De acuerdo",
    worksheetCannotBeCompleted: "La hoja de trabajo no se puede completar",
  },
});

export function saveLanguage(language: Language) {
  setSetting(SettingsType.language, language);
}

export function getDefaultLanguage() {
  const savedItem = getSetting(SettingsType.language);
  if (savedItem === "en" || savedItem === "es") {
    return savedItem;
  } else {
    return getBrowserLanguage();
  }
}

export function getBrowserLanguage() {
  if (window.navigator.language.startsWith("es")) {
    return "es";
  } else {
    return "en";
  }
}
