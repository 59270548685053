import React from "react";
import { Link } from "react-router-dom";
import { strings } from "../../../languages";

interface IProps {
  imagePrefix: string;
  linkPath: string;
  signatureImagePath: string | null;
  signatureExplanation: string;
  disabled?: boolean;
}

const CustomerSignature: React.FunctionComponent<IProps> = ({
  imagePrefix,
  linkPath,
  signatureImagePath,
  disabled,
  signatureExplanation,
}) => {
  return (
    <div>
      <React.Fragment>
        <div className="text-center">
          <div className="d-flex justify-content-center font-weight-light">
            <div style={{ maxWidth: "350px" }}>{signatureExplanation}</div>
          </div>
          {!!signatureImagePath ? (
            <div className="text-center" style={{ overflow: "hidden" }}>
              <img
                src={`${imagePrefix}/${signatureImagePath}`}
                alt="Signature"
                data-testid="signature"
              />
            </div>
          ) : (
            <div className="mb-2">
              <Link to={linkPath}>
                <button
                  className="btn btn-secondary btn-lg"
                  type="button"
                  style={{ marginTop: "10px" }}
                  disabled={disabled}
                >
                  {strings.captureSignature}
                </button>
              </Link>
            </div>
          )}
        </div>
      </React.Fragment>
    </div>
  );
};

export default CustomerSignature;
