import { strings } from "../../../languages";

export function ReferenceInformationField({
  referenceInformation,
  setReferenceInformation,
  idPrefix,
}: {
  referenceInformation: string;
  setReferenceInformation: (newValue: string) => void;
  idPrefix: string;
}) {
  const elementId = `${idPrefix}ReferenceInformation`;
  const testId = `${idPrefix}ReferenceInformation`;
  return (
    <div className="form-group">
      <label htmlFor={elementId}>{strings.referenceInformation}</label>
      <input
        id={elementId}
        type="text"
        className="form-control fs-mask"
        maxLength={250}
        value={referenceInformation}
        onChange={(e) => setReferenceInformation(e.currentTarget.value)}
        data-testid={testId}
        autoComplete="off"
      />
    </div>
  );
}
