import LinkButton from "../../../components/LinkButton";
import { strings } from "../../../languages";
import { FloatingAlert } from "./FloatingAlert";

export function BillableFormFormNotValid({
  showErrors,
}: {
  showErrors(): void;
}) {
  return (
    <FloatingAlert
      type="danger"
      testId="requiredFieldsNotSetForSaveAlert"
      allowWrap
    >
      {strings.formIsNotValid}{" "}
      <LinkButton
        text={strings.showFieldToFix}
        testId="billableFormSaveRetry"
        additionalClassNames="text-dark"
        style={{ verticalAlign: "baseline" }}
        onClick={() => showErrors()}
      />
    </FloatingAlert>
  );
}
