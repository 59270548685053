import { useState, useEffect } from "react";
import { useScheduleJobCache } from "../../../hooks/useScheduleJobCache";
import { getErrorMessageFromError } from "../../../libraries/errorParsing/getErrorMessageFromError";
import ISchedule from "../../../models/ISchedule";
import { IScheduleIdentifier } from "../../../models/IScheduleIdentifier";
import { ErrorResponse } from "../../../libraries/billableForm/types/ErrorResponse";
import { getOrCreateEstimateForPresentationForm } from "../services/estimateDataProvider";
import { IEstimateForPresentation } from "../models/IEstimateForPresentation";
import { IInvoiceForPaymentForm } from "../../../libraries/invoicePaymentForm/models/IInvoiceForPaymentForm";

export function useLoadEstimateForPresentationPage({
  schedule,
  scheduleIdentifier,
  scheduleJobId,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  scheduleJobId: string;
  schedule: ISchedule;
}) {
  const { scheduleJob } = useScheduleJobCache(schedule, scheduleJobId ?? "");
  const [loading, setLoading] = useState(false);
  const [estimate, setEstimate] = useState<IEstimateForPresentation | null>(
    null
  );
  const [depositInvoice, setDepositInvoice] =
    useState<IInvoiceForPaymentForm | null>(null);
  const [loadError, setLoadError] = useState<ErrorResponse | null>(null);
  const [crewMemberSelectedJobToApplyTo, setCrewMemberSelectedJobToApplyTo] =
    useState(false);
  const [canEmail, setCanEmail] = useState(false);
  const [canText, setCanText] = useState(false);

  useEffect(() => {
    loadEstimateForPresentationPage({
      setLoading,
      setLoadError,
      linkedDayScheduleId: scheduleIdentifier.linkedDayScheduleId,
      dayOffset: scheduleIdentifier.dayOffset,
      techAppCrewId: scheduleIdentifier.techAppCrewId,
      scheduleJobId,
      setEstimate,
      setDepositInvoice,
      setCrewMemberSelectedJobToApplyTo,
      setCanEmail,
      setCanText,
    });
  }, [
    scheduleIdentifier.dayOffset,
    scheduleIdentifier.linkedDayScheduleId,
    scheduleIdentifier.techAppCrewId,
    scheduleJobId,
  ]);

  return {
    loading,
    estimate,
    depositInvoice,
    setDepositInvoice,
    loadError,
    scheduleJob,
    crewMemberSelectedJobToApplyTo,
    setEstimate,
    setLoading,
    setLoadError,
    setCrewMemberSelectedJobToApplyTo,
    canEmail,
    canText,
    setCanEmail,
    setCanText,
  };
}

export function loadEstimateForPresentationPage({
  setLoading,
  setLoadError,
  linkedDayScheduleId,
  dayOffset,
  techAppCrewId,
  scheduleJobId,
  setEstimate,
  setDepositInvoice,
  setCrewMemberSelectedJobToApplyTo,
  setCanEmail,
  setCanText,
}: {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setLoadError: React.Dispatch<React.SetStateAction<ErrorResponse | null>>;
  linkedDayScheduleId: string;
  dayOffset: number;
  techAppCrewId: string | null;
  scheduleJobId: string;
  setEstimate: React.Dispatch<
    React.SetStateAction<IEstimateForPresentation | null>
  >;
  setDepositInvoice: React.Dispatch<
    React.SetStateAction<IInvoiceForPaymentForm | null>
  >;
  setCrewMemberSelectedJobToApplyTo: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setCanEmail: React.Dispatch<React.SetStateAction<boolean>>;
  setCanText: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  setLoading(true);
  setLoadError(null);
  getOrCreateEstimateForPresentationForm({
    scheduleIdentifier: {
      linkedDayScheduleId: linkedDayScheduleId,
      dayOffset: dayOffset,
      techAppCrewId,
    },
    jobInstanceId: scheduleJobId,
  }).subscribe({
    next: (result) => {
      setLoading(false);
      setEstimate(result.proposal);
      setDepositInvoice(result.depositInvoice);
      setCrewMemberSelectedJobToApplyTo(result.crewMemberSelectedJobToApplyTo);
      setCanEmail(result.canEmail);
      setCanText(result.canText);
    },

    error: (err) => {
      setLoading(false);

      const allowRetry = err.status === 400;
      setLoadError({
        was400Error: allowRetry,
        message: getErrorMessageFromError(
          err,
          "Please check your Internet connection and try again."
        ),
      });
    },
  });
}
