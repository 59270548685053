import { formatCurrency } from "../../../services/currencyFormatter";
import { DiscountType } from "../enums/DiscountType";
import { getSubtotal } from "../../amountCalculation/getSubtotal";
import { getTotal } from "../../amountCalculation/getTotal";
import CreditAmountField from "../../input/CreditAmountField";
import PercentField from "../../input/PercentField";
import { IDiscount } from "../types/IDiscount";
import { ILineItem } from "./BillableFormLineItem";
import { strings } from "../../../languages";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Popover from "reactstrap/lib/Popover";
import PopoverBody from "reactstrap/lib/PopoverBody";

interface IDiscountFields {
  discount: IDiscount;
  onChange: (newDiscount: IDiscount) => void;
  amountOnly?: boolean;
}

interface IDepositAmountFields {
  maxDepositCreditAmount: number | null;
  depositCreditAmount: number | null;
  onDepositCreditAmountChange: (newAmount: number) => void;
  alwaysShowDepositCredits?: boolean;
}

interface IProps {
  lineItems: Array<ILineItem>;
  discountFields?: IDiscountFields;

  taxRate: number | null;
  onTaxRateChange: (newValue: number | null) => void;

  alwaysShowTaxRate?: boolean;
  depositAmountFields?: IDepositAmountFields;

  disabled?: boolean;
  requireTaxRate?: boolean;
  taxRateDisabled: boolean;
}

const BillableFormAmounts: React.FunctionComponent<IProps> = ({
  lineItems,
  taxRate,
  onTaxRateChange,
  discountFields,
  alwaysShowTaxRate,
  depositAmountFields,
  disabled,
  requireTaxRate,
  taxRateDisabled,
}) => {
  const isQuickBooksEnabled = false;

  const isDepositAmountAvailable =
    (depositAmountFields?.maxDepositCreditAmount ?? 0) > 0 ||
    (depositAmountFields?.depositCreditAmount ?? 0) > 0 ||
    depositAmountFields?.alwaysShowDepositCredits;

  const subtotal = getSubtotal(lineItems);
  return (
    <>
      <div className="form-row align-items-baseline">
        <div className="col-9 text-right">
          <label htmlFor="subtotal">{strings.subtotal}</label>
        </div>
        <div className="col-3 text-right" data-testid="subtotal">
          {formatCurrency(subtotal)}
        </div>
      </div>

      {discountFields ? (
        <div className="form-row form-group align-items-baseline">
          <div className="col-xl-7 col-lg-6 col-md-4"></div>
          <div
            className={`col-xl-2 col-lg-3 col-md-5 text-md-right ${
              !discountFields.amountOnly ? "mb-3 mb-md-0" : "mb-2"
            }`}
          >
            {!discountFields.amountOnly ? (
              <select
                className="form-control"
                aria-label="Discount type"
                data-testid="discountType"
                value={(discountFields.discount.type ?? 0).toString()}
                onChange={(e) => {
                  discountFields.onChange({
                    ...discountFields.discount,
                    type: parseInt(e.currentTarget.value) as DiscountType,
                  });
                }}
                disabled={disabled}
              >
                <option value={DiscountType.percent.toString()}>
                  {strings.discountPercent}
                </option>
                <option value={DiscountType.amount.toString()}>
                  {strings.discountValue}
                </option>
              </select>
            ) : (
              <label htmlFor="discountAmount" className="mb-0">
                {strings.discountValue}
              </label>
            )}
          </div>
          <div className={`col-md-3`}>
            {discountFields.discount.type === DiscountType.amount ||
            discountFields.amountOnly ? (
              <CreditAmountField
                id="discountAmount"
                ariaLabel="Discount amount"
                testId="discountAmount"
                max={subtotal}
                onChange={(e) =>
                  discountFields.onChange({
                    ...discountFields.discount,
                    amount: e ?? 0,
                  })
                }
                value={discountFields.discount.amount}
                disabled={disabled}
              />
            ) : (
              <PercentField
                ariaLabel={strings.discountPercent}
                testId="discountPercent"
                id="discount"
                value={discountFields.discount.percent}
                onChange={(newValue) =>
                  discountFields.onChange({
                    ...discountFields.discount,
                    percent: newValue,
                  })
                }
                decimalPlacesAllowed={0}
                disabled={disabled}
              />
            )}
          </div>
        </div>
      ) : null}

      {!isQuickBooksEnabled || alwaysShowTaxRate ? (
        <>
          <div className="form-row align-items-baseline">
            <div className="col-md-9 text-left text-md-right">
              <label htmlFor="taxRate">
                {strings.taxRate}
                {taxRateDisabled ? (
                  <InfoToolTip
                    text={strings.customerIsTaxExempt}
                    id={"customerTaxExempt"}
                    testId="customerTaxExemptInfoIcon"
                  />
                ) : null}
              </label>
            </div>
            <div className="form-group col-md-3">
              <PercentField
                id="taxRate"
                value={taxRate}
                onChange={(newValue) => onTaxRateChange(newValue)}
                decimalPlacesAllowed={3}
                disabled={disabled || taxRateDisabled}
                testId="taxRate"
                required={requireTaxRate}
                min={requireTaxRate ? 0.001 : undefined}
              />
            </div>
          </div>

          <div className="form-row align-items-baseline">
            <div className="col-9 text-right">
              <label htmlFor="total">
                {isDepositAmountAvailable ? "Total after taxes" : "Total"}
              </label>
            </div>
            <div className="col-3 text-right" data-testid="total">
              {formatCurrency(
                getTotal({
                  taxRate,
                  lineItems,
                  discount: discountFields?.discount,
                })
              )}
            </div>
          </div>
        </>
      ) : null}
      {isDepositAmountAvailable ? (
        <>
          <div className="form-row align-items-baseline">
            <div className="col-md-9 text-left text-md-right">
              <label htmlFor="depositCreditAmount">Prepaid deposit</label>
            </div>
            <div className="form-group col-md-3">
              <CreditAmountField
                id="depositCreditAmount"
                ariaLabel="Deposit credit amount"
                max={depositAmountFields?.maxDepositCreditAmount ?? null}
                onChange={(e) =>
                  depositAmountFields?.onDepositCreditAmountChange(e ?? 0)
                }
                value={depositAmountFields?.depositCreditAmount ?? 0}
                disabled={disabled}
              />
            </div>
          </div>

          {!isQuickBooksEnabled || alwaysShowTaxRate ? (
            <div className="form-row align-items-baseline">
              <div className="col-9 text-right">
                <label htmlFor="balance">Balance due</label>
              </div>
              <div className="col-3 text-right" data-testid="balance">
                {formatCurrency(
                  getTotal(
                    {
                      taxRate,
                      lineItems,
                      discount: discountFields?.discount,
                      depositCreditAmount:
                        depositAmountFields?.depositCreditAmount,
                    } ?? 0
                  )
                )}
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default BillableFormAmounts;

function InfoToolTip({
  id,
  text,
  title,
  testId,
}: {
  id: string;
  text: string | React.ReactNode;
  title?: string;
  testId?: string;
}) {
  const [show, setShow] = useState(false);

  return (
    <>
      <FontAwesomeIcon
        data-testid={testId}
        icon={faInfoCircle}
        style={{ marginLeft: "5px", cursor: "pointer" }}
        id={id}
        title={title}
        onClick={(e) => {
          e.stopPropagation();
          setShow(!show);
        }}
      />
      <Popover
        delay={0}
        // fade={false}
        trigger="legacy"
        placement="bottom"
        isOpen={show}
        target={id}
        toggle={() => {
          setShow(!show);
        }}
      >
        <PopoverBody>{text}</PopoverBody>
      </Popover>
    </>
  );
}
