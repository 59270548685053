import { getErrorMessageFromError } from "./getErrorMessageFromError";

export function getErrorMessagesFromError(
  err: any,
  defaultErrorMessage?: string
): Array<string> {
  if (Array.isArray(err?.response)) {
    const errorMessages = err.response as Array<any>;
    return errorMessages.map((e) => e.message);
  } else {
    return [getErrorMessageFromError(err, defaultErrorMessage)];
  }
}
