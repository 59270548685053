import { useCallback } from "react";
import { UseFormGetValues } from "react-hook-form";
import { IScheduleIdentifier } from "../../../../models/IScheduleIdentifier";
import {
  UpdateInvoicePayload,
  updateInvoiceForForm,
} from "../../services/invoiceDataProvider";
import { IInvoiceFormData } from "./IInvoiceFormData";
import { IInvoiceItem } from "../../../../libraries/billableForm/types/IInvoiceItem";
import { useBillableItemFormSave } from "../../../../libraries/billableForm/hooks/useBillableItemFormSave";
import { mapLineItemForSave } from "../../../../libraries/billableForm/functions/mapLineItemForSave";

export function useInvoiceFormSave({
  scheduleIdentifier,
  jobInstanceId,
  getValues,
  formRef,
  isInvoicePaidWithAmount,
  invoiceItems,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
  getValues: UseFormGetValues<IInvoiceFormData>;
  formRef: React.RefObject<HTMLFormElement>;
  isInvoicePaidWithAmount: boolean;
  invoiceItems: Array<IInvoiceItem>;
}) {
  const mapFormDataToPayload = useCallback(
    (values: IInvoiceFormData) => {
      return {
        customerEmailAddresses: values.emails.map((e) => ({
          id: e.id,
          emailAddress: e.value,
        })),
        discount: values.amountAdjustments.discount,
        taxRate: values.amountAdjustments.taxRate,
        summary: values.summary,
        files: values.files,
        lineItems: values.lineItems.map((l) =>
          mapLineItemForSave(l, invoiceItems)
        ),
        recipientPhoneNumber: values.phoneNumberWithOptInStatus.phoneNumber,
        recipientPhoneNumberOptedIntoSms:
          values.phoneNumberWithOptInStatus.optedIn,
        depositCreditAmount: values.amountAdjustments.depositCreditAmount,
      } as UpdateInvoicePayload;
    },
    [invoiceItems]
  );

  return useBillableItemFormSave<IInvoiceFormData, UpdateInvoicePayload>({
    scheduleIdentifier,
    jobInstanceId,
    getValues,
    formRef,
    invoiceItems,
    save: updateInvoiceForForm,
    mapFormDataToPayload,
    shouldFailTriggerSave: () => (isInvoicePaidWithAmount ? true : false),
  });
}
