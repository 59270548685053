import homepage_log_sm from "../../../homepage_logo_sm.png";

export function AppContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="bg-secondary full-height">
      <div className="container">
        <div className="py-1"></div>
        <div className="d-flex justify-content-center my-4">
          <img src={homepage_log_sm} alt="Crew Control logo" />
        </div>
        <div className="bg-white py-3 px-4">{children}</div>
      </div>
    </div>
  );
}
