// Disable rule since bootstrap docs have the href missing for tabs
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { getParsedPaymentType, PaymentType } from "../enums/paymentType";
import { strings } from "../../../languages";

interface IProps {
  creditCardElement: React.ReactNode;
  bankAccountElement: React.ReactNode;
  offlinePaymentElement: React.ReactNode;
  allowAch: boolean;
  allowCreditCard: boolean;
  paymentType: string;
  onPaymentTypeChanged(newValue: string): void;
}

const PaymentMethodSelection: React.FunctionComponent<IProps> = ({
  creditCardElement,
  bankAccountElement,
  offlinePaymentElement,
  allowAch,
  allowCreditCard,
  paymentType,
  onPaymentTypeChanged,
}) => {
  useEffect(() => {
    if (allowAch && !allowCreditCard) {
      onPaymentTypeChanged(PaymentType.bankTransfer.toString());
    } else if (!allowAch && !allowCreditCard) {
      // Even if CC not allowed, if no options are allowed, allow CC for backwards compatibility
      onPaymentTypeChanged(PaymentType.check.toString());
    }
  }, [allowAch, allowCreditCard, onPaymentTypeChanged]);

  return (
    <>
      <div className="form-group mt-3">
        <select
          aria-label="Payment method"
          placeholder="Select payment method"
          value={paymentType}
          className="form-control"
          onChange={(e) => onPaymentTypeChanged(e.currentTarget.value)}
          data-testid="paymentMethodSelection"
        >
          {allowCreditCard ? (
            <option value={PaymentType.creditCard.toString()}>
              {strings.creditCard}
            </option>
          ) : null}
          {allowAch ? (
            <option value={PaymentType.bankTransfer.toString()}>
              {strings.bankTransfer}
            </option>
          ) : null}
          <option value={PaymentType.cash.toString()}>{strings.cash}</option>
          <option value={PaymentType.check.toString()}>{strings.check}</option>
          <option value={PaymentType.other.toString()}>{strings.other}</option>
        </select>
      </div>

      {allowCreditCard ? (
        <div
          style={{
            display:
              getParsedPaymentType(paymentType) === PaymentType.creditCard
                ? undefined
                : "none",
          }}
        >
          {creditCardElement}
        </div>
      ) : null}

      {allowAch ? (
        <div
          style={{
            display:
              getParsedPaymentType(paymentType) === PaymentType.bankTransfer
                ? undefined
                : "none",
          }}
        >
          {bankAccountElement}
        </div>
      ) : null}

      <div
        style={{
          display: isOfflinePayment(getParsedPaymentType(paymentType))
            ? undefined
            : "none",
        }}
      >
        {offlinePaymentElement}
      </div>
    </>
  );
};

export default PaymentMethodSelection;

function isOfflinePayment(paymentType: PaymentType) {
  return (
    paymentType === PaymentType.cash ||
    paymentType === PaymentType.check ||
    paymentType === PaymentType.other
  );
}
