import ISchedule from "../../../models/ISchedule";
import IScheduledJob from "../../../models/IScheduleJob";

export function getPageHeader({
  schedule,
  scheduleJob,
  pageTitle,
}: {
  schedule: ISchedule;
  scheduleJob: IScheduledJob;
  pageTitle: string;
}) {
  const headerOverride =
    (!schedule.hideCustomerNameOnCrewView
      ? scheduleJob.name
      : scheduleJob.address) + `\n${pageTitle}`;

  return headerOverride.trim();
}
