import React, { useEffect, useState } from "react";
import { routingBuilders } from "../../../services/routing";
import { GenericFooter } from "../../../components/JobDetails/GenericFooter";
import { IScheduleIdentifier } from "../../../models/IScheduleIdentifier";
import Container from "../../../components/Container";
import { getPageHeader } from "../../../libraries/commonUi/functions/getPageHeader";
import { useScheduleJobCache } from "../../../hooks/useScheduleJobCache";
import ISchedule from "../../../models/ISchedule";
import {
  ICustomerHistoryJob,
  getCustomerJobHistory,
} from "../services/scheduleDataProvider";
import { timeout } from "rxjs/operators";
import Spinner from "../../../components/Spinner";
import { PageErrorLoading } from "../../../libraries/commonUi/components/PageErrorLoading";
import { CustomerJobHistoryRow } from "./CustomerJobHistoryRow";
import { strings } from "../../../languages";
import { JobMovedErrorAlert } from "../../../components/JobMovedErrorAlert";

interface IProps {
  scheduleJobId: string;
  scheduleIdentifier: IScheduleIdentifier;
  schedule: ISchedule;
  filePrefix: string;
}

export const CustomerJobHistory: React.FunctionComponent<IProps> = ({
  schedule,
  scheduleJobId,
  scheduleIdentifier,
  filePrefix,
}) => {
  const { scheduleJob } = useScheduleJobCache(schedule, scheduleJobId ?? "");
  const [jobHistory, setJobHistory] =
    useState<Array<ICustomerHistoryJob> | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    loadData({
      setLoading,
      setError,
      scheduleIdentifier,
      scheduleJobId,
      setJobHistory,
    });
  }, [scheduleIdentifier, scheduleJobId]);

  if (!scheduleJob) {
    return <JobMovedErrorAlert scheduleIdentifier={scheduleIdentifier} />;
  }

  return (
    <Container
      isJobListing={false}
      schedule={schedule}
      headerOverride={getPageHeader({
        schedule,
        scheduleJob,
        pageTitle: strings.jobHistory,
      })}
      scheduleIdentifier={scheduleIdentifier}
    >
      <div className="container mt-3 px-0" style={{ marginBottom: "100px" }}>
        {loading ? <Spinner /> : null}

        {error ? (
          <PageErrorLoading
            headerOverride="Job History Could Not Load"
            retryLoad={() => {
              loadData({
                setLoading,
                setError,
                scheduleIdentifier,
                scheduleJobId,
                setJobHistory,
              });
            }}
          />
        ) : null}

        {jobHistory !== null ? (
          <>
            {jobHistory.length === 0 ? (
              <div
                className="alert alert-info"
                data-testid="noPreviousJobsAlert"
              >
                There's no job history for this customer location.
              </div>
            ) : null}

            {jobHistory.map((h) => (
              <CustomerJobHistoryRow
                key={h.id}
                jobHistoryRecord={h}
                filePrefix={filePrefix}
              />
            ))}
          </>
        ) : null}
      </div>

      <GenericFooter
        backLink={routingBuilders.buildScheduleJobPath({
          scheduleIdentifier,
          scheduleJobId: scheduleJobId,
        })}
      />
    </Container>
  );
};

function loadData({
  setLoading,
  setError,
  scheduleIdentifier,
  scheduleJobId,
  setJobHistory,
}: {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  scheduleIdentifier: IScheduleIdentifier;
  scheduleJobId: string;
  setJobHistory: React.Dispatch<
    React.SetStateAction<ICustomerHistoryJob[] | null>
  >;
}) {
  setLoading(true);
  setError(false);
  getCustomerJobHistory({
    scheduleIdentifier,
    jobInstanceId: scheduleJobId,
  })
    .pipe(timeout(10000))
    .subscribe({
      next: (result) => {
        setJobHistory(result);
        setLoading(false);
      },

      error: () => {
        setLoading(false);
        setError(true);
      },
    });
}
