import * as React from "react";
import IScheduledJob from "../../models/IScheduleJob";
import { strings } from "../../languages";
import {
  faCheckCircle,
  faPlayCircle,
  faStopCircle,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { FooterButton } from "../FooterButton";
import { IconWithCaption } from "../IconWithCaption";

interface IProps {
  scheduleJob: IScheduledJob;
  endClock(): void;
  startClock(): void;
  onCompletedButtonClick(): void;
}

const StartStopButtons: React.SFC<IProps> = ({
  startClock,
  endClock,
  onCompletedButtonClick,
  scheduleJob,
}) => {
  return (
    <React.Fragment>
      {scheduleJob.completed ? (
        <FooterButton
          color="text-success"
          icon={faCheckCircle}
          caption={strings.complete}
          onClick={onCompletedButtonClick}
          testId="completeConfirmation"
        />
      ) : scheduleJob.skipped ? (
        <div className="text-warning">
          <IconWithCaption icon={faX} caption={strings.skipped} />
        </div>
      ) : (
        <React.Fragment>
          {scheduleJob.timeRanges.filter((tr) => tr.startTime && !tr.endTime)
            .length === 0 ? (
            <FooterButton
              color="text-success"
              icon={faPlayCircle}
              caption={
                scheduleJob.timeRanges.filter(
                  (tr) => tr.startTime && tr.endTime
                ).length > 0
                  ? strings.resume
                  : strings.start
              }
              onClick={startClock}
            />
          ) : (
            <FooterButton
              color="text-danger"
              icon={faStopCircle}
              caption={strings.stop}
              onClick={endClock}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default StartStopButtons;
