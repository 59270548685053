import IScheduledJob from "../models/IScheduleJob";
import { IGetEstimatedJobTimesResponse } from "../models/IGetEstimatedJobTimesResponse";
import { parseTime } from "./dateTimeService";
import addSeconds from "date-fns/addSeconds";
import { IJobTimeEstimates } from "../models/IGetEstimatedJobTimesResponseJobInstance";
import { differenceInSeconds } from "date-fns";
import { strings } from "../languages";

export function getEstimatedTimeForJob(scheduleJob: IScheduledJob) {
  if (typeof scheduleJob.estimatedManHours !== "number") {
    return "";
  }

  let actualCrewMembers = scheduleJob.actualCrewMembers
    ? scheduleJob.actualCrewMembers
    : 1;
  const estimatedTime =
    (scheduleJob.estimatedManHours * 60) / actualCrewMembers;

  return getTimeWorked(estimatedTime);
}

function getTimeWorked(input: number) {
  if (input < 60) {
    return strings.formatString(strings.minutes, Math.round(input));
  }

  const hours = Math.floor(input / 60);
  const remainingMinutes = input - hours * 60;

  if (remainingMinutes === 0) {
    return strings.formatString(strings.hours, Math.round(hours));
  }

  return strings.formatString(
    strings.hoursAndMinutes,
    Math.round(hours),
    Math.round(remainingMinutes)
  );
}

export function getEstimatedStartAndEndTimes(
  showCrewEstimatedTimes: boolean,
  typicalStartTime: string | null,
  typicalLunchStartTime: string | null,
  typicalLunchEndTime: string | null,
  scheduledJobs: Array<IScheduledJob>,
  estimatedJobTimes: IGetEstimatedJobTimesResponse | null
) {
  let allFieldsSetOnJobs = scheduledJobs.reduce((allFieldsSet, jobInstance) => {
    return (
      allFieldsSet &&
      !!jobInstance.estimatedManHours &&
      !!jobInstance.actualCrewMembers &&
      !!estimatedJobTimes &&
      estimatedJobTimes.jobInstances.some((ji) => jobInstance.id === ji.id)
    );
  }, true);

  if (
    showCrewEstimatedTimes &&
    typicalStartTime &&
    estimatedJobTimes &&
    allFieldsSetOnJobs
  ) {
    let parsedStartTime = parseTime(typicalStartTime);
    let lunchBreakApplied = false;
    return scheduledJobs.map((ji) => {
      let driveTimeInSeconds = 0;
      const est = estimatedJobTimes.jobInstances.find((t) => t.id === ji.id);

      if (est && ji.estimatedManHours && ji.actualCrewMembers) {
        driveTimeInSeconds = est.driveTimeInSeconds;

        const estimatedTime =
          (ji.estimatedManHours * 3600) / ji.actualCrewMembers;

        let jobStartTime = addSeconds(parsedStartTime, driveTimeInSeconds);

        if (
          isLunchBreakDefined(typicalLunchStartTime, typicalLunchEndTime) &&
          !lunchBreakApplied
        ) {
          const typicalLunchStartTimeParsed = parseTime(
            typicalLunchStartTime as string
          );
          const typicalLunchEndTimeParsed = parseTime(
            typicalLunchEndTime as string
          );

          if (jobStartTime >= typicalLunchStartTimeParsed) {
            const lunchBreakDurationInSeconds = differenceInSeconds(
              typicalLunchEndTimeParsed,
              typicalLunchStartTimeParsed
            );
            jobStartTime = addSeconds(
              jobStartTime,
              lunchBreakDurationInSeconds
            );
            lunchBreakApplied = true;
          }
        }

        const jobEndTime = addSeconds(jobStartTime, estimatedTime);

        parsedStartTime = jobEndTime;

        return {
          id: ji.id,
          startTime: jobStartTime,
          endTime: jobEndTime,
        } as IJobTimeEstimates;
      }
      return {
        id: ji.id,
        startTime: null,
        endTime: null,
      };
    });
  } else {
    return [];
  }
}

function isLunchBreakDefined(
  typicalLunchStartTime: string | null,
  typicalLunchEndTime: string | null
) {
  return !!typicalLunchStartTime && !!typicalLunchEndTime;
}
