import * as React from "react";

export type Type = "alert-success" | "alert-danger" | null;

interface IProps {
  type: Type;
  message: JSX.Element;
  onClose: () => void;
}

const Alert: React.FunctionComponent<IProps> = ({ type, message, onClose }) => {
  return (
    <div
      className={"alert " + type}
      role="alert"
      style={{ position: "sticky", top: "10px", left: "0px", zIndex: 100 }}
    >
      <button type="button" className="close" onClick={onClose}>
        &times;
      </button>
      {message}
    </div>
  );
};

export default Alert;
