import React, { useEffect, useState } from "react";
import { IPhotoGetResponses } from "../models/IPhotoGetResponses";
import dataProvider from "../services/DataProvider";
import { getFileComponents } from "../libraries/formAttachments/services/fileService";
import Spinner from "./Spinner";

interface IProps {
  scheduleJobId: string;
  photoId: string;
}

const FullScreenPhoto: React.FunctionComponent<IProps> = ({
  scheduleJobId,
  photoId,
}) => {
  const [photo, setPhoto] = useState<IPhotoGetResponses | null>(null);

  const [loadErrorMessage, setLoadErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    const subscription = dataProvider
      .getPhoto(scheduleJobId, photoId)
      .subscribe(
        (p) => setPhoto(p),

        (error) => {
          if (typeof error?.response === "string") {
            setLoadErrorMessage(error.response);
          } else {
            setLoadErrorMessage(
              "An unknown error occurred loading this photo. Please reload this page to try again."
            );
          }
        }
      );

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, [scheduleJobId, photoId, setPhoto]);

  if (!photo && !loadErrorMessage) {
    return <Spinner />;
  }

  if (loadErrorMessage || !photo) {
    return (
      <div className="">
        <div className="container pt-5">
          <div className="jumbotron bg-light">
            <h1 className="display-4">Unable to Load File</h1>
            <p className="lead">{loadErrorMessage}</p>
          </div>
        </div>
      </div>
    );
  }

  const fileComponents = getFileComponents(photo.imagePath);
  if (!fileComponents.success) {
    console.error("unable to get photo components");
    return null;
  }

  return (
    <img
      alt={photo.caption}
      src={`${photo.imagePrefix}/${fileComponents.linkUrl}`}
    ></img>
  );
};

export default FullScreenPhoto;
