import React, { useState } from "react";
import NewFile from "./Files/NewFile";
import StartStopButtons from "./StartStopButtons";
import IScheduledJob from "../../models/IScheduleJob";
import { IPendingFile } from "./Files/Index";
import { GenericFooter } from "./GenericFooter";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard, faFilePen } from "@fortawesome/free-solid-svg-icons";
import { routingBuilders } from "../../services/routing";
import { strings } from "../../languages";
import { IScheduleIdentifier } from "../../models/IScheduleIdentifier";
import ISchedule from "../../models/ISchedule";
import { fullStoryTrack } from "../../services/fullStoryService";
import { Modal, Popover, PopoverBody } from "reactstrap/lib";
import { ModalBody, ModalFooter } from "reactstrap";
import dataProvider from "../../services/DataProvider";
import Spinner from "../Spinner";
import {
  CompleteJobErrorRequiredItems,
  IUncompletedItem,
} from "./CompleteJobErrorRequiredItems";

interface IProps {
  onFileAdded: (photo: Array<IPendingFile>) => void;
  scheduleJob: IScheduledJob;
  scheduleIdentifier: IScheduleIdentifier;
  endClock(): void;
  startClock(): void;
  onCompletedButtonClick(): void;
  backLink: string;
  schedule: ISchedule;
  onScheduleJobUpdated(
    scheduleJobId: string,
    fieldsToUpdate: Partial<IScheduledJob>
  ): void;
}

export const Footer: React.FunctionComponent<IProps> = ({
  onFileAdded,
  endClock,
  onCompletedButtonClick,
  scheduleJob,
  scheduleIdentifier,
  startClock,
  backLink,
  schedule,
  onScheduleJobUpdated,
}) => {
  const [showEstimatePopover, setShowEstimatePopover] = useState(false);
  const [showInvoicePopover, setShowInvoicePopover] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const history = useHistory();

  const invoiceLinkId = "invoiceLink";
  const estimateLinkId = "estimateLink";
  return (
    <GenericFooter backLink={backLink}>
      {schedule.allowOnsiteEstimating ? (
        <>
          <div
            className={`text-center`}
            data-testid="estimateLinkContainer"
            onClick={() => {
              if (isEstimatingDisabled(scheduleJob)) {
                setShowEstimatePopover(true);
              }
            }}
          >
            <Link
              id={estimateLinkId}
              className={getEstimateClassName(scheduleJob)}
              to={routingBuilders.buildEstimateFormPath({
                scheduleIdentifier,
                scheduleJobId: scheduleJob.id,
              })}
              onClick={(e) => {
                if (isEstimatingDisabled(scheduleJob)) {
                  e.preventDefault();
                } else {
                  fullStoryTrack("Crew: Estimate Clicked");
                }
              }}
              style={{
                paddingTop: "10px",
                pointerEvents: isEstimatingDisabled(scheduleJob)
                  ? "none"
                  : undefined,
              }}
              data-testid="estimateLink"
            >
              <FontAwesomeIcon icon={faFilePen} size="3x" fixedWidth />
              <div>{strings.estimate}</div>
            </Link>
          </div>
          <Popover
            trigger="legacy"
            placement="top"
            isOpen={showEstimatePopover}
            target={estimateLinkId}
            toggle={() => {
              setShowEstimatePopover(!showEstimatePopover);
            }}
          >
            <PopoverBody>
              {getEstimateDisabledExplanation(scheduleJob)}
            </PopoverBody>
          </Popover>
        </>
      ) : null}

      <div>
        <StartStopButtons
          endClock={endClock}
          scheduleJob={scheduleJob}
          startClock={startClock}
          onCompletedButtonClick={onCompletedButtonClick}
        />
      </div>

      {schedule.allowOnsiteInvoicing ? (
        <>
          <div
            className={`text-center`}
            data-testid="invoiceLinkContainer"
            onClick={() => {
              if (isInvoicingDisabled(scheduleJob)) {
                setShowInvoicePopover(true);
              }
            }}
          >
            <Link
              id={invoiceLinkId}
              className={getInvoiceClassName(scheduleJob)}
              to={getInvoiceLink(scheduleJob, scheduleIdentifier)}
              onClick={(e) => {
                if (!scheduleJob.completed && !scheduleJob.invoicePaid) {
                  setShowCompleteModal(true);
                  e.preventDefault();
                } else if (isInvoicingDisabled(scheduleJob)) {
                  e.preventDefault();
                } else {
                  fullStoryTrack("Crew: Invoice Clicked");
                }
              }}
              style={{
                paddingTop: "10px",
                pointerEvents: isInvoicingDisabled(scheduleJob)
                  ? "none"
                  : undefined,
              }}
              data-testid="invoiceLink"
            >
              <FontAwesomeIcon icon={faCreditCard} size="3x" fixedWidth />
              <div>
                {scheduleJob.invoicePaid ? strings.paid : strings.invoice}
              </div>
            </Link>
          </div>
          <Popover
            trigger="legacy"
            placement="top"
            isOpen={showInvoicePopover}
            target={invoiceLinkId}
            toggle={() => {
              setShowInvoicePopover(!showInvoicePopover);
            }}
          >
            <PopoverBody>
              {getInvoiceDisabledExplanation(scheduleJob)}
            </PopoverBody>
          </Popover>
        </>
      ) : null}

      <NewFile tenantId={schedule.tenantId} onPhotoAdded={onFileAdded} />

      {showCompleteModal ? (
        <JobNotCompletedWarning
          scheduleJob={scheduleJob}
          onScheduleJobUpdated={onScheduleJobUpdated}
          onComplete={() => {
            history.push(getInvoiceLink(scheduleJob, scheduleIdentifier));
          }}
          onSkip={() => {
            history.push(getInvoiceLink(scheduleJob, scheduleIdentifier));
          }}
          onCancel={() => {
            setShowCompleteModal(false);
          }}
        />
      ) : null}
    </GenericFooter>
  );
};

function JobNotCompletedWarning({
  onComplete,
  onSkip,
  onCancel,
  scheduleJob,
  onScheduleJobUpdated,
}: {
  onComplete: () => void;
  onSkip: () => void;
  onCancel: () => void;
  scheduleJob: IScheduledJob;
  onScheduleJobUpdated(
    scheduleJobId: string,
    fieldsToUpdate: Partial<IScheduledJob>
  ): void;
}) {
  const [saving, setSaving] = useState(false);
  const [errorSaving, setErrorSaving] = useState(false);
  const [uncompletedItemsForError, setUncompletedItemsForError] = useState<
    Array<IUncompletedItem>
  >([]);

  return (
    <>
      {saving ? (
        <Spinner />
      ) : (
        <Modal isOpen>
          <ModalBody>
            <strong>{strings.thisJobHasNotBeenCompleted}</strong>
            <div>{strings.invoicingJobNotCompleted}</div>
            <div>{strings.wouldYouLikeToCompleteIt}</div>
            {errorSaving ? (
              <div
                className="mt-2 text-danger"
                data-testid="errorCompletingJob"
              >
                {uncompletedItemsForError.length > 0 ? (
                  <CompleteJobErrorRequiredItems
                    uncompletedItems={uncompletedItemsForError}
                  />
                ) : (
                  strings.errorCompletingJob
                )}
              </div>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <div className="d-flex" style={{ gap: "10px" }}>
              {uncompletedItemsForError.length === 0 ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    const savePayload = { completed: true };

                    setSaving(true);
                    setErrorSaving(false);
                    dataProvider
                      .updateScheduleJob(scheduleJob.id, savePayload)
                      .subscribe(
                        () => {
                          onScheduleJobUpdated(scheduleJob.id, savePayload);

                          setSaving(false);
                          onComplete();
                        },
                        (error) => {
                          setSaving(false);
                          setErrorSaving(true);

                          if (
                            error.response &&
                            error.response.errorCode ===
                              "CrewCompletingJobWithoutRequiredTodoItemsDone"
                          ) {
                            setUncompletedItemsForError(
                              error.response.additionalData
                                .uncompletedTodoItems as Array<IUncompletedItem>
                            );
                          } else {
                            setUncompletedItemsForError([]);
                          }
                        }
                      );
                  }}
                >
                  {strings.yesJobCompleted}
                </button>
              ) : null}
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onSkip}
              >
                {strings.skip}
              </button>
              {errorSaving ? (
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onCancel}
                >
                  {strings.cancel}
                </button>
              ) : null}
            </div>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}

function getInvoiceDisabledExplanation(
  scheduleJob: IScheduledJob
): React.ReactNode {
  if (scheduleJob.isMultiDayProject) {
    return "Cannot invoice a multi-day project";
  } else if (scheduleJob.hasPreviousPayments) {
    return "Cannot invoice a job that has already received a payment";
  } else {
    return null;
  }
}

function getEstimateDisabledExplanation(
  scheduleJob: IScheduledJob
): React.ReactNode {
  if (scheduleJob.estimateRequiresPaymentMethodOnFile) {
    return "Cannot edit an estimate that requires payment method on file to be captured. Please have your administrator email a copy of the proposal to the customer.";
  } else {
    return null;
  }
}

function getInvoiceLink(
  scheduleJob: IScheduledJob,
  scheduleIdentifier: IScheduleIdentifier
) {
  if (isInvoicingDisabled(scheduleJob)) {
    return "";
  } else if (!scheduleJob.invoicePaid) {
    return routingBuilders.buildInvoiceFormPath({
      scheduleIdentifier,
      scheduleJobId: scheduleJob.id,
    });
  } else {
    return routingBuilders.buildInvoicePresentation({
      scheduleIdentifier,
      scheduleJobId: scheduleJob.id,
    });
  }
}

function getInvoiceClassName(scheduleJob: IScheduledJob): string | undefined {
  if (scheduleJob.invoicePaid) {
    return "text-success";
  } else if (isInvoicingDisabled(scheduleJob)) {
    return "text-secondary";
  } else {
    return "";
  }
}

function getEstimateClassName(scheduleJob: IScheduledJob): string | undefined {
  if (scheduleJob.proposalAccepted) {
    return "text-success";
  } else if (isEstimatingDisabled(scheduleJob)) {
    return "text-secondary";
  } else {
    return "";
  }
}

function isInvoicingDisabled(scheduleJob: IScheduledJob) {
  return scheduleJob.isMultiDayProject || scheduleJob.hasPreviousPayments;
}

function isEstimatingDisabled(scheduleJob: IScheduledJob) {
  return scheduleJob.estimateRequiresPaymentMethodOnFile;
}
