import {
  faEquals,
  faTriangleExclamation,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ILineItem } from "./BillableFormLineItem";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableProvided,
} from "react-beautiful-dnd";
import { useHistory } from "react-router-dom";
import { formatCurrency } from "../../../services/currencyFormatter";
import { useRef, useState } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap/lib";
import { ModalHeader } from "reactstrap";
import { strings } from "../../../languages";
import { billableFormConstants } from "../billableFormConstants";
import { BuildLineItemLink } from "./BillableFormLineItemsMobile.types";
import { IInvoiceItem } from "../types/IInvoiceItem";
import { isStringSet } from "../../typeUtilities/isStringSet";
import { appendQueryString } from "../../../services/routing";
import { LineItemErrorMessages } from "./BillableFormLineItemsMobile.types";
import { getMobileCardId } from "./BillableFormLineItemsMobile.functions";

interface IProps {
  lineItems: Array<ILineItem>;
  invoiceItems: Array<IInvoiceItem>;
  noLineItemMessage?: string;

  setLineItems: (newLineItems: Array<ILineItem>) => void;

  allowZeroLineItems?: boolean;
  disabled?: boolean;
  buildLineItemLink: BuildLineItemLink;
  hideTaxableField: boolean;
  errorMessages: LineItemErrorMessages;
}

const BillableFormLineItemsMobile: React.FunctionComponent<IProps> = ({
  lineItems,
  invoiceItems,
  setLineItems,
  noLineItemMessage,
  buildLineItemLink,

  allowZeroLineItems,

  disabled,
  hideTaxableField,
  errorMessages,
}) => {
  const history = useHistory();
  const newLineItemButtonContainerRef = useRef<HTMLDivElement>(null);

  const showReorderColumn = lineItems.length > 1 && !disabled;
  const showDeleteColumn =
    (lineItems.length > 1 || (allowZeroLineItems ?? false)) && !disabled;

  return (
    <>
      <div>
        <h4>{strings.lineItems}</h4>
      </div>

      {lineItems.length > 0 ? (
        <DragDropContext
          onDragEnd={(result) => {
            if (!result.destination) {
              return;
            }

            const updatedLineItems = Array.from(lineItems);
            const [removed] = updatedLineItems.splice(result.source.index, 1);
            updatedLineItems.splice(result.destination.index, 0, removed);

            setLineItems(updatedLineItems);
          }}
        >
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {lineItems.map((lineItem, index) => (
                  <Draggable
                    key={lineItem.id}
                    draggableId={lineItem.id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className="shadow-sm card mb-2"
                      >
                        <LineItemCard
                          invoiceItems={invoiceItems}
                          lineItem={lineItem}
                          showReorderColumn={showReorderColumn}
                          showDeleteColumn={showDeleteColumn}
                          lineItems={lineItems}
                          setLineItems={setLineItems}
                          provided={provided}
                          buildLineItemLink={buildLineItemLink}
                          hideTaxableField={hideTaxableField}
                          errorMessage={
                            errorMessages.find(
                              (e) => e.lineItemId === lineItem.id
                            )?.errorMessage ?? null
                          }
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div className="font-weight-light">
          {noLineItemMessage ?? strings.pleaseAddLineItem}
        </div>
      )}

      {!disabled ? (
        <div
          ref={newLineItemButtonContainerRef}
          data-testid="mobileLineItemAddButtonContainer"
        >
          <button
            className="btn btn-secondary btn-block btn-sm my-3"
            type="button"
            data-testid="mobileLineItemAddButton"
            onClick={() => {
              history.push(
                buildLineItemLinkWithScrollPosition({
                  buildLineItemLink,
                  hideTaxableField,
                  lineItemId: null,
                })
              );
            }}
          >
            {strings.addLineItem}
          </button>
        </div>
      ) : null}
    </>
  );
};

export default BillableFormLineItemsMobile;

function LineItemCard({
  lineItem,
  invoiceItems,
  showReorderColumn,
  showDeleteColumn,
  lineItems,
  setLineItems,
  provided,
  buildLineItemLink,
  hideTaxableField,
  errorMessage,
}: {
  lineItem: ILineItem;
  invoiceItems: Array<IInvoiceItem>;
  showReorderColumn: boolean;
  showDeleteColumn: boolean;
  lineItems: Array<ILineItem>;
  setLineItems: (newLineItems: Array<ILineItem>) => void;
  provided: DraggableProvided;
  buildLineItemLink: BuildLineItemLink;
  hideTaxableField: boolean;
  errorMessage: string | null;
}) {
  const cardRef = useRef<HTMLDivElement>(null);
  const history = useHistory();

  return (
    <div
      className={`card-body py-2 px-0`}
      ref={cardRef}
      data-testid="mobileLineItemCard"
      id={getMobileCardId(lineItem.id)}
    >
      <div className="d-flex justify-content-between">
        <div
          className={`${
            showReorderColumn ? "d-flex" : "d-none"
          } align-self-stretch align-items-center px-2`}
          style={{ verticalAlign: "center" }}
          data-testid="lineItemDragHandle"
          {...provided.dragHandleProps}
        >
          <FontAwesomeIcon icon={faEquals} />
        </div>

        <div
          style={{ flexGrow: 1 }}
          className={`${!showReorderColumn ? "pl-2" : ""} ${
            !showDeleteColumn ? "pr-2" : ""
          }`}
        >
          <button
            type="button"
            onClick={() => {
              history.push(
                buildLineItemLinkWithScrollPosition({
                  buildLineItemLink,
                  lineItemId: lineItem.id,
                  hideTaxableField,
                })
              );
            }}
            className="text-dark text-left btn btn-block"
          >
            <div className="d-flex justify-content-between">
              <div className="font-weight-bold">
                {isStringSet(errorMessage) ? (
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className="text-danger mr-1"
                  />
                ) : null}
                {getLineItemName(lineItem, invoiceItems)}
              </div>
              {!isNaN(parseFloat(lineItem.quantity)) &&
              !isNaN(parseFloat(lineItem.amountPerItem)) ? (
                <div className="font-weight-light">
                  {lineItem.quantity} x{" "}
                  {formatCurrency(parseFloat(lineItem.amountPerItem), true)}
                </div>
              ) : null}
            </div>

            {isStringSet(errorMessage) ? (
              <div className="text-danger" data-testid="lineItemError">
                {errorMessage}
              </div>
            ) : null}

            {lineItem.optional ? (
              <div className="float-right font-weight-bold pl-2">
                {strings.optional}
              </div>
            ) : null}

            <div className="line-item-description">{lineItem.description}</div>
          </button>
        </div>

        {showDeleteColumn ? (
          <div className="align-self-center px-2">
            <DeleteLineItem
              name={getLineItemName(lineItem, invoiceItems) ?? ""}
              onDelete={() => {
                setLineItems(lineItems.filter((l) => l.id !== lineItem.id));
              }}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

function buildLineItemLinkWithScrollPosition({
  buildLineItemLink,
  lineItemId,
  hideTaxableField,
}: {
  buildLineItemLink: BuildLineItemLink;
  lineItemId: string | null;
  hideTaxableField: boolean;
}): string {
  let link = appendQueryString({
    base: buildLineItemLink(lineItemId),
    name: billableFormConstants.originalScrollPosition,
    value: window.scrollY,
  });

  if (hideTaxableField) {
    link = appendQueryString({
      base: link,
      name: billableFormConstants.hideTaxableField,
      value: "true",
    });
  }

  return link;
}

function getLineItemName(lineItem: ILineItem, invoiceItems: IInvoiceItem[]) {
  let name = lineItem.name ?? null;
  if (!isStringSet(name)) {
    const item = invoiceItems.find((i) => i.id === lineItem.itemId);
    if (item && isStringSet(item.name)) {
      name = item.name;
    }
  }

  return name;
}

function DeleteLineItem({
  name,
  onDelete,
}: {
  name: string;
  onDelete: () => void;
}) {
  const [showConfirmation, setShowConfirmation] = useState(false);

  return (
    <>
      <button
        type="button"
        className="btn border-0"
        data-testid="deleteLineItem"
        onClick={() => {
          setShowConfirmation(true);
        }}
      >
        <FontAwesomeIcon icon={faXmarkCircle} />
      </button>
      {showConfirmation ? (
        <Modal isOpen>
          <ModalHeader>{strings.deleteLineItem}</ModalHeader>
          <ModalBody>{name}</ModalBody>
          <ModalFooter>
            <button
              className="btn btn-primary mr-2"
              type="button"
              data-testid="confirmDelete"
              onClick={() => {
                setShowConfirmation(false);
                onDelete();
              }}
            >
              {strings.delete}
            </button>
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => {
                setShowConfirmation(false);
              }}
            >
              {strings.cancel}
            </button>
          </ModalFooter>
        </Modal>
      ) : null}
    </>
  );
}
