import React, { CSSProperties } from "react";

interface IProps {
  buttonText: string;
  errorMessage: string;
  onClick: () => void;
  buttonClass: string;
  containingDivStyle: Partial<CSSProperties>;
}

const ShopClockInOutButton: React.FunctionComponent<IProps> = ({
  buttonText,
  errorMessage,
  buttonClass,
  onClick,
  containingDivStyle,
}) => {
  return (
    <div style={containingDivStyle}>
      <button
        type="button"
        className={`btn ${buttonClass} btn-lg btn-block`}
        onClick={onClick}
        style={{ minHeight: "100px", fontSize: "x-large" }}
      >
        {buttonText}
      </button>
      {errorMessage ? (
        <div className="text-danger text-center">{errorMessage}</div>
      ) : null}
    </div>
  );
};

export default ShopClockInOutButton;
