import * as React from "react";
import Alert from "reactstrap/lib/Alert";
import { IPendingFile } from "./Index";
import uuidv4 from "uuid/v4";
import Spinner from "../../../components/Spinner";
import fileService from "../services/fileService";

interface IProps {
  tenantId: string;
  onFilesAdded: (files: Array<IPendingFile>) => void;
  allowExcel?: boolean;
  addAdditionalFiles?: React.ReactNode | null;
  required: boolean;
}

interface IState {
  saving: boolean;
  hasFileSet: boolean;
  fileLabelText: string;
  newPhotoCaption: string;
  error: JSX.Element | null;
}

const defaultFileLabelText = "Select file to upload...";

const pdfMimeType = "application/pdf";
const xlsxMimeType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
const xlsMimeType = "application/vnd.ms-excel";

class NewPhoto extends React.Component<IProps, IState> {
  private fileElement: React.RefObject<HTMLInputElement>;

  constructor(props: IProps) {
    super(props);

    this.state = {
      saving: false,
      hasFileSet: false,
      fileLabelText: defaultFileLabelText,
      newPhotoCaption: "",
      error: null,
    };

    this.fileElement = React.createRef();
  }

  public render() {
    const { allowExcel, required } = this.props;

    let acceptedFileTypes = `image/png, image/jpeg, ${pdfMimeType}, video/*`;
    if (allowExcel) {
      acceptedFileTypes += `, ${xlsxMimeType}, ${xlsMimeType}`;
    }

    return (
      <React.Fragment>
        {this.state.saving ? <Spinner /> : null}
        {this.state.error !== null ? (
          <Alert
            color="danger"
            onClose={() => {
              this.setState({
                error: null,
              });
            }}
          >
            {this.state.error}
          </Alert>
        ) : null}
        <div className="row">
          <div className="col">
            <div className="form-group">
              <div className="custom-file">
                <input
                  required={required}
                  type="file"
                  accept={acceptedFileTypes}
                  className="custom-file-input"
                  ref={this.fileElement}
                  id="fileInput"
                  data-testid="newFile"
                  multiple={true}
                  onChange={() => {
                    const fileList = (this.fileElement.current as any)
                      .files as FileList;
                    if (fileList && fileList.length > 0) {
                      let fileValid = true;
                      const maxFileSize =
                        fileService.getFileSizeAllowedInMegabytes(
                          this.props.tenantId
                        );

                      Array.from(fileList).forEach((file) => {
                        if (file.size > 1024 * 1024 * maxFileSize) {
                          fileValid = false;
                          this.setState({
                            error: (
                              <span>
                                The file cannot be larger than {maxFileSize}MB
                              </span>
                            ),
                          });
                          return;
                        }

                        if (!isFileValid(allowExcel, file)) {
                          fileValid = false;
                          this.setState({
                            error: (
                              <span>
                                {allowExcel
                                  ? "Only image, video, Excel or PDF files can be uploaded."
                                  : "Only image, video or PDF files can be uploaded."}
                              </span>
                            ),
                          });
                          return;
                        }
                      });

                      if (fileValid) {
                        this.props.onFilesAdded(
                          Array.from(fileList).map((file) => ({
                            tempId: uuidv4(),
                            file: file,
                            errorUploading: false,
                          }))
                        );

                        if (this.fileElement.current) {
                          this.fileElement.current.value = "";
                        }
                      }
                    }
                  }}
                />
                <label className="custom-file-label" htmlFor="fileInput">
                  {this.state.fileLabelText}
                </label>
              </div>
              <div className="form-group">{this.props.addAdditionalFiles}</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NewPhoto;

function isFileValid(allowExcel: boolean | undefined, file: File) {
  if (
    file.type.toLowerCase().indexOf("image") !== -1 ||
    file.type.toLowerCase().indexOf("video") !== -1 ||
    file.type.toLowerCase() === pdfMimeType
  ) {
    return true;
  }

  if (allowExcel && (file.type === xlsMimeType || file.type === xlsxMimeType)) {
    return true;
  }

  return false;
}
