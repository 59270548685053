import { Observable } from "rxjs";
import EditableFieldBase from "../../../components/EditableFieldBase";
import { IScheduleIdentifier } from "../../../models/IScheduleIdentifier";
import { WorksheetContentType } from "../enums/WorksheetContentType";
import { IWorksheetContentItem } from "../models/IWorksheet";
import { saveWorksheetResponse } from "../services/worksheetsDataProvider";
import { IAutoSaveResponse } from "../../../models/IAutoSave";
import TextareaAutosize from "react-autosize-textarea";
import Files from "../../../libraries/formAttachments/components/Index";
import { formDataFileSchema } from "../../../libraries/formAttachments/components/ExistingFile";
import { isPhoto } from "../../../libraries/formAttachments/services/fileService";
import ISchedule from "../../../models/ISchedule";
import { z } from "zod";
import { logError } from "../../../libraries/logging/logError";

type ContentItemProps = {
  contentItem: IWorksheetContentItem;
  scheduleIdentifier: IScheduleIdentifier;
  schedule: ISchedule;

  saveItem: (args: {
    value: string;
    oldValues: Array<string>;
  }) => Observable<IAutoSaveResponse>;

  updateResponse: (args: {
    worksheetForJobInstanceContentItemId: string;
    response: string;
  }) => void;
};

export function WorksheetContentItem({
  scheduleIdentifier,
  schedule,
  scheduleJobId,
  contentItem,
  updateResponse,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  schedule: ISchedule;
  scheduleJobId: string;
  contentItem: IWorksheetContentItem;
  updateResponse: (args: {
    worksheetForJobInstanceContentItemId: string;
    response: string;
  }) => void;
}) {
  const props: ContentItemProps = {
    schedule,

    scheduleIdentifier,

    saveItem: ({ value, oldValues }) =>
      saveWorksheetResponse({
        scheduleIdentifier,
        jobInstanceId: scheduleJobId,
        worksheetForJobInstanceContentItemId: contentItem.id,
        newValue: value,
        oldValues,
      }),

    updateResponse,

    contentItem,
  };

  return <div className="form-group">{getContentItem(contentItem, props)}</div>;
}

function getContentItem(
  contentItem: IWorksheetContentItem,
  props: ContentItemProps
) {
  switch (contentItem.type) {
    case WorksheetContentType.QuestionBoolean:
      return <WorksheetContentItemQuestionBoolean {...props} />;
    case WorksheetContentType.QuestionShortAnswer:
      return <WorksheetContentItemQuestionShortAnswer {...props} />;
    case WorksheetContentType.QuestionLongAnswer:
      return <WorksheetContentItemQuestionParagraph {...props} />;
    case WorksheetContentType.QuestionNumber:
      return <WorksheetContentItemQuestionNumber {...props} />;
    case WorksheetContentType.QuestionCheckbox:
      return <WorksheetContentItemQuestionCheckbox {...props} />;
    case WorksheetContentType.QuestionOptionList:
      return <WorksheetContentItemQuestionOptionList {...props} />;
    case WorksheetContentType.QuestionDate:
      return <WorksheetContentItemQuestionDate {...props} />;
    case WorksheetContentType.QuestionFileUpload:
      return <WorksheetContentItemQuestionFileUpload {...props} />;
    default:
      return <></>;
  }
}

function WorksheetContentItemQuestionBoolean(props: ContentItemProps) {
  const id = `contentItem_${props.contentItem.id}`;
  return (
    <EditableFieldForContentItemQuestion
      {...props}
      renderLabel={() => <Label id={id} contentItem={props.contentItem} />}
      renderInputField={({ value, onChange }) => (
        <DropDownField
          id={id}
          contentItem={props.contentItem}
          onChange={onChange}
          value={value}
          options={[
            {
              key: "true",
              value: "true",
              label: "Yes",
            },
            {
              key: "false",
              value: "false",
              label: "No",
            },
          ]}
        />
      )}
    />
  );
}

function WorksheetContentItemQuestionOptionList(props: ContentItemProps) {
  const id = `contentItem_${props.contentItem.id}`;
  return (
    <EditableFieldForContentItemQuestion
      {...props}
      renderLabel={() => <Label id={id} contentItem={props.contentItem} />}
      renderInputField={({ value, onChange }) => (
        <DropDownField
          id={id}
          contentItem={props.contentItem}
          onChange={onChange}
          value={value}
          options={props.contentItem.optionListOptions.map((o) => ({
            key: o.id,
            label: o.label,
            value: o.label,
          }))}
        />
      )}
    />
  );
}

function WorksheetContentItemQuestionCheckbox(props: ContentItemProps) {
  const id = `contentItem_${props.contentItem.id}`;
  return (
    <EditableFieldForContentItemQuestion
      {...props}
      renderLabel={() => <div>&nbsp;</div>}
      renderInputField={({ value, onChange }) => (
        <div className="custom-control-lg custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id={id}
            required={props.contentItem.required}
            checked={value === "true"}
            onChange={(e) => {
              const value = e.currentTarget.checked ? "true" : "false";
              onChange(value);
            }}
          />
          <label className="custom-control-label" htmlFor={id}>
            {props.contentItem.text}
          </label>
        </div>
      )}
    />
  );
}

function WorksheetContentItemQuestionShortAnswer(props: ContentItemProps) {
  const id = `contentItem_${props.contentItem.id}`;
  return (
    <EditableFieldForContentItemQuestion
      {...props}
      renderLabel={() => <Label id={id} contentItem={props.contentItem} />}
      renderInputField={({ value, onChange, onFocus, onBlur }) => (
        <input
          type="text"
          className="form-control form-control-lg"
          id={id}
          required={props.contentItem.required}
          value={value}
          onChange={(e) => {
            const newValue = e.currentTarget.value;

            onChange(newValue);
          }}
          onFocus={onFocus}
          onBlur={onBlur}
          maxLength={100}
        />
      )}
    />
  );
}

function WorksheetContentItemQuestionNumber(props: ContentItemProps) {
  const id = `contentItem_${props.contentItem.id}`;
  return (
    <EditableFieldForContentItemQuestion
      {...props}
      renderLabel={() => <Label id={id} contentItem={props.contentItem} />}
      renderInputField={({ value, onChange, onFocus, onBlur }) => (
        <input
          type="number"
          className="form-control form-control-lg"
          id={id}
          required={props.contentItem.required}
          value={value}
          onChange={(e) => {
            const newValue = e.currentTarget.value;

            onChange(newValue);
          }}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      )}
    />
  );
}

function WorksheetContentItemQuestionDate(props: ContentItemProps) {
  const id = `contentItem_${props.contentItem.id}`;
  return (
    <EditableFieldForContentItemQuestion
      {...props}
      renderLabel={() => <Label id={id} contentItem={props.contentItem} />}
      renderInputField={({ value, onChange, onFocus, onBlur }) => (
        <input
          type="date"
          className="form-control form-control-lg"
          id={id}
          required={props.contentItem.required}
          value={value}
          onChange={(e) => {
            const newValue = e.currentTarget.value;
            onChange(newValue);
          }}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      )}
    />
  );
}

function WorksheetContentItemQuestionFileUpload(props: ContentItemProps) {
  const id = `contentItem_${props.contentItem.id}`;

  return (
    <EditableFieldForContentItemQuestion
      {...props}
      renderLabel={() => <Label id={id} contentItem={props.contentItem} />}
      renderInputField={({ value, onChange }) => {
        const arraySchema = z.array(formDataFileSchema);

        const valueToParse = value === "" || value === null ? "[]" : value;
        const { data, success, error } = arraySchema.safeParse(
          JSON.parse(valueToParse)
        );
        if (!success) {
          logError(
            `unable to parse files in WorksheetContentItem. Error: ${error}`
          );
        }

        const parsedValue = data ?? [];

        return (
          <Files
            scheduleIdentifier={props.scheduleIdentifier}
            files={parsedValue}
            imagePrefix={props.schedule.imagePrefix}
            header={""}
            tenantId={props.schedule.tenantId}
            onFileAdded={(newFile) => {
              onChange(JSON.stringify([...parsedValue, newFile]));
            }}
            onFileRemoved={(fileId, imagePath) => {
              onChange(
                JSON.stringify(
                  parsedValue.filter((f) => !isPhoto(fileId, imagePath, f))
                )
              );
            }}
            onFileUpdated={(fileId, imagePath, caption) => {
              onChange(
                JSON.stringify(
                  parsedValue.map((f) => {
                    if (isPhoto(fileId, imagePath, f)) {
                      return {
                        ...f,
                        caption,
                      };
                    } else {
                      return f;
                    }
                  })
                )
              );
            }}
            onFileUploadingStatusChange={() => {}}
            requireAtLeastOneFile={props.contentItem.required}
          />
        );
      }}
    />
  );
}

function WorksheetContentItemQuestionParagraph(props: ContentItemProps) {
  const id = `contentItem_${props.contentItem.id}`;
  return (
    <EditableFieldForContentItemQuestion
      {...props}
      renderLabel={() => <Label id={id} contentItem={props.contentItem} />}
      renderInputField={({ value, onChange, onFocus, onBlur }) => (
        <TextareaAutosize
          className="form-control form-control-lg"
          id={id}
          required={props.contentItem.required}
          value={value}
          onChange={(e) => {
            const newValue = e.currentTarget.value;

            onChange(newValue);
          }}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      )}
    />
  );
}

function EditableFieldForContentItemQuestion({
  saveItem,
  updateResponse,
  contentItem,
  renderLabel,
  renderInputField,
}: ContentItemProps & {
  renderLabel: () => JSX.Element;
  renderInputField(args: {
    value: string;
    onChange: (newValue: string) => void;
    onFocus: () => void;
    onBlur: () => void;
  }): JSX.Element;
}) {
  return (
    <EditableFieldBase
      initialValue={contentItem.response}
      saveChange={({ value, oldValues }) => saveItem({ value, oldValues })}
      onSaveComplete={(newValue) =>
        updateResponse({
          response: newValue,
          worksheetForJobInstanceContentItemId: contentItem.id,
        })
      }
      renderLabel={renderLabel}
      renderInputField={renderInputField}
    />
  );
}

function Label({
  id,
  contentItem,
}: {
  id: string;
  contentItem: IWorksheetContentItem;
}) {
  return (
    <label
      htmlFor={id}
      className={`col-form-label-lg ${contentItem.required ? "required" : ""}`}
    >
      {contentItem.text}
    </label>
  );
}

function DropDownField({
  id,
  value,
  contentItem,
  onChange,
  options,
}: {
  id: string;
  value: string;
  contentItem: IWorksheetContentItem;
  onChange: (newValue: string) => void;
  options: Array<{ key: string; value: string; label: string }>;
}) {
  return (
    <select
      className="form-control form-control-lg"
      id={id}
      required={contentItem.required}
      value={value}
      onChange={(e) => {
        const newValue = e.currentTarget.value;

        onChange(newValue);
      }}
    >
      <option value=""></option>
      {options.map((o) => (
        <option key={o.key} value={o.value}>
          {o.label}
        </option>
      ))}
    </select>
  );
}
