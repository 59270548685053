import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LinkButton from "../../../components/LinkButton";
import { strings } from "../../../languages";

export function FormLockWarning({
  message,
  onClear,
}: {
  message: string;
  onClear: () => void;
}) {
  return (
    <div
      className="alert alert-warning p-1 pl-2"
      role="alert"
      data-testid="signatureWarning"
    >
      <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
      {message}{" "}
      <LinkButton
        text={strings.unlock}
        additionalClassNames="text-dark"
        style={{ verticalAlign: "baseline" }}
        testId="unlock"
        onClick={() => {
          onClear();
        }}
      />
    </div>
  );
}
