import React from "react";
import TextareaAutosize from "react-autosize-textarea";
import { AutoSaveField } from "../models/IAutoSave";
import dataProvider from "../services/DataProvider";
import EditableFieldBase from "./EditableFieldBase";

interface IProps {
  label: string | null;
  initialValue: string | undefined;
  onSaveComplete(value: string): void;
  autoSaveField: AutoSaveField;
  jobInstanceId: string;
  childId?: string;
  placeholder?: string;
}

const EditableTextField: React.FunctionComponent<IProps> = ({
  label,
  onSaveComplete,
  autoSaveField,
  initialValue,
  jobInstanceId,
  childId,
  placeholder,
}) => {
  if (!initialValue) {
    initialValue = "";
  }

  return (
    <>
      <EditableFieldBase
        initialValue={initialValue}
        saveChange={({ value, oldValues }) =>
          dataProvider.autoSave(jobInstanceId, {
            autoSaveField,
            newValue: value,
            oldValues,
            childId,
          })
        }
        onSaveComplete={onSaveComplete}
        renderLabel={() => (label ? <h6>{label}: </h6> : <h6>&nbsp;</h6>)}
        renderInputField={({ value, onChange, onFocus, onBlur }) => (
          <TextareaAutosize
            className="form-control"
            value={value}
            onChange={(e) => {
              const newValue = e.currentTarget.value;

              onChange(newValue);
            }}
            maxRows={10}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={placeholder || ""}
            data-testid="textField"
          />
        )}
      />
    </>
  );
};

export default EditableTextField;
