import { IDiscount } from "../billableForm/types/IDiscount";
import { round } from "../math/round";
import { getDiscountToApply } from "./getDiscountToApply";
import { getLineItemTaxableAmount } from "./getLineItemTaxableAmount";
import { getSubtotal } from "./getSubtotal";
import { IAmountCalculationLineItem } from "./IAmountCalculationLineItem";

export function getTotal({
  taxRate,
  lineItems,
  discount,
  depositCreditAmount,
}: {
  taxRate: number | null;
  lineItems: Array<IAmountCalculationLineItem>;
  discount?: IDiscount | null;
  depositCreditAmount?: number | null;
}) {
  const subtotal = getSubtotal(lineItems);
  const discountToApply = getDiscountToApply(subtotal, discount);

  // Note: This calculation is also in the API. If the logic
  // is updated, the API code will also need to be updated.
  const subtotalLessDiscount = subtotal - discountToApply;

  const originalTaxAmount = getLineItemTaxableAmount(taxRate, lineItems);

  let proratedTaxAmount: number;
  if (subtotal !== 0) {
    proratedTaxAmount = originalTaxAmount * (subtotalLessDiscount / subtotal);
  } else {
    proratedTaxAmount = 0;
  }

  return round(
    subtotalLessDiscount + proratedTaxAmount - (depositCreditAmount ?? 0),
    2
  );
}
