import { isIOS } from "react-device-detect";
import { AppContainer } from "./AppContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpFromBracket,
  faCheckCircle,
  faEllipsisVertical,
} from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { routingBuilders } from "../../../services/routing";
import { fullStoryInit } from "../../../services/fullStoryService";
import configuration from "../../../services/configuration";

export function InstallationInstructions({
  deferredInstall,
}: {
  deferredInstall: unknown;
}) {
  useRedirectToLoginIfAlreadyRunningAsPwa();

  useEffect(() => {
    if (configuration.fullStoryId) {
      fullStoryInit();
    }
  }, []);

  let installationInstructions: React.ReactNode;
  if (isIOS) {
    installationInstructions = <AppleInstructions />;
  } else {
    installationInstructions = (
      <AndroidInstructions deferredInstall={deferredInstall} />
    );
  }

  return (
    <AppContainer>
      <div className="d-flex justify-content-center">
        <div>
          <h4 className="text-center">Crew Schedule Mobile App</h4>
          <div className="mt-3">{installationInstructions}</div>
          <div className="mt-3">
            Once installed, log in to Crew Control by finding the "Crew
            Schedule" application on your phone's home screen.
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

function useRedirectToLoginIfAlreadyRunningAsPwa() {
  const history = useHistory();

  useEffect(() => {
    if (runningAsPwa()) {
      history.push(routingBuilders.buildLoginPath());
    }
  }, [history]);
}

function AppleInstructions() {
  return (
    <>
      Install Crew Control on your device by tapping
      <FontAwesomeIcon icon={faArrowUpFromBracket} className="ml-2 mr-2" />
      then "Add to Home Screen".
    </>
  );
}

function AndroidInstructions({
  deferredInstall,
}: {
  deferredInstall: unknown;
}) {
  const { hasInitialDelayPassed } =
    useDelayInstructionsForInstallPromptToExist();

  const { appInstalled } = useListenForAppInstalled();

  if (doesDeferredInstallHavePromptMethod(deferredInstall)) {
    return !appInstalled ? (
      <>
        <button
          className="btn btn-primary btn-block"
          type="button"
          onClick={() => {
            deferredInstall.prompt();
          }}
        >
          Install now!
        </button>
      </>
    ) : (
      <div
        className="text-center text-success"
        data-testid="appInstalledConfirmation"
      >
        <FontAwesomeIcon icon={faCheckCircle} className="mr-2" size="2x" />
        <span style={{ fontSize: "2rem", fontWeight: 500 }}>Installed!</span>
      </div>
    );
  } else {
    return (
      <div
        style={{ visibility: !hasInitialDelayPassed ? "hidden" : undefined }}
      >
        Install Crew Control on your device by tapping your browser menu icon
        <FontAwesomeIcon icon={faEllipsisVertical} className="ml-2 mr-2" />
        then "Add to Home screen".
      </div>
    );
  }
}

function useDelayInstructionsForInstallPromptToExist() {
  const [hasInitialDelayPassed, setInitialDelayPassed] = useState(false);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setInitialDelayPassed(true);
    }, 500);

    return function cleanup() {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  return { hasInitialDelayPassed };
}

function useListenForAppInstalled() {
  const [appInstalled, setAppInstalled] = useState(false);

  const handleAppInstalled = useCallback(() => setAppInstalled(true), []);

  useEffect(() => {
    window.addEventListener("appinstalled", handleAppInstalled);

    return function cleanup() {
      window.removeEventListener("appinstalled", handleAppInstalled);
    };
  }, [handleAppInstalled]);

  return { appInstalled };
}

function doesDeferredInstallHavePromptMethod(
  deferredInstall: unknown
): deferredInstall is { prompt: () => void } {
  return (
    deferredInstall !== null &&
    typeof deferredInstall === "object" &&
    "prompt" in deferredInstall
  );
}

function runningAsPwa() {
  let runningAsPwa = false;
  try {
    const navigatorAny = navigator as any;
    if (
      navigatorAny.standalone ||
      window.matchMedia("(display-mode: standalone)").matches
    ) {
      runningAsPwa = true;
    }
  } catch {
    console.error("unable to determine if running as pwa");
  }

  return runningAsPwa;
}
