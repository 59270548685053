import { useState } from "react";
import deletePhoto from "../../../delete-photo.png";
import Prompt from "../../prompt/components/Prompt";
import { strings } from "../../../languages";

interface IProps {
  alt: string;
  src: string;
  width?: number;
  height?: number;

  onDelete: () => void;
}

const ImageWithDeleteOverlay: React.FunctionComponent<IProps> = ({
  alt,
  src,
  width,
  height,
  onDelete,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  return (
    <>
      <div style={{ position: "relative", display: "inline-block" }}>
        <img
          alt={alt}
          src={src}
          width={width}
          height={height}
          onLoad={() => setImageLoaded(true)}
          onError={() => setImageLoaded(true)}
          data-testid="thumbnail"
        />
        {imageLoaded ? (
          <span
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setShowDeleteConfirmation(true);
            }}
          >
            <img src={deletePhoto} alt={strings.delete} />
          </span>
        ) : null}
      </div>

      <Prompt
        promptMessage={strings.deleteFile}
        promptSaveText={strings.delete}
        onCancel={() => setShowDeleteConfirmation(false)}
        onConfirm={() => {
          setShowDeleteConfirmation(false);
          onDelete();
        }}
        showPrompt={showDeleteConfirmation}
      />
    </>
  );
};

export default ImageWithDeleteOverlay;
