import React, { useContext, useMemo } from "react";
import IScheduledJob from "../models/IScheduleJob";
import { Link } from "react-router-dom";
import { formatLatLng } from "../services/addressService";
import { strings } from "../languages";
import {
  formatDateForDisplay,
  formatTimeForDisplay,
} from "../services/dateTimeService";
import { IJobTimeEstimates } from "../models/IGetEstimatedJobTimesResponseJobInstance";
import { estimatedJobTimesLoadingState, LanguageContext } from "../App";
import { ITimeRange } from "../models/ITimeRange";
import { formatCurrency } from "../services/currencyFormatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRepeat } from "@fortawesome/free-solid-svg-icons";
import { routingBuilders } from "../services/routing";
import { IScheduleIdentifier } from "../models/IScheduleIdentifier";
import { JobCategory } from "../slices/scheduling/components/JobCategory";
import { isStringSet } from "../libraries/typeUtilities/isStringSet";
import { NoteWithHyperlinks } from "./NoteWithHyperlinks";
import { getNoteTokens } from "./NoteWithHyperlinks.functions";

interface IProps {
  scheduleIdentifier: IScheduleIdentifier;
  scheduledJob: IScheduledJob;
  index: number;
  showCrewEstimatedTimes: boolean;
  showScheduledTimes: boolean;
  estimatedJobTime: IJobTimeEstimates | null;
  estimatedJobTimesLoading: estimatedJobTimesLoadingState;
  onJobDetailsLinkClicked: () => void;
}

function hasJobStarted(timeRanges: Array<ITimeRange>) {
  return timeRanges.reduce((acc, range) => acc || !!range.startTime, false);
}

function isJobPaused(scheduledJob: IScheduledJob) {
  // Handling case where there are no time ranges with reduce logic's initial value
  return (
    !scheduledJob.completed &&
    hasJobStarted(scheduledJob.timeRanges) &&
    scheduledJob.timeRanges.reduce((acc, tr) => {
      return (
        acc &&
        ((!!tr.startTime && !!tr.endTime) || (!tr.startTime && !tr.endTime))
      );
    }, !(scheduledJob.timeRanges.length === 0))
  );
}

const JobCard: React.FunctionComponent<IProps> = ({
  scheduleIdentifier,
  scheduledJob,
  estimatedJobTime,
  estimatedJobTimesLoading,
  index,
  onJobDetailsLinkClicked,
  showCrewEstimatedTimes,
  showScheduledTimes,
}) => {
  const languageContext = useContext(LanguageContext);

  const actualStartTime: string | null = useMemo(() => {
    if (scheduledJob.timeRanges && scheduledJob.timeRanges.length > 0) {
      if (scheduledJob.timeRanges[0].startTime !== null) {
        return formatTimeForDisplay(scheduledJob.timeRanges[0].startTime);
      }
    }
    return null;
  }, [scheduledJob.timeRanges]);

  const actualEndTime: string | null = useMemo(() => {
    if (
      scheduledJob.completed &&
      scheduledJob.timeRanges &&
      scheduledJob.timeRanges.length > 0
    ) {
      const endTime =
        scheduledJob.timeRanges[scheduledJob.timeRanges.length - 1].endTime;
      if (endTime !== null) {
        return formatTimeForDisplay(endTime);
      }
    }
    return null;
  }, [scheduledJob.completed, scheduledJob.timeRanges]);

  let backgroundColorClass = "";
  const jobStarted = hasJobStarted(scheduledJob.timeRanges);
  if (scheduledJob.completed) {
    backgroundColorClass = "bg-success";
  } else if (isJobPaused(scheduledJob)) {
    backgroundColorClass = "schedule-job-paused";
  } else if (jobStarted) {
    backgroundColorClass = "bg-primary";
  } else if (scheduledJob.skipped) {
    backgroundColorClass = "bg-warning";
  }

  return (
    <Link
      to={routingBuilders.buildScheduleJobPath({
        scheduleIdentifier,
        scheduleJobId: scheduledJob.id,
      })}
      onClick={onJobDetailsLinkClicked}
    >
      <div
        className={"card " + backgroundColorClass}
        style={{ marginBottom: "25px" }}
        data-testid="card"
      >
        <div className="card-body">
          <h5
            className="card-title"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>
              #{index + 1} {scheduledJob.name}
            </div>
            {scheduledJob.isRecurringJob ? (
              <div>
                <FontAwesomeIcon icon={faRepeat} />
              </div>
            ) : null}
          </h5>

          {showScheduledTimes &&
          isStringSet(scheduledJob.startTime) &&
          isStringSet(scheduledJob.endTime) ? (
            <>
              <h5
                className={
                  scheduledJob.completed || jobStarted
                    ? "text-white"
                    : "text-dark"
                }
              >
                <div className="mt-2" data-testid="scheduleTime">
                  {formatTimeForDisplay(scheduledJob.startTime)} -{" "}
                  {formatTimeForDisplay(scheduledJob.endTime)}
                </div>
              </h5>
            </>
          ) : null}

          <div className="card-text">
            <h5>
              {!!scheduledJob.address ? scheduledJob.address : null}
              {!scheduledJob.address &&
              scheduledJob.latitude &&
              scheduledJob.longitude ? (
                <React.Fragment>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div
                      style={{ display: "inline-block", marginRight: "10px" }}
                    >
                      {strings.latitude}:{" "}
                      {formatLatLng(
                        scheduledJob.latitude,
                        scheduledJob.latitudeSignificantDigits
                      )}
                    </div>
                    <div style={{ display: "inline-block" }}>
                      {strings.longitude}:{" "}
                      {formatLatLng(
                        scheduledJob.longitude,
                        scheduledJob.longitudeSignificantDigits
                      )}
                    </div>
                  </div>
                </React.Fragment>
              ) : null}

              {scheduledJob.lastVisitDate ? (
                <div data-testid="lastVisitDateContainer">
                  {strings.lastVisitDate}:{" "}
                  <span data-testid="lastVisitDate" id="lastVisitDate">
                    {formatDateForDisplay(
                      scheduledJob.lastVisitDate,
                      languageContext.language
                    )}
                  </span>
                </div>
              ) : null}

              {scheduledJob.grossRevenuePerVisit ? (
                <div data-testid="grossRevenuePerVisitContainer">
                  <span data-testid="grossRevenuePerVisit">
                    {formatCurrency(scheduledJob.grossRevenuePerVisit)}
                  </span>
                </div>
              ) : null}

              {scheduledJob.highlightNotes &&
              !!(scheduledJob.notes || "").trim() ? (
                <NoteWithHyperlinksDisabled notes={scheduledJob.notes} />
              ) : null}

              {scheduledJob.highlightCustomerNotes &&
              !!(scheduledJob.customerNotes || "").trim() ? (
                <NoteWithHyperlinksDisabled
                  notes={scheduledJob.customerNotes}
                />
              ) : null}

              {scheduledJob.categoriesV2 &&
              scheduledJob.categoriesV2.length > 0 ? (
                <div style={{ marginTop: "10px" }}>
                  {scheduledJob.categoriesV2.map((category) => (
                    <JobCategory
                      key={category.name}
                      name={category.name}
                      color={category.color}
                    />
                  ))}
                </div>
              ) : null}
            </h5>

            {!showScheduledTimes &&
            showCrewEstimatedTimes &&
            estimatedJobTimesLoading !== estimatedJobTimesLoadingState.error ? (
              <React.Fragment>
                <div
                  className={
                    scheduledJob.completed || jobStarted
                      ? "text-white"
                      : "text-dark"
                  }
                >
                  <div className="mt-2">
                    <div>
                      {strings.estimatedArrival}:{" "}
                      {formatEstimate(
                        estimatedJobTimesLoading,
                        estimatedJobTime ? estimatedJobTime.startTime : null
                      )}
                    </div>
                    {actualStartTime ? (
                      <div>
                        {strings.actualArrival}: {actualStartTime}
                      </div>
                    ) : null}
                  </div>
                  <div style={{ marginTop: "1rem" }}>
                    <div>
                      {strings.estimatedComplete}:{" "}
                      {formatEstimate(
                        estimatedJobTimesLoading,
                        estimatedJobTime ? estimatedJobTime.endTime : null
                      )}
                    </div>
                    {actualEndTime ? (
                      <div>
                        {strings.actualComplete}: {actualEndTime}
                      </div>
                    ) : null}
                  </div>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default JobCard;

function formatEstimate(
  estimatedJobTimesLoading: estimatedJobTimesLoadingState,
  time: Date | null
) {
  if (estimatedJobTimesLoading === estimatedJobTimesLoadingState.error) {
    return "Unable to load";
  } else if (
    estimatedJobTimesLoading === estimatedJobTimesLoadingState.loading
  ) {
    return "Loading...";
  } else if (time !== null) {
    return formatTimeForDisplay(time);
  } else {
    return "Unable to calculate";
  }
}

function NoteWithHyperlinksDisabled({ notes }: { notes: string }) {
  const noteTokens = getNoteTokens(notes);

  return (
    <NoteWithHyperlinks
      noteTokens={noteTokens.map((t) => {
        return {
          id: t.id,
          contents:
            t.contents +
            // When removing the link, need to preserve the trailing whitespace
            ("trailingWhitespace" in t ? t.trailingWhitespace : ""),
          trailingWhitespace:
            "trailingWhitespace" in t ? t.trailingWhitespace : undefined,
        };
      })}
      containerClassName="text-dark mt-5"
    />
  );
}
