import React from "react";
import { strings } from "../../languages";

interface IProps {
  actualCrewMembers: number | undefined;
  onChange(newValue: number): void;
}

export const CrewMemberCount: React.SFC<IProps> = ({
  actualCrewMembers,
  onChange
}) => (
  <h6>
    <label style={{ width: "175px" }}>{strings.crewMembers}:</label>
    <div style={{ display: "inline-block" }}>
      <select
        className="form-control"
        style={{ width: "125px" }}
        value={actualCrewMembers}
        onChange={e => {
          const newActualCrewMembers = parseInt(e.target.value, 10);
          onChange(newActualCrewMembers);
        }}
      >
        {Array.from(Array(20).keys())
          .map(k => k + 1)
          .map(k => (
            <option key={k} value={k}>
              {k}
            </option>
          ))}
      </select>
    </div>
  </h6>
);
