import { InputHTMLAttributes } from "react";

interface IProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  onValueChange(value: string): void;
}

const CurrencyInput: React.FunctionComponent<IProps> = ({
  onValueChange,
  ...props
}) => {
  return (
    <input
      {...props}
      type="number"
      step=".01"
      onChange={(e) => {
        let newValue = e.currentTarget.value;
        if (newValue) {
          newValue = newValue.replace("$", "");
        }

        onValueChange(newValue);
      }}
    />
  );
};

export default CurrencyInput;
