import { defaultErrorMessage } from "./defaults";

export function getErrorMessageFromError(
  err: any,
  errorMessageIfNoneReturned?: string
) {
  if (!errorMessageIfNoneReturned) {
    errorMessageIfNoneReturned = defaultErrorMessage;
  }

  let errorMessage = errorMessageIfNoneReturned;
  if (err.response && typeof err.response === "object") {
    if (err.response.message && err.response.errorCode) {
      errorMessage = err.response.message;
    } else if (err.response.title && err.response.traceId) {
      errorMessage = err.response.title;
    } else {
      const properties = Object.getOwnPropertyNames(err.response);
      properties.forEach((property) => {
        if (err.response[property].length > 0) {
          errorMessage = err.response[property];
        }
      });
    }
  } else if (typeof err.response === "string" && err.response.length > 0) {
    errorMessage = err.response;
  } else if (err.status === 401) {
    errorMessage =
      "Your session has timed out.  Please reload this page and login again.";
  } else if (typeof err === "string") {
    errorMessage = err;
  }

  return errorMessage;
}
