import { z } from "zod";

export default interface IPhoto {
  id: string | null;
  caption: string;
  imagePath: string;
  contentType: string;
  thumbnails: Array<IPhotoThumbnail>;
}

export const photoThumbnailSchema = z.object({
  imagePath: z.string(),
  thumbnailKey: z.number(),
  actualHeight: z.number(),
  actualWidth: z.number(),
});

export type IPhotoThumbnail = z.infer<typeof photoThumbnailSchema>;
