import { useRef, useState, useEffect } from "react";
import { FieldValues, UseFormGetValues } from "react-hook-form";
import { Observable, Subject, merge, forkJoin, of } from "rxjs";
import { AjaxResponse } from "rxjs/ajax";
import {
  debounceTime,
  tap,
  switchMap,
  catchError,
  timeout,
} from "rxjs/operators";
import { IScheduleIdentifier } from "../../../models/IScheduleIdentifier";
import { getErrorMessageFromError } from "../../errorParsing/getErrorMessageFromError";
import { ErrorResponse } from "../types/ErrorResponse";
import { IInvoiceItem } from "../types/IInvoiceItem";
import { TriggerBillableFormSave } from "../types/TriggerBillableFormSave";

type SaveSubject = {
  successCallback?: () => void;
  failureCallback?: () => void;
};

export function useBillableItemFormSave<
  TFormData extends FieldValues,
  TPayload
>({
  scheduleIdentifier,
  jobInstanceId,
  getValues,
  formRef,
  invoiceItems,
  save,
  mapFormDataToPayload,
  shouldFailTriggerSave,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
  getValues: UseFormGetValues<TFormData>;
  formRef: React.RefObject<HTMLFormElement>;
  invoiceItems: Array<IInvoiceItem>;
  save: (args: {
    scheduleIdentifier: IScheduleIdentifier;
    jobInstanceId: string;
    payload: TPayload;
  }) => Observable<AjaxResponse>;
  mapFormDataToPayload: (formData: TFormData) => TPayload;
  shouldFailTriggerSave: () => boolean;
}) {
  const debouncedSaveSubject = useRef(new Subject<SaveSubject>());
  const immediateSaveSubject = useRef(new Subject<SaveSubject>());

  const [saveQueued, setSaveQueued] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState<ErrorResponse | null>(null);
  const [isFormValid, setIsFormValid] = useState(true);

  useEffect(() => {
    const handler = (e: BeforeUnloadEvent) => {
      if (saveQueued || saving) {
        const message = "Changes to the form will not be saved if you leave";

        e.preventDefault();
        e.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handler);

    return function cleanup() {
      window.removeEventListener("beforeunload", handler);
    };
  }, [saveQueued, saving]);

  const currentCallbacks = useRef<SaveSubject | null>(null);

  useEffect(() => {
    const debouncedEventStream = debouncedSaveSubject.current.pipe(
      debounceTime(500)
    );

    const subscription = merge(
      debouncedEventStream,
      immediateSaveSubject.current
    )
      .pipe(
        tap((r) => {
          setSaveQueued(false);
          setSaving(true);
          setSaveError(null);

          if (r.successCallback || r.failureCallback) {
            currentCallbacks.current = r;
          }
        }),
        switchMap((input) => {
          const values = getValues();
          return forkJoin({
            success: of(true),
            saveResponse: save({
              scheduleIdentifier,
              jobInstanceId,
              payload: mapFormDataToPayload(values),
            }),
          }).pipe(
            timeout(20000),
            catchError((err) => {
              const errorMessage = getErrorMessageFromError(
                err,
                "Error saving changes!"
              );
              setSaveError({
                message: errorMessage,
                was400Error: err.status === 400,
              });
              return of({
                success: false,
              });
            })
          );
        })
      )
      .subscribe({
        next: (result) => {
          if (
            result.success &&
            typeof currentCallbacks.current?.successCallback === "function"
          ) {
            currentCallbacks.current.successCallback();
            currentCallbacks.current = null;
          } else if (
            !result.success &&
            typeof currentCallbacks.current?.failureCallback === "function"
          ) {
            currentCallbacks.current.failureCallback();
            currentCallbacks.current = null;
          }

          setSaving(false);
        },

        error: (err) => {
          // These log entries are kept intentionally. They shouldn't happen but just in case it does, they'll be available for FullStory.
          console.log("error in useBillableItemFormSave");
          console.log(err);
          setSaving(false);
        },

        complete: () => {
          // These log entries are kept intentionally. They shouldn't happen but just in case it does, they'll be available for FullStory.
          console.log("complete in useBillableItemFormSave");
        },
      });

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, [
    scheduleIdentifier,
    jobInstanceId,
    getValues,
    invoiceItems,
    mapFormDataToPayload,
    save,
  ]);

  const triggerSave: TriggerBillableFormSave = ({
    immediateSave,
    successCallback,
    failureCallback,
    checkValidityOnImmediate,
  }) => {
    if (shouldFailTriggerSave()) {
      if (failureCallback) {
        failureCallback();
      }

      return;
    }

    setSaveQueued(true);

    if (!immediateSave) {
      triggerSaveIfFormValid(debouncedSaveSubject.current);
    } else {
      if (checkValidityOnImmediate) {
        triggerSaveIfFormValid(immediateSaveSubject.current);
      } else {
        immediateSaveSubject.current.next({ successCallback, failureCallback });
      }
    }

    function triggerSaveIfFormValid(saveSubject: Subject<SaveSubject>) {
      // Wrap in settimeout to allow dom elements to sync to new values
      // before evaluating form validity
      setTimeout(() => {
        if (formRef.current !== null) {
          const isValid = formRef.current.checkValidity();
          setIsFormValid(isValid);

          if (isValid) {
            saveSubject.next({
              successCallback,
              failureCallback,
            });
          } else {
            if (failureCallback) {
              failureCallback();
            }
          }
        }
      });
    }
  };

  return {
    saving,
    saveError,
    setSaveError,
    triggerSave,
    isFormValid,
    hasUnsavedChanges: saveQueued || saving,
  };
}
