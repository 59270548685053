import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { strings } from "../languages";
import { IWorksheetForJob } from "../models/IWorksheetForJob";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import { routingBuilders } from "../services/routing";
import { IScheduleIdentifier } from "../models/IScheduleIdentifier";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

export function JobWorksheetsList({
  scheduleIdentifier,
  scheduleJobId,
  worksheetsForJob,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  scheduleJobId: string;
  worksheetsForJob: Array<IWorksheetForJob>;
}) {
  return worksheetsForJob.length > 0 ? (
    <div
      style={{ marginTop: "20px" }}
      className="form-group"
      data-testid="worksheetContainer"
    >
      <h4>{strings.worksheets}: </h4>
      {worksheetsForJob.map((worksheet) => (
        <div key={worksheet.id} className="mb-1">
          <h5>
            <Link
              to={routingBuilders.buildWorksheetPath({
                scheduleIdentifier,
                scheduleJobId,
                worksheetForJobId: worksheet.id,
              })}
            >
              {worksheet.completed ? (
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-success mr-1"
                  fixedWidth
                  data-testid="worksheetIcon"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faFile}
                  className="mr-1"
                  fixedWidth
                  data-testid="worksheetIcon"
                />
              )}

              <span data-testid="worksheetTitle">{worksheet.title}</span>
            </Link>
          </h5>
        </div>
      ))}
    </div>
  ) : null;
}
