import { useMemo } from "react";
import App from "../App";
import { dayOffsetQueryParameterName } from "../services/routing";
import { useQuery } from "../services/useQuery";

export function AppContainer({
  dayScheduleId,
  techAppCrewId,
  jobListingFooterElement,
  alwaysEnableFullStory,
}: {
  dayScheduleId: string;
  techAppCrewId: string | null;
  jobListingFooterElement: React.ReactNode | null;
  alwaysEnableFullStory: boolean;
}) {
  const dayOffset = useGetDayOffset();

  const scheduleIdentifier = useMemo(() => {
    return {
      dayOffset,
      linkedDayScheduleId: dayScheduleId,
      techAppCrewId,
    };
  }, [dayOffset, dayScheduleId, techAppCrewId]);

  return (
    <App
      scheduleIdentifier={scheduleIdentifier}
      jobListingFooterElement={jobListingFooterElement}
      alwaysEnableFullStory={alwaysEnableFullStory}
    />
  );
}

function useGetDayOffset() {
  const params = useQuery();

  return getDayOffset(params);
}

function getDayOffset(query: URLSearchParams) {
  const parameterValue = query.get(dayOffsetQueryParameterName);

  if (!parameterValue) {
    return 0;
  }

  const parsedValue = parseInt(parameterValue);
  if (isNaN(parsedValue)) {
    return 0;
  }

  return parsedValue;
}
