import React from "react";
import { RouteComponentProps, withRouter } from "react-router";

interface IProps extends RouteComponentProps<{}> {
  onMovedToJobDetails: () => void;
  onMovedToJobListing: () => void;
  onMoveFromSignature: () => void;
}

class ScrollToTop extends React.Component<IProps, {}> {
  componentDidUpdate(prevProps: IProps) {
    if (this.props.location !== prevProps.location) {
      if (prevProps.location.pathname.endsWith("sign")) {
        this.props.onMoveFromSignature();
      } else if (this.isDetails(this.props.location.pathname)) {
        this.props.onMovedToJobDetails();
      } else if (!this.props.location.pathname.endsWith("/form")) {
        this.props.onMovedToJobListing();
      }
    }
  }

  render() {
    return this.props.children;
  }

  isDetails(path: string): boolean {
    const parts = path.split("/");
    return parts.length === 3;
  }
}

export default withRouter(ScrollToTop);
