import React from "react";
import { IScheduleIdentifier } from "../models/IScheduleIdentifier";
import { routingBuilders } from "../services/routing";
import { GenericFooter } from "./JobDetails/GenericFooter";
import Error from "./Error";

interface IProps {
  scheduleIdentifier: IScheduleIdentifier;
}

export const JobMovedErrorAlert: React.FunctionComponent<IProps> = ({
  scheduleIdentifier,
}) => {
  return (
    <React.Fragment>
      <Error
        errorHeader="Job Moved"
        errorDetails="The job was moved from this day. Please contact your office for
          details about the change."
      />
      <GenericFooter
        backLink={routingBuilders.buildSchedulePath({
          scheduleIdentifier,
        })}
      />
    </React.Fragment>
  );
};
