import * as FullStory from "@fullstory/browser";
import { trackTrace } from "./applicationInsights";
import configuration from "./configuration";

let fullStoryInitialized: boolean = false;

export function fullStoryInit() {
  try {
    FullStory.init({ orgId: configuration.fullStoryId });
    fullStoryInitialized = true;
  } catch (e) {
    trackTrace(`Fullstory failed to initialize details: ${e}`);
  }
}

export function fullStoryIdentify(
  uid: string,
  customVars?: FullStory.UserVars | undefined
) {
  try {
    if (fullStoryInitialized) {
      FullStory.identify(uid, customVars);
    }
  } catch (e) {
    trackTrace(`Fullstory failed to identify details: ${e}`);
  }
}

export function fullStoryLogError(error: unknown) {
  try {
    if (fullStoryInitialized) {
      if (error instanceof Error) {
        let err: any = error;
        if (err.status === 400 || err.status === 404 || err.status === 401) {
          FullStory.log(
            "log",
            `Error: ${err.name} Message: ${err.message} Cause: ${err.cause} Stack: ${err.stack} Status: ${err.status} Response: ${err.response}`
          );
        } else {
          FullStory.log(
            "error",
            `Error: ${err.name} Message: ${err.message} Cause: ${err.cause} Stack: ${err.stack} Status: ${err.status} Response: ${err.response}`
          );
        }
      } else {
        let errorMessage;
        if (error && typeof error === "object") {
          errorMessage = safeStringify(error);
        } else if (error && typeof error === "function") {
          errorMessage = "<function>";
        } else {
          errorMessage = error;
        }

        FullStory.log("error", `Error: ${errorMessage}`);
      }
    }
  } catch (e) {
    trackTrace(`Fullstory failed to logError details: ${e}`);
  }
}

function safeStringify(error: object): any {
  try {
    return JSON.stringify(error);
  } catch {
    return "<exception in safeStringify>";
  }
}

export function fullStoryLogInfo(message: string) {
  try {
    if (fullStoryInitialized) {
      FullStory.log("log", message);
    }
  } catch (e) {
    trackTrace(`Fullstory failed to logInfo details: ${e}`);
  }
}

export function fullStoryTrack(
  eventName: string,
  eventProperties?: { [key: string]: any }
) {
  try {
    if (fullStoryInitialized) {
      FullStory.event(eventName, eventProperties ?? []);
    }
  } catch (e) {
    trackTrace(`Fullstory failed to track event details: ${e}`);
  }
}
