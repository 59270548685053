import React from "react";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import Spinner from "../../../components/Spinner";
import { IPromptMessageSection } from "../models/IPromptMessageSection";
import { strings } from "../../../languages";

interface IProps {
  showPrompt: boolean;
  promptMessage: string | React.ReactNode;
  promptSaveText?: string | null;
  promptCancelText?: string | null;
  promptSubMessage?: string | null | Array<IPromptMessageSection>;
  promptSubMessageClassName?: string;
  secondaryButtons?: Array<ISecondaryButton> | null;
  onConfirm: () => void;
  onCancel: () => void;
  showSpinner?: boolean;
}

interface ISecondaryButton {
  text: string;
  onClick(): void;
}

const Prompt: React.FunctionComponent<IProps> = ({
  showPrompt,
  promptMessage,
  promptSaveText,
  promptCancelText,
  promptSubMessage,
  promptSubMessageClassName,
  onConfirm,
  onCancel,
  secondaryButtons,
  showSpinner,
}) => {
  const primarySaveButton = (
    <button
      className="btn btn-primary"
      type="button"
      onClick={() => {
        onConfirm();
      }}
      data-testid="promptSave"
    >
      {promptSaveText || "Yes"}
    </button>
  );

  const saveButtons = (
    <>
      {primarySaveButton}
      {!!secondaryButtons
        ? secondaryButtons.map((b) => (
            <div key={b.text} className="btn btn-secondary" onClick={b.onClick}>
              {b.text}
            </div>
          ))
        : null}
    </>
  );

  return (
    <Modal isOpen={showPrompt} backdrop="static" toggle={onCancel}>
      <ModalBody>
        {showSpinner ? <Spinner /> : null}

        <div>{promptMessage}</div>
        {!!promptSubMessage ? (
          <div
            style={{ marginTop: "10px" }}
            className={promptSubMessageClassName}
          >
            <small>
              {typeof promptSubMessage === "string"
                ? promptSubMessage
                : promptSubMessage.map((m, index) => {
                    if (m.bold) {
                      return (
                        <strong
                          className={getSubMessageClassName(m)}
                          key={index}
                        >
                          {m.text}
                        </strong>
                      );
                    } else {
                      return (
                        <span className={getSubMessageClassName(m)} key={index}>
                          {m.text}
                        </span>
                      );
                    }
                  })}
            </small>
          </div>
        ) : null}
      </ModalBody>
      <ModalFooter>
        {saveButtons}
        <button className="btn btn-secondary" onClick={onCancel} type="button">
          {promptCancelText || strings.no}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default Prompt;

function getSubMessageClassName(m: IPromptMessageSection) {
  let className = "";

  if (m.dangerColor) {
    className += " text-danger";
  }

  if (m.newBlock) {
    className += " d-block";
  }

  return className;
}
