import ITodoItem from "./ITodoItem";
import { ICustomCrewQuestionResponse } from "./ICustomCrewQuestionResponse";
import { IFile } from "./IFile";
import { ITimeRange } from "./ITimeRange";
import { ICustomCrewQuestion } from "./ICustomCrewQuestion";
import { ICategory } from "./ICategory";
import IJobLineItem from "./IJobLineItem";
import { IWorksheetForJob } from "./IWorksheetForJob";

export default interface IScheduledJob {
  name: string;
  address: string;
  phoneNumber: string;
  alternativePhoneNumber: string;
  emailAddresses: Array<string>;
  latitude: number | undefined | null;
  longitude: number | undefined | null;
  latitudeSignificantDigits: number | null;
  longitudeSignificantDigits: number | null;
  addressPlaceId: string;
  id: string;
  highlightNotes: boolean;
  notes: string;
  highlightCustomerNotes: boolean;
  customerNotes: string;
  estimatedManHours: number | null;
  actualCrewMembers?: number;
  notesFromCrew?: string;
  todoItems: Array<ITodoItem>;
  customCrewQuestionResponses: Array<ICustomCrewQuestionResponse>;
  photos: Array<IFile>;
  photosFromAdmin: Array<IFile>;
  completed: boolean;
  skipped: boolean;
  timeRanges: Array<ITimeRange>;
  customCrewQuestions: Array<ICustomCrewQuestion>;
  isRecurringJob: boolean;
  categoriesV2: Array<ICategory>;
  lastVisitDate: string | null;
  grossRevenuePerVisit: number | null;
  jobLineItems: Array<IJobLineItem> | null;
  lineItemType: CrewScheduleLineItemType;
  invoicePaid?: boolean | null;
  hasPreviousPayments: boolean | null;
  isMultiDayProject: boolean | null;
  showJobHistory: boolean;
  proposalAccepted: boolean | null;
  hasOptedIntoSMS: boolean | null;
  startTime: string | null;
  endTime: string | null;
  estimateRequiresPaymentMethodOnFile: boolean | null;
  purchaseOrderNumber: string | null;
  worksheets: Array<IWorksheetForJob>;
}

export enum CrewScheduleLineItemType {
  Job = 0,
  Project = 1,
}
