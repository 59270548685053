import { getLineItemSubtotal } from "./getLineItemSubtotal";
import { IAmountCalculationLineItem } from "./IAmountCalculationLineItem";

export function getLineItemTaxableAmount(
  rate: number | null,
  lineItems: Array<IAmountCalculationLineItem>
) {
  if (lineItems.length === 0 || rate === null) {
    return 0;
  }

  return lineItems.reduce((sum, li) => {
    let taxableAmount = 0;
    if (li.taxable) {
      taxableAmount = rate * getLineItemSubtotal(li);
    }

    return sum + taxableAmount;
  }, 0);
}
