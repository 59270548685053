import * as React from "react";
import TextareaAutosize from "react-autosize-textarea/lib";
import { photoThumbnailSchema } from "../models/IPhoto";
import Prompt from "../../prompt/components/Prompt";
import ImageWithDeleteOverlay from "./ImageWithDeleteOverlay";
import photoDisplayService, {
  getFileComponents,
  IPhotoProperiesForImgSrc,
  isImageFile,
} from "../services/fileService";
import { strings } from "../../../languages";
import { isStringSet } from "../../typeUtilities/isStringSet";
import { formatDateTimeForDateTimeDisplay } from "../../../services/dateTimeService";
import parseISO from "date-fns/parseISO";
import { useContext } from "react";
import { LanguageContext } from "../../../App";
import { z } from "zod";

interface IProps {
  tenantId: string;
  imagePrefix: string;
  file: IFormDataFile;
  onFileRemoved: (fileId: string | null, imagePath: string | null) => void;
  onFileUpdated: (
    fileId: string | null,
    imagePath: string | null,
    caption: string
  ) => void;
  onPhotoClicked: () => void;
  disabled?: boolean;
}

export const formDataFileSchema = z.object({
  id: z.string(),
  caption: z.string(),
  contentType: z.string(),
  imagePath: z.string(),

  // Only for existing items
  thumbnails: z.array(photoThumbnailSchema),

  // Only applicable for new items
  actualWidth: z.nullable(z.number()),
  actualHeight: z.nullable(z.number()),
  timestamp: z.nullable(z.string()),
});

export type IFormDataFile = z.infer<typeof formDataFileSchema>;

function ExistingForForm({
  disabled,
  onFileUpdated,
  imagePrefix,
  tenantId,
  file,
  onFileRemoved,
}: IProps) {
  const languageContext = useContext(LanguageContext);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    React.useState(false);

  const deleteFile = () => {
    onFileRemoved(file.id, file.imagePath);
  };

  let isImage: boolean;
  let imageElement: JSX.Element | null;
  if (isImageFile(file)) {
    const thumbnailWidth = 350;
    let photoProps: IPhotoProperiesForImgSrc | null;
    if (file.id) {
      photoProps = photoDisplayService.getPhotoPropertiesForImgSrc(
        imagePrefix,
        file.id,
        file.thumbnails,
        thumbnailWidth
      );
    } else if (tenantId && file.imagePath && file.contentType) {
      photoProps =
        photoDisplayService.getPhotoPropertiesForImgSrcWithoutPhotoId(
          tenantId,
          imagePrefix,
          file.imagePath,
          file.contentType,
          file.thumbnails,
          thumbnailWidth
        );
    } else {
      throw new Error("image missing id OR path + content type + tenant ID");
    }

    if (!photoProps) {
      return null;
    }

    const imageThumbnailSource = photoProps.url;
    const height = photoProps.height;
    const width = photoProps.width;

    imageElement = (
      <ImageWithDeleteOverlay
        alt="Thumbnail"
        src={imageThumbnailSource}
        width={width}
        height={height}
        onDelete={() => {
          deleteFile();
        }}
      />
    );
    isImage = true;
  } else {
    imageElement = null;
    isImage = false;
  }

  const fileComponents = getFileComponents(file.imagePath);

  let path: string;
  let name: string;
  if (fileComponents.success) {
    path = fileComponents.linkUrl as string;
    name = fileComponents.name as string;
  } else {
    path = file.imagePath;
    name = file.imagePath;
  }

  return (
    <React.Fragment>
      <div className="form-group" style={{ textAlign: "center" }}>
        {isImage ? (
          imageElement
        ) : file.imagePath ? (
          <React.Fragment>
            <a
              href={`${imagePrefix}/${path}`}
              className="btn btn-link"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "underline",
                fontWeight: "bold",
                margin: "0",
                padding: "0",
              }}
            >
              {name}
            </a>{" "}
            -{" "}
            <button
              type="button"
              className="btn btn-link"
              onClick={(e) => {
                e.stopPropagation();
                setShowDeleteConfirmation(true);
              }}
              style={{ margin: "0", padding: "0" }}
              data-testid="existingFileDelete"
            >
              <span
                className="text-danger"
                style={{
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
              >
                {strings.delete}
              </span>
            </button>
          </React.Fragment>
        ) : null}
        {isStringSet(file.timestamp) ? (
          <div className="mt-2" data-testid="timestampContainer">
            {formatDateTimeForDateTimeDisplay(
              parseISO(file.timestamp),
              languageContext.language
            )}
          </div>
        ) : null}
        <div className="form-group mt-2">
          <TextareaAutosize
            maxRows={10}
            className="form-control"
            value={file.caption}
            placeholder="Caption"
            data-testid="caption"
            onChange={(e) => {
              onFileUpdated(file.id, file.imagePath, e.currentTarget.value);
            }}
            disabled={disabled}
          />
        </div>
        <Prompt
          promptMessage={strings.deleteFile}
          promptSaveText={strings.delete}
          onCancel={() => setShowDeleteConfirmation(false)}
          onConfirm={() => {
            deleteFile();
            setShowDeleteConfirmation(false);
          }}
          showPrompt={showDeleteConfirmation}
        />
      </div>
    </React.Fragment>
  );
}

export default ExistingForForm;
