import { useState, useEffect } from "react";
import { useScheduleJobCache } from "../../../hooks/useScheduleJobCache";
import { getErrorMessageFromError } from "../../../libraries/errorParsing/getErrorMessageFromError";
import { IInvoiceForPaymentForm } from "../../../libraries/invoicePaymentForm/models/IInvoiceForPaymentForm";
import ISchedule from "../../../models/ISchedule";
import { IScheduleIdentifier } from "../../../models/IScheduleIdentifier";
import { getOrCreateInvoiceForPaymentForm } from "../services/invoiceDataProvider";
import { ErrorResponse } from "../../../libraries/billableForm/types/ErrorResponse";

export function useLoadInvoiceForPaymentForm({
  schedule,
  scheduleIdentifier,
  scheduleJobId,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  scheduleJobId: string;
  schedule: ISchedule;
}) {
  const { scheduleJob } = useScheduleJobCache(schedule, scheduleJobId ?? "");
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState<IInvoiceForPaymentForm | null>(null);
  const [loadError, setLoadError] = useState<ErrorResponse | null>(null);
  const [invoicePaid, setInvoicePaid] = useState(false);

  useEffect(() => {
    loadInvoiceForPaymentForm({
      setLoading,
      setLoadError,
      linkedDayScheduleId: scheduleIdentifier.linkedDayScheduleId,
      dayOffset: scheduleIdentifier.dayOffset,
      techAppCrewId: scheduleIdentifier.techAppCrewId,
      scheduleJobId,
      setInvoice,
      setInvoicePaid,
    });
  }, [
    scheduleIdentifier.dayOffset,
    scheduleIdentifier.linkedDayScheduleId,
    scheduleIdentifier.techAppCrewId,
    scheduleJobId,
  ]);

  return {
    loading,
    invoice,
    loadError,
    invoicePaid,
    scheduleJob,
    setInvoice,
    setInvoicePaid,
    setLoading,
    setLoadError,
  };
}

export function loadInvoiceForPaymentForm({
  setLoading,
  setLoadError,
  linkedDayScheduleId,
  dayOffset,
  techAppCrewId,
  scheduleJobId,
  setInvoice,
  setInvoicePaid,
}: {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setLoadError: React.Dispatch<React.SetStateAction<ErrorResponse | null>>;
  linkedDayScheduleId: string;
  dayOffset: number;
  techAppCrewId: string | null;
  scheduleJobId: string;
  setInvoice: React.Dispatch<
    React.SetStateAction<IInvoiceForPaymentForm | null>
  >;
  setInvoicePaid: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  setLoading(true);
  setLoadError(null);
  getOrCreateInvoiceForPaymentForm({
    scheduleIdentifier: {
      linkedDayScheduleId: linkedDayScheduleId,
      dayOffset: dayOffset,
      techAppCrewId,
    },
    jobInstanceId: scheduleJobId,
  }).subscribe({
    next: (result) => {
      setLoading(false);
      setInvoice(result);
      setInvoicePaid(result.paid);
    },

    error: (err) => {
      setLoading(false);

      const allowRetry = err.status === 400;
      setLoadError({
        was400Error: allowRetry,
        message: getErrorMessageFromError(
          err,
          "Please check your Internet connection and try again."
        ),
      });
    },
  });
}
