import { faSave } from "@fortawesome/free-solid-svg-icons";
import { useRef, useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { FooterButton } from "../../../components/FooterButton";
import { GenericFooter } from "../../../components/JobDetails/GenericFooter";
import { strings } from "../../../languages";
import { createNewLineItem } from "../functions/createNewLineItem";
import { IInvoiceItem } from "../types/IInvoiceItem";
import { ILineItem } from "./BillableFormLineItem";
import { LineItemFormPageBody } from "./LineItemFormPageBody";
import { useQuery } from "../../../services/useQuery";
import { billableFormConstants } from "../billableFormConstants";

export function LineItemFormPageBodyContainer({
  lineItemId,
  getLineItems,
  setLineItems,
  invoiceItems,
  setInvoiceItems,
  onSaveComplete,
  getLineItemBackLink,
  disabled,
  allowOptionalItems,
  allowZeroQuantity,
}: {
  lineItemId: string | null;
  getLineItems: () => Array<ILineItem>;
  setLineItems: (newValue: Array<ILineItem>) => void;
  invoiceItems: Array<IInvoiceItem>;
  setInvoiceItems: (newValue: Array<IInvoiceItem>) => void;
  onSaveComplete(lineItemId: string): void;
  getLineItemBackLink: (lineItemId: string | null) => string;
  disabled: boolean;
  allowOptionalItems: boolean;
  allowZeroQuantity: boolean;
}) {
  const formRef = useRef<HTMLFormElement>(null);
  const formId = "lineItemForm";

  const [lineItemForForm, setLineItemForForm] = useState<ILineItem | null>(
    null
  );
  const [originalLineItemForForm, setOriginalLineItemForForm] =
    useState<ILineItem | null>(null);

  const history = useHistory();
  const query = useQuery();
  const hideTaxableField =
    query.get(billableFormConstants.hideTaxableField) === "true";

  const hasLoadedData = useRef(false);
  useEffect(() => {
    if (!hasLoadedData.current) {
      let formData: ILineItem | null = null;
      if (lineItemId === null) {
        formData = createNewLineItem(1);
      } else {
        formData = getLineItems().find((li) => li.id === lineItemId) ?? null;
      }

      setLineItemForForm(formData);
      setOriginalLineItemForForm(formData);

      hasLoadedData.current = true;
    }
  }, [lineItemId, getLineItems]);

  const hasDataChanged = useMemo(() => {
    return (
      JSON.stringify(originalLineItemForForm) !==
      JSON.stringify(lineItemForForm)
    );
  }, [originalLineItemForForm, lineItemForForm]);

  if (!lineItemForForm) {
    return <></>;
  }

  const backLinkPath = getLineItemBackLink(lineItemId ?? null);

  return (
    <div style={{ marginBottom: "100px" }}>
      <LineItemFormPageBody
        setInvoiceItems={setInvoiceItems}
        disabled={disabled}
        lineItem={lineItemForForm}
        invoiceItems={invoiceItems}
        onClearErrorMessage={() => {}}
        onLineItemChange={(newValue) => {
          setLineItemForForm(newValue);
        }}
        formRef={formRef}
        formId={formId}
        hideTaxableField={hideTaxableField}
        onSubmit={() => {
          const isMatchingLineItem = (li: ILineItem) =>
            li.id === lineItemForForm.id;
          const newLineItems = getLineItems().map((li) => {
            if (isMatchingLineItem(li)) {
              return lineItemForForm;
            } else {
              return li;
            }
          });

          if (!newLineItems.some(isMatchingLineItem)) {
            newLineItems.push(lineItemForForm);
          }

          setLineItems(newLineItems);

          onSaveComplete(lineItemForForm.id);
        }}
        allowOptional={allowOptionalItems}
        allowZeroQuantity={allowZeroQuantity}
      />

      <GenericFooter
        blockBackLink={hasDataChanged}
        onBackClick={() => {
          if (hasDataChanged) {
            if (window.confirm(strings.unsavedChangesWarning)) {
              history.push(backLinkPath);
            }
          }
        }}
        backLink={backLinkPath}
      >
        {!disabled ? (
          <div className="text-center">
            <FooterButton
              icon={faSave}
              caption={lineItemId === null ? strings.add : strings.update}
              color="text-primary"
              type="submit"
              formId={formId}
              testId="lineItemSave"
            />
          </div>
        ) : null}
      </GenericFooter>
    </div>
  );
}
