import { ajax } from "rxjs/ajax";
import { map } from "rxjs/operators";
import { IScheduleIdentifier } from "../../../models/IScheduleIdentifier";
import { buildFullUrl, getHeaders } from "../../../services/DataProvider";
import { IEstimateForForm } from "../models/IEstimateForForm";
import { IEstimateTemplate } from "../models/IEstimateTemplate";
import { IEstimateForPresentation } from "../models/IEstimateForPresentation";
import { JobToApplyProposalTo } from "../enums/JobToApplyProposalTo";
import { ISelectedItem } from "../../../models/ISelectedItem";
import { IInvoiceForPaymentForm } from "../../../libraries/invoicePaymentForm/models/IInvoiceForPaymentForm";

export type UpdateEstimatePayload = Omit<
  Partial<IEstimateForForm>,
  "signatureImagePath" | "isDraft"
>;

export function getEstimateForForm({
  scheduleIdentifier,
  jobInstanceId,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
}) {
  return ajax
    .get(
      buildFullUrl(
        `Proposal/${scheduleIdentifier.linkedDayScheduleId}/${jobInstanceId}?dayOffset=${scheduleIdentifier.dayOffset}`
      ),
      getHeaders()
    )
    .pipe(
      map((response) => {
        return response.response as IEstimateForForm;
      })
    );
}

export function getEstimateTemplates({
  scheduleIdentifier,
}: {
  scheduleIdentifier: IScheduleIdentifier;
}) {
  return ajax
    .get(
      buildFullUrl(
        `Proposal/${scheduleIdentifier.linkedDayScheduleId}/templates?dayOffset=${scheduleIdentifier.dayOffset}`
      ),
      getHeaders()
    )
    .pipe(
      map((response) => {
        return response.response.templates as Array<IEstimateTemplate>;
      })
    );
}

export function updateEstimateForForm({
  scheduleIdentifier,
  jobInstanceId,
  payload,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
  payload: UpdateEstimatePayload;
}) {
  return ajax.patch(
    buildFullUrl(
      `Proposal/${scheduleIdentifier.linkedDayScheduleId}/${jobInstanceId}?dayOffset=${scheduleIdentifier.dayOffset}`
    ),
    payload,
    getHeaders()
  );
}

export function acceptEstimate({
  scheduleIdentifier,
  jobInstanceId,
  payload,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
  payload: { signatureImagePath: string };
}) {
  return ajax.post(
    buildFullUrl(
      `Proposal/${scheduleIdentifier.linkedDayScheduleId}/${jobInstanceId}/accept?dayOffset=${scheduleIdentifier.dayOffset}`
    ),
    payload,
    getHeaders()
  );
}

export function deleteAcceptForEstimate({
  scheduleIdentifier,
  jobInstanceId,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
}) {
  return ajax.delete(
    buildFullUrl(
      `Proposal/${scheduleIdentifier.linkedDayScheduleId}/${jobInstanceId}/accept?dayOffset=${scheduleIdentifier.dayOffset}`
    ),
    getHeaders()
  );
}

export function emailEstimate({
  scheduleIdentifier,
  jobInstanceId,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
}) {
  return ajax.post(
    buildFullUrl(
      `Proposal/${scheduleIdentifier.linkedDayScheduleId}/${jobInstanceId}/email?dayOffset=${scheduleIdentifier.dayOffset}`
    ),
    {},
    getHeaders()
  );
}

export function textEstimate({
  scheduleIdentifier,
  jobInstanceId,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
}) {
  return ajax.post(
    buildFullUrl(
      `Proposal/${scheduleIdentifier.linkedDayScheduleId}/${jobInstanceId}/text?dayOffset=${scheduleIdentifier.dayOffset}`
    ),
    {},
    getHeaders()
  );
}

export function getOrCreateEstimateForPresentationForm({
  scheduleIdentifier,
  jobInstanceId,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
}) {
  return ajax
    .post(
      buildFullUrl(
        `Proposal/${scheduleIdentifier.linkedDayScheduleId}/${jobInstanceId}/proposalforpresentation?dayOffset=${scheduleIdentifier.dayOffset}`
      ),
      {},
      getHeaders()
    )
    .pipe(
      map((response) => {
        return response.response as {
          proposal: IEstimateForPresentation;
          depositInvoice: IInvoiceForPaymentForm | null;
          crewMemberSelectedJobToApplyTo: boolean;
          canText: boolean;
          canEmail: boolean;
        };
      })
    );
}

export function applyToJob({
  scheduleIdentifier,
  jobInstanceId,
  payload,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
  payload: {
    jobToApplyTo: JobToApplyProposalTo;
  };
}) {
  return ajax.post(
    buildFullUrl(
      `Proposal/${scheduleIdentifier.linkedDayScheduleId}/${jobInstanceId}/applyToJob?dayOffset=${scheduleIdentifier.dayOffset}`
    ),
    payload,
    getHeaders()
  );
}

export function updateEstimateWithSelectedItems({
  scheduleIdentifier,
  jobInstanceId,
  selectedLineItemIds,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
  selectedLineItemIds: Array<ISelectedItem>;
}) {
  let patch = {
    changes: selectedLineItemIds.map((s) => {
      return { method: "PATCH", id: s.id, body: { selected: s.selected } };
    }),
  };
  return ajax
    .patch(
      buildFullUrl(
        `Proposal/${scheduleIdentifier.linkedDayScheduleId}/${jobInstanceId}/UpdateWithSelectedItems?dayOffset=${scheduleIdentifier.dayOffset}`
      ),
      JSON.stringify(patch),
      getHeaders()
    )
    .pipe(
      map((response) => {
        return response.response.proposal as IEstimateForPresentation;
      })
    );
}
