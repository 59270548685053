import { ajax } from "rxjs/ajax";
import { map } from "rxjs/operators";
import { IScheduleIdentifier } from "../../../models/IScheduleIdentifier";
import { buildFullUrl, getHeaders } from "../../../services/DataProvider";
import { IFile } from "../../../models/IFile";
import { ICategory } from "../../../models/ICategory";

export interface ICustomerHistoryJob {
  id: string;
  date: string;
  crewName: string;
  notes: string;
  notesFromAdmin: string;
  photos: Array<IFile>;
  photosFromAdmin: Array<IFile>;
  categories: Array<ICategory>;
  jobStatus: CustomerHistoryJobStatus;
}

export enum CustomerHistoryJobStatus {
  notStarted = 0,
  skipped = 1,
  paused = 2,
  inProgress = 3,
  completed = 4,
}

export function getCustomerJobHistory({
  scheduleIdentifier,
  jobInstanceId,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
}) {
  return ajax
    .get(
      buildFullUrl(
        `crew/${scheduleIdentifier.linkedDayScheduleId}/CustomerJobHistory/${jobInstanceId}?dayOffset=${scheduleIdentifier.dayOffset}`
      ),
      getHeaders()
    )
    .pipe(
      map((response) => {
        return response.response.jobs as Array<ICustomerHistoryJob>;
      })
    );
}
