import { useState } from "react";
import Spinner from "../../../components/Spinner";
import { IScheduleIdentifier } from "../../../models/IScheduleIdentifier";
import { formatCurrency } from "../../../services/currencyFormatter";
import { PaymentType } from "../enums/paymentType";
import { IInvoiceForPaymentForm } from "../models/IInvoiceForPaymentForm";
import { IZonedDateTime } from "../models/IZonedDateTime";
import { saveOfflinePayment } from "../services/invoicePaymentFormDataProvider";
import { ReferenceInformationField } from "./ReferenceInformationField";
import { getErrorMessageFromError } from "../../errorParsing/getErrorMessageFromError";
import { strings } from "../../../languages";
import { InvoiceType } from "../../../enums/InvoiceType";

interface IProps {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
  paymentMethodType: PaymentType;
  invoice: IInvoiceForPaymentForm;
  onSave: (saveResult: {
    transactionDateTime: IZonedDateTime | null;
    convenienceFeePaid: number | null;
    paymentMethodAuthorized: boolean;
  }) => void;
  paymentAmount: number;
  invoiceType: InvoiceType;
}

function InvoicePayFormOfflinePayment(props: IProps) {
  const {
    paymentAmount,
    scheduleIdentifier,
    jobInstanceId,
    paymentMethodType,
    invoiceType,
  } = props;

  const [referenceInformation, setReferenceInformation] = useState("");

  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();

          if (saving) {
            return;
          }

          setSaving(true);

          saveOfflinePayment({
            scheduleIdentifier,
            jobInstanceId,
            paymentMethodType,
            paymentReferenceInformation: referenceInformation,
            invoiceType,
          }).subscribe({
            next: () => {
              setSaving(false);

              props.onSave({
                convenienceFeePaid: null,
                paymentMethodAuthorized: false,
                transactionDateTime: null,
              });
            },

            error: (err) => {
              const parsedErrorMessages =
                getErrorMessageFromError(err) ??
                "An unknown error while saving.";

              setErrorMessage(parsedErrorMessages);

              setSaving(false);
            },
          });
        }}
      >
        {saving ? <Spinner testIdPrefix="offline" /> : null}

        <ReferenceInformationField
          referenceInformation={referenceInformation}
          setReferenceInformation={setReferenceInformation}
          idPrefix="offline"
        />

        {errorMessage ? (
          <div className="text-danger my-3" data-testid="errorMessage">
            {errorMessage}
          </div>
        ) : null}

        <div className="text-center">
          <button
            className="btn btn-primary btn-lg"
            type="submit"
            data-testid="offlinePaymentButton"
          >
            {`${strings.pay} ${formatCurrency(paymentAmount)}`}
          </button>
        </div>
      </form>
    </>
  );
}

export default InvoicePayFormOfflinePayment;
