import { Controller, Control, UseFormSetValue } from "react-hook-form";
import { EmailAddresses } from "../../../../libraries/emailAddresses/EmailAddresses";
import Files from "../../../../libraries/formAttachments/components/Index";
import { isPhoto } from "../../../../libraries/formAttachments/services/fileService";
import ISchedule from "../../../../models/ISchedule";
import { IScheduleIdentifier } from "../../../../models/IScheduleIdentifier";
import { IInvoiceItem } from "../../../../libraries/billableForm/types/IInvoiceItem";
import { IInvoiceFormData } from "./IInvoiceFormData";
import { TriggerBillableFormSave } from "../../../../libraries/billableForm/types/TriggerBillableFormSave";
import IScheduledJob from "../../../../models/IScheduleJob";
import BillableFormAmounts from "../../../../libraries/billableForm/components/BillableFormAmounts";
import { ILineItem } from "../../../../libraries/billableForm/components/BillableFormLineItem";
import BillableLineItems from "../../../../libraries/billableForm/components/BillableFormLineItems";
import { BillableFormSummaryField } from "../../../../libraries/billableForm/components/BillableFormSummaryField";
import { strings } from "../../../../languages";
import { routingBuilders } from "../../../../services/routing";
import { CopyJobFilesLink } from "../../../../libraries/formAttachments/components/CopyJobFilesLink";
import { PhoneNumberWithOptIn } from "../../../../libraries/input/PhoneNumberWithOptIn";
import { LineItemErrorMessages } from "../../../../libraries/billableForm/components/BillableFormLineItemsMobile.types";

export function InvoiceFormBody({
  formRef,
  control,
  triggerSave,
  invoiceItems,
  setInvoiceItems,
  lineItems,
  scheduleIdentifier,
  schedule,
  isInvoicePaidWithAmount,
  doesInvoiceHaveSignature,
  isDraft,
  scheduleJob,
  scheduleJobId,
  customerTaxExempt,
  taxRateAlreadySet,
  originalPhoneNumber,
  originalPhoneNumberOptedIntoSms,
  setValue,
  lastEmailAddressInputRef,
  lineItemErrorMessages,
  alwaysShowDepositCredits,
}: {
  formRef: React.RefObject<HTMLFormElement>;
  control: Control<IInvoiceFormData, any>;
  triggerSave: TriggerBillableFormSave;
  invoiceItems: IInvoiceItem[];
  setInvoiceItems: React.Dispatch<React.SetStateAction<Array<IInvoiceItem>>>;
  lineItems: ILineItem[];
  scheduleIdentifier: IScheduleIdentifier;
  schedule: ISchedule;
  isInvoicePaidWithAmount: boolean;
  doesInvoiceHaveSignature: boolean;
  isDraft: boolean;
  customerTaxExempt: boolean;
  scheduleJob: IScheduledJob;
  scheduleJobId: string;
  taxRateAlreadySet: boolean;
  originalPhoneNumber: string;
  originalPhoneNumberOptedIntoSms: boolean;
  setValue: UseFormSetValue<IInvoiceFormData>;
  lastEmailAddressInputRef: React.MutableRefObject<HTMLInputElement | null>;
  lineItemErrorMessages: LineItemErrorMessages;
  alwaysShowDepositCredits: boolean;
}) {
  const isFormDisabled = isInvoicePaidWithAmount || doesInvoiceHaveSignature;
  const taxRateDisabled = customerTaxExempt && !taxRateAlreadySet;

  return (
    <form ref={formRef}>
      <div style={{ marginBottom: "160px" }}>
        <Controller
          control={control}
          name="phoneNumberWithOptInStatus"
          render={({ field }) => (
            <PhoneNumberWithOptIn
              originalPhoneNumber={originalPhoneNumber}
              originalPhoneNumberOptedIntoSms={originalPhoneNumberOptedIntoSms}
              value={field.value}
              onChange={(newValue) => {
                field.onChange(newValue);
                triggerSave({});
              }}
              disabled={isFormDisabled}
            />
          )}
        />

        <label>{strings.customerEmailAddresses}</label>

        <Controller
          control={control}
          name="emails"
          render={({ field }) => (
            <EmailAddresses
              label={strings.customerEmailAddresses}
              emailAddresses={field.value}
              onChange={(newValue) => {
                field.onChange(newValue);
                triggerSave({});
              }}
              required={false}
              disabled={isFormDisabled}
              lastEmailAddressInputRef={lastEmailAddressInputRef}
            />
          )}
        />

        <div className="mt-3">
          <Controller
            control={control}
            name="lineItems"
            render={({ field }) => (
              <BillableLineItems
                hideTaxableField={taxRateDisabled}
                lineItems={field.value}
                invoiceItems={invoiceItems}
                onClearErrorMessage={() => {}}
                elementIdPrefix={""}
                allowOptionalItems={false}
                allowZeroQuantity={false}
                errorMessages={lineItemErrorMessages}
                setLineItems={(newLineItems) => {
                  field.onChange(newLineItems);
                  triggerSave({});
                }}
                setInvoiceItems={(newInvoiceItems) => {
                  setInvoiceItems(newInvoiceItems);
                }}
                disabled={isFormDisabled}
                enableRequiredFields={!isDraft}
                allowBlankAmountPerItem={isDraft}
                allowBlankQuantity={isDraft}
                buildLineItemLink={(id) =>
                  routingBuilders.buildInvoiceLineItemPath({
                    scheduleIdentifier,
                    scheduleJobId,
                    lineItemId: id,
                  })
                }
              />
            )}
          />
        </div>

        <div className={isFormDisabled ? "mt-4" : ""}>
          <Controller
            control={control}
            name="amountAdjustments"
            render={({ field }) => (
              <BillableFormAmounts
                taxRateDisabled={taxRateDisabled}
                lineItems={lineItems}
                disabled={isFormDisabled}
                taxRate={field.value.taxRate}
                depositAmountFields={{
                  depositCreditAmount: field.value.depositCreditAmount,
                  maxDepositCreditAmount: field.value.maxDepositCreditAmount,
                  onDepositCreditAmountChange: (v) => {
                    field.onChange({
                      ...field.value,
                      depositCreditAmount: v,
                    });
                    triggerSave({});
                  },
                  alwaysShowDepositCredits: alwaysShowDepositCredits,
                }}
                requireTaxRate={!isDraft && lineItems.some((li) => li.taxable)}
                onTaxRateChange={(newValue) => {
                  field.onChange({
                    ...field.value,
                    taxRate: newValue,
                  });
                  triggerSave({});
                }}
                discountFields={{
                  discount: field.value.discount,
                  onChange: (newValue) => {
                    field.onChange({
                      ...field.value,
                      discount: newValue,
                    });
                    triggerSave({});
                  },
                  amountOnly: false,
                }}
              />
            )}
          />
        </div>

        <Controller
          control={control}
          name="files"
          render={({ field }) => (
            <Files
              scheduleIdentifier={scheduleIdentifier}
              files={field.value}
              imagePrefix={schedule.imagePrefix}
              header={"Files"}
              tenantId={schedule.tenantId}
              disabled={isFormDisabled}
              onFileAdded={(newFile) => {
                field.onChange([...field.value, newFile]);
                triggerSave({});
              }}
              onFileRemoved={(fileId, imagePath) => {
                field.onChange(
                  field.value.filter((f) => !isPhoto(fileId, imagePath, f))
                );
                triggerSave({});
              }}
              onFileUpdated={(fileId, imagePath, caption) => {
                field.onChange(
                  field.value.map((f) => {
                    if (isPhoto(fileId, imagePath, f)) {
                      return {
                        ...f,
                        caption,
                      };
                    } else {
                      return f;
                    }
                  })
                );
                triggerSave({});
              }}
              onFileUploadingStatusChange={() => {}}
              addAdditionalFiles={
                <CopyJobFilesLink
                  jobPhotos={scheduleJob.photos}
                  existingFiles={field.value}
                  setFiles={(newValue) => {
                    setValue("files", newValue);
                  }}
                  triggerSave={triggerSave}
                />
              }
            />
          )}
        />

        <div className="form-group">
          <Controller
            control={control}
            name="summary"
            render={({ field }) => (
              <BillableFormSummaryField
                value={field.value}
                notesFromCrew={scheduleJob.notesFromCrew ?? ""}
                disabled={isFormDisabled}
                onChange={(newValue) => {
                  field.onChange(newValue);
                  triggerSave({});
                }}
              />
            )}
          />
        </div>
      </div>
    </form>
  );
}
