import TextareaAutosize from "react-autosize-textarea/lib";
import LinkButton from "../../../components/LinkButton";
import { isStringSet } from "../../typeUtilities/isStringSet";
import { strings } from "../../../languages";

export function BillableFormSummaryField({
  value,
  onChange,
  disabled,
  notesFromCrew,
}: {
  value: string;
  onChange(newValue: string): void;
  disabled?: boolean;
  notesFromCrew: string;
}) {
  return (
    <>
      <label htmlFor="summary">{strings.summary}</label>
      <TextareaAutosize
        id="summary"
        className="form-control"
        value={value}
        onChange={(e) => {
          onChange(e.currentTarget.value);
        }}
        disabled={disabled}
      />
      {isStringSet(notesFromCrew ?? null) &&
      value.indexOf(notesFromCrew ?? "") === -1 ? (
        <div>
          <LinkButton
            additionalClassNames="btn-sm"
            text="Copy job notes"
            testId="copyJobNotes"
            onClick={() => {
              if (isStringSet(value)) {
                onChange(`${value}\n${notesFromCrew}`);
              } else {
                onChange(notesFromCrew);
              }
            }}
          />
        </div>
      ) : null}
    </>
  );
}
