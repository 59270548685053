import { strings } from "../../../languages";
import { formatCurrency } from "../../../services/currencyFormatter";
import ResponsiveGridTable, {
  ResponsiveGridTableDisplayType,
} from "../../responsiveGrid/ResponsiveGridTable";
import { isStringSet } from "../../typeUtilities/isStringSet";
import { ICommonLineItem } from "../models/ICommonLineItem";

export default function LineItemDisplay({
  lineItems,
  hideLineItemPrices,
  setLineItems,
  disableSelected,
  failedSelectionUpdateRowIndex,
}: {
  lineItems: Array<ICommonLineItem>;
  hideLineItemPrices: boolean;
  setLineItems?: (
    newLineItems: Array<ICommonLineItem>,
    rowIndex: number
  ) => void;
  disableSelected?: boolean;
  failedSelectionUpdateRowIndex?: number | null;
}) {
  const proposalLineItemRows = lineItems.map((li) => ({
    ...li,
    key: li.id,
  }));

  function updateLineItem<T>(
    onLineItemChanged: (updatedItem: ICommonLineItem) => void,
    lineItem: ICommonLineItem,
    propName: keyof ICommonLineItem,
    propValue: T
  ) {
    onLineItemChanged({
      ...lineItem,
      [propName]: propValue,
    });
  }

  const buildOnLineItemChanged = (li: ICommonLineItem, rowIndex: number) => {
    return (newLineItem: ICommonLineItem) => {
      if (typeof setLineItems === "function") {
        setLineItems(
          lineItems.map((lineItemToUpdate) => {
            if (lineItemToUpdate.id === li.id) {
              return newLineItem;
            } else {
              return lineItemToUpdate;
            }
          }),
          rowIndex
        );
      }
    };
  };

  const responsiveGridBreakpoint = "md";

  return (
    <ResponsiveGridTable
      rowKey={"id"}
      rowGap="20px"
      columnGap="10px"
      breakpoint={responsiveGridBreakpoint}
      columns={[
        {
          header: ({ displayType }) => (
            <div
              className={`${displayType === "desktop" ? "border-bottom" : ""}`}
            >
              {strings.productService}
            </div>
          ),
          width: "auto",
          cell: ({ rowObject: lineItem, index, displayType }) => (
            <div style={getContainerStyle({ rowIndex: index, displayType })}>
              <div>
                <div className="d-flex">
                  {lineItems.some((li) => li.optional) &&
                  (lineItem.optional || displayType === "desktop") ? (
                    <div
                      className="custom-control custom-checkbox"
                      style={{
                        visibility: !lineItem.optional ? "hidden" : undefined,
                      }}
                    >
                      <input
                        id={`Selected${index}`}
                        type="checkbox"
                        className={"custom-control-input"}
                        checked={lineItem["selected"] ?? false}
                        onChange={(e) => {
                          updateLineItem(
                            buildOnLineItemChanged(lineItem, index),
                            lineItem,
                            "selected",
                            e.target.checked
                          );
                        }}
                        data-testid={`Selected${index}`}
                        disabled={disableSelected}
                      />
                      <label
                        htmlFor={`Selected${index}`}
                        data-testid={`SelectedLabel${index}`}
                        className="custom-control-label"
                      >
                        <span className="sr-only"></span>
                      </label>
                    </div>
                  ) : null}
                  <div>
                    {isStringSet(lineItem.name) ? (
                      <div data-testid="lineItemName">
                        <label className="mb-0" htmlFor={`Selected${index}`}>
                          {lineItem.name}
                        </label>
                      </div>
                    ) : null}
                    <div
                      data-testid="lineItemDescription"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {lineItem.description}
                    </div>
                  </div>
                </div>
              </div>
              {failedSelectionUpdateRowIndex === index ? (
                <div>
                  <small className="text-danger">
                    There was an error selecting the item. Please try again.
                  </small>
                </div>
              ) : null}
            </div>
          ),
          key: "name",
          className: "text-nowrap",
          headerClassName: "font-weight-bold",
        },
        {
          header: ({ displayType }) => (
            <div
              className={`${displayType === "desktop" ? "border-bottom" : ""}`}
            >
              {strings.quantity}
            </div>
          ),
          width: "min-content",
          cell: ({ rowObject: lineItem, index, displayType }) => (
            <div style={getContainerStyle({ rowIndex: index, displayType })}>
              {lineItem.quantity}
            </div>
          ),
          key: "quantity",
          className: "text-right",
          cellTestId: "lineItemQuantity",
          headerClassName: "font-weight-bold",
        },
        {
          header: ({ displayType }) => (
            <div
              className={`${displayType === "desktop" ? "border-bottom" : ""}`}
            >
              {strings.unitPrice}
            </div>
          ),
          width: "min-content",
          cell: ({ rowObject: lineItem, index, displayType }) => (
            <div style={getContainerStyle({ rowIndex: index, displayType })}>
              {lineItem.amountPerItem !== null
                ? formatCurrency(lineItem.amountPerItem, true)
                : ""}
            </div>
          ),
          key: "amountPerItem",
          className: "text-right",
          hidden: hideLineItemPrices,
          cellTestId: "lineItemAmountPerItem",
          headerClassName: "font-weight-bold",
        },
        {
          header: ({ displayType }) => (
            <div
              className={`${displayType === "desktop" ? "border-bottom" : ""}`}
            >
              {strings.total}
            </div>
          ),
          width: "min-content",
          contentClassName: "text-nowrap",
          cell: ({ rowObject: lineItem, index, displayType }) => (
            <div style={getContainerStyle({ rowIndex: index, displayType })}>
              <div>
                {lineItem.lineItemTotal !== null
                  ? formatCurrency(lineItem.lineItemTotal, true)
                  : ""}
              </div>
              {lineItem.optional ? (
                <>
                  <div
                    style={{ marginTop: "-5px" }}
                    data-testid="includedStatus"
                  >
                    {!lineItem.selected ? (
                      <small>{strings.notIncluded}</small>
                    ) : lineItem.selected ? (
                      <small className="text-success font-weight-bold">
                        {strings.included}
                      </small>
                    ) : null}
                  </div>{" "}
                </>
              ) : null}
            </div>
          ),
          key: "lineItemTotal",
          className: "text-right",
          hidden: hideLineItemPrices,
          cellTestId: "lineItemTotal",
          headerClassName: "font-weight-bold",
        },
      ]}
      rows={proposalLineItemRows}
    />
  );
}

function getContainerStyle({
  rowIndex,
  displayType,
}: {
  rowIndex: number;
  displayType: ResponsiveGridTableDisplayType;
}) {
  if (rowIndex === 0 && displayType === "desktop") {
    return {
      marginTop: "-15px",
    };
  } else {
    return {};
  }
}
