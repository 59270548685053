import React from "react";
import { IFile } from "../../models/IFile";
import dataProvider from "../../services/DataProvider";
import TextareaAutosize from "react-autosize-textarea";
import {
  isImageByType,
  getFileName,
  getFileComponents,
} from "../../libraries/formAttachments/services/fileService";
import { strings } from "../../languages";

interface IProps {
  scheduleJob: { id: string };
  files: Array<IFile>;
  imagePrefix: string;
}

export const AdminFiles: React.FunctionComponent<IProps> = ({
  scheduleJob,
  files,
  imagePrefix,
}) =>
  files && files.length > 0 ? (
    <React.Fragment>
      <br />
      {files.map((p) => {
        let fileElement: JSX.Element | null = null;
        if (isImageByType(p.contentType)) {
          const thumbnailKey = 280;
          const thumbnail = dataProvider.getPhotoUrl(
            imagePrefix,
            thumbnailKey,
            p
          );

          let url: string | undefined = undefined;
          let width: number | undefined = undefined;
          let height: number | undefined = undefined;
          if (thumbnail) {
            url = thumbnail.url;
            width = thumbnail.width;
            height = thumbnail.height;

            fileElement = (
              <React.Fragment>
                <div className="form-group" style={{ textAlign: "center" }}>
                  <div>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`/photo/${scheduleJob.id}/${p.id}`}
                    >
                      <img src={url} height={height} width={width} alt="" />
                    </a>
                  </div>
                  <div>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`/photo/${scheduleJob.id}/${p.id}`}
                    >
                      {strings.viewFullImage}
                    </a>
                  </div>
                </div>
                {p.caption ? (
                  <div className="form-group">
                    <TextareaAutosize
                      disabled={true}
                      maxRows={10}
                      className="form-control"
                      defaultValue={p.caption}
                    />
                  </div>
                ) : null}
              </React.Fragment>
            );
          } else {
            console.log("thumbnail not found");
          }
        } else {
          let path: string;
          let name: string;
          const fileComponents = getFileComponents(p.imagePath);
          if (fileComponents.success) {
            path = fileComponents.linkUrl as string;
            name = fileComponents.name as string;
          } else {
            path = p.imagePath;
            name = p.imagePath;
          }

          fileElement = (
            <React.Fragment>
              <div className="form-group" style={{ textAlign: "center" }}>
                <a
                  href={`${imagePrefix}/${path}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "underline",
                    fontWeight: "bold",
                  }}
                >
                  {getFileName(name)}
                </a>
                {p.caption ? <span> - {p.caption}</span> : null}
              </div>
            </React.Fragment>
          );
        }

        return fileElement ? (
          <React.Fragment key={p.id}>{fileElement}</React.Fragment>
        ) : null;
      })}
    </React.Fragment>
  ) : null;
