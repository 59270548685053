import IJobLineItem from "../models/IJobLineItem";
import { CrewScheduleLineItemType } from "../models/IScheduleJob";
import ResponsiveGridTable from "../libraries/responsiveGrid/ResponsiveGridTable";
import { strings } from "../languages";

export default function JobDetailsLineItems({
  lineItems,
  lineItemType,
}: {
  lineItems: Array<IJobLineItem>;
  lineItemType: CrewScheduleLineItemType;
}) {
  return (
    <div className="mt-4">
      <h4>
        {lineItemType === CrewScheduleLineItemType.Project ? "Project" : "Job"}{" "}
        Line Items:
      </h4>
      <ResponsiveGridTable<IJobLineItem>
        columnGap="30px"
        showRowIndex={false}
        rows={lineItems}
        columns={[
          {
            header: () => (
              <span className="font-weight-bold">{strings.productService}</span>
            ),
            cell: (li) => li.rowObject.name,
            key: "name",
            width: "min-content",
            className: "text-nowrap",
            cellTestId: "lineItemName",
          },
          {
            header: () => <span className="font-weight-bold">Description</span>,
            cell: (li) => li.rowObject.description,
            key: "description",
            width: "minmax(auto, max-content)",
            cellTestId: "lineItemDescription",
          },
          {
            header: () => <span className="font-weight-bold">Quantity</span>,
            cell: (li) => li.rowObject.quantity,
            key: "quantity",
            width: "min-content",
            className: "text-right",
            cellTestId: "lineItemQuantity",
          },
        ]}
        mobileHeader={(arg, index) => (
          <div className="font-weight-bold">Item {index + 1}</div>
        )}
        rowKey={"id"}
      />
    </div>
  );
}
