import { formatCurrency } from "../../../services/currencyFormatter";
import { IInvoiceForPaymentForm } from "../models/IInvoiceForPaymentForm";
import { getConvenienceFee } from "../services/invoiceService";

export function ConvenienceFeeInUseAlert({
  invoice,
}: {
  invoice: IInvoiceForPaymentForm;
}) {
  const convienceFeeToDisplay = getConvenienceFee(invoice);
  if (typeof convienceFeeToDisplay !== "number") {
    return null;
  }

  return (
    <div className="font-weight-light">
      Includes {formatCurrency(convienceFeeToDisplay)} (
      {invoice.paymentsConvenienceFeePercentForDisplay}%) convenience fee for
      card transactions
    </div>
  );
}
