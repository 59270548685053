import Container from "../../../components/Container";
import { GenericFooter } from "../../../components/JobDetails/GenericFooter";
import Spinner from "../../../components/Spinner";
import InvoicePayForm from "../../../libraries/invoicePaymentForm/components/InvoicePayForm";
import ISchedule from "../../../models/ISchedule";
import { IScheduleIdentifier } from "../../../models/IScheduleIdentifier";
import { routingBuilders } from "../../../services/routing";
import { getPageHeader } from "../../../libraries/commonUi/functions/getPageHeader";
import IScheduledJob from "../../../models/IScheduleJob";
import { PresentationFormLoadFailureAlert } from "../../../libraries/billableForm/components/PresentationFormLoadFailureAlert";
import { strings } from "../../../languages";
import {
  loadEstimateForPresentationPage,
  useLoadEstimateForPresentationPage,
} from "../hooks/useLoadEstimateForPresentationPage";
import { InvoiceType } from "../../../enums/InvoiceType";

export function DepositPaymentPage({
  scheduleJobId,
  scheduleIdentifier,
  schedule,
  onScheduleJobUpdated,
}: {
  scheduleJobId: string;
  scheduleIdentifier: IScheduleIdentifier;
  schedule: ISchedule;
  onScheduleJobUpdated(
    scheduleJobId: string,
    fieldsToUpdate: Partial<IScheduledJob>
  ): void;
}) {
  const {
    loading,
    depositInvoice,
    loadError,
    scheduleJob,
    setDepositInvoice,
    setLoading,
    setLoadError,
    setEstimate,
    setCanEmail,
    setCanText,
    setCrewMemberSelectedJobToApplyTo,
  } = useLoadEstimateForPresentationPage({
    schedule,
    scheduleIdentifier,
    scheduleJobId,
  });

  if (!scheduleJob) {
    return null;
  }

  return (
    <Container
      isJobListing={false}
      schedule={schedule}
      headerOverride={getPageHeader({
        schedule,
        scheduleJob,
        pageTitle: strings.estimate,
      })}
      scheduleIdentifier={scheduleIdentifier}
    >
      {loading ? <Spinner /> : null}

      <div style={{ marginBottom: "100px" }}>
        {loadError ? (
          <PresentationFormLoadFailureAlert
            loadError={loadError}
            backLinkPath={routingBuilders.buildEstimateFormPath({
              scheduleIdentifier,
              scheduleJobId,
            })}
            backLinkText="Estimate Form"
            onTryAgain={() => {
              loadEstimateForPresentationPage({
                setLoading,
                setLoadError,
                linkedDayScheduleId: scheduleIdentifier.linkedDayScheduleId,
                dayOffset: scheduleIdentifier.dayOffset,
                techAppCrewId: scheduleIdentifier.techAppCrewId,
                scheduleJobId,
                setDepositInvoice,
                setCanEmail,
                setCanText,
                setEstimate,
                setCrewMemberSelectedJobToApplyTo,
              });
            }}
          />
        ) : null}

        {depositInvoice ? (
          <InvoicePayForm
            scheduleIdentifier={scheduleIdentifier}
            jobInstanceId={scheduleJobId}
            invoice={depositInvoice}
            successfulPaymentRedirectPath={routingBuilders.buildEstimatePresentationPath(
              {
                scheduleIdentifier: scheduleIdentifier,
                scheduleJobId,
              }
            )}
            onPaid={() => {}}
            invoiceType={InvoiceType.depositInvoice}
          />
        ) : null}
      </div>

      <GenericFooter
        backLink={routingBuilders.buildEstimatePresentationPath({
          scheduleIdentifier: scheduleIdentifier,
          scheduleJobId: scheduleJob.id,
        })}
      />
    </Container>
  );
}
