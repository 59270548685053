import DepositRequiredFields from "./DepositRequiredFields";
import { IDepositSettings } from "../../models/IDepositSettings";
import { formatCurrency } from "../../../../services/currencyFormatter";
import { DepositType } from "../../enums/DepositType";
import { getTotal } from "../../../../libraries/amountCalculation/getTotal";
import { ILineItem } from "../../../../libraries/billableForm/components/BillableFormLineItem";
import { IAmountAdjustments } from "../../../../libraries/billableForm/types/IBillableFormData";
import { IInvoiceItem } from "../../../../libraries/billableForm/types/IInvoiceItem";
import { BillableFormLineItemInvoiceItemField } from "../../../../libraries/billableForm/components/BillableFormLineItemInvoiceItemField";
import { DepositSettingsFormData } from "./IEstimateFormData";

export default function DepositFields({
  depositSettings,
  onDepositSettingsChanged,
  amountAdjustments,
  lineItems,
  defaultDepositItemId,
  invoiceItems,
  disabled,
  areNonDraftFieldsRequired,
}: {
  depositSettings: DepositSettingsFormData | null;
  amountAdjustments: IAmountAdjustments;
  lineItems: Array<ILineItem>;
  onDepositSettingsChanged: (newValue: DepositSettingsFormData | null) => void;
  defaultDepositItemId: string | null;
  invoiceItems: IInvoiceItem[];
  disabled: boolean;
  areNonDraftFieldsRequired: boolean;
}) {
  const depositInUse = isDepositInUse(depositSettings);
  const showDepositField =
    !defaultDepositItemId ||
    !invoiceItems.some((i) => i.id === defaultDepositItemId);

  return (
    <>
      <>
        <h4>Deposit</h4>
        <div>
          <DepositRequiredFields
            depositSettings={depositSettings}
            setDepositSettings={(newValue) => {
              onDepositSettingsChanged(newValue);
            }}
            disabled={disabled}
          />

          {depositInUse ? (
            <>
              {showDepositField ? (
                <div className="form-group">
                  <BillableFormLineItemInvoiceItemField
                    lineItem={
                      getDepositItem({
                        depositItemId: depositSettings?.depositItemId ?? null,
                        invoiceItems: invoiceItems,
                      }) ?? {
                        amountPerItem: "",
                        description: "",
                        name: "",
                        quantity: "",
                        serviceDate: "",
                        id: "",
                        itemId: "",
                        taxable: false,
                      }
                    }
                    items={invoiceItems}
                    onLineItemChanged={(newItem) => {
                      onDepositSettingsChanged({
                        ...(depositSettings ?? {
                          amount: null,
                          percent: null,
                          type: DepositType.percent,
                        }),
                        depositItemId: newItem.itemId,
                        depositItemName: newItem.name ?? null,
                        depositItemDescription: newItem.description ?? null,
                      });
                    }}
                    onItemUpdated={() => {}}
                    onClearErrorMessage={() => {}}
                    setInvoiceItems={() => {}}
                    inputElementId={"depositItemId"}
                    ariaLabel="Deposit Item"
                    placeHolderText={"Select deposit item..."}
                    disabled={false}
                    scrollIntoView={false}
                    labelRef={undefined}
                    required={areNonDraftFieldsRequired}
                  />
                </div>
              ) : null}

              <div className="form-group" data-testid="depositAmount">
                <strong>
                  {formatCurrency(
                    depositSettings?.type === DepositType.percent
                      ? getTotal({
                          // Intentionally exclude taxes from deposit amount
                          taxRate: null,
                          lineItems,
                          discount: amountAdjustments.discount,
                        }) * (depositSettings?.percent ?? 0)
                      : depositSettings?.type === DepositType.amount
                      ? depositSettings?.amount ?? 0
                      : 0
                  )}{" "}
                  deposit
                </strong>
              </div>
            </>
          ) : null}
        </div>
      </>
    </>
  );
}

function isDepositInUse(depositSettings: IDepositSettings | null): boolean {
  return (
    (depositSettings?.type === DepositType.percent &&
      (depositSettings?.percent ?? 0) > 0) ||
    (depositSettings?.type === DepositType.amount &&
      (depositSettings?.amount ?? 0) > 0)
  );
}

function getDepositItem({
  depositItemId,
  invoiceItems,
}: {
  depositItemId: string | null;
  invoiceItems: Array<IInvoiceItem>;
}) {
  if (!depositItemId) {
    return null;
  }

  let items = invoiceItems.filter((i) => i.id === depositItemId);
  let result: ILineItem | null = null;

  if (items.length > 0) {
    let item = items[0];
    result = {
      ...item,
      description: item.description ?? "",
      itemId: item.id,
      quantity: "",
      amountPerItem: "",
    };
  }
  return result;
}
