import { useRef } from "react";
import TextareaAutosize from "react-autosize-textarea/lib";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import CurrencyInput from "../../input/CurrencyInput";
import { ResponsiveGridTableDisplayType } from "../../responsiveGrid/ResponsiveGridTable";
import { KeyOfType } from "../../typeUtilities/keyOfType";

export interface ILineItem {
  id: string;
  name?: string;
  quantity: string;
  amountPerItem: string;
  description: string;
  itemId: string;
  taxable?: boolean | null;
  hide?: boolean | null;
  serviceDate?: string;
  optional?: boolean | null;
  selected?: boolean | null;
}

function updateLineItem<T>(
  onLineItemChanged: (updatedItem: ILineItem) => void,
  lineItem: ILineItem,
  propName: keyof ILineItem,
  propValue: T
) {
  onLineItemChanged({
    ...lineItem,
    [propName]: propValue,
  });
}

export function DescriptionField({
  lineItem,
  onLineItemChanged,
  inputElementId,
  disabled,
}: {
  lineItem: ILineItem;
  onLineItemChanged: (updatedItem: ILineItem) => void;
  inputElementId: string;
  disabled: boolean;
}) {
  return (
    <>
      <TextareaAutosize
        maxRows={10}
        id={inputElementId}
        className="form-control"
        name="notes"
        value={lineItem.description}
        disabled={disabled}
        data-testid="description"
        onChange={(e) =>
          updateLineItem(
            onLineItemChanged,
            lineItem,
            "description",
            e.currentTarget.value
          )
        }
      />
    </>
  );
}

export function QuantityField({
  lineItem,
  onLineItemChanged,
  inputElementId,
  allowBlankQuantity,
  disabled,
  allowZeroQuantity,
}: {
  lineItem: ILineItem;
  onLineItemChanged: (updatedItem: ILineItem) => void;
  inputElementId: string;
  allowBlankQuantity?: boolean;
  disabled?: boolean;
  allowZeroQuantity: boolean;
}) {
  const lineItemQuantityElement = useRef<HTMLInputElement | null>(null);

  let min: number | undefined;
  if (!allowBlankQuantity && !allowZeroQuantity) {
    min = 0.1;
  } else if (!allowBlankQuantity && allowZeroQuantity) {
    min = 0;
  } else {
    min = undefined;
  }

  return (
    <>
      <input
        className="form-control"
        id={inputElementId}
        type="number"
        step="any"
        value={lineItem.quantity}
        ref={lineItemQuantityElement}
        onChange={(e) => {
          const value = e.currentTarget.value;
          updateLineItem(onLineItemChanged, lineItem, "quantity", value);
        }}
        required={allowBlankQuantity ? undefined : true}
        disabled={disabled}
        min={min}
      />
    </>
  );
}

export function AmountPerItemField({
  lineItem,
  onLineItemChanged,
  inputElementId,
  allowBlankAmountPerItem,
  disabled,
}: {
  lineItem: ILineItem;
  onLineItemChanged: (updatedItem: ILineItem) => void;
  inputElementId: string;
  allowBlankAmountPerItem?: boolean;
  disabled?: boolean;
}) {
  return (
    <>
      <CurrencyInput
        className="form-control"
        id={inputElementId}
        value={lineItem.amountPerItem}
        onValueChange={(newValue) =>
          updateLineItem(onLineItemChanged, lineItem, "amountPerItem", newValue)
        }
        required={allowBlankAmountPerItem ? undefined : true}
        disabled={disabled}
      />
    </>
  );
}

export function CheckboxField({
  onLineItemChanged,
  lineItem,
  property,
  inputElementId,
  disabled,
  inlineLabelText,
}: {
  onLineItemChanged: (updatedItem: ILineItem) => void;
  lineItem: ILineItem;
  property: KeyOfType<ILineItem, boolean | null | undefined>;
  inputElementId: string;
  disabled?: boolean;
  inlineLabelText?: string;
}) {
  return (
    <>
      <div className="custom-control custom-checkbox mr-sm-2 ml-2">
        <input
          id={inputElementId}
          type="checkbox"
          className="custom-control-input"
          checked={lineItem[property] ?? false}
          disabled={disabled}
          onChange={(e) => {
            updateLineItem(
              onLineItemChanged,
              lineItem,
              property,
              e.target.checked
            );
          }}
        />
        <label htmlFor={inputElementId} className="custom-control-label">
          {inlineLabelText ? inlineLabelText : <>&nbsp;</>}
        </label>
      </div>
    </>
  );
}

export function DeleteLineItemButton({
  lineItem,
  lineItems,
  setLineItems,
  displayType,
}: {
  lineItem: ILineItem;
  lineItems: Array<ILineItem>;
  setLineItems: (newLineItems: Array<ILineItem>) => void;
  displayType: ResponsiveGridTableDisplayType;
}) {
  return (
    <button
      type="button"
      className={`btn pt-0 ${displayType === "mobile" ? "pr-0" : ""}`}
      onClick={() =>
        setLineItems(lineItems.filter((l) => l.id !== lineItem.id))
      }
    >
      <FontAwesomeIcon icon={faTrash} title="Remove line item" />
    </button>
  );
}
