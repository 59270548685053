import { format, isValid, parse } from "date-fns";
import EXIF from "exif-js";
import { Observable } from "rxjs";

const dateTimeExifAttributeName = "DateTime";
export function exifParser(img: HTMLImageElement) {
  return new Observable<{ timestamp: string | null }>((subscriber) => {
    try {
      EXIF.getData(img as any, () => {
        let timestamp: string | null = null;
        try {
          if (img) {
            const dateTimeOriginal = EXIF.getTag(
              img,
              dateTimeExifAttributeName
            );

            if (dateTimeOriginal && typeof dateTimeOriginal === "string") {
              timestamp = formatExifDateAsIso8601(dateTimeOriginal);
            } else if (isCapturedByIOSCamera(img)) {
              timestamp = formatDate(new Date());
            }
          }
        } catch (err) {
          console.error(`unable to parse exif date`);
          console.error(err);
        }

        subscriber.next({ timestamp });

        subscriber.complete();
      });
    } catch (err) {
      subscriber.error(err);
    }
  });
}

export function formatExifDateAsIso8601(dateTimeOriginal: string) {
  let timestamp: string | null = null;

  const parsedDate = parse(dateTimeOriginal, "yyyy:LL:dd HH:mm:ss", new Date());

  if (isValid(parsedDate)) {
    timestamp = formatDate(parsedDate);
  }

  return timestamp;
}

function isCapturedByIOSCamera(img: HTMLImageElement) {
  if (!isIOS()) {
    return false;
  }

  // Assuming that if DateTime not set but rest of properties are,
  // this image was just captured by the ios camera since
  // the datetime prop is stripped but these attributes are set
  return (
    !isExifAttributeSet(img, dateTimeExifAttributeName) &&
    isExifAttributeSet(img, "ExifIFDPointer") &&
    isExifAttributeSet(img, "ColorSpace") &&
    isExifAttributeSet(img, "PixelXDimension") &&
    isExifAttributeSet(img, "PixelYDimension")
  );
}

function formatDate(parsedDate: Date): string | null {
  return format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss");
}

function isIOS() {
  console.log(`navigator.platform: ${navigator.platform}`);
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

function isExifAttributeSet(img: HTMLImageElement, attributeName: string) {
  const v = EXIF.getTag(img, attributeName);

  return typeof v === "string" || typeof v === "number";
}
