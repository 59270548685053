import React, { useState } from "react";
import IScheduledJob from "../../models/IScheduleJob";
import { formatLatLng } from "../../services/addressService";
import { strings } from "../../languages";
import { fullStoryTrack } from "../../services/fullStoryService";
import { IScheduleIdentifier } from "../../models/IScheduleIdentifier";
import ISchedule from "../../models/ISchedule";
import { FloatingAlert } from "../../libraries/billableForm/components/FloatingAlert";
import { isStringSet } from "../../libraries/typeUtilities/isStringSet";
import { formatTimeForDisplay } from "../../services/dateTimeService";
import { JobHeaderActionButtons } from "./JobHeaderActionButtons";

interface IProps {
  schedule: ISchedule;
  scheduleIdentifier: IScheduleIdentifier;
  scheduledJob: IScheduledJob;
  index: number;
  hideCustomerNameOnCrewView: boolean;
  hideCustomerPhoneNumberOnCrewView: boolean;
  showCustomerEmailAddressOnCrewView: boolean;
  showCustomerJobHistory: boolean;
  showScheduledTimes: boolean;
  showNotifiedOnTheWayAlert: boolean;
  setShowNotifiedOnTheWayAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

export const JobHeader: React.FunctionComponent<IProps> = ({
  schedule,
  scheduleIdentifier,
  scheduledJob,
  index,
  hideCustomerNameOnCrewView,
  hideCustomerPhoneNumberOnCrewView,
  showCustomerEmailAddressOnCrewView,
  showCustomerJobHistory,
  showScheduledTimes,
  showNotifiedOnTheWayAlert,
  setShowNotifiedOnTheWayAlert,
}) => {
  const [showOptInAlert, setShowOptInAlert] = useState(false);
  let scheduledTime = <></>;
  if (
    showScheduledTimes &&
    isStringSet(scheduledJob.startTime) &&
    isStringSet(scheduledJob.endTime)
  ) {
    scheduledTime = (
      <h5 className="mt-2" data-testid="scheduleTimeJobHeader">
        {formatTimeForDisplay(scheduledJob.startTime)} -{" "}
        {formatTimeForDisplay(scheduledJob.endTime)}
      </h5>
    );
  }

  return (
    <React.Fragment>
      {!hideCustomerNameOnCrewView ? (
        <React.Fragment>
          <h4>
            #{index + 1}. {scheduledJob.name}
          </h4>
          <JobHeaderActionButtons
            schedule={schedule}
            scheduledJob={scheduledJob}
            scheduleIdentifier={scheduleIdentifier}
            showCustomerJobHistory={showCustomerJobHistory}
            setShowOptInAlert={setShowOptInAlert}
          />
          {scheduledTime}
          {getAddressElement(scheduledJob)}
        </React.Fragment>
      ) : !!scheduledJob.address ? (
        <React.Fragment>
          <h4>
            #{index + 1}. {getAddressLinkElement(scheduledJob)}
          </h4>
          <JobHeaderActionButtons
            schedule={schedule}
            scheduledJob={scheduledJob}
            scheduleIdentifier={scheduleIdentifier}
            showCustomerJobHistory={showCustomerJobHistory}
            setShowOptInAlert={setShowOptInAlert}
          />
          {scheduledTime}
        </React.Fragment>
      ) : (
        <>
          <h4>#{index + 1}</h4>
          <JobHeaderActionButtons
            schedule={schedule}
            scheduledJob={scheduledJob}
            scheduleIdentifier={scheduleIdentifier}
            showCustomerJobHistory={showCustomerJobHistory}
            setShowOptInAlert={setShowOptInAlert}
          />
        </>
      )}
      {getLatLngElement(scheduledJob)}

      {!hideCustomerPhoneNumberOnCrewView ? (
        <>
          <PhoneNumber phoneNumber={scheduledJob.phoneNumber} />
          <PhoneNumber
            phoneNumber={scheduledJob.alternativePhoneNumber}
            prefix="Alt: "
          />
        </>
      ) : null}

      {showCustomerEmailAddressOnCrewView
        ? getEmailAddressElement(scheduledJob)
        : null}

      {showOptInAlert ? (
        <FloatingAlert
          type="danger"
          testId="requirePhoneNumberOptIn"
          allowWrap
          showClose
          onClose={() => setShowOptInAlert(false)}
        >
          The phone number for this customer has not been opted into receiving
          text messages. Please contact your administrator to correct.
        </FloatingAlert>
      ) : null}

      {showNotifiedOnTheWayAlert ? (
        <FloatingAlert
          type="success"
          testId="onTheWaySuccess"
          allowWrap
          showClose
          onClose={() => setShowNotifiedOnTheWayAlert(false)}
          autoCloseTimeout={2000}
        >
          {isStringSet(scheduledJob.name) ? scheduledJob.name : "The customer"}{" "}
          has been notifed that you're on the way!
        </FloatingAlert>
      ) : null}
    </React.Fragment>
  );
};

function getAddressLinkElement(job: IScheduledJob) {
  if (job.address) {
    let link: string;
    if (job.addressPlaceId) {
      link = `https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${encodeURIComponent(
        job.address
      )}&destination_place_id=${job.addressPlaceId}`;
    } else if (job.latitude && job.longitude) {
      link = `https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${job.latitude},${job.longitude}`;
    } else {
      link = `https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${encodeURIComponent(
        job.address
      )}`;
    }
    return (
      <a
        href={link}
        style={{ textDecoration: "underline" }}
        onClick={() => {
          fullStoryTrack("Crew: Address Link Clicked");
        }}
      >
        {job.address}
      </a>
    );
  }

  return null;
}

function getAddressElement(job: IScheduledJob) {
  return <h5>{getAddressLinkElement(job)}</h5>;
}

function getLatLngElement(job: IScheduledJob) {
  if (!job.address && job.latitude && job.longitude) {
    const link = `https://www.google.com/maps/search/?api=1&query=${job.latitude},${job.longitude}`;
    return (
      <h5>
        <a
          href={link}
          style={{ textDecoration: "underline" }}
          onClick={() => {
            fullStoryTrack("Crew: Address Coordinates Link Clicked");
          }}
        >
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div style={{ display: "inline-block", marginRight: "10px" }}>
              {strings.latitude}:{" "}
              {formatLatLng(job.latitude, job.latitudeSignificantDigits)}
            </div>
            <div style={{ display: "inline-block" }}>
              {strings.longitude}:{" "}
              {formatLatLng(job.longitude, job.longitudeSignificantDigits)}
            </div>
          </div>
        </a>
      </h5>
    );
  }

  return null;
}

function PhoneNumber({
  phoneNumber,
  prefix,
}: {
  phoneNumber: string;
  prefix?: string;
}) {
  if (phoneNumber) {
    return (
      <React.Fragment>
        <h5>
          <a
            href={"tel:" + phoneNumber}
            style={{ textDecoration: "underline" }}
            onClick={() => {
              fullStoryTrack("Crew: Phone Link Clicked");
            }}
          >
            {prefix ?? ""} {phoneNumber}
          </a>
        </h5>
      </React.Fragment>
    );
  }
  return null;
}

function getEmailAddressElement(job: IScheduledJob) {
  if (job.emailAddresses && job.emailAddresses.length > 0) {
    return (
      <React.Fragment>
        <h5 className="d-flex flex-wrap">
          {job.emailAddresses.map((emailAddress) => (
            <a
              key={emailAddress}
              className="mr-2"
              href={"mailto:" + emailAddress}
              style={{ textDecoration: "underline" }}
              onClick={() => {
                fullStoryTrack("Crew: Email Link Clicked");
              }}
            >
              {emailAddress}
            </a>
          ))}
        </h5>
      </React.Fragment>
    );
  }
  return null;
}
