import { ReactNode, useEffect } from "react";

export function FloatingAlert({
  type,
  children,
  showClose,
  onClose,
  testId,
  style,
  allowWrap,
  autoCloseTimeout,
}: {
  type: "danger" | "success";
  children: ReactNode;
  showClose?: boolean;
  onClose?: () => void;
  testId?: string;
  style?: Partial<React.CSSProperties>;
  allowWrap?: boolean;
  autoCloseTimeout?: number;
}) {
  useEffect(() => {
    if ((autoCloseTimeout ?? 0) > 0) {
      const timer = setTimeout(() => {
        if (typeof onClose === "function") {
          onClose();
        }
      }, autoCloseTimeout);
      return () => clearTimeout(timer);
    }
  }, [autoCloseTimeout, onClose]);

  return (
    <div
      className={`m-2 p-2 alert alert-${type}`}
      role="alert"
      style={{
        position: "fixed",
        bottom: "100px",
        right: "10px",
        zIndex: 25,
        ...(style ?? {}),
      }}
      data-testid={testId}
    >
      {showClose ? (
        <button
          type="button"
          className="close"
          data-testid="invoiceFloatingAlertClose"
          onClick={() => {
            if (typeof onClose === "function") {
              onClose();
            }
          }}
        >
          &times;
        </button>
      ) : null}

      <div className={`pt-1 mr-4 ${allowWrap ? "" : "text-nowrap"}`}>
        {children}
      </div>
    </div>
  );
}
