import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import TextareaAutosize from "react-autosize-textarea/lib";
import { LanguageContext } from "../../../App";
import { AdminFiles } from "../../../components/JobDetails/AdminFiles";
import { isStringSet } from "../../../libraries/typeUtilities/isStringSet";
import { formatDateForDisplay } from "../../../services/dateTimeService";
import { ICustomerHistoryJob } from "../services/scheduleDataProvider";
import { JobCategory } from "./JobCategory";
import { strings } from "../../../languages";
import { CustomerHistoryJobStatus } from "../services/scheduleDataProvider";

export function CustomerJobHistoryRow({
  jobHistoryRecord,
  filePrefix,
}: {
  jobHistoryRecord: ICustomerHistoryJob;
  filePrefix: string;
}) {
  const language = useContext(LanguageContext);
  const [detailsExpanded, setDetailsExpanded] = useState(false);

  const combinedFiles = [
    ...jobHistoryRecord.photos,
    ...jobHistoryRecord.photosFromAdmin,
  ];

  const chevronVisible =
    isStringSet(jobHistoryRecord.notes) ||
    isStringSet(jobHistoryRecord.notesFromAdmin) ||
    combinedFiles.length > 0;

  return (
    <>
      <div>
        <div className="d-flex justify-content-between">
          <h5 className="mb-0">
            {formatDateForDisplay(jobHistoryRecord.date, language.language)}
          </h5>
          <div>
            {formatJobStatus(jobHistoryRecord.jobStatus)}
            <button
              type="button"
              className={chevronVisible ? "btn" : "btn invisible"}
              data-testid="chevronButton"
              style={{ border: 0 }}
              onClick={() => setDetailsExpanded(!detailsExpanded)}
            >
              <FontAwesomeIcon
                title="Toggle Details"
                icon={detailsExpanded ? faChevronUp : faChevronDown}
              />
            </button>
          </div>
        </div>
        <h5>{jobHistoryRecord.crewName}</h5>
        {jobHistoryRecord.categories.length > 0 ? (
          <div>
            {jobHistoryRecord.categories.map((category) => (
              <JobCategory
                key={category.id}
                name={category.name}
                color={category.color}
              />
            ))}
          </div>
        ) : null}
        {detailsExpanded ? (
          <div className="mb-4">
            <NoteField
              note={jobHistoryRecord.notesFromAdmin}
              label={strings.jobNotes}
              containerTestId="adminNotesContainer"
            />

            <NoteField
              note={jobHistoryRecord.notes}
              label={strings.crewNotes}
              containerTestId="crewNotesContainer"
            />

            {combinedFiles.length > 0 ? (
              <div className="my-3">
                <h6>{strings.files}:</h6>
                <AdminFiles
                  files={combinedFiles}
                  imagePrefix={filePrefix}
                  scheduleJob={jobHistoryRecord}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      <hr className="mt-3" />
    </>
  );
}

function formatJobStatus(status: CustomerHistoryJobStatus) {
  switch (status) {
    case CustomerHistoryJobStatus.notStarted:
      return <h5 className="d-inline-block">Not started</h5>;
    case CustomerHistoryJobStatus.inProgress:
      return <h5 className="d-inline-block text-primary">In progress</h5>;
    case CustomerHistoryJobStatus.paused:
      return (
        <h5
          className="d-inline-block"
          style={{
            color: "#ffc107",
          }}
        >
          Paused
        </h5>
      );
    case CustomerHistoryJobStatus.skipped:
      return <h5 className="d-inline-block text-warning">Skipped</h5>;
    case CustomerHistoryJobStatus.completed:
      return <h5 className="d-inline-block text-success">Completed</h5>;
  }
}

function NoteField({
  note,
  label,
  containerTestId,
}: {
  note: string;
  label: string;
  containerTestId: string;
}) {
  return (
    <>
      {isStringSet(note) ? (
        <div className="my-3" data-testid={containerTestId}>
          <h6>{label}:</h6>
          <TextareaAutosize
            disabled={true}
            maxRows={10}
            className="form-control"
            defaultValue={note}
          />
        </div>
      ) : null}
    </>
  );
}
