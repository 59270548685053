import Container from "../../../components/Container";
import { GenericFooter } from "../../../components/JobDetails/GenericFooter";
import Spinner from "../../../components/Spinner";
import InvoicePayForm from "../../../libraries/invoicePaymentForm/components/InvoicePayForm";
import ISchedule from "../../../models/ISchedule";
import { IScheduleIdentifier } from "../../../models/IScheduleIdentifier";
import { routingBuilders } from "../../../services/routing";
import { getPageHeader } from "../../../libraries/commonUi/functions/getPageHeader";
import IScheduledJob from "../../../models/IScheduleJob";
import {
  loadInvoiceForPaymentForm,
  useLoadInvoiceForPaymentForm,
} from "../hooks/useLoadInvoiceForPaymentForm";
import { PresentationFormLoadFailureAlert } from "../../../libraries/billableForm/components/PresentationFormLoadFailureAlert";
import { strings } from "../../../languages";
import { InvoiceType } from "../../../enums/InvoiceType";

export function PaymentPage({
  scheduleJobId,
  scheduleIdentifier,
  schedule,
  onScheduleJobUpdated,
}: {
  scheduleJobId: string;
  scheduleIdentifier: IScheduleIdentifier;
  schedule: ISchedule;
  onScheduleJobUpdated(
    scheduleJobId: string,
    fieldsToUpdate: Partial<IScheduledJob>
  ): void;
}) {
  const {
    loading,
    invoice,
    loadError,
    scheduleJob,
    setInvoice,
    setInvoicePaid,
    setLoading,
    setLoadError,
  } = useLoadInvoiceForPaymentForm({
    schedule,
    scheduleIdentifier,
    scheduleJobId,
  });

  if (!scheduleJob) {
    return null;
  }

  return (
    <Container
      isJobListing={false}
      schedule={schedule}
      headerOverride={getPageHeader({
        schedule,
        scheduleJob,
        pageTitle: strings.invoice,
      })}
      scheduleIdentifier={scheduleIdentifier}
    >
      {loading ? <Spinner /> : null}

      <div style={{ marginBottom: "100px" }}>
        {loadError ? (
          <PresentationFormLoadFailureAlert
            loadError={loadError}
            backLinkPath={routingBuilders.buildInvoiceFormPath({
              scheduleIdentifier,
              scheduleJobId,
            })}
            backLinkText="Invoice Form"
            onTryAgain={() => {
              loadInvoiceForPaymentForm({
                setLoading,
                setLoadError,
                linkedDayScheduleId: scheduleIdentifier.linkedDayScheduleId,
                dayOffset: scheduleIdentifier.dayOffset,
                techAppCrewId: scheduleIdentifier.techAppCrewId,
                scheduleJobId,
                setInvoice,
                setInvoicePaid,
              });
            }}
          />
        ) : null}

        {invoice ? (
          <InvoicePayForm
            scheduleIdentifier={scheduleIdentifier}
            jobInstanceId={scheduleJobId}
            invoice={invoice}
            successfulPaymentRedirectPath={routingBuilders.buildInvoicePresentation(
              {
                scheduleIdentifier: scheduleIdentifier,
                scheduleJobId,
              }
            )}
            onPaid={() => {
              setInvoicePaid(true);
              onScheduleJobUpdated(scheduleJobId, {
                invoicePaid: true,
              });
            }}
            invoiceType={InvoiceType.finalInvoice}
          />
        ) : null}
      </div>

      <GenericFooter
        backLink={routingBuilders.buildInvoicePresentation({
          scheduleIdentifier: scheduleIdentifier,
          scheduleJobId: scheduleJob.id,
        })}
      />
    </Container>
  );
}
