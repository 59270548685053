const hostnameLowercase = window.location.hostname.toLowerCase();

let urlBase: string;
let fullStoryId: string;

if (
  hostnameLowercase === "localhost" ||
  hostnameLowercase === "greenerpastures-local-frontend.azurewebsites.net"
) {
  urlBase = "https://localhost:44350";
  fullStoryId = "";
} else if (
  hostnameLowercase ===
    "greenerpastures-staging-frontend-crew.azurewebsites.net" ||
  hostnameLowercase === "greenerpastures-staging-frontend-crew.azureedge.net"
) {
  urlBase = "https://greenerpastures-staging-api.azurewebsites.net";
  fullStoryId = "o-1GDFXX-na1";
} else {
  urlBase = "https://api.crewcontrol.us";
  fullStoryId = "o-1GDFXX-na1";
}

const configuration = {
  urlBase,
  fullStoryId,
};

export default configuration;
