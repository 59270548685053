import { ajax } from "rxjs/ajax";
import { map } from "rxjs/operators";
import { IInvoiceForPaymentForm } from "../../../libraries/invoicePaymentForm/models/IInvoiceForPaymentForm";
import { IScheduleIdentifier } from "../../../models/IScheduleIdentifier";
import { buildFullUrl, getHeaders } from "../../../services/DataProvider";
import { IInvoiceForForm } from "../models/IInvoiceForForm";
import { IInvoiceItem } from "../../../libraries/billableForm/types/IInvoiceItem";

export type UpdateInvoicePayload = Omit<
  Partial<IInvoiceForForm>,
  "signatureImagePath" | "maxDepositCreditAmount"
>;

export function getInvoiceForForm({
  scheduleIdentifier,
  jobInstanceId,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
}) {
  return ajax
    .get(
      buildFullUrl(
        `Invoice/${scheduleIdentifier.linkedDayScheduleId}/${jobInstanceId}?dayOffset=${scheduleIdentifier.dayOffset}`
      ),
      getHeaders()
    )
    .pipe(
      map((response) => {
        return response.response as IInvoiceForForm;
      })
    );
}

export function getInvoiceItemsForForm({
  scheduleIdentifier,
  jobInstanceId,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
}) {
  return ajax
    .get(
      buildFullUrl(
        `Invoice/${scheduleIdentifier.linkedDayScheduleId}/${jobInstanceId}/invoiceitems?dayOffset=${scheduleIdentifier.dayOffset}`
      ),
      getHeaders()
    )
    .pipe(
      map((response) => {
        return response.response.items as Array<IInvoiceItem>;
      })
    );
}

export function updateInvoiceForForm({
  scheduleIdentifier,
  jobInstanceId,
  payload,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
  payload: UpdateInvoicePayload;
}) {
  return ajax.patch(
    buildFullUrl(
      `Invoice/${scheduleIdentifier.linkedDayScheduleId}/${jobInstanceId}?dayOffset=${scheduleIdentifier.dayOffset}`
    ),
    payload,
    getHeaders()
  );
}

export function updateInvoiceSignature({
  scheduleIdentifier,
  jobInstanceId,
  payload,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
  payload: Pick<Partial<IInvoiceForForm>, "signatureImagePath">;
}) {
  return ajax.patch(
    buildFullUrl(
      `Invoice/${scheduleIdentifier.linkedDayScheduleId}/${jobInstanceId}/signature?dayOffset=${scheduleIdentifier.dayOffset}`
    ),
    payload,
    getHeaders()
  );
}

export function emailInvoice({
  scheduleIdentifier,
  jobInstanceId,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
}) {
  return ajax.post(
    buildFullUrl(
      `Invoice/${scheduleIdentifier.linkedDayScheduleId}/${jobInstanceId}/email?dayOffset=${scheduleIdentifier.dayOffset}`
    ),
    {},
    getHeaders()
  );
}

export function textInvoice({
  scheduleIdentifier,
  jobInstanceId,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
}) {
  return ajax.post(
    buildFullUrl(
      `Invoice/${scheduleIdentifier.linkedDayScheduleId}/${jobInstanceId}/text?dayOffset=${scheduleIdentifier.dayOffset}`
    ),
    {},
    getHeaders()
  );
}

export function getOrCreateInvoiceForPaymentForm({
  scheduleIdentifier,
  jobInstanceId,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
}) {
  return ajax
    .post(
      buildFullUrl(
        `Invoice/${scheduleIdentifier.linkedDayScheduleId}/${jobInstanceId}/invoiceforpayment?dayOffset=${scheduleIdentifier.dayOffset}`
      ),
      {},
      getHeaders()
    )
    .pipe(
      map((response) => {
        return response.response as IInvoiceForPaymentForm;
      })
    );
}
