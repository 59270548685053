import * as React from "react";
import Spinner from "./components/Spinner";
import Error from "./components/Error";
import IScheduledJob from "./models/IScheduleJob";
import ISchedule from "./models/ISchedule";
import dataProvider from "./services/DataProvider";
import { identifyApplicationInsightsUser } from "./services/applicationInsights";
import CrewJobs from "./components/CrewJobs";
import {
  strings,
  Language,
  getDefaultLanguage,
  saveLanguage,
} from "./languages";
import { IGetEstimatedJobTimesResponse } from "./models/IGetEstimatedJobTimesResponse";
import { timeout } from "rxjs/operators";
import configuration from "./services/configuration";
import {
  fullStoryIdentify,
  fullStoryInit,
  fullStoryTrack,
} from "./services/fullStoryService";
import { IScheduleIdentifier } from "./models/IScheduleIdentifier";

export enum estimatedJobTimesLoadingState {
  loading,
  error,
  complete,
}

enum scheduleLoadErrorType {
  scheduleNotFound = 0,
}

interface IProps {
  scheduleIdentifier: IScheduleIdentifier;
  jobListingFooterElement: React.ReactNode | null;
  alwaysEnableFullStory: boolean;
}

interface IState {
  loading: boolean;
  schedule: ISchedule | null;
  scheduleLoadErrorType: scheduleLoadErrorType | null;
  estimatedJobTimes: IGetEstimatedJobTimesResponse | null;
  estimatedJobTimesLoading: estimatedJobTimesLoadingState;
  languageContext: ILanguageContext;
}

interface ILanguageContext {
  language: Language;
  setLanguage: (newLanguage: Language) => void;
}

export const LanguageContext = React.createContext<ILanguageContext>({
  language: "en",
  setLanguage: (newLanguage: Language) => {},
});

function getRandomInt(max: number) {
  return Math.floor(Math.random() * Math.floor(max));
}

// 15 seconds or 5 minutes
const pollingIntervalMs =
  window.location.href.indexOf("fastmode=1") !== -1 ? 15000 : 300000;

let fullStoryRegistered = false;
let fullStoryEnabledBySampling = getRandomInt(60) === 0;

let isAutomatedTest = false;

try {
  if (window.sessionStorage.getItem("automated_test") === "1") {
    fullStoryEnabledBySampling = true;
    isAutomatedTest = true;
  }
} catch {
  console.log("window.sessionStorage is not available");
}

class App extends React.Component<IProps, IState> {
  private intervalId: number | null = null;
  private lastLoadTime: Date | null = null;

  constructor(props: IProps) {
    super(props);

    const currentLanguage = getDefaultLanguage();
    strings.setLanguage(currentLanguage);

    this.state = {
      loading: true,
      schedule: null,
      scheduleLoadErrorType: null,
      estimatedJobTimes: null,
      estimatedJobTimesLoading: estimatedJobTimesLoadingState.loading,
      languageContext: {
        language: currentLanguage,
        setLanguage: this.setLanguage.bind(this),
      },
    };

    this.onScheduleJobUpdated = this.onScheduleJobUpdated.bind(this);
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
    this.loadSchedule = this.loadSchedule.bind(this);
  }

  public setLanguage(newLanguage: Language) {
    strings.setLanguage(newLanguage);
    saveLanguage(newLanguage);
    this.setState({
      languageContext: {
        language: newLanguage,
        setLanguage: this.state.languageContext.setLanguage,
      },
    });
  }

  public componentDidUpdate(prevProps: IProps) {
    if (
      hasScheduleIdentifierChanged(
        prevProps.scheduleIdentifier,
        this.props.scheduleIdentifier
      )
    ) {
      this.setState({
        loading: true,
      });

      dataProvider.getSchedule(this.props.scheduleIdentifier).subscribe(
        (schedule) => {
          this.handleScheduleLoaded(schedule, false);

          this.lastLoadTime = new Date();
        },
        (err) => {
          this.handleScheduleLoadError(err);
        }
      );
    }
  }

  public componentDidMount() {
    dataProvider.getSchedule(this.props.scheduleIdentifier).subscribe(
      (schedule) => {
        identifyApplicationInsightsUser(schedule.tenantName);

        if (
          configuration.fullStoryId &&
          (fullStoryEnabledBySampling ||
            this.props.alwaysEnableFullStory ||
            schedule.allowOnsiteInvoicing ||
            schedule.allowOnTheWayNotification ||
            schedule?.featureFlags?.enableCrewLogging ||
            alwaysLogTenant(schedule)) &&
          !fullStoryRegistered
        ) {
          fullStoryInit();
          fullStoryIdentify(schedule.tenantName, {
            displayName: schedule.tenantName,
            tenant_id: schedule.tenantId,
            company_name: schedule.tenantName,
            running_as_pwa_crew: isRunningAsPwa(),
            build_version: process.env.REACT_APP_BUILD_VERSION ?? "null",
          });

          if (isAutomatedTest) {
            fullStoryTrack("Automated Test Run");
          }

          fullStoryRegistered = true;
        }

        this.handleScheduleLoaded(schedule, false);

        this.lastLoadTime = new Date();

        this.setupPolling();
      },
      (err) => {
        this.handleScheduleLoadError(err);
      }
    );

    document.addEventListener("visibilitychange", this.onVisibilityChange);
  }

  public componentWillUnmount() {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
    }

    document.removeEventListener("visibilitychange", this.onVisibilityChange);
  }

  public setupPolling() {
    // this.log("setup polling");
    this.intervalId = setInterval(() => {
      this.log("loading data for polling");
      this.loadSchedule();
    }, pollingIntervalMs) as unknown as number;
  }

  private loadSchedule() {
    dataProvider
      .getSchedule(this.props.scheduleIdentifier)
      .subscribe((schedule) => {
        this.handleScheduleLoaded(schedule, this.state.loading);
        this.lastLoadTime = new Date();
      });
  }

  public log(msg: string) {
    // Polling logging not currently needed
    // console.log(msg);
  }

  public onVisibilityChange() {
    if (document.visibilityState === "visible") {
      this.log("made visible");

      if (this.intervalId !== null) {
        clearInterval(this.intervalId);
      }

      const timeSinceLastLoad =
        this.lastLoadTime === null
          ? null
          : new Date().getTime() - this.lastLoadTime.getTime();

      if (
        timeSinceLastLoad === null ||
        timeSinceLastLoad >= pollingIntervalMs
      ) {
        this.log("has been long enough - load data again");
        dataProvider
          .getSchedule(this.props.scheduleIdentifier)
          .subscribe((schedule) => {
            this.setState({
              schedule,
            });

            this.lastLoadTime = new Date();

            this.setupPolling();
          });
      } else {
        this.log("not enough time passed - set timeout");
        window.setTimeout(() => {
          this.log("loading data after timeout");
          dataProvider
            .getSchedule(this.props.scheduleIdentifier)
            .subscribe((schedule) => {
              this.handleScheduleLoaded(schedule, this.state.loading);

              this.lastLoadTime = new Date();

              this.setupPolling();
            });
        }, timeSinceLastLoad);
      }
    }
  }

  private handleScheduleLoadError(err: any) {
    let errorMode: scheduleLoadErrorType | null = null;
    if (err.status === 404) {
      errorMode = scheduleLoadErrorType.scheduleNotFound;
    }

    this.setState({
      loading: false,
      scheduleLoadErrorType: errorMode,
    });
  }

  private handleScheduleLoaded(schedule: ISchedule, loading: boolean) {
    const haveJobsChanged = this.hasJobInstancesChanged(
      this.state.schedule,
      schedule
    );

    if (
      !doAllLocationsHaveCoordinates(schedule) ||
      !doAllJobsHaveEstimatedManHours(schedule)
    ) {
      schedule.showCrewEstimatedTimes = false;
    }

    this.setState({
      loading,
      schedule,
      estimatedJobTimesLoading: haveJobsChanged
        ? estimatedJobTimesLoadingState.loading
        : this.state.estimatedJobTimesLoading,
    });

    if (
      haveJobsChanged &&
      schedule.showCrewEstimatedTimes &&
      schedule.typicalStartTime
    ) {
      dataProvider
        .getEstimatedJobTimes({
          lookupId: this.props.scheduleIdentifier.linkedDayScheduleId,
          dayOffset: this.props.scheduleIdentifier.dayOffset,
          crewLocation: schedule.crewLocation,
          startTime: schedule.date + "T" + schedule.typicalStartTime,
          jobInstances: schedule.scheduledJobs.map((ji) => ({
            id: ji.id,
            location: {
              latitude: ji.latitude as number,
              longitude: ji.longitude as number,
            },
          })),
        })
        .pipe(timeout(5000))
        .subscribe(
          (estimatedJobTimes) => {
            this.setState({
              estimatedJobTimes,
              estimatedJobTimesLoading: estimatedJobTimesLoadingState.complete,
            });
          },
          (err) => {
            console.error("unable to load estimated times");
            console.log(err);

            this.setState({
              estimatedJobTimes: null,
              estimatedJobTimesLoading: estimatedJobTimesLoadingState.error,
            });
          }
        );
    }
  }

  private getErrorComponent() {
    if (
      this.state.scheduleLoadErrorType ===
      scheduleLoadErrorType.scheduleNotFound
    ) {
      return (
        <Error
          errorHeader="Schedule Not Found"
          errorDetails="Please contact your office to get a new link."
        />
      );
    } else {
      return (
        <Error
          errorHeader="Error Occurred"
          errorDetails="Please ensure you can connect to the internet."
        />
      );
    }
  }

  public render() {
    const { loading, schedule, estimatedJobTimes, estimatedJobTimesLoading } =
      this.state;
    return (
      <LanguageContext.Provider value={this.state.languageContext}>
        <>
          {loading ? (
            <Spinner />
          ) : schedule === null ? (
            this.getErrorComponent()
          ) : (
            <CrewJobs
              schedule={schedule}
              scheduleIdentifier={this.props.scheduleIdentifier}
              estimatedJobTimes={estimatedJobTimes}
              estimatedJobTimesLoading={estimatedJobTimesLoading}
              onScheduleJobUpdated={this.onScheduleJobUpdated}
              onScheduleUpdated={(updatedScheduleFields) => {
                this.setState({
                  schedule: {
                    ...schedule,
                    ...updatedScheduleFields,
                  },
                });
              }}
              reloadSchedule={() => this.loadSchedule()}
              jobListingFooterElement={this.props.jobListingFooterElement}
            />
          )}
        </>
      </LanguageContext.Provider>
    );
  }

  private hasJobInstancesChanged(
    oldSchedule: ISchedule | null,
    newSchedule: ISchedule
  ) {
    if (oldSchedule === null) {
      return true;
    }

    if (oldSchedule.scheduledJobs.length !== newSchedule.scheduledJobs.length) {
      return true;
    }

    for (let i = 0; i < oldSchedule.scheduledJobs.length; i++) {
      if (oldSchedule.scheduledJobs[i].id !== newSchedule.scheduledJobs[i].id) {
        return true;
      }
    }

    return false;
  }

  private onScheduleJobUpdated(
    scheduleJobId: string,
    fieldsToUpdate: Partial<IScheduledJob>
  ) {
    if (this.state.schedule === null) {
      return;
    }

    const newScheduleJobs = this.state.schedule.scheduledJobs.map(
      (scheduledJob) => {
        if (scheduledJob.id === scheduleJobId) {
          return {
            ...scheduledJob,
            ...fieldsToUpdate,
          };
        } else {
          return scheduledJob;
        }
      }
    );

    this.setState({
      schedule: {
        ...this.state.schedule,
        scheduledJobs: newScheduleJobs,
      },
    });
  }
}

export default App;

function doAllLocationsHaveCoordinates(schedule: ISchedule) {
  const hasCrewLocation =
    schedule.crewLocation &&
    schedule.crewLocation.latitude &&
    schedule.crewLocation.longitude;
  const hasAllJobLocations = schedule.scheduledJobs.reduce(
    (acc, ji) => acc && !!ji.latitude && !!ji.longitude,
    true
  );
  return hasCrewLocation && hasAllJobLocations;
}

function doAllJobsHaveEstimatedManHours(schedule: ISchedule) {
  return schedule.scheduledJobs.reduce(
    (acc, ji) => acc && !!ji.estimatedManHours,
    true
  );
}

function hasScheduleIdentifierChanged(
  one: IScheduleIdentifier,
  two: IScheduleIdentifier
) {
  return (
    one.dayOffset !== two.dayOffset ||
    one.linkedDayScheduleId !== two.linkedDayScheduleId
  );
}

function alwaysLogTenant(schedule: ISchedule) {
  if (typeof schedule.tenantId !== "string") {
    return false;
  }

  const normalizedTenantId = schedule.tenantId.toUpperCase();

  // 0F0F41C7-6587-4C3A-B0B6-5A9E98BCB4B0 = Ted Lare Design Build
  // 13C5B346-95A7-4260-B5D5-FEAACB07450B = CTX Mowing, LLC
  // BD4FD9F6-7845-4BC1-AE81-15241621D800 = Lucky Lawn Sprinkler Co.
  // 1A5A5356-2CB9-4937-9B55-E1E82BE011F5 = For Garden's Sake
  // 84e37bb2-b53c-4a14-9975-bbb45378d435 = Rea Landscape Management, LLC
  const result =
    normalizedTenantId === "0F0F41C7-6587-4C3A-B0B6-5A9E98BCB4B0" ||
    normalizedTenantId === "13C5B346-95A7-4260-B5D5-FEAACB07450B" ||
    normalizedTenantId === "BD4FD9F6-7845-4BC1-AE81-15241621D800" ||
    normalizedTenantId === "1A5A5356-2CB9-4937-9B55-E1E82BE011F5" ||
    normalizedTenantId === "84E37BB2-B53C-4A14-9975-BBB45378D435";

  return result;
}

function isRunningAsPwa() {
  let runningAsPwa = false;
  try {
    const navigatorAny = navigator as any;
    if (
      navigatorAny.standalone ||
      window.matchMedia("(display-mode: standalone)").matches
    ) {
      runningAsPwa = true;
    }
  } catch {
    console.error("unable to determine if running as pwa");
  }

  return runningAsPwa;
}
