import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TriggerBillableFormSave } from "../types/TriggerBillableFormSave";
import { BillableFormFormNotValid } from "./BillableFormFormNotValid";
import { FloatingErrorSavingIndicator } from "./FloatingErrorSavingIndicator";
import { ErrorResponse } from "../types/ErrorResponse";

export function FloatingIndicator({
  saving,
  errorFromFormSave,
  setErrorFromFormSave,
  triggerInvoiceFormSave,
  isFormValid,
  formRef,
  errorSavingForPaymentForm,
  setErrorSavingForPaymentForm,
  triggerPresentInvoice,
  didUnlockingFail,
  setDidUnlockingFail,
}: {
  saving: boolean;
  errorFromFormSave: ErrorResponse | null;
  setErrorFromFormSave: React.Dispatch<
    React.SetStateAction<ErrorResponse | null>
  >;
  triggerInvoiceFormSave: TriggerBillableFormSave;
  isFormValid: boolean;
  formRef: React.RefObject<HTMLFormElement>;
  errorSavingForPaymentForm: ErrorResponse | null;
  setErrorSavingForPaymentForm: React.Dispatch<
    React.SetStateAction<ErrorResponse | null>
  >;
  triggerPresentInvoice: () => void;
  didUnlockingFail: boolean;
  setDidUnlockingFail: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  let floatingAlertOrIndicator: JSX.Element | null = null;
  if (saving) {
    floatingAlertOrIndicator = (
      <div
        style={{
          position: "fixed",
          bottom: "100px",
          right: "25px",
          zIndex: 1050,
        }}
        data-testid="nonBlockingSpinner"
      >
        <FontAwesomeIcon icon={faSpinner} spin size="3x" fixedWidth />
      </div>
    );
  } else if (errorFromFormSave && !saving) {
    floatingAlertOrIndicator = (
      <FloatingErrorSavingIndicator
        errorMessage={errorFromFormSave.message}
        clearErrorMessage={() => setErrorFromFormSave(null)}
        triggerSave={triggerInvoiceFormSave}
        showRetry={!errorFromFormSave.was400Error}
      />
    );
  } else if (!errorFromFormSave && !isFormValid) {
    floatingAlertOrIndicator = (
      <BillableFormFormNotValid
        showErrors={() => {
          if (formRef.current) {
            formRef.current.reportValidity();
          }
        }}
      />
    );
  } else if (errorSavingForPaymentForm) {
    floatingAlertOrIndicator = (
      <FloatingErrorSavingIndicator
        errorMessage={errorSavingForPaymentForm.message}
        clearErrorMessage={() => setErrorSavingForPaymentForm(null)}
        triggerSave={() => triggerPresentInvoice()}
        showRetry={!errorSavingForPaymentForm.was400Error}
      />
    );
  } else if (didUnlockingFail) {
    floatingAlertOrIndicator = (
      <FloatingErrorSavingIndicator
        errorMessage={
          "An unknown error occurred while unlocking this form. Please try again."
        }
        clearErrorMessage={() => setDidUnlockingFail(false)}
        triggerSave={() => {}}
        showRetry={false}
      />
    );
  }

  return floatingAlertOrIndicator;
}
