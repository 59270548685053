import { useRef, useState } from "react";
import { Observable } from "rxjs";
import Spinner from "../../../components/Spinner";
import { formatCurrency } from "../../../services/currencyFormatter";
import { getErrorMessagesFromError } from "../../errorParsing/getErrorMessagesFromError";
import { defaultErrorMessage } from "../../errorParsing/defaults";
import { PayrixBankAccountType } from "../enums/payrixBankAccountType";
import { ReferenceInformationField } from "./ReferenceInformationField";
import { strings } from "../../../languages";

export type SaveCallbackArgs = {
  firstName: string;
  lastName: string;
  routingNumber: string;
  accountNumber: string;
  accountType: PayrixBankAccountType;
  referenceInformation: string;
};

interface IProps<TResult> {
  showAuthorizePaymentMethodOnFile: boolean;
  onSave: (saveResult: TResult) => void;
  paymentAmount: number;
  saveCall: (args: SaveCallbackArgs) => Observable<TResult>;
}

function BankAccount<TResult>(props: IProps<TResult>) {
  const { onSave, paymentAmount, saveCall } = props;

  const [name, setName] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountNumberConfirmed, setAccountNumberConfirmed] = useState("");
  const [accountType, setAccountType] = useState<PayrixBankAccountType | null>(
    null
  );

  const [inRoutingNumber, setInRoutingNumber] = useState(false);
  const [inAccountNumber, setInAccountNumber] = useState(false);

  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const accountNumberConfirmedRef = useRef<HTMLInputElement>(null);

  const [referenceInformation, setReferenceInformation] = useState("");

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        if (saving) {
          return;
        }

        setSaving(true);

        const { firstName, lastName } = parseName(name);

        saveCall({
          firstName,
          lastName,
          routingNumber,
          accountNumber,
          accountType: accountType as PayrixBankAccountType,
          referenceInformation,
        }).subscribe({
          next: (result) => {
            setSaving(false);
            onSave(result);
          },

          error: (err) => {
            const parsedErrorMessages = getErrorMessagesFromError(err);

            setErrorMessage(
              parsedErrorMessages.length > 0
                ? parsedErrorMessages[0]
                : defaultErrorMessage
            );

            setSaving(false);
          },
        });
      }}
    >
      {saving ? <Spinner /> : null}
      <div className="form-group">
        <label htmlFor="name" className="required">
          {strings.name}
        </label>
        <input
          id="name"
          type="text"
          className="form-control"
          required
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          placeholder="JOHN SMITH"
        />
      </div>

      <div className="form-group">
        <label htmlFor="bankAccountType" className="required">
          {strings.bankAccountType}
        </label>
        <select
          className="form-control"
          id="bankAccountType"
          required
          value={accountType === null ? "" : accountType}
          onChange={(e) => {
            const accountTypeValue = e.currentTarget.value;
            if (accountTypeValue === "") {
              setAccountType(null);
            } else {
              setAccountType(parseInt(accountTypeValue));
            }
          }}
        >
          <option value="">{strings.selectBankAccountType}</option>
          <option value={PayrixBankAccountType.corporateChecking}>
            {strings.businessChecking}
          </option>
          <option value={PayrixBankAccountType.personalChecking}>
            {strings.personalChecking}
          </option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="routingNumber" className="required">
          {strings.routingNumber}
        </label>
        <input
          id="routingNumber"
          minLength={9}
          maxLength={9}
          type="text"
          inputMode="numeric"
          className="form-control fs-mask"
          required
          value={routingNumber}
          onChange={(e) => setRoutingNumber(e.currentTarget.value)}
          onFocus={() => setInRoutingNumber(true)}
          onBlur={() => setInRoutingNumber(false)}
          placeholder="123456789"
        />
      </div>
      <div className="form-row">
        <div className="col-12 col-sm-6 form-group">
          <label htmlFor="accountNumber" className="required">
            {strings.accountNumber}
          </label>
          <input
            id="accountNumber"
            minLength={5}
            maxLength={17}
            type="password"
            inputMode="numeric"
            className="form-control fs-mask"
            required
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.currentTarget.value)}
            placeholder="00123456789"
            onFocus={() => setInAccountNumber(true)}
            onBlur={() => setInAccountNumber(false)}
          />
        </div>
        <div className="col-12 col-sm-6 form-group">
          <label htmlFor="accountNumberConfirmed" className="required">
            {strings.confirmAccountNumber}
          </label>
          <input
            id="accountNumberConfirmed"
            minLength={5}
            maxLength={17}
            type="password"
            inputMode="numeric"
            className="form-control fs-mask"
            required
            value={accountNumberConfirmed}
            onChange={(e) => setAccountNumberConfirmed(e.currentTarget.value)}
            ref={accountNumberConfirmedRef}
            placeholder="00123456789"
            onFocus={() => setInAccountNumber(true)}
            onBlur={() => {
              setInAccountNumber(false);

              if (accountNumberConfirmedRef.current) {
                const isFieldInvalid =
                  accountNumber &&
                  accountNumberConfirmed &&
                  accountNumber !== accountNumberConfirmed;

                accountNumberConfirmedRef.current.setCustomValidity(
                  isFieldInvalid ? strings.accountNumbersDoNotMatch : ""
                );
              }
            }}
          />
        </div>
      </div>

      <div
        className="border p-2 mb-3 font-weight-light bg-white"
        style={{
          display: "grid",
          gridTemplateColumns: "min-content min-content min-content",
          columnGap: "10px",
        }}
      >
        <div className={inRoutingNumber ? "font-weight-bold" : ""}>
          123456789
        </div>
        <div>|:</div>
        <div className={inAccountNumber ? "font-weight-bold" : ""}>
          00123456789
        </div>
        <div className={inRoutingNumber ? "font-weight-bold" : ""}>
          {strings.routingNumberExampleLabel}
        </div>
        <div></div>
        <div className={inAccountNumber ? "font-weight-bold" : ""}>
          {strings.accountNumberExampleLabel}
        </div>
      </div>

      <ReferenceInformationField
        referenceInformation={referenceInformation}
        setReferenceInformation={setReferenceInformation}
        idPrefix="bankAccount"
      />

      {errorMessage ? (
        <div className="text-danger my-3" data-testid="errorMessage">
          {errorMessage}
        </div>
      ) : null}

      <div className="text-center">
        <button
          className="btn btn-primary btn-lg"
          type="submit"
          data-testid="bankAccountPaymentButton"
        >
          {paymentAmount > 0
            ? `${strings.pay} ${formatCurrency(paymentAmount)}`
            : "Save"}
        </button>
      </div>
    </form>
  );
}

export default BankAccount;

export function parseName(input: string) {
  const nameParts = input.trim().split(/\s/);

  let firstName = "";
  let lastName = "";
  if (nameParts.length === 1) {
    firstName = nameParts[0];
  } else if (nameParts.length > 1) {
    const [, ...lastNameParts] = nameParts;
    firstName = nameParts[0];
    lastName = lastNameParts.join(" ");
  }

  return {
    firstName,
    lastName,
  };
}
