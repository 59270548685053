import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

const Spinner = ({ testIdPrefix }: { testIdPrefix?: string }) => {
  return (
    <div
      className="loading"
      style={{ zIndex: 2050 }}
      data-testid={`${testIdPrefix ?? ""}spinner`}
    >
      <FontAwesomeIcon icon={faSpinner} spin size="3x" fixedWidth />
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default Spinner;
