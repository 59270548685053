import { appendQueryString } from "../../../services/routing";

export function getLineItemBackLinkToForm({
  formPath,
  originalScrollPosition,
}: {
  formPath: string;
  originalScrollPosition: string | null;
}): string {
  return appendQueryString({
    base: formPath,
    name: "originalScrollPosition",
    value: originalScrollPosition,
  });
}
