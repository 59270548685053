import * as React from "react";
import { IFile } from "../../../models/IFile";
import ExistingFile from "./ExistingFile";
import PendingFile from "./PendingFile";
import IScheduledJob from "../../../models/IScheduleJob";
import ISchedule from "../../../models/ISchedule";
import { LanguageContext } from "../../../App";
import { useContext } from "react";

interface IProps {
  filePrefix: string;
  tenantId: string;
  schedule: ISchedule;
  scheduledJob: IScheduledJob;
  pendingFiles: Array<IPendingFile>;
  files: Array<IFile>;
  onRetryFileUpload: (file: IPendingFile) => void;
  onErrorClosed: (fileTempId: string) => void;
  onFileAdded: (file: IFile) => void;
  onFileremoved: (fileId: string) => void;
  onFileUpdated: (fileId: string, caption: string) => void;
}

export interface IPendingFile {
  tempId: string;
  file: File;
  errorUploading: boolean;
  errorUploadingMessage?: JSX.Element | undefined;
}

const Files = (props: IProps) => {
  const languageContext = useContext(LanguageContext);

  return props.files.length > 0 || props.pendingFiles.length > 0 ? (
    <React.Fragment>
      {props.files.map((p) => (
        <ExistingFile
          schedule={props.schedule}
          imagePrefix={props.filePrefix}
          key={p.id}
          file={p}
          scheduledJob={props.scheduledJob}
          onFileRemoved={props.onFileremoved}
          onFileUpdated={props.onFileUpdated}
          language={languageContext.language}
        />
      ))}

      {props.pendingFiles.map((p) => (
        <PendingFile
          file={p}
          key={p.tempId}
          onRetryFileUpload={() => props.onRetryFileUpload(p)}
          onCloseError={() => {
            props.onErrorClosed(p.tempId);
          }}
        />
      ))}
    </React.Fragment>
  ) : null;
};

export default Files;
