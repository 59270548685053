import { useRef } from "react";
import { BillableFormLineItemInvoiceItemField } from "./BillableFormLineItemInvoiceItemField";
import {
  AmountPerItemField,
  CheckboxField,
  DescriptionField,
  ILineItem,
  QuantityField,
} from "./BillableFormLineItem";
import { IInvoiceItem } from "../types/IInvoiceItem";
import { strings } from "../../../languages";

export function LineItemFormPageBody({
  lineItem,
  invoiceItems,
  setInvoiceItems,
  onClearErrorMessage,
  onLineItemChange,
  formRef,
  formId,
  onSubmit,
  disabled,
  hideTaxableField,
  allowOptional,
  allowZeroQuantity,
}: {
  lineItem: ILineItem;
  invoiceItems: Array<IInvoiceItem>;
  setInvoiceItems: (newValue: Array<IInvoiceItem>) => void;
  onClearErrorMessage: () => void;
  onLineItemChange: (newLineItem: ILineItem) => void;
  formRef: React.RefObject<HTMLFormElement>;
  formId: string;
  onSubmit: () => void;
  disabled: boolean;
  hideTaxableField: boolean;
  allowOptional: boolean;
  allowZeroQuantity: boolean;
}) {
  const productServiceLabelRef = useRef<HTMLLabelElement>(null);

  return (
    <form
      id={formId}
      ref={formRef}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
      data-testid="lineItemForm"
    >
      <div className="form-group">
        <label
          htmlFor={"productService"}
          className="required"
          ref={productServiceLabelRef}
        >
          {strings.productService}
        </label>
        <BillableFormLineItemInvoiceItemField
          lineItem={lineItem}
          onLineItemChanged={onLineItemChange}
          items={invoiceItems}
          onClearErrorMessage={onClearErrorMessage}
          setInvoiceItems={setInvoiceItems}
          disabled={disabled}
          required={true}
          labelRef={productServiceLabelRef}
          inputElementId={"productService"}
          scrollIntoView={true}
          onItemAdded={() => {
            //  Not implemented
          }}
          onItemUpdated={() => {
            //  Not implemented
          }}
        />
        <DescriptionField
          lineItem={lineItem}
          onLineItemChanged={onLineItemChange}
          disabled={disabled}
          inputElementId={"description"}
        />
      </div>
      {allowOptional ? (
        <div className="form-group">
          <CheckboxField
            property={"optional"}
            inputElementId={"optional"}
            lineItem={lineItem}
            onLineItemChanged={onLineItemChange}
            disabled={disabled}
            inlineLabelText={strings.optional}
          />
        </div>
      ) : null}
      <div className="form-row">
        <div className="form-group col-6">
          <label htmlFor={"quantity"} className="required">
            {strings.quantity}
          </label>
          <QuantityField
            allowBlankQuantity={false}
            lineItem={lineItem}
            onLineItemChanged={onLineItemChange}
            inputElementId={"quantity"}
            disabled={disabled}
            allowZeroQuantity={allowZeroQuantity}
          />
        </div>
        <div className="form-group col-6">
          <label htmlFor={"amountPerItem"} className="required">
            {strings.amountPerItem}
          </label>
          <AmountPerItemField
            allowBlankAmountPerItem={false}
            inputElementId={"amountPerItem"}
            lineItem={lineItem}
            onLineItemChanged={onLineItemChange}
            disabled={disabled}
          />
        </div>
      </div>
      <div className="d-flex justify-content">
        {!hideTaxableField ? (
          <div className="form-group mr-3">
            <CheckboxField
              property={"taxable"}
              inputElementId={"taxable"}
              lineItem={lineItem}
              onLineItemChanged={onLineItemChange}
              disabled={disabled}
              inlineLabelText={strings.taxable}
            />
          </div>
        ) : null}
      </div>
    </form>
  );
}
