import * as ReactDOM from "react-dom";
import Error from "./components/Error";
import "./cerulean-bootstrap.min.css";
import { register } from "./registerServiceWorker";
import "./polyfills";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import FullScreenPhoto from "./components/FullScreenPhoto";
import { routingPatterns } from "./services/routing";
import { AppContainer } from "./components/AppContainer";
import { Login } from "./slices/app/components/Login";
import { AppHome } from "./slices/app/components/AppHome";
import { InstallationInstructions } from "./slices/app/components/InstallationInstructions";
import { useEffect, useState } from "react";

const rootEl = document.getElementById("root") as HTMLElement;

ReactDOM.render(<RouteContainer />, rootEl);
console.log(`Build Version: ${process.env.REACT_APP_BUILD_VERSION}`);

function RouteContainer() {
  const [deferredInstall, setDeferredInstall] = useState<unknown>(null);
  useEffect(() => {
    const handleInstallPrompt = (e: unknown) => {
      // Prevents the default mini-infobar or install dialog from appearing on mobile
      if (
        e &&
        typeof e === "object" &&
        "preventDefault" in e &&
        typeof e.preventDefault === "function"
      ) {
        e.preventDefault();

        setDeferredInstall(e);
      }
    };

    if ("BeforeInstallPromptEvent" in window) {
      window.addEventListener("beforeinstallprompt", handleInstallPrompt);
    }

    return function cleanup() {
      window.removeEventListener("beforeinstallprompt", handleInstallPrompt);
    };
  }, []);

  return (
    <Router>
      <Switch>
        <Route
          path={"/install"}
          render={() => (
            <InstallationInstructions deferredInstall={deferredInstall} />
          )}
        />
        <Route path={routingPatterns.getLoginPath()} component={Login} />
        <Route path={routingPatterns.getTechAppPath()} component={AppHome} />
        <Route
          path={`/photo/:scheduleJobId/:photoId`}
          render={(props) => {
            const { scheduleJobId, photoId } = props.match.params;

            return (
              <FullScreenPhoto
                scheduleJobId={scheduleJobId}
                photoId={photoId}
              />
            );
          }}
        />
        <Route
          path={routingPatterns.getSchedulePath()}
          render={(props) => {
            const dayScheduleId = props.match.params.dayScheduleId;
            return (
              <AppContainer
                dayScheduleId={dayScheduleId}
                techAppCrewId={null}
                jobListingFooterElement={null}
                alwaysEnableFullStory={false}
              />
            );
          }}
        ></Route>
        <Route path="*">
          <Error
            errorHeader="Schedule Missing"
            errorDetails="Please contact your office to get a new link."
          />
        </Route>
      </Switch>
    </Router>
  );
}

// Learn more about service workers: https://cra.link/PWA
register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  },
});
