import { isStringSet } from "../../typeUtilities/isStringSet";
import { IInvoiceItem } from "../types/IInvoiceItem";

export function getLineItemName(
  name: string | undefined,
  itemId: string,
  invoiceItems: IInvoiceItem[]
): string | null {
  if (isStringSet(name ?? null)) {
    return name as string;
  }

  const item = invoiceItems.find((i) => i.id === itemId);
  if (item) {
    return item.name;
  }

  return null;
}
