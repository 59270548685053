import * as React from "react";

interface IProps {
  style?: Partial<React.CSSProperties>;
  text: string | React.ReactNode;
  onClick(): void;
  testId?: string;
  additionalClassNames?: string;
}

const LinkButton: React.FunctionComponent<IProps> = ({
  text,
  onClick,
  style,
  testId,
  additionalClassNames,
}) => {
  return (
    <button
      type="button"
      className={`btn btn-link ${additionalClassNames ?? ""}`}
      style={{ padding: "0px", textDecoration: "underline", ...style }}
      onClick={onClick}
      data-testid={testId}
    >
      {text}
    </button>
  );
};

export default LinkButton;
