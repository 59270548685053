import { faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useRef, useState } from "react";
import {
  Control,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
  useForm,
} from "react-hook-form";
import Container from "../../../components/Container";
import { GenericFooter } from "../../../components/JobDetails/GenericFooter";
import Spinner from "../../../components/Spinner";
import { useScheduleJobCache } from "../../../hooks/useScheduleJobCache";
import { createEmailAddressRecord } from "../../../libraries/emailAddresses/EmailAddresses.functions";
import ISchedule from "../../../models/ISchedule";
import { IScheduleIdentifier } from "../../../models/IScheduleIdentifier";
import { routingBuilders, routingPatterns } from "../../../services/routing";
import { DiscountType } from "../../../libraries/billableForm/enums/DiscountType";
import { getPageHeader } from "../../../libraries/commonUi/functions/getPageHeader";
import { IInvoiceFormData } from "./invoiceForm/IInvoiceFormData";
import { InvoiceFormBody } from "./invoiceForm/InvoiceFormBody";
import { useInvoiceFormSave } from "./invoiceForm/useInvoiceFormSave";
import { useInvoiceFormLoad } from "./invoiceForm/useInvoiceFormLoad";
import { PageErrorLoading } from "../../../libraries/commonUi/components/PageErrorLoading";
import {
  getOrCreateInvoiceForPaymentForm,
  updateInvoiceSignature,
} from "../services/invoiceDataProvider";
import { TriggerBillableFormSave } from "../../../libraries/billableForm/types/TriggerBillableFormSave";
import { FooterButton } from "../../../components/FooterButton";
import { FormLockWarning } from "../../../libraries/billableForm/components/FormLockWarning";
import { FloatingIndicator } from "../../../libraries/billableForm/components/FloatingIndicator";
import { usePresentBillableItem } from "../../../libraries/billableForm/hooks/usePresentBillableItem";
import {
  Prompt,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { strings } from "../../../languages";
import { LineItemFormPageBodyContainer } from "../../../libraries/billableForm/components/LineItemFormPageBodyContainer";
import IScheduledJob from "../../../models/IScheduleJob";
import { ILineItem } from "../../../libraries/billableForm/components/BillableFormLineItem";
import { useGetFormTitle } from "../../../libraries/billableForm/hooks/useGetFormTitle";
import { getLineItemBackLinkToForm } from "../../../libraries/billableForm/functions/getLineItemBackLinkToForm";
import { IInvoiceItem } from "../../../libraries/billableForm/types/IInvoiceItem";
import { useScrollToOriginalPosition } from "../../../libraries/billableForm/hooks/useScrollToOriginalPosition";
import { billableFormConstants } from "../../../libraries/billableForm/billableFormConstants";
import { useQuery } from "../../../services/useQuery";
import { getTotal } from "../../../libraries/amountCalculation/getTotal";
import ModalPrompt from "../../../libraries/prompt/components/Prompt";
import { JobMovedErrorAlert } from "../../../components/JobMovedErrorAlert";
import { LineItemErrorMessages } from "../../../libraries/billableForm/components/BillableFormLineItemsMobile.types";
import { useIsLargeLineItemDisplay } from "../../../libraries/billableForm/hooks/useIsLargeLineItemDisplay";
import { getMobileCardId } from "../../../libraries/billableForm/components/BillableFormLineItemsMobile.functions";

export function InvoiceFormPage({
  scheduleJobId,
  scheduleIdentifier,
  schedule,
}: {
  scheduleJobId: string;
  scheduleIdentifier: IScheduleIdentifier;
  schedule: ISchedule;
}) {
  const { scheduleJob } = useScheduleJobCache(schedule, scheduleJobId ?? "");
  const [isUnlocking, setIsUnlocking] = useState(false);
  const [didUnlockingFail, setDidUnlockingFail] = useState(false);
  const { path } = useRouteMatch();
  const history = useHistory();
  const pageTitle = useGetFormTitle(strings.invoice);
  const query = useQuery();

  const formRef = useRef<HTMLFormElement>(null);

  const { control, getValues, setValue, watch } = useForm<IInvoiceFormData>({
    defaultValues: getEmptyFormData(),
  });
  const lastEmailAddressRef = useRef<HTMLInputElement | null>(null);

  const {
    loading,
    invoiceItems,
    setInvoiceItems,
    errorLoading,
    isInvoicePaid,
    doesInvoiceHaveSignature,
    isDraft,
    retryLoad,
    wasOriginallyZeroDollarInvoice,
    customerTaxExempt,
    taxRateAlreadySet,
    originalPhoneNumber,
    originalPhoneNumberOptedIntoSms,
    wasOriginallyInvoiceWithDepositAmount,
  } = useInvoiceFormLoad({
    scheduleIdentifier,
    scheduleJobId,
    setValue,
  });

  const isInvoicePaidWithAmount =
    isInvoicePaid && !wasOriginallyZeroDollarInvoice;

  const {
    saving,
    saveError: errorFromFormSave,
    setSaveError: setErrorFromFormSave,
    triggerSave: baseTriggerInvoiceFormSave,
    isFormValid,
    hasUnsavedChanges,
  } = useInvoiceFormSave({
    scheduleIdentifier,
    jobInstanceId: scheduleJobId,
    getValues,
    formRef,
    isInvoicePaidWithAmount,
    invoiceItems,
  });

  const lineItems = watch("lineItems");
  const lineItemErrorMessages: LineItemErrorMessages = lineItems
    .filter((li) => li.quantity === "" || li.quantity === "0")
    .map((li) => ({
      lineItemId: li.id,
      errorMessage: "Quantity must be greater than 0",
    }));

  const {
    presentSaving: paySaving,
    presentStarted: payStarted,
    triggerPresentation: baseTriggerPresentInvoice,
    errorSavingForPresentationForm: errorSavingForPaymentForm,
    setErrorSavingForPresentationForm: setErrorSavingForPaymentForm,
  } = usePresentBillableItem({
    scheduleIdentifier,
    jobInstanceId: scheduleJob?.id ?? "",
    triggerFormSave: baseTriggerInvoiceFormSave,
    skipSave: isInvoicePaidWithAmount || doesInvoiceHaveSignature,
    fullStoryEvent: "Crew: Present Invoice Clicked",
    presentationPagePath: routingBuilders.buildInvoicePresentation({
      scheduleIdentifier: scheduleIdentifier,
      scheduleJobId: scheduleJob?.id ?? "",
    }),
    getOrCreateCall: getOrCreateInvoiceForPaymentForm,
    lastEmailAddressInputRef: lastEmailAddressRef,
  });

  const triggerInvoiceFormSave: TriggerBillableFormSave = (arg) => {
    setErrorSavingForPaymentForm(null);
    baseTriggerInvoiceFormSave(arg);
  };

  const isLargeLineItemDisplay = useIsLargeLineItemDisplay();
  const triggerPresentInvoice = () => {
    if (!isLargeLineItemDisplay) {
      if (lineItemErrorMessages.length > 0) {
        const firstLineItemId = lineItemErrorMessages[0].lineItemId;
        const cardId = getMobileCardId(firstLineItemId);
        const cardElement = document.getElementById(cardId);
        if (cardElement) {
          cardElement.scrollIntoView();
          return;
        }
      }
    }

    baseTriggerPresentInvoice();
  };

  const setLineItems = useCallback((v) => setValue("lineItems", v), [setValue]);
  const getLineItems = useCallback(() => getValues("lineItems"), [getValues]);

  if (!scheduleJob) {
    return <JobMovedErrorAlert scheduleIdentifier={scheduleIdentifier} />;
  }

  return (
    <Container
      isJobListing={false}
      schedule={schedule}
      headerOverride={getPageHeader({
        schedule,
        scheduleJob,
        pageTitle,
      })}
      scheduleIdentifier={scheduleIdentifier}
    >
      {loading || paySaving || isUnlocking ? <Spinner /> : null}

      {!loading && errorLoading ? (
        <>
          <PageErrorLoading
            retryLoad={retryLoad}
            disableRetry={errorLoading.was400Error}
            errorDetails={errorLoading.message}
          />
          <GenericFooter
            backLink={routingBuilders.buildScheduleJobPath({
              scheduleIdentifier,
              scheduleJobId: scheduleJobId,
            })}
          />
        </>
      ) : null}

      {!loading && !errorLoading ? (
        <>
          {doesInvoiceHaveSignature ? (
            <FormLockWarning
              message={strings.voidInvoiceWarning}
              onClear={() => {
                setIsUnlocking(true);
                setDidUnlockingFail(false);

                updateInvoiceSignature({
                  scheduleIdentifier,
                  jobInstanceId: scheduleJobId,
                  payload: {
                    signatureImagePath: null,
                  },
                }).subscribe({
                  next: () => {
                    setIsUnlocking(false);
                    retryLoad();
                  },

                  error: (err) => {
                    setIsUnlocking(false);
                    setDidUnlockingFail(true);
                  },
                });
              }}
            />
          ) : null}

          <Switch>
            <Route
              path={routingPatterns.getLineItemFormPath(path)}
              render={(props) => {
                const lineItemId = props.match.params.lineItemId;
                return (
                  <LineItemFormPageBodyContainer
                    invoiceItems={invoiceItems}
                    setInvoiceItems={setInvoiceItems}
                    lineItemId={lineItemId ?? null}
                    setLineItems={setLineItems}
                    getLineItems={getLineItems}
                    disabled={isInvoicePaidWithAmount}
                    allowOptionalItems={false}
                    allowZeroQuantity={false}
                    getLineItemBackLink={() =>
                      getLineItemBackLink({
                        scheduleIdentifier,
                        scheduleJobId,
                        originalScrollPosition: query.get(
                          billableFormConstants.originalScrollPosition
                        ),
                      })
                    }
                    onSaveComplete={(id) => {
                      history.push(
                        getLineItemBackLink({
                          scheduleIdentifier,
                          scheduleJobId,
                          originalScrollPosition: query.get(
                            billableFormConstants.originalScrollPosition
                          ),
                        })
                      );

                      triggerInvoiceFormSave({});
                    }}
                  />
                );
              }}
            />
            <Route
              render={() => {
                return (
                  <>
                    <Prompt
                      message={strings.unsavedChangesWarning}
                      when={hasUnsavedChanges}
                    />
                    <InvoiceFormContainer
                      formRef={formRef}
                      control={control}
                      triggerInvoiceFormSave={triggerInvoiceFormSave}
                      invoiceItems={invoiceItems}
                      setInvoiceItems={setInvoiceItems}
                      lineItems={lineItems}
                      scheduleIdentifier={scheduleIdentifier}
                      schedule={schedule}
                      isDraft={isDraft}
                      scheduleJob={scheduleJob}
                      scheduleJobId={scheduleJobId}
                      triggerPresentation={triggerPresentInvoice}
                      isInvoicePaidWithAmount={isInvoicePaidWithAmount}
                      doesInvoiceHaveSignature={doesInvoiceHaveSignature}
                      payStarted={payStarted}
                      getValues={getValues}
                      wasOriginallyZeroDollarInvoice={
                        wasOriginallyZeroDollarInvoice
                      }
                      customerTaxExempt={customerTaxExempt}
                      taxRateAlreadySet={taxRateAlreadySet}
                      setValue={setValue}
                      watch={watch}
                      originalPhoneNumber={originalPhoneNumber}
                      originalPhoneNumberOptedIntoSms={
                        originalPhoneNumberOptedIntoSms
                      }
                      lastEmailAddressInputRef={lastEmailAddressRef}
                      lineItemErrorMessages={lineItemErrorMessages}
                      alwaysShowDepositCredits={
                        wasOriginallyInvoiceWithDepositAmount
                      }
                    />

                    <FloatingIndicator
                      saving={saving && !paySaving}
                      errorFromFormSave={errorFromFormSave}
                      setErrorFromFormSave={setErrorFromFormSave}
                      triggerInvoiceFormSave={triggerInvoiceFormSave}
                      isFormValid={isFormValid}
                      formRef={formRef}
                      errorSavingForPaymentForm={errorSavingForPaymentForm}
                      setErrorSavingForPaymentForm={
                        setErrorSavingForPaymentForm
                      }
                      triggerPresentInvoice={triggerPresentInvoice}
                      didUnlockingFail={didUnlockingFail}
                      setDidUnlockingFail={setDidUnlockingFail}
                    />
                  </>
                );
              }}
            />
          </Switch>
        </>
      ) : null}
    </Container>
  );
}

function InvoiceFormContainer({
  formRef,
  control,
  triggerInvoiceFormSave,
  invoiceItems,
  setInvoiceItems,
  lineItems,
  scheduleIdentifier,
  schedule,
  isInvoicePaidWithAmount,
  doesInvoiceHaveSignature,
  isDraft,
  payStarted,
  scheduleJob,
  scheduleJobId,
  triggerPresentation,
  getValues,
  wasOriginallyZeroDollarInvoice,
  customerTaxExempt,
  taxRateAlreadySet,
  setValue,
  watch,
  originalPhoneNumber,
  originalPhoneNumberOptedIntoSms,
  lastEmailAddressInputRef,
  lineItemErrorMessages,
  alwaysShowDepositCredits,
}: {
  formRef: React.RefObject<HTMLFormElement>;
  control: Control<IInvoiceFormData, any>;
  getValues: UseFormGetValues<IInvoiceFormData>;
  triggerInvoiceFormSave: TriggerBillableFormSave;
  invoiceItems: IInvoiceItem[];
  setInvoiceItems: React.Dispatch<React.SetStateAction<Array<IInvoiceItem>>>;
  lineItems: ILineItem[];
  scheduleIdentifier: IScheduleIdentifier;
  schedule: ISchedule;
  isInvoicePaidWithAmount: boolean;
  doesInvoiceHaveSignature: boolean;
  isDraft: boolean;
  payStarted: boolean;
  scheduleJob: IScheduledJob;
  scheduleJobId: string;
  triggerPresentation: () => void;
  wasOriginallyZeroDollarInvoice: boolean;
  customerTaxExempt: boolean;
  taxRateAlreadySet: boolean;
  setValue: UseFormSetValue<IInvoiceFormData>;
  watch: UseFormWatch<IInvoiceFormData>;
  originalPhoneNumber: string;
  originalPhoneNumberOptedIntoSms: boolean;
  lastEmailAddressInputRef: React.MutableRefObject<HTMLInputElement | null>;
  lineItemErrorMessages: LineItemErrorMessages;
  alwaysShowDepositCredits: boolean;
}) {
  useScrollToOriginalPosition();
  const [showZeroDollarPrompt, setShowZeroDollarPrompt] = useState(false);

  return (
    <>
      <InvoiceFormBody
        formRef={formRef}
        control={control}
        triggerSave={triggerInvoiceFormSave}
        invoiceItems={invoiceItems}
        setInvoiceItems={setInvoiceItems}
        lineItems={lineItems}
        scheduleIdentifier={scheduleIdentifier}
        schedule={schedule}
        isInvoicePaidWithAmount={isInvoicePaidWithAmount}
        doesInvoiceHaveSignature={doesInvoiceHaveSignature}
        isDraft={isDraft && !payStarted}
        scheduleJob={scheduleJob}
        scheduleJobId={scheduleJobId}
        customerTaxExempt={customerTaxExempt}
        taxRateAlreadySet={taxRateAlreadySet}
        setValue={setValue}
        originalPhoneNumber={originalPhoneNumber}
        originalPhoneNumberOptedIntoSms={originalPhoneNumberOptedIntoSms}
        lastEmailAddressInputRef={lastEmailAddressInputRef}
        lineItemErrorMessages={lineItemErrorMessages}
        alwaysShowDepositCredits={alwaysShowDepositCredits}
      />
      <GenericFooter
        backLink={routingBuilders.buildScheduleJobPath({
          scheduleIdentifier,
          scheduleJobId: scheduleJobId,
        })}
      >
        <div className="text-center">
          <FooterButton
            icon={faFileInvoice}
            caption={strings.present}
            color="text-primary"
            testId="presentLink"
            onClick={() => {
              const { discount, taxRate } = getValues("amountAdjustments");
              const total = getTotal(
                {
                  taxRate,
                  lineItems,
                  discount,
                  depositCreditAmount: null,
                } ?? 0
              );

              if (total !== 0 || wasOriginallyZeroDollarInvoice) {
                triggerPresentation();
              } else {
                setShowZeroDollarPrompt(true);
              }
            }}
          />
        </div>
      </GenericFooter>
      <ModalPrompt
        showPrompt={showZeroDollarPrompt}
        promptMessage={
          <div data-testid="zeroDollarWarning">
            <div>
              <strong>This invoice is for $0!</strong>
            </div>
            <div className="mt-2">
              A receipt will be emailed and this invoice will be marked as paid
              when saved.
            </div>
            <div className="mt-2">Are you sure you want to continue?</div>
          </div>
        }
        onConfirm={() => {
          setShowZeroDollarPrompt(false);
          triggerPresentation();
        }}
        onCancel={() => setShowZeroDollarPrompt(false)}
      />
    </>
  );
}

function getLineItemBackLink({
  scheduleIdentifier,
  scheduleJobId,
  originalScrollPosition,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  scheduleJobId: string;
  originalScrollPosition: string | null;
}): string {
  return getLineItemBackLinkToForm({
    formPath: routingBuilders.buildInvoiceFormPath({
      scheduleIdentifier,
      scheduleJobId,
    }),
    originalScrollPosition: originalScrollPosition,
  });
}

function getEmptyFormData(): IInvoiceFormData {
  return {
    phoneNumberWithOptInStatus: {
      phoneNumber: "",
      optedIn: false,
    },
    emails: [createEmailAddressRecord("")],
    amountAdjustments: {
      discount: {
        type: DiscountType.amount,
        amount: null,
        percent: null,
      },
      taxRate: null,
      depositCreditAmount: null,
      maxDepositCreditAmount: null,
    },
    files: [],
    lineItems: [],
    summary: "",
  };
}
