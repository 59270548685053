import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function IconWithCaption({
  icon,
  caption,
}: {
  icon: IconProp;
  caption: string;
}) {
  return (
    <>
      <FontAwesomeIcon icon={icon} size="3x" fixedWidth />

      <div>{caption}</div>
    </>
  );
}
