import { useEffect, useRef, useState } from "react";
import Container from "../../../components/Container";
import { GenericFooter } from "../../../components/JobDetails/GenericFooter";
import Spinner from "../../../components/Spinner";
import { getErrorMessageFromError } from "../../../libraries/errorParsing/getErrorMessageFromError";
import ISchedule from "../../../models/ISchedule";
import { IScheduleIdentifier } from "../../../models/IScheduleIdentifier";
import { routingBuilders } from "../../../services/routing";
import { getPageHeader } from "../../../libraries/commonUi/functions/getPageHeader";
import { faCreditCard, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { fullStoryTrack } from "../../../services/fullStoryService";
import { FloatingAlert } from "../../../libraries/billableForm/components/FloatingAlert";
import { FloatingErrorSavingIndicator } from "../../../libraries/billableForm/components/FloatingErrorSavingIndicator";
import { ErrorResponse } from "../../../libraries/billableForm/types/ErrorResponse";
import {
  loadEstimateForPresentationPage,
  useLoadEstimateForPresentationPage,
} from "../hooks/useLoadEstimateForPresentationPage";
import { emailEstimate, textEstimate } from "../services/estimateDataProvider";
import { PresentationFormLoadFailureAlert } from "../../../libraries/billableForm/components/PresentationFormLoadFailureAlert";
import EstimatePresentation from "./EstimatePresentation";
import { ApplyToCurrentJobModal } from "./ApplyToCurrentJobModal";
import { Link, useHistory } from "react-router-dom";
import { JobToApplyProposalTo } from "../enums/JobToApplyProposalTo";
import { strings } from "../../../languages";
import { JobMovedErrorAlert } from "../../../components/JobMovedErrorAlert";
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap/lib";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function EstimatePresentationPage({
  scheduleJobId,
  scheduleIdentifier,
  schedule,
  imagePrefix,
  hasMovedFromSignaturePad,
  reloadSchedule,
}: {
  scheduleJobId: string;
  scheduleIdentifier: IScheduleIdentifier;
  schedule: ISchedule;
  imagePrefix: string;
  hasMovedFromSignaturePad: boolean;
  reloadSchedule: () => void;
}) {
  const [showApplyToCurrentJobModal, setShowApplyToCurrentJobModal] =
    useState(false);

  const history = useHistory();

  const {
    loading,
    estimate,
    setEstimate,
    depositInvoice,
    setDepositInvoice,
    loadError,
    scheduleJob,
    setLoading,
    setLoadError,
    crewMemberSelectedJobToApplyTo,
    setCrewMemberSelectedJobToApplyTo,
    canEmail,
    setCanEmail,
    canText,
    setCanText,
  } = useLoadEstimateForPresentationPage({
    schedule,
    scheduleIdentifier,
    scheduleJobId,
  });

  const {
    triggerEmailEstimate,
    triggerTextEstimate,
    sendInvoiceSaving,
    showSendInvoiceSuccess,
    setShowSendInvoiceSuccess,
    errorSendingInvoice,
    setErrorSendingInvoice,
  } = useEmailEstimate({
    scheduleIdentifier,
    jobInstanceId: scheduleJob?.id ?? "",
  });

  const [isSendDropdownOpen, setIsSendDropDownOpen] = useState(false);

  let floatingAlertOrIndicator: JSX.Element | null = null;
  if (errorSendingInvoice) {
    floatingAlertOrIndicator = (
      <FloatingErrorSavingIndicator
        errorMessage={errorSendingInvoice.message}
        clearErrorMessage={() => setErrorSendingInvoice(null)}
        triggerSave={() => triggerEmailEstimate()}
        showRetry={!errorSendingInvoice.was400Error}
      />
    );
  } else if (showSendInvoiceSuccess) {
    floatingAlertOrIndicator = (
      <FloatingAlert
        type="success"
        showClose
        onClose={() => setShowSendInvoiceSuccess(false)}
        testId="messageSentConfirmation"
      >
        {strings.messageSent}
      </FloatingAlert>
    );
  }

  if (!scheduleJob) {
    return <JobMovedErrorAlert scheduleIdentifier={scheduleIdentifier} />;
  }

  const showApplyToJobModal =
    !!estimate?.acceptanceDateTime && !crewMemberSelectedJobToApplyTo;

  return (
    <Container
      isJobListing={false}
      schedule={schedule}
      headerOverride={getPageHeader({
        schedule,
        scheduleJob,
        pageTitle: strings.estimate,
      })}
      scheduleIdentifier={scheduleIdentifier}
    >
      {loading || sendInvoiceSaving ? <Spinner /> : null}

      <div style={{ marginBottom: "100px" }}>
        {loadError ? (
          <PresentationFormLoadFailureAlert
            loadError={loadError}
            backLinkPath={routingBuilders.buildEstimateFormPath({
              scheduleIdentifier,
              scheduleJobId,
            })}
            backLinkText="Estimate Form"
            onTryAgain={() => {
              loadEstimateForPresentationPage({
                setLoading,
                setLoadError,
                linkedDayScheduleId: scheduleIdentifier.linkedDayScheduleId,
                dayOffset: scheduleIdentifier.dayOffset,
                techAppCrewId: scheduleIdentifier.techAppCrewId,
                scheduleJobId,
                setEstimate,
                setDepositInvoice,
                setCrewMemberSelectedJobToApplyTo,
                setCanEmail,
                setCanText,
              });
            }}
          />
        ) : null}

        {estimate ? (
          <EstimatePresentation
            estimate={estimate}
            depositInvoice={depositInvoice}
            imagePrefix={imagePrefix}
            signatureLinkPath={routingBuilders.buildEstimateSignaturePath({
              scheduleIdentifier,
              scheduleJobId,
            })}
            hasMovedFromSignaturePad={hasMovedFromSignaturePad}
            setEstimate={setEstimate}
            scheduleIdentifier={scheduleIdentifier}
            scheduleJobId={scheduleJobId}
          />
        ) : null}
      </div>

      <ApplyToCurrentJobModal
        scheduleIdentifier={scheduleIdentifier}
        scheduleJobId={scheduleJobId}
        onSave={(jobToApplyTo) => {
          setShowApplyToCurrentJobModal(false);

          if (jobToApplyTo === JobToApplyProposalTo.currentJob) {
            reloadSchedule();
          }

          history.push(
            routingBuilders.buildScheduleJobPath({
              scheduleIdentifier,
              scheduleJobId,
            })
          );
        }}
        onCancel={() => {
          setShowApplyToCurrentJobModal(false);

          history.push(
            routingBuilders.buildScheduleJobPath({
              scheduleIdentifier,
              scheduleJobId,
            })
          );
        }}
        show={showApplyToCurrentJobModal}
      />

      {floatingAlertOrIndicator}

      <GenericFooter
        backLink={routingBuilders.buildEstimateFormPath({
          scheduleIdentifier: scheduleIdentifier,
          scheduleJobId: scheduleJob.id,
        })}
        blockBackLink={showApplyToJobModal}
        onBackClick={() => {
          if (showApplyToJobModal) {
            setShowApplyToCurrentJobModal(true);
          }
        }}
      >
        {estimate && (canEmail || canText || estimate.lookupId) ? (
          <div>
            <Dropdown
              isOpen={isSendDropdownOpen}
              toggle={() => setIsSendDropDownOpen((v) => !v)}
              direction="up"
            >
              <DropdownToggle className="text-primary p-0 bg-transparent border-0 text-center">
                <FontAwesomeIcon icon={faEnvelope} size="3x" fixedWidth />
                <div>{strings.send}</div>
              </DropdownToggle>
              <DropdownMenu
                style={{
                  borderRadius: ".75rem",
                  border: "2px solid rgba(0, 0, 0, 0.15)",
                }}
              >
                <>
                  {canEmail ? (
                    <>
                      <DropdownItem
                        onClick={() => {
                          triggerEmailEstimate();
                        }}
                        data-testid="emailEstimate"
                        className="text-primary font-weight-bold"
                      >
                        {strings.email}
                      </DropdownItem>
                      <DropdownItem divider />
                    </>
                  ) : null}

                  {canText ? (
                    <>
                      <DropdownItem
                        onClick={() => {
                          triggerTextEstimate();
                        }}
                        data-testid="textEstimate"
                        className="text-primary font-weight-bold"
                      >
                        {strings.text}
                      </DropdownItem>
                      <DropdownItem divider />
                    </>
                  ) : null}
                </>
                {estimate.lookupId ? (
                  <>
                    <DropdownItem
                      tag={"a"}
                      data-testid="printPage"
                      href={`${schedule.customerUrlRoot}/print/proposal/${estimate.lookupId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary font-weight-bold"
                    >
                      {strings.print}
                    </DropdownItem>
                  </>
                ) : null}
              </DropdownMenu>
            </Dropdown>
          </div>
        ) : null}

        {depositInvoice ? (
          <div className={`text-center`}>
            <Link
              to={routingBuilders.buildEstimateDepositInvoicePaymentPath({
                scheduleIdentifier,
                scheduleJobId,
              })}
              data-testid="payLink"
              className={depositInvoice?.paid ? "text-success" : ""}
              style={{
                paddingTop: "10px",
                pointerEvents: depositInvoice?.paid ? "none" : undefined,
              }}
              onClick={() => {
                fullStoryTrack("Crew: Pay Deposit Invoice Clicked");
              }}
            >
              <FontAwesomeIcon icon={faCreditCard} size="3x" fixedWidth />
              <div>{depositInvoice?.paid ? strings.paid : strings.pay}</div>
            </Link>
          </div>
        ) : null}
      </GenericFooter>
    </Container>
  );
}

function useEmailEstimate({
  scheduleIdentifier,
  jobInstanceId,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
}) {
  const [sendInvoiceSaving, setSendInvoiceSaving] = useState(false);
  const [showSendInvoiceSuccess, setShowSendInvoiceSuccess] = useState(false);
  const [errorSendingInvoice, setErrorSendingInvoice] =
    useState<ErrorResponse | null>(null);

  const clearConfirmationTimeoutId = useRef<NodeJS.Timeout | null>(null);

  // If component is disposed, remove the timeout
  useEffect(() => {
    return function cleanup() {
      if (clearConfirmationTimeoutId.current) {
        clearTimeout(clearConfirmationTimeoutId.current);
      }
    };
  }, []);

  const triggerEmailEstimate = () => {
    fullStoryTrack("Crew: Email Estimate Clicked");

    setSendInvoiceSaving(true);
    setShowSendInvoiceSuccess(false);
    setErrorSendingInvoice(null);

    emailEstimate({
      scheduleIdentifier,
      jobInstanceId,
    }).subscribe({
      next: () => {
        setSendInvoiceSaving(false);
        setShowSendInvoiceSuccess(true);

        if (clearConfirmationTimeoutId.current) {
          clearTimeout(clearConfirmationTimeoutId.current);
        }

        clearConfirmationTimeoutId.current = setTimeout(() => {
          setShowSendInvoiceSuccess(false);
        }, 5000);
      },

      error: (err) => {
        setSendInvoiceSaving(false);
        setErrorSendingInvoice({
          message: getErrorMessageFromError(err),
          was400Error: err.status === 400,
        });
      },
    });
  };

  const triggerTextEstimate = () => {
    fullStoryTrack("Crew: Text Estimate Clicked");

    setSendInvoiceSaving(true);
    setShowSendInvoiceSuccess(false);
    setErrorSendingInvoice(null);

    textEstimate({
      scheduleIdentifier,
      jobInstanceId,
    }).subscribe({
      next: () => {
        setSendInvoiceSaving(false);
        setShowSendInvoiceSuccess(true);

        if (clearConfirmationTimeoutId.current) {
          clearTimeout(clearConfirmationTimeoutId.current);
        }

        clearConfirmationTimeoutId.current = setTimeout(() => {
          setShowSendInvoiceSuccess(false);
        }, 5000);
      },

      error: (err) => {
        setSendInvoiceSaving(false);
        setErrorSendingInvoice({
          message: getErrorMessageFromError(err),
          was400Error: err.status === 400,
        });
      },
    });
  };

  return {
    sendInvoiceSaving,
    showSendInvoiceSuccess,
    errorSendingInvoice,
    triggerEmailEstimate,
    triggerTextEstimate,
    setShowSendInvoiceSuccess,
    setErrorSendingInvoice,
  };
}
