function getKey(settingsType: UserSettingsType) {
  return `usersettingskey_${settingsType}`;
}

export enum UserSettingsType {
  onTheWayCrewMemberName = 0,
  onTheWayCrewMemberPhoneNumber = 1,
  loginPhoneNumber = 2,
}

export function getUserSettings<T>(settingsType: UserSettingsType) {
  try {
    const serializedSettings = window.localStorage.getItem(
      getKey(settingsType)
    );
    if (serializedSettings) {
      return JSON.parse(serializedSettings) as T;
    } else {
      return null;
    }
  } catch (err) {
    // Handling local storage disabled
    return null;
  }
}

export function setUserSettings<T>(
  settingsType: UserSettingsType,
  settings: T
) {
  try {
    window.localStorage.setItem(getKey(settingsType), JSON.stringify(settings));
  } catch (err) {
    // Handling local storage disabled
  }
}
