import { useEffect, useState } from "react";
import {
  clearAuthenticationInfo,
  getAccessToken,
  hasAuthenticationExpired,
} from "../services/authentication";
import { Link, Route, useHistory, useRouteMatch } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import { timeout } from "rxjs/operators";
import Error from "../../../components/Error";
import { routingBuilders, routingPatterns } from "../../../services/routing";
import {
  getAvailableSchedules,
  GetAvailableSchedulesResponse,
} from "../services/appDataProvider";
import { getErrorMessageFromError } from "../../../libraries/errorParsing/getErrorMessageFromError";
import { AppSchedule } from "./AppSchedule";
import React from "react";
import { AppContainer } from "./AppContainer";
import { GenericFooter } from "../../../components/JobDetails/GenericFooter";
import { LogoutFooterButton } from "./LogoutFooterButton";
import { fullStoryInit } from "../../../services/fullStoryService";
import configuration from "../../../services/configuration";

export function AppHome() {
  const { availableSchedules, loadingSchedules, errorGettingSchedules } =
    useLoadAvailableSchedules();

  const { path } = useRouteMatch();

  useEffect(() => {
    if (configuration.fullStoryId) {
      fullStoryInit();
    }
  }, []);

  return (
    <>
      {loadingSchedules ? <Spinner /> : null}

      {availableSchedules !== null && errorGettingSchedules == null ? (
        <>
          <Route
            path={path}
            exact={true}
            render={() => {
              return (
                <CrewListing
                  currentPath={path}
                  availableSchedules={availableSchedules}
                />
              );
            }}
          />

          <Route
            path={routingPatterns.getTechAppSchedulePath(path)}
            render={(props) => {
              return (
                <AppSchedule
                  crewId={props.match.params.crewId}
                  hasMultipleCrews={!hasSingleSchedule(availableSchedules)}
                />
              );
            }}
          />
        </>
      ) : null}

      {errorGettingSchedules !== null ? (
        <Error
          errorHeader={
            errorGettingSchedules.scheduleNotFound
              ? "Schedule Not Found"
              : "Error Occurred"
          }
          errorDetails={errorGettingSchedules.errorMessage}
        />
      ) : null}
    </>
  );
}

function CrewListing({
  availableSchedules,
  currentPath,
}: {
  availableSchedules: GetAvailableSchedulesResponse;
  currentPath: string;
}) {
  const history = useHistory();

  if (availableSchedules) {
    if (hasSingleSchedule(availableSchedules)) {
      history.push(
        routingBuilders.buildTechAppSchedulePath({
          parentPath: currentPath,
          crewId: availableSchedules.tenants[0].crews[0].id,
        })
      );
    }
  }

  return (
    <AppContainer>
      {availableSchedules ? (
        <>
          <div style={{ marginBottom: "100px" }}>
            {availableSchedules.tenants.map((tenant) => (
              <React.Fragment key={tenant.id}>
                <div className="mb-3">
                  <h4 className="mb-3">{tenant.name} Schedules</h4>

                  <div className="list-group">
                    {tenant.crews.map((crew) => (
                      <React.Fragment key={crew.id}>
                        <Link
                          to={routingBuilders.buildTechAppSchedulePath({
                            parentPath: currentPath,
                            crewId: crew.id,
                          })}
                          className="btn btn-block btn-primary mb-2"
                        >
                          {crew.name}
                        </Link>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>

          <GenericFooter hideBackLink={true} backLink={null}>
            <LogoutFooterButton />
          </GenericFooter>
        </>
      ) : null}
    </AppContainer>
  );
}

function hasSingleSchedule(availableSchedules: GetAvailableSchedulesResponse) {
  return (
    availableSchedules.tenants.length === 1 &&
    availableSchedules.tenants[0].crews.length === 1
  );
}

function useLoadAvailableSchedules() {
  const history = useHistory();

  const [availableSchedules, setAvailableSchedules] =
    useState<GetAvailableSchedulesResponse | null>(null);
  const [loadingSchedules, setLoadingSchedules] = useState(true);
  const [errorGettingSchedules, setErrorGettingSchedules] = useState<{
    scheduleNotFound: boolean;
    errorMessage: string;
  } | null>(null);

  useEffect(() => {
    const accessToken = getAccessToken();
    if (!accessToken || hasAuthenticationExpired()) {
      clearAuthenticationInfo();
      history.push(routingBuilders.buildLoginPath());
    } else {
      setLoadingSchedules(true);
      getAvailableSchedules({ accessToken })
        .pipe(timeout(10000))
        .subscribe({
          next: (result) => {
            setLoadingSchedules(false);

            setAvailableSchedules(result);
          },

          error: (err) => {
            setLoadingSchedules(false);

            // If token is no longer valid, have user log back in
            if (err.status === 401) {
              clearAuthenticationInfo();
              history.push(routingBuilders.buildLoginPath());
            }

            setErrorGettingSchedules({
              scheduleNotFound: err.status === 400,
              errorMessage: getErrorMessageFromError(
                err,
                "Please ensure you can connect to the internet."
              ),
            });
          },
        });
    }
  }, [history]);

  return {
    availableSchedules,
    loadingSchedules,
    errorGettingSchedules,
  };
}
