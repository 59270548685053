export enum PaymentType {
  creditCard = 0,
  bankTransfer = 1,
  check = 2,
  cash = 3,
  other = 4,
}

export function getParsedPaymentType(input: string): PaymentType {
  return parseInt(input);
}
