import { useState, useEffect, useRef } from "react";
import { UseFormSetValue } from "react-hook-form";
import { forkJoin } from "rxjs";
import { finalize, timeout } from "rxjs/operators";
import { IScheduleIdentifier } from "../../../../models/IScheduleIdentifier";
import { IInvoiceItem } from "../../../../libraries/billableForm/types/IInvoiceItem";
import {
  getInvoiceForForm,
  getInvoiceItemsForForm,
} from "../../services/invoiceDataProvider";
import { IInvoiceFormData } from "./IInvoiceFormData";
import { Subscription } from "rxjs";
import { isStringSet } from "../../../../libraries/typeUtilities/isStringSet";
import { getEmailAddressesForFormData } from "../../../../libraries/billableForm/functions/getEmailAddressesForFormData";
import { getLineItemsForFormData } from "../../../../libraries/billableForm/functions/getLineItemsForFormData";
import { getFilesForFormData } from "../../../../libraries/billableForm/functions/getFilesForFormData";
import { getTotal } from "../../../../libraries/amountCalculation/getTotal";
import { ErrorResponse } from "../../../../libraries/billableForm/types/ErrorResponse";
import { getErrorMessageFromError } from "../../../../libraries/errorParsing/getErrorMessageFromError";
import { isBadRequestResponse } from "../../../../libraries/errorParsing/isBadRequestResponse";

export function useInvoiceFormLoad({
  scheduleIdentifier,
  scheduleJobId,
  setValue,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  scheduleJobId: string;
  setValue: UseFormSetValue<IInvoiceFormData>;
}) {
  const [loading, setLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState<ErrorResponse | null>(null);

  const [invoiceItems, setInvoiceItems] = useState<IInvoiceItem[]>([]);
  const [isInvoicePaid, setIsInvoicePaid] = useState(false);
  const [doesInvoiceHaveSignature, setDoesInvoiceHaveSignature] =
    useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [customerTaxExempt, setCustomerTaxExempt] = useState(false);
  const [taxRateAlreadySet, setTaxRateAlreadySet] = useState(false);
  const [wasOriginallyZeroDollarInvoice, setWasOriginallyZeroDollarInvoice] =
    useState(false);
  const [
    wasOriginallyInvoiceWithDepositAmount,
    setWasOriginallyInvoiceWithDepositAmount,
  ] = useState(false);
  const [originalPhoneNumber, setOriginalPhoneNumber] = useState("");
  const [originalPhoneNumberOptedIntoSms, setOriginalPhoneNumberOptedIntoSms] =
    useState(false);

  const subscriptions = useRef<Array<Subscription>>([]);

  useEffect(() => {
    loadData({
      setLoading,
      setErrorLoading,
      setIsInvoicePaid,
      setDoesInvoiceHaveSignature,
      setIsDraft,
      subscriptions,
      linkedDayScheduleId: scheduleIdentifier.linkedDayScheduleId,
      dayOffset: scheduleIdentifier.dayOffset,
      techAppCrewId: scheduleIdentifier.techAppCrewId,
      scheduleJobId,
      setInvoiceItems,
      setValue,
      setWasOriginallyZeroDollarInvoice,
      setWasOriginallyInvoiceWithDepositAmount,
      setCustomerTaxExempt,
      setTaxRateAlreadySet,
      setOriginalPhoneNumber,
      setOriginalPhoneNumberOptedIntoSms,
    });

    return function cleanup() {
      if (subscriptions.current) {
        // This should be safe since the warning is regarding element refs
        // rather than refs we manage
        // eslint-disable-next-line react-hooks/exhaustive-deps
        subscriptions.current.forEach((s) => s.unsubscribe());
      }
    };
  }, [
    scheduleIdentifier.linkedDayScheduleId,
    scheduleIdentifier.dayOffset,
    scheduleIdentifier.techAppCrewId,
    scheduleJobId,
    setValue,
  ]);

  const retryLoad = () =>
    loadData({
      setLoading,
      setErrorLoading,
      setIsInvoicePaid,
      setDoesInvoiceHaveSignature,
      setIsDraft,
      subscriptions,
      linkedDayScheduleId: scheduleIdentifier.linkedDayScheduleId,
      dayOffset: scheduleIdentifier.dayOffset,
      techAppCrewId: scheduleIdentifier.techAppCrewId,
      scheduleJobId,
      setInvoiceItems,
      setValue,
      setWasOriginallyZeroDollarInvoice,
      setWasOriginallyInvoiceWithDepositAmount,
      setCustomerTaxExempt,
      setTaxRateAlreadySet,
      setOriginalPhoneNumber,
      setOriginalPhoneNumberOptedIntoSms,
    });

  return {
    loading,
    invoiceItems,
    setInvoiceItems,
    isInvoicePaid,
    isDraft,
    errorLoading,
    retryLoad,
    doesInvoiceHaveSignature,
    wasOriginallyZeroDollarInvoice,
    customerTaxExempt,
    taxRateAlreadySet,
    originalPhoneNumber,
    originalPhoneNumberOptedIntoSms,
    wasOriginallyInvoiceWithDepositAmount,
  };
}

function loadData({
  setLoading,
  setErrorLoading,
  setIsInvoicePaid,
  setDoesInvoiceHaveSignature,
  setIsDraft,
  subscriptions,
  linkedDayScheduleId,
  dayOffset,
  techAppCrewId,
  scheduleJobId,
  setInvoiceItems,
  setValue,
  setWasOriginallyZeroDollarInvoice,
  setWasOriginallyInvoiceWithDepositAmount,
  setCustomerTaxExempt,
  setTaxRateAlreadySet,
  setOriginalPhoneNumber,
  setOriginalPhoneNumberOptedIntoSms,
}: {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorLoading: React.Dispatch<React.SetStateAction<ErrorResponse | null>>;
  setIsInvoicePaid: React.Dispatch<React.SetStateAction<boolean>>;
  setDoesInvoiceHaveSignature: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDraft: React.Dispatch<React.SetStateAction<boolean>>;
  setCustomerTaxExempt: React.Dispatch<React.SetStateAction<boolean>>;
  subscriptions: React.MutableRefObject<Array<Subscription>>;
  linkedDayScheduleId: string;
  dayOffset: number;
  techAppCrewId: string | null;
  scheduleJobId: string;
  setInvoiceItems: React.Dispatch<React.SetStateAction<Array<IInvoiceItem>>>;
  setValue: UseFormSetValue<IInvoiceFormData>;
  setWasOriginallyZeroDollarInvoice: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setWasOriginallyInvoiceWithDepositAmount: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setTaxRateAlreadySet: React.Dispatch<React.SetStateAction<boolean>>;
  setOriginalPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  setOriginalPhoneNumberOptedIntoSms: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}) {
  setLoading(true);
  setErrorLoading(null);
  setIsInvoicePaid(false);
  setDoesInvoiceHaveSignature(false);

  const scheduleIdentifier = {
    linkedDayScheduleId: linkedDayScheduleId,
    dayOffset: dayOffset,
    techAppCrewId,
  };
  subscriptions.current.push(
    forkJoin({
      invoiceForForm: getInvoiceForForm({
        scheduleIdentifier,
        jobInstanceId: scheduleJobId,
      }),
      invoiceItems: getInvoiceItemsForForm({
        scheduleIdentifier,
        jobInstanceId: scheduleJobId,
      }),
    })
      .pipe(
        timeout(25000),
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: ({ invoiceForForm, invoiceItems }) => {
          setInvoiceItems(invoiceItems);
          setIsInvoicePaid(invoiceForForm.paid);
          setDoesInvoiceHaveSignature(
            isStringSet(invoiceForForm.signatureImagePath)
          );
          setIsDraft(invoiceForForm.isDraft);
          setCustomerTaxExempt(invoiceForForm.customerTaxExempt);
          setTaxRateAlreadySet(
            typeof invoiceForForm.taxRate === "number" &&
              invoiceForForm.taxRate > 0
          );

          setValue("summary", invoiceForForm.summary);
          setValue("amountAdjustments.discount", invoiceForForm.discount);
          setValue("amountAdjustments.taxRate", invoiceForForm.taxRate);
          setValue(
            "amountAdjustments.depositCreditAmount",
            invoiceForForm.depositCreditAmount
          );
          setValue(
            "amountAdjustments.maxDepositCreditAmount",
            invoiceForForm.maxDepositCreditAmount
          );
          setWasOriginallyInvoiceWithDepositAmount(
            typeof invoiceForForm.depositCreditAmount === "number" &&
              invoiceForForm.depositCreditAmount > 0
          );
          setValue(
            "emails",
            getEmailAddressesForFormData(invoiceForForm.customerEmailAddresses)
          );

          setValue("phoneNumberWithOptInStatus", {
            phoneNumber: invoiceForForm.recipientPhoneNumber,
            optedIn: invoiceForForm.recipientPhoneNumberOptedIntoSms,
          });
          setOriginalPhoneNumber(invoiceForForm.recipientPhoneNumber);

          setOriginalPhoneNumberOptedIntoSms(
            invoiceForForm.recipientPhoneNumberOptedIntoSms
          );

          const mappedLineItems = getLineItemsForFormData(
            invoiceForForm.lineItems
          );
          setValue("lineItems", mappedLineItems);

          setValue("files", getFilesForFormData(invoiceForForm.files));

          if (!invoiceForForm.isDraft) {
            const total = getTotal(
              {
                taxRate: invoiceForForm.taxRate,
                lineItems: mappedLineItems,
                discount: invoiceForForm.discount,
                depositCreditAmount: null,
              } ?? 0
            );

            if (total === 0) {
              setWasOriginallyZeroDollarInvoice(true);
            }
          }
        },

        error: (err) => {
          setErrorLoading({
            message: getErrorMessageFromError(err),
            was400Error: isBadRequestResponse(err),
          });
        },
      })
  );
}
