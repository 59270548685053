import { useCallback } from "react";
import { IScheduleIdentifier } from "../../../models/IScheduleIdentifier";
import { IInvoiceForPaymentForm } from "../models/IInvoiceForPaymentForm";
import { IZonedDateTime } from "../models/IZonedDateTime";
import { saveAchPayment } from "../services/invoicePaymentFormDataProvider";
import BankAccount, { SaveCallbackArgs } from "./BankAccount";
import { InvoiceType } from "../../../enums/InvoiceType";

interface IProps {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
  invoice: IInvoiceForPaymentForm;
  onSave: (saveResult: {
    transactionDateTime: IZonedDateTime | null;
    convenienceFeePaid: number | null;
    paymentMethodAuthorized: boolean;
  }) => void;
  paymentAmount: number;
  invoiceType: InvoiceType;
}

function InvoicePayFormBankAccount(props: IProps) {
  const {
    onSave,
    paymentAmount,
    invoice,
    scheduleIdentifier,
    jobInstanceId,
    invoiceType,
  } = props;

  const saveAchFn = useCallback(
    ({
      firstName,
      lastName,
      routingNumber,
      accountNumber,
      accountType,
      referenceInformation,
    }: SaveCallbackArgs) => {
      return saveAchPayment({
        achProperties: {
          firstName,
          lastName,
          routingNumber,
          accountNumber,
          accountType: accountType as number,
        },
        version: invoice.version,
        scheduleIdentifier: {
          linkedDayScheduleId: scheduleIdentifier.linkedDayScheduleId,
          dayOffset: scheduleIdentifier.dayOffset,
          techAppCrewId: scheduleIdentifier.techAppCrewId,
        },
        jobInstanceId,
        paymentReferenceInformation: referenceInformation,
        invoiceType,
      });
    },
    [scheduleIdentifier, jobInstanceId, invoice.version, invoiceType]
  );

  return (
    <BankAccount
      showAuthorizePaymentMethodOnFile={
        invoice.showAuthorizePaymentMethodOnFile ?? false
      }
      onSave={onSave}
      paymentAmount={paymentAmount}
      saveCall={saveAchFn}
    />
  );
}

export default InvoicePayFormBankAccount;

export function parseName(input: string) {
  const nameParts = input.trim().split(/\s/);

  let firstName = "";
  let lastName = "";
  if (nameParts.length === 1) {
    firstName = nameParts[0];
  } else if (nameParts.length > 1) {
    const [, ...lastNameParts] = nameParts;
    firstName = nameParts[0];
    lastName = lastNameParts.join(" ");
  }

  return {
    firstName,
    lastName,
  };
}
