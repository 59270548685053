import { ajax } from "rxjs/ajax";
import { map } from "rxjs/operators";
import { IScheduleIdentifier } from "../../../models/IScheduleIdentifier";
import { buildFullUrl, getHeaders } from "../../../services/DataProvider";
import { PaymentType } from "../enums/paymentType";
import { IZonedDateTime } from "../models/IZonedDateTime";
import { InvoiceType } from "../../../enums/InvoiceType";

export function saveCreditCardPayment({
  scheduleIdentifier,
  jobInstanceId,
  paymentReferenceInformation,
  payrixToken,
  partialNumber,
  version,
  invoiceType,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
  paymentReferenceInformation: string;
  payrixToken: string;
  partialNumber: string;
  version: number;
  invoiceType: InvoiceType;
}) {
  return ajax
    .post(
      buildFullUrl(
        `Invoice/${scheduleIdentifier.linkedDayScheduleId}/${jobInstanceId}/creditCardPayment?dayOffset=${scheduleIdentifier.dayOffset}`
      ),
      {
        invoiceType,
        payrixToken,
        version,
        partialNumber,
        paymentReferenceInformation,
      },
      getHeaders()
    )
    .pipe(
      map((response) => {
        return response.response as {
          transactionDateTime: IZonedDateTime | null;
          convenienceFeePaid: number | null;
          paymentMethodAuthorized: boolean;
        };
      })
    );
}

export function saveAchPayment({
  scheduleIdentifier,
  jobInstanceId,
  paymentReferenceInformation,
  achProperties,
  version,
  invoiceType,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
  paymentReferenceInformation: string;
  achProperties: {
    firstName: string;
    lastName: string;
    accountType: number;
    routingNumber: string;
    accountNumber: string;
  };
  version: number;
  invoiceType: InvoiceType;
}) {
  return ajax
    .post(
      buildFullUrl(
        `Invoice/${scheduleIdentifier.linkedDayScheduleId}/${jobInstanceId}/achPayment?dayOffset=${scheduleIdentifier.dayOffset}`
      ),
      {
        ...achProperties,
        invoiceType,
        version,
        paymentReferenceInformation,
      },
      getHeaders()
    )
    .pipe(
      map((response) => {
        return response.response as {
          transactionDateTime: IZonedDateTime | null;
          convenienceFeePaid: number | null;
          paymentMethodAuthorized: boolean;
        };
      })
    );
}

export function saveOfflinePayment({
  scheduleIdentifier,
  jobInstanceId,
  paymentReferenceInformation,
  paymentMethodType,
  invoiceType,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
  paymentReferenceInformation: string;
  paymentMethodType: PaymentType;
  invoiceType: InvoiceType;
}) {
  return ajax.post(
    buildFullUrl(
      `Invoice/${scheduleIdentifier.linkedDayScheduleId}/${jobInstanceId}/offlinepayment?dayOffset=${scheduleIdentifier.dayOffset}`
    ),
    {
      invoiceType,
      paymentReferenceInformation,
      paymentMethodType,
    },
    getHeaders()
  );
}
