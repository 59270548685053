import { IBillableItemTechnicianDraftEmailAddress } from "../types/IBillableItemTechnicianDraftEmailAddress";
import uuidv4 from "uuid/v4";

export function getEmailAddressesForFormData(
  customerEmailAddresses: Array<IBillableItemTechnicianDraftEmailAddress>
) {
  const emailsToSet = customerEmailAddresses.map((e) => ({
    id: e.id,
    value: e.emailAddress,
  }));

  if (emailsToSet.length === 0) {
    emailsToSet.push({
      id: uuidv4(),
      value: "",
    });
  }

  return emailsToSet;
}
