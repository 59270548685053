import { useState, useEffect, useRef } from "react";
import { UseFormSetValue } from "react-hook-form";
import { forkJoin } from "rxjs";
import { finalize, timeout } from "rxjs/operators";
import { IScheduleIdentifier } from "../../../../models/IScheduleIdentifier";
import { Subscription } from "rxjs";
import { IInvoiceItem } from "../../../../libraries/billableForm/types/IInvoiceItem";
import { getInvoiceItemsForForm } from "../../../billing/services/invoiceDataProvider";
import { IEstimateFormData } from "./IEstimateFormData";
import {
  getEstimateForForm,
  getEstimateTemplates,
} from "../../services/estimateDataProvider";
import { getEmailAddressesForFormData } from "../../../../libraries/billableForm/functions/getEmailAddressesForFormData";
import { getLineItemsForFormData } from "../../../../libraries/billableForm/functions/getLineItemsForFormData";
import { getFilesForFormData } from "../../../../libraries/billableForm/functions/getFilesForFormData";
import { IEstimateTemplate } from "../../models/IEstimateTemplate";
import { getNumericString } from "../../../../libraries/typeUtilities/getNumericString";
import { DepositType } from "../../enums/DepositType";
import { isStringSet } from "../../../../libraries/typeUtilities/isStringSet";
import { getErrorMessageFromError } from "../../../../libraries/errorParsing/getErrorMessageFromError";
import { ErrorResponse } from "../../../../libraries/billableForm/types/ErrorResponse";
import { isBadRequestResponse } from "../../../../libraries/errorParsing/isBadRequestResponse";

export function useEstimateFormLoad({
  scheduleIdentifier,
  scheduleJobId,
  setValue,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  scheduleJobId: string;
  setValue: UseFormSetValue<IEstimateFormData>;
}) {
  const [loading, setLoading] = useState(false);

  const [errorLoading, setErrorLoading] = useState<ErrorResponse | null>(null);

  const [invoiceItems, setInvoiceItems] = useState<IInvoiceItem[]>([]);
  const [isEstimateAccepted, setIsEstimateAccepted] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [customerTaxExempt, setCustomerTaxExempt] = useState(false);
  const [taxRateAlreadySet, setTaxRateAlreadySet] = useState(false);
  const [templates, setTemplates] = useState<Array<IEstimateTemplate>>([]);
  const [originalPhoneNumber, setOriginalPhoneNumber] = useState("");
  const [originalPhoneNumberOptedIntoSms, setOriginalPhoneNumberOptedIntoSms] =
    useState(false);
  const [defaultDepositItemId, setDefaultDepositItemId] = useState<
    string | null
  >(null);

  const subscriptions = useRef<Array<Subscription>>([]);

  useEffect(() => {
    loadData({
      setLoading,
      setErrorLoading,
      setIsEstimateAccepted,
      setIsDraft,
      subscriptions,
      linkedDayScheduleId: scheduleIdentifier.linkedDayScheduleId,
      dayOffset: scheduleIdentifier.dayOffset,
      techAppCrewId: scheduleIdentifier.techAppCrewId,
      scheduleJobId,
      setInvoiceItems,
      setValue,
      setTemplates,
      setCustomerTaxExempt,
      setTaxRateAlreadySet,
      setOriginalPhoneNumber,
      setOriginalPhoneNumberOptedIntoSms,
      setDefaultDepositItemId,
    });

    return function cleanup() {
      if (subscriptions.current) {
        // This should be safe since the warning is regarding element refs
        // rather than refs we manage
        // eslint-disable-next-line react-hooks/exhaustive-deps
        subscriptions.current.forEach((s) => s.unsubscribe());
      }
    };
  }, [
    scheduleIdentifier.linkedDayScheduleId,
    scheduleIdentifier.dayOffset,
    scheduleIdentifier.techAppCrewId,
    scheduleJobId,
    setValue,
  ]);

  const retryLoad = () =>
    loadData({
      setLoading,
      setErrorLoading,
      setIsEstimateAccepted,
      setIsDraft,
      subscriptions,
      linkedDayScheduleId: scheduleIdentifier.linkedDayScheduleId,
      dayOffset: scheduleIdentifier.dayOffset,
      techAppCrewId: scheduleIdentifier.techAppCrewId,
      scheduleJobId,
      setInvoiceItems,
      setValue,
      setTemplates,
      setCustomerTaxExempt,
      setTaxRateAlreadySet,
      setOriginalPhoneNumber,
      setOriginalPhoneNumberOptedIntoSms,
      setDefaultDepositItemId,
    });

  return {
    loading,
    invoiceItems,
    setInvoiceItems,
    isDraft,
    errorLoading,
    retryLoad,
    isEstimateAccepted,
    templates,
    customerTaxExempt,
    taxRateAlreadySet,
    originalPhoneNumber,
    originalPhoneNumberOptedIntoSms,
    defaultDepositItemId,
  };
}

function loadData({
  setLoading,
  setErrorLoading,
  setIsEstimateAccepted,
  setIsDraft,
  subscriptions,
  linkedDayScheduleId,
  dayOffset,
  techAppCrewId,
  scheduleJobId,
  setInvoiceItems,
  setValue,
  setTemplates,
  setCustomerTaxExempt,
  setTaxRateAlreadySet,
  setOriginalPhoneNumber,
  setOriginalPhoneNumberOptedIntoSms,
  setDefaultDepositItemId,
}: {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorLoading: React.Dispatch<React.SetStateAction<ErrorResponse | null>>;
  setIsEstimateAccepted: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDraft: React.Dispatch<React.SetStateAction<boolean>>;
  subscriptions: React.MutableRefObject<Array<Subscription>>;
  linkedDayScheduleId: string;
  dayOffset: number;
  techAppCrewId: string | null;
  scheduleJobId: string;
  setInvoiceItems: React.Dispatch<React.SetStateAction<Array<IInvoiceItem>>>;
  setValue: UseFormSetValue<IEstimateFormData>;
  setTemplates: React.Dispatch<React.SetStateAction<Array<IEstimateTemplate>>>;
  setCustomerTaxExempt: React.Dispatch<React.SetStateAction<boolean>>;
  setTaxRateAlreadySet: React.Dispatch<React.SetStateAction<boolean>>;
  setOriginalPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  setOriginalPhoneNumberOptedIntoSms: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setDefaultDepositItemId: React.Dispatch<React.SetStateAction<string | null>>;
}) {
  setLoading(true);
  setErrorLoading(null);
  setIsEstimateAccepted(false);

  const scheduleIdentifier = {
    linkedDayScheduleId: linkedDayScheduleId,
    dayOffset: dayOffset,
    techAppCrewId,
  };
  subscriptions.current.push(
    forkJoin({
      estimateForForm: getEstimateForForm({
        scheduleIdentifier,
        jobInstanceId: scheduleJobId,
      }),
      invoiceItems: getInvoiceItemsForForm({
        scheduleIdentifier,
        jobInstanceId: scheduleJobId,
      }),
      estimateTemplates: getEstimateTemplates({
        scheduleIdentifier,
      }),
    })
      .pipe(
        timeout(25000),
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: ({ estimateForForm, invoiceItems, estimateTemplates }) => {
          setInvoiceItems(invoiceItems);
          setTemplates(estimateTemplates);
          setIsEstimateAccepted(estimateForForm.isAccepted);
          setIsDraft(estimateForForm.isDraft);
          setCustomerTaxExempt(estimateForForm.customerTaxExempt);
          setTaxRateAlreadySet(
            typeof estimateForForm.taxRate === "number" &&
              estimateForForm.taxRate > 0
          );
          setDefaultDepositItemId(estimateForForm.defaultDepositItemId);

          let defaultDepositItemId = "";
          let defaultDepositItemName = "";
          let defaultDepositItemDescription = "";
          if (estimateForForm.defaultDepositItemId) {
            const matchingItem = invoiceItems.find(
              (i) => i.id === estimateForForm.defaultDepositItemId
            );
            if (matchingItem) {
              defaultDepositItemId = estimateForForm.defaultDepositItemId;
              defaultDepositItemName = matchingItem.name;
              defaultDepositItemDescription = matchingItem.description;
            }
          }

          setValue("summary", estimateForForm.summary);
          setValue("subject", estimateForForm.subject);
          setValue("daysValid", getNumericString(estimateForForm.daysValid));
          setValue("proposalDate", estimateForForm.proposalDate ?? "");
          setValue("validUntilDate", estimateForForm.validUntilDate ?? "");
          setValue("proposalTemplateId", estimateForForm.proposalTemplateId);
          setValue("amountAdjustments.discount", estimateForForm.discount);
          setValue("amountAdjustments.taxRate", estimateForForm.taxRate);
          setValue(
            "emails",
            getEmailAddressesForFormData(estimateForForm.customerEmailAddresses)
          );

          setValue("phoneNumberWithOptInStatus", {
            phoneNumber: estimateForForm.recipientPhoneNumber,
            optedIn: estimateForForm.recipientPhoneNumberOptedIntoSms,
          });
          setOriginalPhoneNumber(estimateForForm.recipientPhoneNumber);

          setOriginalPhoneNumberOptedIntoSms(
            estimateForForm.recipientPhoneNumberOptedIntoSms
          );

          setValue(
            "lineItems",
            getLineItemsForFormData(estimateForForm.lineItems)
          );

          setValue("files", getFilesForFormData(estimateForForm.files));

          setValue(
            "depositSettings",
            estimateForForm.depositSettings !== null
              ? {
                  ...estimateForForm.depositSettings,
                  depositItemId: isStringSet(estimateForForm.depositItemId)
                    ? estimateForForm.depositItemId
                    : defaultDepositItemId,
                  // Check that ID is set for name & description since the name/description are tied to the ID and need to stay in sync.
                  depositItemName: isStringSet(estimateForForm.depositItemId)
                    ? estimateForForm.depositItemName
                    : defaultDepositItemName,
                  depositItemDescription: isStringSet(
                    estimateForForm.depositItemId
                  )
                    ? estimateForForm.depositItemDescription
                    : defaultDepositItemDescription,
                }
              : {
                  type: DepositType.percent,
                  amount: null,
                  percent: null,
                  depositItemId: defaultDepositItemId,
                  depositItemName: defaultDepositItemName,
                  depositItemDescription: defaultDepositItemDescription,
                }
          );
        },

        error: (err: unknown) => {
          setErrorLoading({
            message: getErrorMessageFromError(err),
            was400Error: isBadRequestResponse(err),
          });
        },
      })
  );
}
