export function formatLatLng(val: number, significantDigits: number | null) {
  if (significantDigits === null) {
    return val;
  } else {
    const parts = val.toString().split(".");
    if (parts.length !== 2) {
      return val.toFixed(significantDigits);
    }

    if (parts[1].length < significantDigits) {
      return val.toFixed(significantDigits);
    }

    return val;
  }
}
