import * as React from "react";
import IScheduledJob from "../models/IScheduleJob";
import Alert, { Type as AlertType } from "./Alert";
import EditableTextField from "./EditableTextField";
import Spinner from "./Spinner";
import { ICustomCrewQuestion } from "../models/ICustomCrewQuestion";
import Files from "./JobDetails/Files/Index";
import { IFile } from "../models/IFile";
import "../custom.css";
import ISchedule from "../models/ISchedule";
import Container from "./Container";
import "./JobDetails.css";
import { TodoItems } from "./JobDetails/TodoItems";
import { JobHeader } from "./JobDetails/JobHeader";
import { AdminFiles } from "./JobDetails/AdminFiles";
import { CrewMemberCount } from "./JobDetails/CrewMemberCount";
import { Footer } from "./JobDetails/Footer";
import { IPendingFile } from "./JobDetails/Files/Index";
import { map, mergeMap, timeout } from "rxjs/operators";
import { ResetTimesConfirmation } from "./ResetTimesConfirmation";
import { ClockStopModal } from "./ClockStopModal";
import { CompleteJobErrorModal } from "./JobDetails/CompleteJobErrorModal";
import { IUncompletedItem } from "./JobDetails/CompleteJobErrorRequiredItems";
import {
  getErrorMessage,
  getReturnedErrorMessage,
} from "../services/errorHandlerService";
import { strings } from "../languages";
import { formatTimeForDisplay } from "../services/dateTimeService";
import { getEstimatedTimeForJob } from "../services/estimationService";
import { AutoSaveField } from "../models/IAutoSave";
import dataProvider from "../services/DataProvider";
import { getSortedItemsV2 } from "../services/sortingService";
import { Observable } from "rxjs";
import { formatCurrency } from "../services/currencyFormatter";
import JobDetailsLineItems from "./JobDetailsLineItems";
import {
  resizeAndUploadImage,
  uploadFile,
} from "../libraries/formAttachments/services/fileUpload";
import { routingBuilders } from "../services/routing";
import { IScheduleIdentifier } from "../models/IScheduleIdentifier";
import { JobCategory } from "../slices/scheduling/components/JobCategory";
import { NoteWithHyperlinks } from "./NoteWithHyperlinks";
import { isStringSet } from "../libraries/typeUtilities/isStringSet";
import uuidv4 from "uuid/v4";
import {
  trackDependencyData,
  trackTrace,
} from "../services/applicationInsights";
import { JobWorksheetsList } from "./JobWorksheetsList";

interface IProps {
  schedule: ISchedule;
  scheduleIdentifier: IScheduleIdentifier;
  filePrefix: string;
  tenantId: string;
  index: number;
  customCrewQuestions: Array<ICustomCrewQuestion>;
  scheduleJob: IScheduledJob;
  hideCustomerNameOnCrewView: boolean;
  hideCustomerPhoneNumberOnCrewView: boolean;
  showCustomerEmailAddressOnCrewView: boolean;
  onScheduleJobUpdated(
    scheduleJobId: string,
    fieldsToUpdate: Partial<IScheduledJob>
  ): void;
  showNotifiedOnTheWayAlert: boolean;
  setShowNotifiedOnTheWayAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

export enum Forms {
  Dates,
}

interface IState {
  alertMessage: JSX.Element | null;
  alertType: AlertType;
  formVisible: Forms | null;
  saving: boolean;
  pendingFiles: Array<IPendingFile>;
  showClearCompletedConfirmation: boolean;
  showStopClockModal: boolean;
  showCompleteJobErrorModal: boolean;
  uncompletedItems: Array<IUncompletedItem>;
  newScheduleForJobDayOffset: number | null;
}

class JobDetails extends React.Component<IProps, IState> {
  private timeContainerRef: React.RefObject<HTMLDivElement>;

  constructor(props: IProps) {
    super(props);

    this.state = {
      alertMessage: null,
      alertType: null,
      formVisible: null,
      saving: false,
      pendingFiles: [],
      showClearCompletedConfirmation: false,
      showStopClockModal: false,
      showCompleteJobErrorModal: false,
      uncompletedItems: [],
      newScheduleForJobDayOffset: null,
    };

    this.timeContainerRef = React.createRef<HTMLDivElement>();

    this.startClock = this.startClock.bind(this);
    this.endClock = this.endClock.bind(this);
    this.onCompletedButtonClick = this.onCompletedButtonClick.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.getUpdatedCustomCrewResponses =
      this.getUpdatedCustomCrewResponses.bind(this);

    this.onFileAdded = this.onFileAdded.bind(this);
    this.onFileRemoved = this.onFileRemoved.bind(this);
    this.onFileUpdated = this.onFileUpdated.bind(this);
  }

  public render() {
    const {
      index,
      scheduleJob,
      hideCustomerNameOnCrewView,
      hideCustomerPhoneNumberOnCrewView,
      showCustomerEmailAddressOnCrewView,
      schedule,
    } = this.props;
    const { alertType, alertMessage, saving } = this.state;

    let estimatedTimeText = getEstimatedTimeForJob(scheduleJob);

    let customCrewQuestions = this.props.customCrewQuestions || [];
    if (scheduleJob && scheduleJob.customCrewQuestions) {
      customCrewQuestions = [
        ...customCrewQuestions,
        ...scheduleJob.customCrewQuestions.filter(
          (jq) => !customCrewQuestions.some((q) => q.id === jq.id)
        ),
      ];
    }

    return (
      <React.Fragment>
        <div style={{ marginBottom: "100px" }}>
          <Container
            schedule={schedule}
            isJobListing={false}
            scheduleIdentifier={this.props.scheduleIdentifier}
          >
            {saving ? <Spinner /> : null}

            {alertMessage !== null ? (
              <div>
                <Alert
                  type={alertType}
                  message={alertMessage}
                  onClose={this.closeAlert}
                />
              </div>
            ) : null}

            <div>
              <div>
                <JobHeader
                  schedule={schedule}
                  scheduleIdentifier={this.props.scheduleIdentifier}
                  scheduledJob={scheduleJob}
                  index={index}
                  hideCustomerNameOnCrewView={hideCustomerNameOnCrewView}
                  hideCustomerPhoneNumberOnCrewView={
                    hideCustomerPhoneNumberOnCrewView
                  }
                  showCustomerEmailAddressOnCrewView={
                    showCustomerEmailAddressOnCrewView
                  }
                  showCustomerJobHistory={scheduleJob.showJobHistory}
                  showScheduledTimes={this.props.schedule.showScheduledTimes}
                  showNotifiedOnTheWayAlert={
                    this.props.showNotifiedOnTheWayAlert
                  }
                  setShowNotifiedOnTheWayAlert={
                    this.props.setShowNotifiedOnTheWayAlert
                  }
                />

                {!schedule.hideEstimatedTimeOnCrewView &&
                scheduleJob.estimatedManHours ? (
                  <h6 style={{ marginTop: "25px" }}>
                    <label style={{ width: "175px", marginBottom: "0px" }}>
                      {strings.estimatedTime}:
                    </label>
                    {estimatedTimeText}
                  </h6>
                ) : null}

                <div style={{ marginBottom: "20px" }}>
                  <CrewMemberCount
                    actualCrewMembers={scheduleJob.actualCrewMembers}
                    onChange={(newActualCrewMembers) => {
                      const updatedFields: Partial<IScheduledJob> = {
                        actualCrewMembers: newActualCrewMembers,
                      };
                      this.props.onScheduleJobUpdated(
                        scheduleJob.id,
                        updatedFields
                      );
                      this.updateScheduleJob(updatedFields, {});
                    }}
                  />
                </div>

                {isStringSet(scheduleJob.purchaseOrderNumber) ? (
                  <h6 data-testid="purchaseOrderNumber">
                    PO #: {scheduleJob.purchaseOrderNumber}
                  </h6>
                ) : null}

                {scheduleJob.grossRevenuePerVisit ? (
                  <h6 data-testid="grossRevenuePerVisit">
                    {strings.total}:{" "}
                    {formatCurrency(scheduleJob.grossRevenuePerVisit)}
                  </h6>
                ) : null}

                {scheduleJob.categoriesV2.length > 0 ? (
                  <div data-testid="categoriesContainer" className="my-3">
                    <h5>
                      {scheduleJob.categoriesV2.map((category) => (
                        <JobCategory
                          key={category.name}
                          name={category.name}
                          color={category.color}
                        />
                      ))}
                    </h5>
                  </div>
                ) : null}

                {!!scheduleJob.customerNotes || !!scheduleJob.notes ? (
                  <React.Fragment>
                    <NoteWithHyperlinks
                      containerClassName="alert alert-dark p-2 overflow-auto"
                      containerStyle={{
                        color: "#495057",
                        backgroundColor: "#e9ecef",
                        maxHeight: "15rem",
                      }}
                      notes={[scheduleJob.customerNotes, scheduleJob.notes]
                        .filter((n) => !!n)
                        .join("\n\n")}
                    />
                  </React.Fragment>
                ) : null}

                <JobWorksheetsList
                  scheduleIdentifier={this.props.scheduleIdentifier}
                  scheduleJobId={scheduleJob.id}
                  worksheetsForJob={scheduleJob.worksheets}
                />

                <TodoItems
                  todoItems={scheduleJob.todoItems}
                  onChecked={(todoItemId, checked) => {
                    const newTodoItems = scheduleJob.todoItems.map((i) => {
                      if (i.id === todoItemId) {
                        return {
                          ...i,
                          completed: checked,
                        };
                      } else {
                        return i;
                      }
                    });
                    const updatedFields: Partial<IScheduledJob> = {
                      todoItems: newTodoItems,
                    };
                    this.props.onScheduleJobUpdated(
                      scheduleJob.id,
                      updatedFields
                    );
                    this.updateScheduleJob(updatedFields, {});
                  }}
                />

                <div style={{ marginTop: "25px" }}>
                  <EditableTextField
                    label={strings.notes}
                    initialValue={scheduleJob.notesFromCrew}
                    jobInstanceId={scheduleJob.id}
                    autoSaveField={AutoSaveField.NoteFromCrew}
                    onSaveComplete={(value) => {
                      this.props.onScheduleJobUpdated(
                        this.props.scheduleJob.id,
                        {
                          notesFromCrew: value,
                        }
                      );
                    }}
                  />
                </div>

                {getSortedItemsV2(
                  customCrewQuestions.map((q) => {
                    const response =
                      scheduleJob.customCrewQuestionResponses.find(
                        (r) => r.customCrewQuestionId === q.id
                      );
                    const value = response ? response.answer : "";
                    const questionText =
                      response && response.questionText
                        ? response.questionText
                        : q.question;

                    return {
                      ...q,
                      value,
                      questionText: (questionText || "").trim(),
                    };
                  }),
                  ["questionText"]
                ).map((q) => {
                  return (
                    <div
                      style={{ marginTop: "25px" }}
                      key={q.id}
                      data-testid="customQuestion"
                    >
                      <EditableTextField
                        label={q.questionText}
                        initialValue={q.value}
                        jobInstanceId={scheduleJob.id}
                        autoSaveField={AutoSaveField.CustomCrewQuestion}
                        childId={q.id}
                        onSaveComplete={(newValue) => {
                          this.props.onScheduleJobUpdated(
                            this.props.scheduleJob.id,
                            {
                              customCrewQuestionResponses:
                                this.getUpdatedCustomCrewResponses(
                                  q.id,
                                  q.questionText,
                                  newValue
                                ),
                            }
                          );
                        }}
                      />
                    </div>
                  );
                })}

                <AdminFiles
                  files={scheduleJob.photosFromAdmin}
                  imagePrefix={this.props.filePrefix}
                  scheduleJob={scheduleJob}
                />

                {scheduleJob.photos.length > 0 ||
                this.state.pendingFiles.length > 0 ? (
                  <div style={{ marginTop: "40px" }}>
                    <Files
                      schedule={schedule}
                      filePrefix={this.props.filePrefix}
                      tenantId={this.props.tenantId}
                      scheduledJob={scheduleJob}
                      onFileAdded={this.onFileAdded}
                      onFileUpdated={this.onFileUpdated}
                      onFileremoved={this.onFileRemoved}
                      pendingFiles={this.state.pendingFiles}
                      files={scheduleJob.photos}
                      onRetryFileUpload={(p) => {
                        this.setState({
                          pendingFiles: this.state.pendingFiles.map((f) => {
                            if (f.tempId === p.tempId) {
                              return {
                                ...f,
                                errorUploading: false,
                                errorUploadingMessage: undefined,
                              };
                            } else {
                              return f;
                            }
                          }),
                        });

                        this.uploadFiles(schedule.id, [p]);
                      }}
                      onErrorClosed={(pendingFileTempId) => {
                        this.setState({
                          pendingFiles: this.state.pendingFiles.filter(
                            (pendingFileToCheck) =>
                              pendingFileToCheck.tempId !== pendingFileTempId
                          ),
                        });
                      }}
                    />
                  </div>
                ) : null}

                {scheduleJob.jobLineItems &&
                scheduleJob.jobLineItems.length > 0 ? (
                  <JobDetailsLineItems
                    lineItems={scheduleJob.jobLineItems}
                    lineItemType={scheduleJob.lineItemType}
                  />
                ) : null}
              </div>

              {scheduleJob.timeRanges.filter((tr) => tr.startTime || tr.endTime)
                .length > 0 ? (
                <div
                  style={{
                    marginTop: "20px",
                    paddingBottom: "20px",
                  }}
                  ref={this.timeContainerRef}
                >
                  <table className="table table-sm">
                    <tbody>
                      {scheduleJob.timeRanges
                        .filter((tr) => tr.startTime || tr.endTime)
                        .map((tr) => (
                          <React.Fragment key={tr.id}>
                            <tr>
                              <th scope="row" style={{ width: "125px" }}>
                                {strings.startTime}
                              </th>
                              <td>
                                {tr.startTime
                                  ? formatTimeForDisplay(tr.startTime)
                                  : ""}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" style={{ width: "125px" }}>
                                {strings.stopTime}
                              </th>
                              <td>
                                {tr.endTime
                                  ? formatTimeForDisplay(tr.endTime)
                                  : ""}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                    </tbody>
                  </table>
                  <button
                    type="button"
                    className="btn btn-dark btn-block"
                    onClick={() =>
                      this.setState({
                        showClearCompletedConfirmation: true,
                      })
                    }
                  >
                    {strings.resetJobTimes}
                  </button>
                </div>
              ) : null}

              {showSkipJobButton(schedule, scheduleJob) ? (
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-link btn-lg text-danger"
                    onClick={() => {
                      this.updateScheduleJob(
                        {
                          skipped: !scheduleJob.skipped,
                        },
                        {}
                      );
                    }}
                  >
                    {scheduleJob.skipped ? strings.unskipJob : strings.skipJob}
                  </button>
                </div>
              ) : null}
            </div>
          </Container>
        </div>
        <div>
          <Footer
            backLink={
              typeof this.state.newScheduleForJobDayOffset === "number"
                ? routingBuilders.buildSchedulePath({
                    scheduleIdentifier: {
                      ...this.props.scheduleIdentifier,
                      dayOffset: this.state.newScheduleForJobDayOffset,
                    },
                  })
                : routingBuilders.buildSchedulePath({
                    scheduleIdentifier: this.props.scheduleIdentifier,
                  })
            }
            endClock={this.endClock}
            onCompletedButtonClick={this.onCompletedButtonClick}
            onScheduleJobUpdated={this.props.onScheduleJobUpdated}
            scheduleJob={scheduleJob}
            scheduleIdentifier={this.props.scheduleIdentifier}
            startClock={this.startClock}
            schedule={schedule}
            onFileAdded={(files) => {
              this.setState({
                pendingFiles: [...this.state.pendingFiles, ...files],
              });

              this.uploadFiles(schedule.id, files);
            }}
          />
        </div>

        <ResetTimesConfirmation
          show={this.state.showClearCompletedConfirmation}
          onClose={(confirmed) => {
            this.setState({
              showClearCompletedConfirmation: false,
            });

            if (confirmed) {
              this.updateScheduleJob(
                {
                  timeRanges: [],
                  completed: false,
                },
                {}
              );
            }
          }}
        />

        <ClockStopModal
          show={this.state.showStopClockModal}
          timeRanges={scheduleJob.timeRanges}
          scheduleJobId={scheduleJob.id}
          onAttemptedCompleteWithoutRequiredTodoItemsDone={(
            uncompletedTodoItems
          ) => {
            this.setState({
              saving: false,
              showStopClockModal: false,
              showCompleteJobErrorModal: true,
              uncompletedItems: uncompletedTodoItems,
            });
          }}
          onCloseWithSave={(updatedTimeRanges, updatedCompleted) => {
            this.setState({
              showStopClockModal: false,
            });

            this.props.onScheduleJobUpdated(this.props.scheduleJob.id, {
              timeRanges: updatedTimeRanges,
              completed: updatedCompleted,
            });
          }}
          onCloseWithoutSave={() => {
            this.setState({
              showStopClockModal: false,
            });
          }}
        />

        <CompleteJobErrorModal
          show={this.state.showCompleteJobErrorModal}
          uncompletedItems={this.state.uncompletedItems}
          onClose={() =>
            this.setState({
              showCompleteJobErrorModal: false,
              uncompletedItems: [],
            })
          }
        />
      </React.Fragment>
    );
  }

  private uploadFiles(
    dayScheduleId: string,
    pendingFiles: Array<IPendingFile>
  ) {
    if (pendingFiles.length === 0) {
      return;
    }

    const pendingFile = pendingFiles[0];

    let uploadObservable: Observable<{
      imagePath: string;
      actualWidth: number | null;
      actualHeight: number | null;
      thumbnails: Array<{
        imagePath: string;
        thumbnailKey: number;
        actualWidth: number;
        actualHeight: number;
      }>;
      timestamp: string | null;
    }>;

    const startTime = new Date();
    let isImageFile = false;
    let fileSize = pendingFile.file.size;
    let imageUploadVersion = "1";
    if (!pendingFile.file.type.startsWith("video")) {
      isImageFile = true;

      if (this.props.schedule.featureFlags.serverSideImageResizing) {
        imageUploadVersion = "2";
        uploadObservable = dataProvider
          .uploadImage(this.props.scheduleIdentifier, pendingFile.file)
          .pipe(
            timeout(120000),
            map((r) => {
              return {
                ...r,
                timestamp: null,
              };
            })
          );
      } else {
        uploadObservable = resizeAndUploadImage(
          this.props.scheduleIdentifier,
          pendingFile.file
        ).pipe(
          map((result) => {
            const baseImage = result.find((r) => r.thumbnailKey === null);
            if (!baseImage) {
              throw new Error("base image not found");
            }

            const thumbnails = result
              .filter((r) => r !== baseImage && r.thumbnailKey)
              .map((r) => ({
                ...r,
                thumbnailKey: r.thumbnailKey as number,
              }));

            return {
              imagePath: baseImage.imagePath,
              actualWidth: baseImage.actualWidth,
              actualHeight: baseImage.actualHeight,
              timestamp: baseImage.timestamp,
              thumbnails,
            };
          })
        );
      }
    } else {
      uploadObservable = uploadFile(
        this.props.scheduleIdentifier,
        pendingFile.file
      ).pipe(
        map((result) => {
          return {
            imagePath: result.imagePath,
            actualWidth: null,
            actualHeight: null,
            thumbnails: [],
            timestamp: null,
          };
        })
      );
    }

    uploadObservable
      .pipe(
        mergeMap((result) => {
          return dataProvider.addPhoto(
            this.props.scheduleJob.id,
            result.imagePath,
            pendingFile.file.type,
            "",
            result.actualWidth,
            result.actualHeight,
            result.thumbnails,
            result.timestamp
          );
        })
      )
      .subscribe(
        (result) => {
          this.onFileAdded(result);

          this.setState({
            pendingFiles: this.state.pendingFiles.filter(
              (x) => x.tempId !== pendingFile.tempId
            ),
          });

          this.uploadFiles(dayScheduleId, pendingFiles.slice(1));

          trackUploadFileDependency({
            responseCode: 200,
            success: true,
          });
        },
        (error: unknown) => {
          console.log(error);

          let errorMessage = "";
          if (typeof error === "string") {
            errorMessage = error;
          } else if (!!error && typeof error.toString === "function") {
            errorMessage = error.toString();
          }

          trackTrace("Job details - error uploading file.", {
            errorMessage,
          });

          trackUploadFileDependency({
            responseCode: 500,
            success: false,
          });

          this.setState({
            pendingFiles: this.state.pendingFiles.map((x) =>
              x.tempId === pendingFile.tempId
                ? {
                    ...x,
                    errorUploading: true,
                    errorUploadingMessage: getReturnedErrorMessage(error) ? (
                      <span>{getReturnedErrorMessage(error)}</span>
                    ) : undefined,
                  }
                : x
            ),
          });
        }
      );

    function trackUploadFileDependency({
      responseCode,
      success,
    }: {
      responseCode: number;
      success: boolean;
    }) {
      const endTime = new Date();
      trackDependencyData({
        id: uuidv4(),
        name: "jobDetailsUploadFile",
        responseCode,
        success,
        duration: endTime.getTime() - startTime.getTime(),
        properties: {
          fileSize,
          isImageFile,
          uploadVersion: imageUploadVersion,
        },
      });
    }
  }

  private onFileAdded(photo: IFile) {
    var scheduleJob = this.props.scheduleJob;
    this.props.onScheduleJobUpdated(scheduleJob.id, {
      photos: [...scheduleJob.photos, photo],
    });
  }

  private onFileRemoved(photoId: string) {
    var scheduleJob = this.props.scheduleJob;
    this.props.onScheduleJobUpdated(scheduleJob.id, {
      photos: scheduleJob.photos.filter((p) => p.id !== photoId),
    });
  }

  private onFileUpdated(photoId: string, caption: string) {
    var scheduleJob = this.props.scheduleJob;
    this.props.onScheduleJobUpdated(scheduleJob.id, {
      photos: scheduleJob.photos.map((p) => {
        if (p.id === photoId) {
          return {
            ...p,
            caption,
          };
        } else {
          return p;
        }
      }),
    });
  }

  private startClock() {
    this.setState({
      saving: true,
    });

    let startClockScheduleIdentifier = this.props.scheduleIdentifier;

    // To avoid error on resume job, pass up the job's current day offset
    // Otherwise, server will give validation warning that the current
    // day for the schedule doesn't match what was passed up.
    let updateNewScheduleJobDayOffset = true;
    if (typeof this.state.newScheduleForJobDayOffset === "number") {
      updateNewScheduleJobDayOffset = false;
      startClockScheduleIdentifier = {
        ...startClockScheduleIdentifier,
        dayOffset: this.state.newScheduleForJobDayOffset,
      };
    }

    dataProvider
      .startClock(
        this.props.scheduleJob.id,
        new Date(),
        startClockScheduleIdentifier
      )
      .subscribe(
        (result) => {
          this.props.onScheduleJobUpdated(this.props.scheduleJob.id, {
            timeRanges: result.updatedTimeRanges,
          });

          this.setState({
            saving: false,
            newScheduleForJobDayOffset: updateNewScheduleJobDayOffset
              ? result.newScheduleForJobDayOffset
              : this.state.newScheduleForJobDayOffset,
          });
        },
        (error) => {
          this.showErrorSaving(error);
        }
      );
  }

  private endClock() {
    this.setState({
      showStopClockModal: true,
    });
  }

  private onCompletedButtonClick() {
    if (this.timeContainerRef.current) {
      this.timeContainerRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }

  private getUpdatedCustomCrewResponses(
    customCrewQuestionId: string,
    questionText: string,
    newAnswer: string
  ) {
    const responses = this.props.scheduleJob.customCrewQuestionResponses.map(
      (r) => ({ ...r })
    );
    const existingResponse = responses.find(
      (r) => r.customCrewQuestionId === customCrewQuestionId
    );
    if (existingResponse) {
      existingResponse.questionText = questionText;
      existingResponse.answer = newAnswer;
    } else {
      responses.push({
        answer: newAnswer,
        questionText,
        customCrewQuestionId,
      });
    }

    return responses;
  }

  private save(dataToSave: Partial<IScheduledJob>) {
    this.updateScheduleJob(dataToSave, {
      formVisible: null,
    });
  }

  private cancel() {
    this.setState({
      formVisible: null,
    });
  }

  private closeAlert() {
    this.setState({
      alertMessage: null,
      alertType: null,
    });
  }

  private updateScheduleJob(
    fieldsToUpdate: Partial<IScheduledJob>,
    stateToUpdate: Partial<IState>
  ) {
    this.setState({
      saving: true,
    });

    dataProvider
      .updateScheduleJob(this.props.scheduleJob.id, fieldsToUpdate)
      .subscribe(
        (result) => {
          this.props.onScheduleJobUpdated(this.props.scheduleJob.id, {
            ...fieldsToUpdate,
            timeRanges: result.updatedTimeRanges,
          });

          this.setState({
            ...this.state,
            ...stateToUpdate,
            alertMessage: null,
            alertType: null,
            saving: false,
          });
        },
        (error) => {
          this.showErrorSaving(error);
        }
      );
  }

  private showErrorSaving(error: any) {
    const errorMessage = getErrorMessage(error);

    this.setState({
      alertMessage: errorMessage,
      alertType: "alert-danger",
      saving: false,
    });
  }
}

export default JobDetails;

function showSkipJobButton(schedule: ISchedule, scheduleJob: IScheduledJob) {
  return (
    schedule.allowCrewsToSkipJobs &&
    !scheduleJob.timeRanges.some((tr) => tr.startTime || tr.endTime) &&
    !scheduleJob.completed
  );
}
