import Container from "../../../components/Container";
import { GenericFooter } from "../../../components/JobDetails/GenericFooter";
import { useScheduleJobCache } from "../../../hooks/useScheduleJobCache";
import ISchedule from "../../../models/ISchedule";
import { IScheduleIdentifier } from "../../../models/IScheduleIdentifier";
import { routingBuilders } from "../../../services/routing";
import { getPageHeader } from "../../../libraries/commonUi/functions/getPageHeader";
import { JobMovedErrorAlert } from "../../../components/JobMovedErrorAlert";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ErrorResponse } from "../../../libraries/billableForm/types/ErrorResponse";
import {
  completeWorksheet,
  getWorksheet,
} from "../services/worksheetsDataProvider";
import { IWorksheet } from "../models/IWorksheet";
import { getErrorResponse } from "../../../libraries/errorParsing/getErrorResponse";
import Spinner from "../../../components/Spinner";
import { PageErrorLoading } from "../../../libraries/commonUi/components/PageErrorLoading";
import { Subscription } from "rxjs";
import { WorksheetContentItem } from "./WorksheetContentItem";
import { timeout } from "rxjs/operators";
import { strings } from "../../../languages";
import { FooterButton } from "../../../components/FooterButton";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap/lib";

export function WorksheetPage({
  scheduleJobId,
  scheduleIdentifier,
  schedule,
  worksheetForJobId,
  onCompleted,
}: {
  scheduleJobId: string;
  scheduleIdentifier: IScheduleIdentifier;
  schedule: ISchedule;
  worksheetForJobId: string;
  onCompleted: () => void;
}) {
  const { scheduleJob } = useScheduleJobCache(schedule, scheduleJobId ?? "");
  const formRef = useRef<HTMLFormElement>(null);

  const {
    loading,
    errorLoading,
    worksheet,
    updateResponse,
    updateCompleted,
    retryLoad,
  } = useLoadWorksheet({
    scheduleJobId,
    scheduleIdentifier,
    worksheetForJobId,
  });

  const pageTitle = `${strings.update} ${worksheet?.title ?? "Worksheet"}`;

  if (!scheduleJob) {
    return <JobMovedErrorAlert scheduleIdentifier={scheduleIdentifier} />;
  }

  return (
    <Container
      isJobListing={false}
      schedule={schedule}
      headerOverride={getPageHeader({
        schedule,
        scheduleJob,
        pageTitle,
      })}
      scheduleIdentifier={scheduleIdentifier}
    >
      <div style={{ marginBottom: "125px" }}>
        {loading ? <Spinner /> : null}

        {!loading && errorLoading ? (
          <>
            <PageErrorLoading
              retryLoad={retryLoad}
              disableRetry={errorLoading.was400Error}
              errorDetails={errorLoading.message}
            />
          </>
        ) : null}

        {!loading && worksheet !== null ? (
          <>
            <form ref={formRef}>
              {worksheet.contentItems.map((ci) => (
                <React.Fragment key={ci.id}>
                  <WorksheetContentItem
                    schedule={schedule}
                    scheduleIdentifier={scheduleIdentifier}
                    scheduleJobId={scheduleJobId}
                    contentItem={ci}
                    updateResponse={updateResponse}
                  />
                </React.Fragment>
              ))}
            </form>
          </>
        ) : null}
      </div>

      <GenericFooter
        backLink={routingBuilders.buildScheduleJobPath({
          scheduleIdentifier,
          scheduleJobId: scheduleJobId,
        })}
      >
        <CompleteButton
          worksheet={worksheet}
          scheduleIdentifier={scheduleIdentifier}
          scheduleJobId={scheduleJobId}
          onCompleted={() => {
            updateCompleted();
            onCompleted();
          }}
          worksheetForJobId={worksheetForJobId}
          formRef={formRef}
        />
        {/* Add empty element to keep the complete button in the center */}
        <div></div>
      </GenericFooter>
    </Container>
  );
}

function CompleteButton({
  worksheet,
  scheduleIdentifier,
  scheduleJobId,
  worksheetForJobId,
  onCompleted,
  formRef,
}: {
  worksheet: IWorksheet | null;
  scheduleIdentifier: IScheduleIdentifier;
  scheduleJobId: string;
  worksheetForJobId: string;
  onCompleted: () => void;
  formRef: React.RefObject<HTMLFormElement>;
}) {
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ErrorResponse | null>(null);

  return (
    <>
      {saving ? <Spinner /> : null}

      {worksheet !== null ? (
        <FooterButton
          icon={faCheckCircle}
          caption={worksheet.completed ? strings.completed : strings.complete}
          onClick={() => {
            if (!worksheet.completed) {
              if (
                formRef.current !== null &&
                formRef.current.reportValidity()
              ) {
                setSaving(true);
                completeWorksheet({
                  scheduleIdentifier,
                  jobInstanceId: scheduleJobId,
                  worksheetForJobId,
                }).subscribe({
                  next: () => {
                    setSaving(false);
                    onCompleted();
                  },

                  error: (err) => {
                    setSaving(false);
                    setErrorMessage(getErrorResponse(err));
                  },
                });
              }
            }
          }}
          testId="completeWorksheet"
          color={worksheet.completed ? "text-success" : "text-primary"}
        />
      ) : null}

      {errorMessage !== null ? (
        <Modal
          isOpen
          centered={true}
          toggle={() => {
            setErrorMessage(null);
          }}
        >
          <ModalHeader>{strings.worksheetCannotBeCompleted}</ModalHeader>
          <ModalBody>{errorMessage.message}</ModalBody>
          <ModalFooter>
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => {
                setErrorMessage(null);
              }}
            >
              {strings.ok}
            </button>
          </ModalFooter>
        </Modal>
      ) : null}
    </>
  );
}

function useLoadWorksheet({
  scheduleJobId,
  scheduleIdentifier,
  worksheetForJobId,
}: {
  scheduleJobId: string;
  scheduleIdentifier: IScheduleIdentifier;
  worksheetForJobId: string;
}) {
  const [loading, setLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState<ErrorResponse | null>(null);
  const [worksheet, setWorksheet] = useState<IWorksheet | null>(null);

  const subscriptions = useRef<Array<Subscription>>([]);

  const load = useCallback(() => {
    setLoading(true);
    setErrorLoading(null);

    subscriptions.current.push(
      getWorksheet({
        scheduleIdentifier,
        jobInstanceId: scheduleJobId,
        worksheetForJobId,
      })
        .pipe(timeout(10_000))
        .subscribe({
          next: (result) => {
            setLoading(false);
            setWorksheet(result);
          },

          error: (err) => {
            setLoading(false);
            setErrorLoading(getErrorResponse(err));
          },
        })
    );
  }, [scheduleJobId, scheduleIdentifier, worksheetForJobId]);

  const hasLoaded = useRef(false);
  useEffect(() => {
    if (!hasLoaded.current) {
      hasLoaded.current = true;

      load();
    }
  }, [load]);

  useEffect(() => {
    // subscriptionCopy is used to resolve warning about <ref>.current possibly changing in cleanup function
    const subscriptionCopy = subscriptions.current;
    return function cleanup() {
      subscriptionCopy.forEach((s) => s.unsubscribe());
    };
  }, []);

  const updateResponse = ({
    worksheetForJobInstanceContentItemId,
    response,
  }: {
    worksheetForJobInstanceContentItemId: string;
    response: string;
  }) => {
    setWorksheet((w) =>
      w !== null
        ? {
            ...w,
            contentItems: w.contentItems.map((ci) => {
              if (ci.id === worksheetForJobInstanceContentItemId) {
                return {
                  ...ci,
                  response,
                };
              } else {
                return ci;
              }
            }),
          }
        : null
    );
  };

  const updateCompleted = () => {
    setWorksheet((w) =>
      w !== null
        ? {
            ...w,
            completed: true,
          }
        : w
    );
  };

  return {
    loading,
    errorLoading,
    worksheet,
    retryLoad: load,
    updateResponse,
    updateCompleted,
  };
}
