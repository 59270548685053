import { DiscountType } from "../billableForm/enums/DiscountType";
import { IDiscount } from "../billableForm/types/IDiscount";
import { round } from "../math/round";

export function getDiscountToApply(
  subtotal: number,
  discount: IDiscount | null | undefined
) {
  let discountToApply = 0;
  if (!!discount) {
    if (discount.type === DiscountType.percent) {
      if (discount.percent !== null && !isNaN(discount.percent)) {
        discountToApply = subtotal * discount.percent;
      }
    } else if (discount.type === DiscountType.amount) {
      if (discount.amount !== null && !isNaN(discount.amount)) {
        discountToApply = discount.amount;
      }
    }

    discountToApply = round(discountToApply, 2);
  }

  return discountToApply;
}
