import LinkButton from "../../../components/LinkButton";
import { strings } from "../../../languages";
import { IFile } from "../../../models/IFile";
import { TriggerBillableFormSave } from "../../billableForm/types/TriggerBillableFormSave";
import { IFormDataFile } from "./ExistingFile";

interface IProps {
  jobPhotos: IFile[];
  existingFiles: IFormDataFile[];
  triggerSave: TriggerBillableFormSave;
  setFiles: (newValue: Array<IFormDataFile>) => void;
}

export const CopyJobFilesLink: React.FunctionComponent<IProps> = ({
  jobPhotos,
  existingFiles,
  triggerSave,
  setFiles,
}) => {
  return jobPhotos?.filter(
    (p) => !existingFiles.some((v) => v.imagePath === p.imagePath)
  )?.length > 0 ? (
    <LinkButton
      additionalClassNames="btn-sm"
      text={strings.copyJobFiles}
      testId="copyJobFiles"
      onClick={() => {
        setFiles(
          existingFiles.concat(
            jobPhotos
              .filter(
                (p) => !existingFiles.some((v) => v.imagePath === p.imagePath)
              )
              ?.map((p) => {
                return { ...p, id: "" } as IFormDataFile;
              }) ?? []
          )
        );
        triggerSave({});
      }}
    />
  ) : null;
};
