export function JobCategory({ name, color }: { name: string; color: string }) {
  return (
    <span
      className="badge badge-info"
      style={{
        marginRight: "5px",
        fontSize: "75%",
        backgroundColor: color,
        whiteSpace: "normal",
        overflowWrap: "anywhere",
      }}
    >
      {name}
    </span>
  );
}
