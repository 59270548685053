import * as React from "react";
import Spinner from "../../Spinner";
import { IPendingFile } from "./Index";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import uuidv4 from "uuid/v4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { strings } from "../../../languages";
import fileService from "../../../libraries/formAttachments/services/fileService";

interface IProps {
  tenantId: string;
  onPhotoAdded: (photo: Array<IPendingFile>) => void;
}

interface IState {
  saving: boolean;
  hasFileSet: boolean;
  error: JSX.Element | null;
}

class NewFile extends React.Component<IProps, IState> {
  private fileElement: React.RefObject<HTMLInputElement>;

  constructor(props: IProps) {
    super(props);

    this.state = {
      saving: false,
      hasFileSet: false,
      error: null,
    };

    this.fileElement = React.createRef();
  }

  public render() {
    return (
      <React.Fragment>
        {this.state.saving ? <Spinner /> : null}
        <div className="image-upload text-center">
          <label htmlFor="file-upload" className="text-primary mb-0">
            <FontAwesomeIcon icon={faCamera} size="3x" fixedWidth />
            <div>{strings.upload}</div>
          </label>
          <input
            type="file"
            id="file-upload"
            accept="image/png, image/jpeg, video/*"
            ref={this.fileElement}
            multiple={true}
            onChange={() => {
              const fileList = (this.fileElement.current as any)
                .files as FileList;
              if (fileList && fileList.length > 0) {
                let fileValid = true;
                const maxFileSize = fileService.getFileSizeAllowedInMegabytes(
                  this.props.tenantId
                );

                Array.from(fileList).forEach((file) => {
                  if (file.size > 1024 * 1024 * maxFileSize) {
                    fileValid = false;
                    this.setState({
                      error: (
                        <span>
                          The file cannot be larger than {maxFileSize}MB
                        </span>
                      ),
                    });
                    return;
                  }

                  if (
                    file.type.toLowerCase().indexOf("image") === -1 &&
                    file.type.toLowerCase().indexOf("video") === -1
                  ) {
                    fileValid = false;
                    this.setState({
                      error: (
                        <span>Only image or video files can be uploaded.</span>
                      ),
                    });
                    return;
                  }
                });

                if (fileValid) {
                  this.props.onPhotoAdded(
                    Array.from(fileList).map((file) => ({
                      tempId: uuidv4(),
                      file: file,
                      errorUploading: false,
                    }))
                  );

                  if (this.fileElement.current) {
                    this.fileElement.current.value = "";
                  }
                }
              }
            }}
          />
        </div>

        <Modal
          isOpen={this.state.error !== null}
          centered={true}
          toggle={() => {
            this.setState({
              error: null,
            });
          }}
        >
          <ModalBody>{this.state.error}</ModalBody>
          <ModalFooter>
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => {
                this.setState({
                  error: null,
                });
              }}
            >
              OK
            </button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default NewFile;
