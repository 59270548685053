import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function InvoicePaid() {
  return (
    <div className="my-3 bg-white">
      <h1 className="mb-0 text-success text-center">
        <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
        Invoice Paid!
      </h1>
    </div>
  );
}
