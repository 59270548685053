export function getSortedItems<T>(
  items: Array<T>,
  field: keyof T,
  reverse?: boolean
) {
  return getSortedItemsV2(items, [field], reverse);
}

export function getSortedItemsV2<T>(
  items: Array<T>,
  fields: Array<((t: T) => string | number) | keyof T>,
  reverse?: boolean
) {
  const newItems = [...items];
  newItems.sort((a, b) => {
    const sortValue = fields.reduce((acc, field) => {
      let returnValue = acc;
      if (acc === 0) {
        let aSourceValue: string | number;
        let bSourceValue: string | number;
        if (typeof field === "function") {
          aSourceValue = field(a);
          bSourceValue = field(b);
        } else {
          aSourceValue = (a[field] as unknown) as string;
          bSourceValue = (b[field] as unknown) as string;
        }

        let aValueToCompare = normalizeValueToCompare(aSourceValue);
        let bValueToCompare = normalizeValueToCompare(bSourceValue);

        if (aValueToCompare < bValueToCompare) {
          returnValue = -1;
        } else if (aValueToCompare > bValueToCompare) {
          returnValue = 1;
        } else {
          returnValue = 0;
        }

        if (reverse) {
          returnValue *= -1;
        }
      }
      return returnValue;
    }, 0);

    return sortValue;
  });
  return newItems;

  function normalizeValueToCompare(input: string | number) {
    if (typeof input === "number") {
      return input;
    } else {
      return (input ? input.toString().trim() : "").toLocaleLowerCase();
    }
  }
}

export function getSortFunctionForString(a: string, b: string) {
  let returnValue: number;
  if (a < b) {
    returnValue = -1;
  } else if (a > b) {
    returnValue = 1;
  } else {
    returnValue = 0;
  }
  return returnValue;
}
