import React, { useState } from "react";
import { IInvoiceForPaymentForm } from "../models/IInvoiceForPaymentForm";
import { getParsedPaymentType, PaymentType } from "../enums/paymentType";
import { isConvenienceFeeInUse } from "../services/invoiceService";
import InvoicePayMethod from "./InvoicePayMethod";
import { IScheduleIdentifier } from "../../../models/IScheduleIdentifier";
import { useHistory } from "react-router-dom";
import { InvoicePaid } from "./InvoicePaid";
import { DisplayHeader } from "./DisplayHeader";
import { InvoiceType } from "../../../enums/InvoiceType";

interface IProps {
  invoice: IInvoiceForPaymentForm;
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
  onPaid?: () => void;
  successfulPaymentRedirectPath: string;
  invoiceType: InvoiceType;
}

const InvoicePayForm: React.FunctionComponent<IProps> = ({
  invoice,
  scheduleIdentifier,
  jobInstanceId,
  onPaid,
  successfulPaymentRedirectPath,
  invoiceType,
}) => {
  const [paid, setPaid] = useState(invoice.paid);
  const [paymentType, setPaymentType] = useState<string>(
    PaymentType.creditCard.toString()
  );
  const history = useHistory();

  const convenienceFeeInUse = isConvenienceFeeInUse(
    getParsedPaymentType(paymentType),
    invoice
  );

  return invoice ? (
    <>
      <div>
        <div>
          <div>
            <DisplayHeader
              convenienceFeeInUse={convenienceFeeInUse}
              invoice={invoice}
            />

            <div>
              {!paid ? (
                <InvoicePayMethod
                  onSave={() => {
                    setPaid(true);

                    if (typeof onPaid === "function") {
                      onPaid();
                    }

                    history.push(successfulPaymentRedirectPath);
                  }}
                  invoice={invoice}
                  paymentType={paymentType}
                  onPaymentTypeChanged={setPaymentType}
                  scheduleIdentifier={scheduleIdentifier}
                  jobInstanceId={jobInstanceId}
                  invoiceType={invoiceType}
                />
              ) : (
                <InvoicePaid />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default InvoicePayForm;
