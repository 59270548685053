import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";
import { Language } from "../languages";
import { IZonedDateTime } from "../libraries/invoicePaymentForm/models/IZonedDateTime";
import parseISO from "date-fns/parseISO";
import isValid from "date-fns/isValid";
import format from "date-fns/format";
import { logError } from "../libraries/logging/logError";

const machineTimeFormatString = "HH:mm";

export function formatDateTimeForDateTimeDisplay(
  input: Date,
  language: Language
) {
  return `${formatDateForDisplay(input, language)} ${formatTimeForDisplay(
    input
  )}`;
}

export function formatTimeForDisplay(input: string | Date) {
  let parsedTime: Date;
  if (typeof input === "string") {
    parsedTime = parseTime(input);
  } else {
    parsedTime = input;
  }

  return dateFnsFormat(parsedTime, "h:mm a");
}

export function formatDateForDisplay(input: string | Date, language: Language) {
  return dateFnsFormat(
    typeof input === "string"
      ? dateFnsParse(input, "yyyy-MM-dd", new Date(2000, 1, 1))
      : input,
    getDateFormatString(language)
  );
}

function getDateFormatString(language: string): string {
  return language === "es" ? "d/M/yyyy" : "M/d/yyyy";
}

export function parseTime(input: string) {
  let parsedDate = dateFnsParse(input, "HH:mm:ss", new Date(2000, 1, 1));
  if (isNaN(parsedDate as unknown as number)) {
    parsedDate = dateFnsParse(
      input,
      machineTimeFormatString,
      new Date(2000, 1, 1)
    );
  }

  return parsedDate;
}

export function removeTimeComponents(input: Date) {
  const stringWithoutTime = dateFnsFormat(input, "YYYY-MM-DD");
  return dateFnsParse(stringWithoutTime, "yyyy-MM-dd", new Date(2000, 1, 1));
}

export function getZonedDateTimeForDisplay(acceptanceDateTime: IZonedDateTime) {
  const parsedDateTime = parseISO(acceptanceDateTime.value);
  if (!isValid(parsedDateTime)) {
    return "";
  }

  const timeZoneForDisplay = getTimeZoneForDisplay(acceptanceDateTime.timeZone);

  let timeZoneString = "";
  if (timeZoneForDisplay !== null) {
    timeZoneString = ` ${timeZoneForDisplay}`;
  }

  return `${format(parsedDateTime, "M/d/yyyy")} (${format(
    parsedDateTime,
    "h:mmb"
  )}${timeZoneString})`;
}

export function getTimeZoneForDisplay(timeZone: string) {
  switch (timeZone) {
    case "America/New_York":
      return "ET";
    case "America/Chicago":
      return "CT";
    case "America/Denver":
      return "MT";
    case "America/Los_Angeles":
      return "PT";
    case "Pacific/Honolulu":
      return "HAT";
    case "America/Anchorage":
      return "AKT";
    case "America/Phoenix":
      return "Arizona";
    default:
      logError(`invalid timeZone: ${timeZone}`);
      return null;
  }
}
