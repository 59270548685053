import { getFloatFromNumberOrString } from "./getFloatFromNumberOrString";

export function getNumber(input: number | string) {
  let result = getFloatFromNumberOrString(input);
  if (isNaN(result)) {
    result = 0;
  }
  return result;
}

export function getNumberOrNull(input: number | string) {
  let result: number | null = getFloatFromNumberOrString(input);
  if (isNaN(result)) {
    result = null;
  }
  return result;
}
