import { useCallback, useEffect, useRef, useState } from "react";
import {
  clearAuthenticationInfo,
  getAccessToken,
  hasAuthenticationExpired,
} from "../services/authentication";
import { useHistory } from "react-router-dom";
import { AppContainer } from "../../../components/AppContainer";
import { isStringSet } from "../../../libraries/typeUtilities/isStringSet";
import Spinner from "../../../components/Spinner";
import { timeout } from "rxjs/operators";
import Error from "../../../components/Error";
import { routingBuilders } from "../../../services/routing";
import addHours from "date-fns/addHours";
import { getCurrentDate } from "../../../services/getCurrentDate";
import formatISO from "date-fns/formatISO";
import { getScheduleLinkId } from "../services/appDataProvider";
import { GenericFooter } from "../../../components/JobDetails/GenericFooter";
import { LogoutFooterButton } from "./LogoutFooterButton";
import { getErrorMessageFromError } from "../../../libraries/errorParsing/getErrorMessageFromError";

export function AppSchedule({
  hasMultipleCrews,
  crewId,
}: {
  hasMultipleCrews: boolean;
  crewId: string;
}) {
  const {
    dayScheduleId,
    setDayScheduleId,
    scheduleDate,
    setScheduleDate,
    loadingScheduleId,
    errorGettingSchedule,
  } = useLoadDayScheduleId({ crewId });

  const onUpdateCurrentDateSchedule = useCallback(() => {
    const accessToken = getAccessToken();
    if (!hasAuthenticationExpired() && isStringSet(accessToken)) {
      getCurrentScheduleObservable({ crewId, accessToken }).subscribe({
        next: (result) => {
          setDayScheduleId(result.dayScheduleLinkId);
          setScheduleDate(result.scheduleDate);
        },

        error: () => {
          // Nothing to handle since it's a background process
        },
      });
    }
  }, [crewId, setDayScheduleId, setScheduleDate]);

  useEnsureScheduleDateCurrentOnVisibilityLoad({
    scheduleDate,
    onUpdateCurrentDateSchedule,
  });

  const footerElement = hasMultipleCrews ? (
    <GenericFooter backLink={routingBuilders.buildTechAppPath()} />
  ) : (
    <GenericFooter hideBackLink={true} backLink={null}>
      <LogoutFooterButton />
    </GenericFooter>
  );

  return (
    <>
      {loadingScheduleId ? <Spinner /> : null}

      {isStringSet(dayScheduleId) && !errorGettingSchedule.errorOccurred ? (
        <AppContainer
          dayScheduleId={dayScheduleId}
          techAppCrewId={crewId}
          alwaysEnableFullStory={false}
          jobListingFooterElement={footerElement}
        />
      ) : null}

      {errorGettingSchedule.errorOccurred ? (
        <>
          <Error
            errorHeader="Error Occurred"
            errorDetails={
              errorGettingSchedule.message ??
              "Please ensure you can connect to the internet."
            }
          />
          {footerElement}
        </>
      ) : null}
    </>
  );
}

function getCurrentScheduleObservable({
  crewId,
  accessToken,
}: {
  crewId: string;
  accessToken: string;
}) {
  return getScheduleLinkId({
    accessToken: accessToken,
    crewId: crewId,
  }).pipe(timeout(10000));
}

function useLoadDayScheduleId({ crewId }: { crewId: string }) {
  const history = useHistory();

  const [dayScheduleId, setDayScheduleId] = useState<string | null>(null);
  const [scheduleDate, setScheduleDate] = useState<string | null>(null);
  const [loadingScheduleId, setLoadingScheduleId] = useState(true);
  const [errorGettingSchedule, setErrorGettingSchedule] = useState<
    { errorOccurred: true; message: string } | { errorOccurred: false }
  >({ errorOccurred: false });

  useEffect(() => {
    const accessToken = getAccessToken();
    if (!accessToken || hasAuthenticationExpired()) {
      clearAuthenticationInfo();
      history.push(routingBuilders.buildLoginPath());
    } else {
      setLoadingScheduleId(true);
      setErrorGettingSchedule({ errorOccurred: false });
      getCurrentScheduleObservable({ accessToken, crewId }).subscribe({
        next: (result) => {
          setLoadingScheduleId(false);

          setDayScheduleId(result.dayScheduleLinkId);
          setScheduleDate(result.scheduleDate);
        },

        error: (err) => {
          setLoadingScheduleId(false);

          // If token is no longer valid, have user log back in
          if (err.status === 401) {
            clearAuthenticationInfo();
            history.push(routingBuilders.buildLoginPath());
          }

          setErrorGettingSchedule({
            errorOccurred: true,
            message: getErrorMessageFromError(err, ""),
          });
        },
      });
    }
  }, [crewId, history]);

  return {
    dayScheduleId,
    setDayScheduleId,
    scheduleDate,
    setScheduleDate,
    loadingScheduleId,
    errorGettingSchedule,
  };
}

// Used to make sure that if a user leaves the app open over night, they'll load the current date
// when they open the app the next morning.
function useEnsureScheduleDateCurrentOnVisibilityLoad({
  scheduleDate,
  onUpdateCurrentDateSchedule,
}: {
  scheduleDate: string | null;
  onUpdateCurrentDateSchedule: () => void;
}) {
  const lastDateCheck = useRef(getCurrentDate());
  const onVisibilityChange = useCallback(() => {
    if (!scheduleDate) {
      return;
    }

    const have6HoursTimePassedSinceLastCheck =
      addHours(lastDateCheck.current, 6) <= getCurrentDate();
    if (!have6HoursTimePassedSinceLastCheck) {
      return;
    }

    if (
      scheduleDate < formatISO(getCurrentDate(), { representation: "date" })
    ) {
      onUpdateCurrentDateSchedule();
      lastDateCheck.current = getCurrentDate();
    }
  }, [scheduleDate, onUpdateCurrentDateSchedule]);

  useEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange);

    return function cleanup() {
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, [onVisibilityChange]);
}
