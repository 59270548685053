import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IInvoiceForPaymentForm } from "../../../libraries/invoicePaymentForm/models/IInvoiceForPaymentForm";
import { formatCurrency } from "../../../services/currencyFormatter";
import { getZonedDateTimeForDisplay } from "../../../services/dateTimeService";
import { getInvoicePaymentAmount } from "../../../libraries/invoicePaymentForm/services/invoiceService";
import { ConvenienceFeeInUseAlert } from "../../../libraries/invoicePaymentForm/components/ConvenienceFeeInUseAlert";

export function DepositInvoice({
  depositInvoice,
  depositAmount,
}: {
  depositInvoice: IInvoiceForPaymentForm | null;
  depositAmount: number | null;
}) {
  // There's no payment form so no way to know if conveience fee applies
  const convenienceFeeInUse = false;

  const depositAmountSet =
    typeof depositAmount === "number" && depositAmount > 0;
  if (depositInvoice === null && !depositAmountSet) {
    return null;
  }

  let depositAmountToShow: number = 0;
  if (depositInvoice !== null) {
    depositAmountToShow = getInvoicePaymentAmount(
      convenienceFeeInUse,
      depositInvoice
    );
  } else if (depositAmountSet) {
    depositAmountToShow = depositAmount;
  }

  return (
    <>
      <hr />
      <div className="my-4 text-center">
        {depositInvoice?.paid ? (
          <>
            <div className="text-center">
              <h4 className="mb-0 text-success">
                <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
                Deposit payment of {formatCurrency(depositInvoice.total)}{" "}
                received
              </h4>
              {depositInvoice.transactionDateTime ? (
                <div className="small" data-testid="depositTransactionDateTime">
                  on{" "}
                  {getZonedDateTimeForDisplay(
                    depositInvoice.transactionDateTime
                  )}
                </div>
              ) : null}
              {depositInvoice.convenienceFeePaid ? (
                <div className="small">
                  an additional{" "}
                  {formatCurrency(depositInvoice.convenienceFeePaid)} was
                  charged as a convenience fee
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <div>
            <div className="mb-3">
              <h5 data-testid="depositExplanation">
                <div>
                  In order to begin work, we'll need to collect a deposit
                  payment of {formatCurrency(depositAmountToShow)}.
                </div>
              </h5>
              {convenienceFeeInUse && depositInvoice ? (
                <ConvenienceFeeInUseAlert invoice={depositInvoice} />
              ) : null}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
