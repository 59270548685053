import React, { useState, useRef, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import { PicaService } from "../../formAttachments/services/image-resize/pica.service";
import Spinner from "../../../components/Spinner";
import {
  convertFileToImage,
  uploadSignature,
} from "../../formAttachments/services/fileUpload";
import { IScheduleIdentifier } from "../../../models/IScheduleIdentifier";
import { mergeMap } from "rxjs/operators";
import ReactSignatureCanvas from "react-signature-canvas";
import { faChevronLeft, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { strings } from "../../../languages";
import { FooterButton } from "../../../components/FooterButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getErrorMessageFromError } from "../../errorParsing/getErrorMessageFromError";
import { FloatingAlert } from "../../billableForm/components/FloatingAlert";
import { Observable } from "rxjs";
import { AjaxResponse } from "rxjs/ajax";

interface IProps {
  scheduleIdentifier: IScheduleIdentifier;
  scheduleJobId: string;
  onCancel: () => void;
  onSaveCompleted: () => void;
  saveMethod: (args: {
    scheduleIdentifier: IScheduleIdentifier;
    jobInstanceId: string;
    payload: { signatureImagePath: string };
  }) => Observable<AjaxResponse>;
}

const buttonContainerHeight = 75;
const topLevelElementPadding = 15;

function handleLayoutChange({
  containerRef,
  signatureRef,
  canvasWidth,
  setCanvasWidth,
  canvasHeight,
  setCanvasHeight,
}: {
  containerRef: React.RefObject<HTMLDivElement>;
  signatureRef: React.RefObject<ReactSignatureCanvas>;
  canvasWidth: number | undefined;
  setCanvasWidth: (newWidth: number) => void;
  canvasHeight: number | undefined;
  setCanvasHeight: (newHeight: number) => void;
}) {
  console.group("handleLayoutChange");
  console.log(`window height: ${window.innerHeight}`);
  if (containerRef.current) {
    console.log(`container height: ${containerRef.current.clientHeight}`);
  } else {
    console.log("container not set");
  }
  console.groupEnd();

  setCanvasWidth(275);
  setCanvasHeight(150);

  return window.setTimeout(() => {
    console.group("setTimeout");
    console.log(`window height: ${window.innerHeight}`);
    if (containerRef.current) {
      console.log(`container height: ${containerRef.current.clientHeight}`);
    } else {
      console.log("container not set");
    }
    console.groupEnd();

    if (containerRef.current) {
      let newCanvasWidth = containerRef.current.clientWidth;
      if (newCanvasWidth < 275) {
        newCanvasWidth = 275;
      }

      if (newCanvasWidth !== canvasWidth) {
        setCanvasWidth(newCanvasWidth);
      }

      let newCanvasHeight = containerRef.current.clientHeight;
      if (newCanvasHeight < 150) {
        newCanvasHeight = 150;
      }

      if (newCanvasHeight !== canvasHeight) {
        setCanvasHeight(newCanvasHeight);
      }
    }
  });
}

export const CaptureSignaturePad: React.FunctionComponent<IProps> = ({
  onCancel,
  onSaveCompleted,
  scheduleIdentifier,
  scheduleJobId,
  saveMethod,
}) => {
  const [fileUploading, setFileUploading] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState(275);
  const [canvasHeight, setCanvasHeight] = useState(150);
  const signatureRef = useRef<ReactSignatureCanvas | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const picaService = useRef<PicaService>(new PicaService());
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    console.log("useEffect");
    const timeoutId = handleLayoutChange({
      containerRef,
      signatureRef,
      canvasWidth: undefined,
      setCanvasWidth,
      canvasHeight: undefined,
      setCanvasHeight,
    });

    return function cleanup() {
      window.clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    let timeoutIds: Array<number> = [];
    const handleOrientationChanged = () => {
      timeoutIds.push(
        handleLayoutChange({
          containerRef,
          signatureRef,
          canvasWidth: undefined,
          setCanvasWidth,
          canvasHeight: undefined,
          setCanvasHeight,
        })
      );
    };
    window.addEventListener("orientationchange", handleOrientationChanged);
    window.addEventListener("resize", handleOrientationChanged);

    return function cleanup() {
      window.removeEventListener("orientationchange", handleOrientationChanged);
      window.removeEventListener("resize", handleOrientationChanged);
      timeoutIds.forEach((timeoutId) => window.clearTimeout(timeoutId));
    };
  }, []);

  return (
    <React.Fragment>
      {fileUploading ? <Spinner /> : null}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          paddingLeft: `${topLevelElementPadding}px`,
          paddingRight: `${topLevelElementPadding}px`,
          paddingTop: `${topLevelElementPadding}px`,
          paddingBottom: `${topLevelElementPadding}px`,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{ flex: "1" }}
          ref={containerRef}
          data-testid="canvasContainer"
        >
          <div>
            <button
              type="button"
              className="btn btn-transparent"
              style={{ position: "absolute", right: 10, padding: 5 }}
              onClick={() => {
                if (signatureRef.current) {
                  signatureRef.current.clear();
                  setErrorMessage("");
                }
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} size="2x" fixedWidth />
            </button>
          </div>
          <SignatureCanvas
            ref={(ref) => (signatureRef.current = ref)}
            canvasProps={{
              width: canvasWidth,
              height: canvasHeight,
              style: { border: "1px solid black" },
            }}
          />
        </div>

        <div
          className="d-flex justify-content-between"
          style={{
            height: `${buttonContainerHeight}px`,
          }}
        >
          <FooterButton
            icon={faChevronLeft}
            caption={strings.back}
            color="text-primary"
            onClick={() => {
              onCancel();
            }}
          />
          <button
            className="btn btn-primary"
            type="button"
            style={{ alignSelf: "center" }}
            onClick={() => {
              if (!signatureRef.current) {
                console.error("signatureRef.current not set");
                return;
              }

              if (signatureRef.current.isEmpty()) {
                setErrorMessage("Please enter your signature");
                return;
              }

              setErrorMessage("");

              setFileUploading(true);
              const canvas =
                signatureRef.current.getCanvas() as HTMLCanvasElement;
              canvas.toBlob((signatureBlob) => {
                if (signatureBlob) {
                  convertFileToImage(signatureBlob)
                    .pipe(
                      mergeMap((conversionResult) =>
                        picaService.current.resize(
                          conversionResult.img,
                          "signature.png",
                          "image/png",
                          300,
                          150,
                          true
                        )
                      ),
                      mergeMap((result) => {
                        return uploadSignature(scheduleIdentifier, result.file);
                      }),
                      mergeMap((newSignaturePath) => {
                        const payload = {
                          signatureImagePath: newSignaturePath,
                        };

                        return saveMethod({
                          scheduleIdentifier,
                          jobInstanceId: scheduleJobId,
                          payload,
                        });
                      })
                    )
                    .subscribe({
                      next: () => {
                        setFileUploading(false);
                        onSaveCompleted();
                      },

                      error: (err) => {
                        setErrorMessage(getErrorMessageFromError(err));
                        setFileUploading(false);
                      },
                    });
                }
              });
            }}
          >
            {strings.confirm}
          </button>
        </div>

        {errorMessage ? (
          <FloatingAlert
            onClose={() => setErrorMessage("")}
            type="danger"
            showClose
          >
            <>{errorMessage}</>
          </FloatingAlert>
        ) : null}
      </div>
    </React.Fragment>
  );
};
