import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { timeout } from "rxjs/operators";
import { Observable } from "rxjs";

import CreditCardLogos from "../images/CreditCardLogos.png";
import Spinner from "../../../components/Spinner";
import { formatCurrency } from "../../../services/currencyFormatter";
import { logError } from "../../logging/logError";
import { getErrorMessagesFromError } from "../../errorParsing/getErrorMessagesFromError";
import { defaultErrorMessage } from "../../errorParsing/defaults";
import { ReferenceInformationField } from "./ReferenceInformationField";
import { Controller, useForm } from "react-hook-form";
import { strings } from "../../../languages";
import { LanguageContext } from "../../../App";

export type SaveCallbackArgs = {
  token: string;
  partialNumber: string;
  referenceInformation: string;
};

interface IProps<TResult> {
  isNonProductionEnvironment: boolean;
  payrixKey: string;
  merchantAccountId: string;
  onSave: (saveResult: TResult) => void;
  paymentAmount: number;
  saveCall: (args: SaveCallbackArgs) => Observable<TResult>;
  errorLogMsg: string;
  mode?: "token" | "txnToken";
}

function CreditCard<TResult>(props: IProps<TResult>) {
  const {
    isNonProductionEnvironment,
    payrixKey,
    merchantAccountId,
    onSave,
    paymentAmount,
    saveCall,
    errorLogMsg,
    mode,
  } = props;

  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const creditCardIframeRef = useRef<HTMLIFrameElement | null>(null);
  const awaitingPayfieldsMessage = useRef(false);
  const { control, getValues } = useForm<{ referenceInformation: string }>({
    defaultValues: {
      referenceInformation: "",
    },
  });
  const languageContext = useContext(LanguageContext);

  // Payrix expects integers for amount.  For example, $9.99 equals 999
  // Need to round in cases like 16.4 * 100 equals 1639.9999999999998.
  const payrixAmount = Math.round(paymentAmount * 100);

  const handleMessage = useCallback(
    (event) => {
      if (event.origin !== window.document.location.origin) {
        return;
      }

      if (event.data.payfieldsMessage && awaitingPayfieldsMessage.current) {
        awaitingPayfieldsMessage.current = false;

        if (event.data.successMessage) {
          if (!event.data.response || !event.data.response.data) {
            setSaving(false);
            logError("received invalid data response from payfields");
          } else {
            const payrixResponse = event.data.response.data[0];

            let partialNumber = "";
            let token = "";
            if (typeof payrixResponse.payment === "object") {
              partialNumber = payrixResponse.payment.number;
              token = payrixResponse.token;
            } else {
              partialNumber = payrixResponse.token.payment.number;
              token = payrixResponse.token.token;
            }

            saveCall({
              token,
              partialNumber,
              referenceInformation: getValues("referenceInformation"),
            })
              .pipe(timeout(15000))
              .subscribe({
                next: (result) => {
                  setSaving(false);
                  onSave(result);
                },
                error: (err) => {
                  const parsedErrorMessages = getErrorMessagesFromError(err);

                  setErrorMessage(
                    parsedErrorMessages.length > 0
                      ? parsedErrorMessages[0]
                      : defaultErrorMessage
                  );

                  setSaving(false);

                  if (err.status !== 400) {
                    logError(errorLogMsg);
                  }
                },
              });
          }
        } else if (event.data.failureMessage) {
          setSaving(false);
          const response = event.data?.response;

          if (
            response?.errors &&
            response.errors.length > 0 &&
            response.errors[0]?.msg
          ) {
            setErrorMessage(response.errors[0].msg);
          } else {
            setErrorMessage(
              "An unknown error occurred saving the credit card.  The administrators have been notified and will address it shortly."
            );
            logError(
              "Unknown error tokenizing card data: " +
                JSON.stringify(event.data)
            );
          }
        } else if (event.data.validationFailure) {
          setSaving(false);
        }
      } else if (event.data.iframeLoaded) {
        if (
          creditCardIframeRef?.current?.contentWindow?.document?.body
            ?.scrollHeight
        ) {
          creditCardIframeRef.current.style.height = `${event.data.height}px`;
        }

        setIframeLoaded(true);
      }
    },
    [onSave, setSaving, setErrorMessage, saveCall, errorLogMsg, getValues]
  );

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    return function cleanup() {
      window.removeEventListener("message", handleMessage);
    };
  });

  let modeQueryString = "";
  if (typeof mode === "string") {
    modeQueryString = `mode=${mode}`;
  }

  let languageQueryString = "";
  if (languageContext.language === "es") {
    languageQueryString = "&language=es";
  }

  return (
    <>
      {!iframeLoaded || saving ? <Spinner /> : null}
      <iframe
        ref={creditCardIframeRef}
        style={{
          visibility: iframeLoaded ? undefined : "hidden",
          border: 0,
          height: "260px",
          width: "100%",
          marginBottom: "-20px",
        }}
        name="paymentFields"
        title="Payment Fields"
        src={`${process.env.PUBLIC_URL}/assets/payrix${
          isNonProductionEnvironment ? "" : "-production"
        }.html?k=${encodeURIComponent(payrixKey)}&m=${encodeURIComponent(
          merchantAccountId
        )}&amount=${payrixAmount}&${modeQueryString}${languageQueryString}`}
        data-testid="creditCardFrame"
      ></iframe>

      {errorMessage ? (
        <div className="text-danger my-3" data-testid="errorMessage">
          {errorMessage}
        </div>
      ) : null}

      <Controller
        control={control}
        name="referenceInformation"
        render={({ field }) => (
          <ReferenceInformationField
            referenceInformation={field.value}
            setReferenceInformation={field.onChange}
            idPrefix="creditCard"
          />
        )}
      />

      <div className="text-center">
        <button
          className="btn btn-primary btn-lg"
          data-testid="creditCardPaymentButton"
          onClick={() => {
            if (
              creditCardIframeRef.current &&
              creditCardIframeRef.current.contentWindow &&
              !saving
            ) {
              setSaving(true);
              setErrorMessage("");

              awaitingPayfieldsMessage.current = true;

              creditCardIframeRef.current.contentWindow.postMessage(
                { submitForm: true },
                window.document.location.origin
              );
            }
          }}
        >
          {paymentAmount > 0
            ? `${strings.pay} ${formatCurrency(paymentAmount)}`
            : "Save"}
        </button>
        <div className="mt-2">
          <img src={CreditCardLogos} alt="Credit Card Logos" />
        </div>
      </div>
    </>
  );
}

export default CreditCard;
