import { useState } from "react";
import dataProvider from "../../../services/DataProvider";
import { IScheduleIdentifier } from "../../../models/IScheduleIdentifier";
import { getErrorMessageFromError } from "../../../libraries/errorParsing/getErrorMessageFromError";
import Spinner from "../../../components/Spinner";
import { getPageHeader } from "../../../libraries/commonUi/functions/getPageHeader";
import { strings } from "../../../languages";
import ISchedule from "../../../models/ISchedule";
import { useScheduleJobCache } from "../../../hooks/useScheduleJobCache";
import { routingBuilders } from "../../../services/routing";
import Container from "../../../components/Container";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { GenericFooter } from "../../../components/JobDetails/GenericFooter";
import { FooterButton } from "../../../components/FooterButton";
import PhoneNumberField from "../../../libraries/input/PhoneNumberField";
import TextareaAutosize from "react-autosize-textarea/lib";
import {
  UserSettingsType,
  getUserSettings,
  setUserSettings,
} from "../../../services/userSettingsService";
import { isStringSet } from "../../../libraries/typeUtilities/isStringSet";
import { JobMovedErrorAlert } from "../../../components/JobMovedErrorAlert";

interface IProps {
  schedule: ISchedule;
  scheduleIdentifier: IScheduleIdentifier;
  scheduleJobId: string;
  onSaveCompleted: () => void;
}

function NotifyOnTheWay(props: IProps) {
  const { schedule, scheduleIdentifier, scheduleJobId, onSaveCompleted } =
    props;

  const { scheduleJob } = useScheduleJobCache(schedule, scheduleJobId ?? "");

  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [crewMemberName, setCrewMemberName] = useState(
    getUserSettings(UserSettingsType.onTheWayCrewMemberName) ?? ""
  );
  const [crewMemberPhoneNumber, setCrewMemberPhoneNumber] = useState(
    getUserSettings(UserSettingsType.onTheWayCrewMemberPhoneNumber) ?? ""
  );
  const [approximateTimeMinutes, setApproximateTimeMinutes] = useState<
    number | null
  >(null);

  const formId = "notifyOnTheWayForm";
  const customerNotificationMaximumMessageLength = 320;

  function buildMessage() {
    let message = schedule.customerTextTemplateMessageOnTheWay;
    if (!scheduleJob) {
      return "";
    }

    if (scheduleJob.name?.length > 0) {
      message = message.replace("<Customer name>", scheduleJob.name);
    }
    message = message.replace("<Company name>", schedule.tenantName);
    message = message.replace(
      " at <Customer address>",
      isStringSet(scheduleJob.address) ? ` at ${scheduleJob.address}` : ""
    );

    if (isStringSet(crewMemberName)) {
      message = message.replace("<Crew member name>", crewMemberName);
    }

    if (isStringSet(crewMemberPhoneNumber)) {
      message = message.replace("<Phone number>", crewMemberPhoneNumber);
    }

    let arrivalTimeMessage = "";

    if ((approximateTimeMinutes ?? 0) > 0) {
      arrivalTimeMessage = ` and will be arriving in approximately ${approximateTimeMinutes} minutes`;
    }

    message = message.replace("<Approximate time>", arrivalTimeMessage);

    return message;
  }

  if (!scheduleJob) {
    return <JobMovedErrorAlert scheduleIdentifier={scheduleIdentifier} />;
  }

  return (
    <Container
      isJobListing={false}
      schedule={schedule}
      headerOverride={getPageHeader({
        schedule,
        scheduleJob,
        pageTitle: strings.sendOnTheWayNotification,
      })}
      scheduleIdentifier={scheduleIdentifier}
    >
      <div className="container mt-3 px-0" style={{ marginBottom: "100px" }}>
        <form
          id={formId}
          name={formId}
          onSubmit={(e) => {
            e.preventDefault();

            if (saving) {
              return;
            }

            setSaving(true);

            setUserSettings(
              UserSettingsType.onTheWayCrewMemberName,
              crewMemberName
            );
            setUserSettings(
              UserSettingsType.onTheWayCrewMemberPhoneNumber,
              crewMemberPhoneNumber
            );

            dataProvider
              .notifyOnTheWay({
                scheduleIdentifier,
                scheduleJobId,
                crewMemberName,
                crewMemberPhoneNumber,
                approximateTimeMinutes,
              })
              .subscribe({
                next: () => {
                  setSaving(false);
                  onSaveCompleted();
                },

                error: (err) => {
                  const parsedErrorMessages =
                    getErrorMessageFromError(err) ??
                    "An unknown error while saving.";

                  setErrorMessage(parsedErrorMessages);
                  setSaving(false);
                },
              });
          }}
        >
          {saving ? <Spinner testIdPrefix="offline" /> : null}

          <div className="form-group">
            <label htmlFor={"crewMemberName"} className="required">
              {strings.myName}
            </label>
            <input
              id="crewMemberName"
              data-testid="crewMemberName"
              className="form-control"
              type="text"
              maxLength={50}
              required={true}
              value={crewMemberName}
              onChange={(e) => setCrewMemberName(e.currentTarget.value)}
            />
          </div>

          <div className="form-row">
            <div className="form-group col-7 md-col-6">
              <label htmlFor={"crewMemberPhoneNumber"} className="required">
                {strings.myPhoneNumber}
              </label>
              <PhoneNumberField
                required={true}
                id="crewMemberPhoneNumber"
                dataTestId="crewMemberPhoneNumber"
                value={crewMemberPhoneNumber}
                className="form-control"
                onChange={(e) => {
                  setCrewMemberPhoneNumber(e.currentTarget.value);
                }}
              />
            </div>
            <div className="form-group col-5 md-col-6 ">
              <label htmlFor={"approximateTime"}>{strings.arrivalTime}</label>
              <select
                id="approximateTime"
                data-testid="approximateTime"
                className="form-control"
                value={approximateTimeMinutes?.toString() ?? "0"}
                onChange={(e) => {
                  switch (e.currentTarget.value) {
                    case "0":
                      setApproximateTimeMinutes(null);
                      break;
                    default:
                      setApproximateTimeMinutes(
                        e.currentTarget.value
                          ? parseInt(e.currentTarget.value)
                          : null
                      );
                      break;
                  }
                }}
              >
                <option value="0">{strings.none}</option>
                <option value="5">
                  {strings.formatString(strings.minutes, 5)}
                </option>
                <option value="10">
                  {strings.formatString(strings.minutes, 10)}
                </option>
                <option value="15">
                  {strings.formatString(strings.minutes, 15)}
                </option>
                <option value="30">
                  {strings.formatString(strings.minutes, 30)}
                </option>
                <option value="45">
                  {strings.formatString(strings.minutes, 45)}
                </option>
                <option value="60">
                  {strings.formatString(strings.minutes, 60)}
                </option>
              </select>
            </div>
            <TextareaAutosize
              id="textMessage"
              data-testid="textMessage"
              aria-label="Text message"
              maxRows={10}
              className="form-control"
              value={buildMessage()}
              disabled={true}
              maxLength={customerNotificationMaximumMessageLength}
              required
            />
          </div>

          {errorMessage ? (
            <div className="text-danger my-3" data-testid="errorMessage">
              {errorMessage}
            </div>
          ) : null}
        </form>
      </div>
      <GenericFooter
        backLink={routingBuilders.buildScheduleJobPath({
          scheduleIdentifier,
          scheduleJobId: scheduleJobId,
        })}
      >
        <FooterButton
          icon={faMessage}
          caption={strings.send}
          color="text-primary"
          type="submit"
          formId={formId}
          testId="SendOnTheWay"
        />
      </GenericFooter>
    </Container>
  );
}

export default NotifyOnTheWay;
