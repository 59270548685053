import { IScheduleIdentifier } from "../models/IScheduleIdentifier";

export const dayOffsetQueryParameterName = "dayOffset";

export const routingPatterns = {
  getLoginPath: () => "/login" as const,

  getTechAppPath: () => "/app" as const,

  getTechAppSchedulePath: (parentPath: string) =>
    `${parentPath}/:crewId` as const,

  getSchedulePath: () => "/:dayScheduleId" as const,

  getScheduleJobPath: (parentPath: string) =>
    `${parentPath}/:scheduleJobId` as const,

  getCustomerJobHistoryPath: (parentPath: string) =>
    `${parentPath}/:scheduleJobId/jobhistory` as const,

  getNotifyOnTheWayPath: (parentPath: string) =>
    `${parentPath}/:scheduleJobId/notifyOnTheWay` as const,

  getInvoiceFormPath: (parentPath: string) =>
    `${parentPath}/:scheduleJobId/invoice/form` as const,

  getInvoicePresentationPath: (parentPath: string) =>
    `${parentPath}/:scheduleJobId/invoice/present` as const,

  getInvoicePaymentPath: (parentPath: string) =>
    `${parentPath}/:scheduleJobId/invoice/pay` as const,

  getEstimateDepositInvoicePaymentPath: (parentPath: string) =>
    `${parentPath}/:scheduleJobId/estimate/deposit/pay` as const,

  getInvoiceSignaturePath: (parentPath: string) =>
    `${parentPath}/:scheduleJobId/invoice/sign` as const,

  getEstimateFormPath: (parentPath: string) =>
    `${parentPath}/:scheduleJobId/estimate/form` as const,

  getEstimatePresentationPath: (parentPath: string) =>
    `${parentPath}/:scheduleJobId/estimate/present` as const,

  getEstimateSignaturePath: (parentPath: string) =>
    `${parentPath}/:scheduleJobId/estimate/sign` as const,

  getLineItemFormPath: (parentPath: string) =>
    `${parentPath}/lineitem/:lineItemId?` as const,

  getWorksheetPath: (parentPath: string) =>
    `${parentPath}/:scheduleJobId/worksheet/:worksheetForJobId` as const,
};

export const routingBuilders = {
  buildLoginPath: () => routingPatterns.getLoginPath(),

  buildTechAppPath: () => routingPatterns.getTechAppPath(),

  buildTechAppSchedulePath: ({
    parentPath,
    crewId,
  }: {
    parentPath: string;
    crewId: string;
  }) =>
    routingPatterns
      .getTechAppSchedulePath(parentPath)
      .replace(":crewId", crewId),

  buildSchedulePath: ({
    scheduleIdentifier,
  }: {
    scheduleIdentifier: IScheduleIdentifier;
  }) => {
    return appendOriginalScheduleParameter({
      path: buildSchedulePathWithoutQueryString({ scheduleIdentifier }),
      dayOffset: scheduleIdentifier.dayOffset,
    });
  },

  buildScheduleJobPath: ({
    scheduleIdentifier,
    scheduleJobId,
  }: {
    scheduleIdentifier: IScheduleIdentifier;
    scheduleJobId: string;
  }) => {
    const parentPath = buildSchedulePathWithoutQueryString({
      scheduleIdentifier,
    });
    const scheduleJobPath = routingPatterns
      .getScheduleJobPath(parentPath)
      .replace(":scheduleJobId", scheduleJobId);
    const result = appendOriginalScheduleParameter({
      path: scheduleJobPath,
      dayOffset: scheduleIdentifier.dayOffset,
    });

    return result;
  },

  buildInvoiceFormPath: ({
    scheduleIdentifier,
    scheduleJobId,
  }: {
    scheduleIdentifier: IScheduleIdentifier;
    scheduleJobId: string;
  }) => {
    return appendOriginalScheduleParameter({
      path: buildInvoiceFormPath(scheduleIdentifier, scheduleJobId),
      dayOffset: scheduleIdentifier.dayOffset,
    });
  },

  buildInvoicePresentation: ({
    scheduleIdentifier,
    scheduleJobId,
  }: {
    scheduleIdentifier: IScheduleIdentifier;
    scheduleJobId: string;
  }) => {
    return appendOriginalScheduleParameter({
      path: routingPatterns
        .getInvoicePresentationPath(
          buildSchedulePathWithoutQueryString({ scheduleIdentifier })
        )
        .replace(":scheduleJobId", scheduleJobId),
      dayOffset: scheduleIdentifier.dayOffset,
    });
  },

  buildInvoicePaymentPath: ({
    scheduleIdentifier,
    scheduleJobId,
  }: {
    scheduleIdentifier: IScheduleIdentifier;
    scheduleJobId: string;
  }) => {
    return appendOriginalScheduleParameter({
      path: routingPatterns
        .getInvoicePaymentPath(
          buildSchedulePathWithoutQueryString({ scheduleIdentifier })
        )
        .replace(":scheduleJobId", scheduleJobId),
      dayOffset: scheduleIdentifier.dayOffset,
    });
  },

  buildEstimateDepositInvoicePaymentPath: ({
    scheduleIdentifier,
    scheduleJobId,
  }: {
    scheduleIdentifier: IScheduleIdentifier;
    scheduleJobId: string;
  }) => {
    return appendOriginalScheduleParameter({
      path: routingPatterns
        .getEstimateDepositInvoicePaymentPath(
          buildSchedulePathWithoutQueryString({ scheduleIdentifier })
        )
        .replace(":scheduleJobId", scheduleJobId),
      dayOffset: scheduleIdentifier.dayOffset,
    });
  },

  buildInvoiceSignaturePath: ({
    scheduleIdentifier,
    scheduleJobId,
  }: {
    scheduleIdentifier: IScheduleIdentifier;
    scheduleJobId: string;
  }) => {
    return appendOriginalScheduleParameter({
      path: routingPatterns
        .getInvoiceSignaturePath(
          buildSchedulePathWithoutQueryString({ scheduleIdentifier })
        )
        .replace(":scheduleJobId", scheduleJobId),
      dayOffset: scheduleIdentifier.dayOffset,
    });
  },

  buildInvoiceLineItemPath: ({
    scheduleIdentifier,
    scheduleJobId,
    lineItemId,
  }: {
    scheduleIdentifier: IScheduleIdentifier;
    scheduleJobId: string;
    lineItemId: string | null;
  }) => {
    return appendOriginalScheduleParameter({
      path: routingPatterns
        .getLineItemFormPath(
          buildInvoiceFormPath(scheduleIdentifier, scheduleJobId)
        )
        .replace(":scheduleJobId", scheduleJobId)
        .replace(":lineItemId?", lineItemId ?? ""),
      dayOffset: scheduleIdentifier.dayOffset,
    });
  },

  buildEstimateFormPath: ({
    scheduleIdentifier,
    scheduleJobId,
  }: {
    scheduleIdentifier: IScheduleIdentifier;
    scheduleJobId: string;
  }) => {
    return appendOriginalScheduleParameter({
      path: buildEstimateFormPath(scheduleIdentifier, scheduleJobId),
      dayOffset: scheduleIdentifier.dayOffset,
    });
  },

  buildEstimatePresentationPath: ({
    scheduleIdentifier,
    scheduleJobId,
  }: {
    scheduleIdentifier: IScheduleIdentifier;
    scheduleJobId: string;
  }) => {
    return appendOriginalScheduleParameter({
      path: routingPatterns
        .getEstimatePresentationPath(
          buildSchedulePathWithoutQueryString({ scheduleIdentifier })
        )
        .replace(":scheduleJobId", scheduleJobId),
      dayOffset: scheduleIdentifier.dayOffset,
    });
  },

  buildEstimateSignaturePath: ({
    scheduleIdentifier,
    scheduleJobId,
  }: {
    scheduleIdentifier: IScheduleIdentifier;
    scheduleJobId: string;
  }) => {
    return appendOriginalScheduleParameter({
      path: routingPatterns
        .getEstimateSignaturePath(
          buildSchedulePathWithoutQueryString({ scheduleIdentifier })
        )
        .replace(":scheduleJobId", scheduleJobId),
      dayOffset: scheduleIdentifier.dayOffset,
    });
  },

  buildEstimateLineItemPath: ({
    scheduleIdentifier,
    scheduleJobId,
    lineItemId,
  }: {
    scheduleIdentifier: IScheduleIdentifier;
    scheduleJobId: string;
    lineItemId: string | null;
  }) => {
    return appendOriginalScheduleParameter({
      path: routingPatterns
        .getLineItemFormPath(
          buildEstimateFormPath(scheduleIdentifier, scheduleJobId)
        )
        .replace(":scheduleJobId", scheduleJobId)
        .replace(":lineItemId?", lineItemId ?? ""),
      dayOffset: scheduleIdentifier.dayOffset,
    });
  },

  buildCustomerJobHistoryPath: ({
    scheduleIdentifier,
    scheduleJobId,
  }: {
    scheduleIdentifier: IScheduleIdentifier;
    scheduleJobId: string;
  }) => {
    return appendOriginalScheduleParameter({
      path: routingPatterns
        .getCustomerJobHistoryPath(
          buildSchedulePathWithoutQueryString({ scheduleIdentifier })
        )
        .replace(":scheduleJobId", scheduleJobId),
      dayOffset: scheduleIdentifier.dayOffset,
    });
  },

  buildNotifyOnTheWayPath: ({
    scheduleIdentifier,
    scheduleJobId,
  }: {
    scheduleIdentifier: IScheduleIdentifier;
    scheduleJobId: string;
  }) => {
    return appendOriginalScheduleParameter({
      path: routingPatterns
        .getNotifyOnTheWayPath(
          buildSchedulePathWithoutQueryString({ scheduleIdentifier })
        )
        .replace(":scheduleJobId", scheduleJobId),
      dayOffset: scheduleIdentifier.dayOffset,
    });
  },

  buildWorksheetPath: ({
    scheduleIdentifier,
    scheduleJobId,
    worksheetForJobId,
  }: {
    scheduleIdentifier: IScheduleIdentifier;
    scheduleJobId: string;
    worksheetForJobId: string;
  }) => {
    return appendOriginalScheduleParameter({
      path: routingPatterns
        .getWorksheetPath(
          buildSchedulePathWithoutQueryString({ scheduleIdentifier })
        )
        .replace(":scheduleJobId", scheduleJobId)
        .replace(":worksheetForJobId", worksheetForJobId),
      dayOffset: scheduleIdentifier.dayOffset,
    });
  },
};

function buildInvoiceFormPath(
  scheduleIdentifier: IScheduleIdentifier,
  scheduleJobId: string
) {
  return routingPatterns
    .getInvoiceFormPath(
      buildSchedulePathWithoutQueryString({ scheduleIdentifier })
    )
    .replace(":scheduleJobId", scheduleJobId);
}

function buildEstimateFormPath(
  scheduleIdentifier: IScheduleIdentifier,
  scheduleJobId: string
) {
  return routingPatterns
    .getEstimateFormPath(
      buildSchedulePathWithoutQueryString({ scheduleIdentifier })
    )
    .replace(":scheduleJobId", scheduleJobId);
}

function buildSchedulePathWithoutQueryString({
  scheduleIdentifier,
}: {
  scheduleIdentifier: IScheduleIdentifier;
}) {
  const result =
    typeof scheduleIdentifier.techAppCrewId === "string"
      ? routingBuilders.buildTechAppSchedulePath({
          parentPath: routingBuilders.buildTechAppPath(),
          crewId: scheduleIdentifier.techAppCrewId,
        })
      : routingPatterns
          .getSchedulePath()
          .replace(":dayScheduleId", scheduleIdentifier.linkedDayScheduleId);

  return result;
}

function appendOriginalScheduleParameter({
  path,
  dayOffset,
}: {
  path: string;
  dayOffset: number;
}) {
  if (dayOffset !== 0) {
    return appendQueryString({
      base: path,
      name: dayOffsetQueryParameterName,
      value: dayOffset,
    });
  } else {
    return path;
  }
}

export function appendQueryString({
  base,
  name,
  value,
}: {
  base: string;
  name: string;
  value: string | number | null;
}) {
  let separator: string | null = null;
  if (base.indexOf("?") === -1) {
    separator = "?";
  } else {
    separator = "&";
  }

  return `${base}${separator}${name}=${value ?? ""}`;
}
