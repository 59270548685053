import React from "react";
import { fullStoryLogError } from "./fullStoryService";

export function getReturnedErrorMessage(error: any): string | null {
  fullStoryLogError(error);

  if (typeof error.response === "string") {
    return error.response;
  } else {
    return null;
  }
}

export function getErrorMessage(
  error: any,
  defaultErrorMessage?: JSX.Element | undefined
) {
  const customErrorMessage = getReturnedErrorMessage(error);

  let errorMessage: JSX.Element;
  if (typeof customErrorMessage === "string") {
    errorMessage = <span>{customErrorMessage}</span>;
  } else {
    errorMessage = defaultErrorMessage || (
      <span>
        Save failed!
        <br />
        <br />
        Please ensure you have an internet connection.
      </span>
    );
  }

  return errorMessage;
}
