import React from "react";
import { useScheduleJobCache } from "../hooks/useScheduleJobCache";
import { ICustomCrewQuestion } from "../models/ICustomCrewQuestion";
import ISchedule from "../models/ISchedule";
import { IScheduleIdentifier } from "../models/IScheduleIdentifier";
import IScheduledJob from "../models/IScheduleJob";
import JobDetails from "./JobDetails";
import { JobMovedErrorAlert } from "./JobMovedErrorAlert";

interface IProps {
  schedule: ISchedule;
  filePrefix: string;
  tenantId: string;
  customCrewQuestions: Array<ICustomCrewQuestion>;
  scheduleJobId: string;
  hideCustomerNameOnCrewView: boolean;
  hideCustomerPhoneNumberOnCrewView: boolean;
  showCustomerEmailAddressOnCrewView: boolean;
  onScheduleJobUpdated(
    scheduleJobId: string,
    fieldsToUpdate: Partial<IScheduledJob>
  ): void;
  scheduleIdentifier: IScheduleIdentifier;
  showNotifiedOnTheWayAlert: boolean;
  setShowNotifiedOnTheWayAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

const JobDetailsWithCache: React.FunctionComponent<IProps> = (props) => {
  const { scheduleJob, scheduleJobIndex, updatedCachedScheduleJob } =
    useScheduleJobCache(props.schedule, props.scheduleJobId);

  if (scheduleJob) {
    return (
      <JobDetails
        {...props}
        onScheduleJobUpdated={(updatedJobId, fieldsToUpdate) => {
          updatedCachedScheduleJob(updatedJobId, fieldsToUpdate);

          props.onScheduleJobUpdated(updatedJobId, fieldsToUpdate);
        }}
        scheduleJob={scheduleJob}
        index={scheduleJobIndex === -1 ? 0 : scheduleJobIndex}
      />
    );
  } else {
    return <JobMovedErrorAlert scheduleIdentifier={props.scheduleIdentifier} />;
  }
};

export default JobDetailsWithCache;
