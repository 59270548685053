import React, { useEffect, useRef, useState } from "react";
import { formatCurrency } from "../../../services/currencyFormatter";
import { IEstimateForPresentation } from "../models/IEstimateForPresentation";
import LineItemDisplay from "../../../libraries/invoicePaymentForm/components/LineItemDisplay";
import RichTextDisplay from "../../../libraries/richTextDisplay/RichTextDisplay";
import { Attachments } from "../../../libraries/invoicePaymentForm/components/Attachments";
import CustomerSignature from "../../../libraries/signature/components/CustomerSignature";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { IZonedDateTime } from "../../../libraries/invoicePaymentForm/models/IZonedDateTime";
import { getZonedDateTimeForDisplay } from "../../../services/dateTimeService";
import { strings } from "../../../languages";
import Spinner from "../../../components/Spinner";
import { ISelectedItem } from "../../../models/ISelectedItem";
import { updateEstimateWithSelectedItems } from "../services/estimateDataProvider";
import { IScheduleIdentifier } from "../../../models/IScheduleIdentifier";
import { loadWithRetries } from "../services/loadWithRetries";
import { NoteWithHyperlinks } from "../../../components/NoteWithHyperlinks";
import { IInvoiceForPaymentForm } from "../../../libraries/invoicePaymentForm/models/IInvoiceForPaymentForm";
import { DepositInvoice } from "./DepositInvoice";

interface IProps {
  estimate: IEstimateForPresentation;
  depositInvoice: IInvoiceForPaymentForm | null;
  imagePrefix: string;
  signatureLinkPath: string;
  hasMovedFromSignaturePad: boolean;
  setEstimate: (estimate: IEstimateForPresentation) => void;
  scheduleJobId: string;
  scheduleIdentifier: IScheduleIdentifier;
}

const EstimatePresentation: React.FunctionComponent<IProps> = ({
  estimate,
  depositInvoice,
  imagePrefix,
  signatureLinkPath,
  hasMovedFromSignaturePad,
  setEstimate,
  scheduleJobId,
  scheduleIdentifier,
}) => {
  const customerSignatureContainerRef = useRef<HTMLDivElement>(null);
  const [refreshing, setRefreshing] = useState(false);
  const [failedSelectionUpdateRowIndex, setFailedSelectionUpdateRowIndex] =
    useState<number | null>(null);

  const hasScrolled = useRef(false);
  useEffect(() => {
    if (hasMovedFromSignaturePad && !hasScrolled.current) {
      if (customerSignatureContainerRef.current) {
        customerSignatureContainerRef.current.scrollIntoView(true);
        hasScrolled.current = true;
      }
    }
  }, [hasMovedFromSignaturePad]);

  return estimate ? (
    <>
      <div>
        <div>
          <div>
            <div className="form-group row">
              <div className="col-6">
                <label style={{ marginBottom: 0 }}>Total:</label>
                <h4 data-testid="paymentAmount">
                  {formatCurrency(estimate.total)}
                </h4>
              </div>
              <div className="col-6 text-right">
                {estimate.number ? (
                  <h4 data-testid="estimateNumber">
                    {strings.estimate} #{estimate.number}
                  </h4>
                ) : null}
              </div>
            </div>

            {!!estimate.introTextHtml ? (
              <div data-testid="introTextContainer" className="my-3">
                <RichTextDisplay html={estimate.introTextHtml} />
              </div>
            ) : null}

            {!!estimate.summary ? (
              <NoteWithHyperlinks
                containerClassName="my-3"
                testId="summaryContainer"
                notes={estimate.summary}
              />
            ) : null}

            <hr />

            <div>
              <LineItemDisplay
                lineItems={estimate.lineItems}
                hideLineItemPrices={false}
                setLineItems={(newLineItems, rowIndex) => {
                  let originalItems = estimate.lineItems;
                  setRefreshing(true);
                  setFailedSelectionUpdateRowIndex(null);
                  setEstimate({ ...estimate, lineItems: newLineItems });
                  loadWithRetries(() =>
                    updateEstimateWithSelectedItems({
                      scheduleIdentifier,
                      jobInstanceId: scheduleJobId,
                      selectedLineItemIds: newLineItems.map((li) => {
                        return {
                          id: li.id,
                          selected: li.selected,
                        } as ISelectedItem;
                      }),
                    })
                  ).subscribe({
                    next: (estimate) => {
                      setEstimate(estimate);
                      setRefreshing(false);
                    },
                    error: () => {
                      setFailedSelectionUpdateRowIndex(rowIndex);
                      setRefreshing(false);
                      setEstimate({
                        ...estimate,
                        lineItems: originalItems,
                      });
                    },
                  });
                }}
                disableSelected={!!estimate.acceptanceDateTime}
                failedSelectionUpdateRowIndex={failedSelectionUpdateRowIndex}
              />
              {refreshing ? <Spinner /> : null}

              <hr />

              <div className="mt-3 d-flex justify-content-end">
                <table>
                  <tbody>
                    <tr>
                      <td>{strings.subtotal}: </td>
                      <td className="pl-3 text-right" data-testid="subtotal">
                        {formatCurrency(estimate.subtotal)}
                      </td>
                    </tr>
                    {typeof estimate.discountAmount === "number" ? (
                      <tr>
                        <td>{strings.discount}: </td>
                        <td
                          className="pl-3 text-right"
                          data-testid="discountAmount"
                        >
                          ({formatCurrency(estimate.discountAmount)})
                        </td>
                      </tr>
                    ) : null}

                    <tr>
                      <td>{strings.taxes}: </td>
                      <td className="pl-3 text-right" data-testid="taxAmount">
                        {formatCurrency(estimate.taxAmount ?? 0)}
                      </td>
                    </tr>

                    {typeof estimate.depositAmount === "number" ? (
                      <>
                        <tr>
                          <td data-testid="depositLabel">
                            {strings.initialDeposit}:
                          </td>
                          <td className="pl-3 text-right" data-testid="deposit">
                            {formatCurrency(estimate.depositAmount)}
                          </td>
                        </tr>

                        {typeof estimate.balanceDueAtCompletion === "number" ? (
                          <tr>
                            <td data-testid="balanceDueLabel">
                              {strings.balanceDueAtCompletion}:
                            </td>
                            <td
                              className="pl-3 text-right"
                              data-testid="balanceDue"
                            >
                              {formatCurrency(estimate.balanceDueAtCompletion)}
                            </td>
                          </tr>
                        ) : null}
                      </>
                    ) : null}

                    <tr>
                      <td>{strings.total}:</td>
                      <td className="pl-3 text-right" data-testid="total">
                        {formatCurrency(estimate.total)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <Attachments
                files={estimate.files}
                filePrefix={estimate.filePrefix}
              />

              <DepositInvoice
                depositInvoice={depositInvoice}
                depositAmount={estimate.depositAmount}
              />

              <hr />

              {isEstimateAccepted(estimate) ? (
                <div
                  className="text-center mb-3"
                  data-testid="acceptanceContainer"
                >
                  <h4 className="mb-0 text-success">
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
                    {strings.accepted}
                  </h4>
                  <div className="small" data-testid="acceptanceDetails">
                    {`${strings.on} ${getZonedDateTimeForDisplay(
                      estimate.acceptanceDateTime as IZonedDateTime
                    )}`}
                  </div>
                </div>
              ) : null}

              <div
                ref={customerSignatureContainerRef}
                data-testid="customerSignatureContainer"
              >
                <CustomerSignature
                  imagePrefix={imagePrefix}
                  linkPath={signatureLinkPath}
                  signatureImagePath={estimate.signatureImagePath}
                  signatureExplanation={strings.signatureExplanationEstimate}
                />
              </div>

              <hr />

              {!!estimate.footerTextHtml ? (
                <div data-testid="footerTextContainer" className="mt-3">
                  <RichTextDisplay html={estimate.footerTextHtml} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default EstimatePresentation;

function isEstimateAccepted(estimate: IEstimateForPresentation) {
  return (
    typeof estimate.acceptanceDateTime === "object" &&
    estimate.acceptanceDateTime !== null
  );
}
