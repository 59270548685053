import * as React from "react";
import { IPendingFile } from "./Index";
import { strings } from "../../../languages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  file: IPendingFile;
  onRetryFileUpload: () => any;
  onCloseError: () => any;
}

const PendingFile: React.FunctionComponent<IProps> = ({
  file,
  onRetryFileUpload,
  onCloseError,
}) => {
  const elRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (elRef.current) {
      elRef.current.scrollIntoView(true);
    }
  });

  return (
    <div
      className="form-group mt-4"
      style={{ textAlign: "center" }}
      ref={elRef}
    >
      <div style={{ position: "relative", display: "inline-block" }}>
        {!file.file.type.startsWith("video") ? (
          <img src={window.URL.createObjectURL(file.file)} width={280} alt="" />
        ) : (
          file.file.name
        )}
        {!file.errorUploading ? (
          <span
            style={{
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "10px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <FontAwesomeIcon icon={faSpinner} size="3x" spin fixedWidth />
            <br />
            {strings.uploading}
          </span>
        ) : null}
      </div>
      {file.errorUploading ? (
        <div className="alert alert-danger" style={{ marginTop: "10px" }}>
          <button type="button" className="close" onClick={onCloseError}>
            &times;
          </button>
          {file.errorUploadingMessage ? (
            file.errorUploadingMessage
          ) : (
            <React.Fragment>
              <p>{strings.uploadFailed}</p>
              <p>{strings.checkInternetConnection}</p>
              <p>
                <a
                  className="alert-link"
                  href="/retry"
                  onClick={(e) => {
                    e.preventDefault();
                    onRetryFileUpload();
                  }}
                >
                  {strings.retry}
                </a>
              </p>
            </React.Fragment>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default PendingFile;
