import React, { useEffect, useRef } from "react";
import { IInvoiceForPaymentForm } from "../models/IInvoiceForPaymentForm";
import { formatCurrency } from "../../../services/currencyFormatter";
import {
  getHeaderForLocationLineItems,
  getInvoicePaymentAmount,
  shouldShowLineItemHeader,
} from "../services/invoiceService";
import RichTextDisplay from "../../richTextDisplay/RichTextDisplay";
import { Attachments } from "./Attachments";
import LineItemDisplay from "./LineItemDisplay";
import { InvoicePaid } from "./InvoicePaid";
import { DisplayHeader } from "./DisplayHeader";
import CustomerSignature from "../../signature/components/CustomerSignature";
import { strings } from "../../../languages";
import { NoteWithHyperlinks } from "../../../components/NoteWithHyperlinks";

interface IProps {
  invoice: IInvoiceForPaymentForm;
  imagePrefix: string;
  signatureLinkPath: string;
  hasMovedFromSignaturePad: boolean;
}

const InvoicePresentation: React.FunctionComponent<IProps> = ({
  invoice,
  imagePrefix,
  signatureLinkPath,
  hasMovedFromSignaturePad,
}) => {
  const customerSignatureContainerRef = useRef<HTMLDivElement>(null);

  const hasScrolled = useRef(false);
  useEffect(() => {
    if (hasMovedFromSignaturePad && !hasScrolled.current) {
      if (customerSignatureContainerRef.current) {
        customerSignatureContainerRef.current.scrollIntoView(true);
        hasScrolled.current = true;
      }
    }
  }, [hasMovedFromSignaturePad]);

  return invoice ? (
    <>
      <div>
        <div>
          <div>
            <DisplayHeader convenienceFeeInUse={false} invoice={invoice} />

            {invoice.paid ? <InvoicePaid /> : null}

            <div className="mt-3 mb-5">
              {!!invoice.introTextHtml ? (
                <div data-testid="introTextContainer">
                  <RichTextDisplay html={invoice.introTextHtml} />
                </div>
              ) : null}

              {!!invoice.summary ? (
                <NoteWithHyperlinks
                  containerClassName="mt-5"
                  testId="summaryContainer"
                  notes={invoice.summary}
                />
              ) : null}
            </div>

            <div>
              {invoice.locationsWithLineItems.map((l) => {
                return (
                  <div className="mt-3" key={l.customerAdditionalLocationId}>
                    {shouldShowLineItemHeader(invoice) ? (
                      <h5
                        data-testid="lineItemHeader"
                        className="font-weight-normal mb-3"
                      >
                        {getHeaderForLocationLineItems(l)}
                      </h5>
                    ) : null}

                    <LineItemDisplay
                      lineItems={l.lineItems}
                      hideLineItemPrices={false}
                    />
                  </div>
                );
              })}

              <div className="mt-3 d-flex justify-content-end">
                <table>
                  <tbody>
                    <tr>
                      <td>{strings.subtotal}: </td>
                      <td className="pl-3 text-right" data-testid="subtotal">
                        {formatCurrency(invoice.subtotal)}
                      </td>
                    </tr>
                    {typeof invoice.discountAmount === "number" ? (
                      <tr>
                        <td>{strings.discount}: </td>
                        <td
                          className="pl-3 text-right"
                          data-testid="discountAmount"
                        >
                          ({formatCurrency(invoice.discountAmount)})
                        </td>
                      </tr>
                    ) : null}

                    <tr>
                      <td>{strings.taxes}: </td>
                      <td className="pl-3 text-right" data-testid="taxAmount">
                        {formatCurrency(invoice.taxAmount ?? 0)}
                      </td>
                    </tr>
                    {(invoice.depositCreditAmount ?? 0) > 0 ? (
                      <>
                        {(invoice.taxAmount ?? 0) > 0 ? (
                          <tr>
                            <td>Total after taxes: </td>
                            <td
                              className="pl-3 text-right"
                              data-testid="totalAfterTaxes"
                            >
                              {formatCurrency(
                                invoice.totalWithoutDepositCredit
                              )}
                            </td>
                          </tr>
                        ) : null}
                        <tr>
                          <td>Prepaid deposit: </td>
                          <td
                            className="pl-3 text-right"
                            data-testid="depositCredit"
                          >
                            ({formatCurrency(invoice.depositCreditAmount ?? 0)})
                          </td>
                        </tr>
                      </>
                    ) : null}
                    <tr>
                      <td>
                        {(invoice.depositCreditAmount ?? 0) > 0
                          ? "Balance due: "
                          : `${strings.total}: `}
                      </td>
                      <td className="pl-3 text-right" data-testid="total">
                        {formatCurrency(
                          getInvoicePaymentAmount(false, invoice)
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <Attachments
                files={invoice.files}
                filePrefix={invoice.filePrefix}
              />

              <hr />

              <div
                ref={customerSignatureContainerRef}
                data-testid="customerSignatureContainer"
              >
                <CustomerSignature
                  imagePrefix={imagePrefix}
                  linkPath={signatureLinkPath}
                  signatureImagePath={invoice.signatureImagePath}
                  signatureExplanation={strings.signatureExplanationInvoice}
                />
              </div>

              <hr />

              {!!invoice.footerTextHtml ? (
                <div data-testid="footerTextContainer" className="mt-3">
                  <RichTextDisplay html={invoice.footerTextHtml} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default InvoicePresentation;
