import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { strings } from "../../languages";

type IProps = {
  backLink: string | null;
  blockBackLink?: boolean;
  hideBackLink?: boolean;
  onBackClick?: () => void;
};

export const GenericFooter: React.FunctionComponent<IProps> = ({
  backLink,
  children,
  blockBackLink,
  hideBackLink,
  onBackClick,
}) => (
  <div
    className="d-flex justify-content-between py-2 px-2"
    style={{
      height: "70x",
      position: "fixed",
      bottom: "0px",
      left: "0px",
      right: "0px",
      borderTop: "1px solid black",
      backgroundColor: "white",
      zIndex: 15,
    }}
  >
    {!hideBackLink && backLink !== null ? (
      <div className="text-center">
        <Link
          to={backLink}
          style={{
            paddingTop: "10px",
          }}
          data-testid="backLink"
          onClick={(e) => {
            if (blockBackLink) {
              e.preventDefault();
            }

            if (typeof onBackClick === "function") {
              onBackClick();
            }
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} size="3x" fixedWidth />
          <div>{strings.back}</div>
        </Link>
      </div>
    ) : null}

    {children}
  </div>
);
