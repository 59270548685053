export type AuthenticationInfo = {
  accessToken: string;
  expiresIn: number;
};

const storageKeyToken = "techapp_auth_token";
const storageKeyExpiresAt = "techapp_auth_expiresat";
export function storeAuthenticationInfo(authInfo: AuthenticationInfo) {
  window.localStorage.setItem(storageKeyToken, authInfo.accessToken);

  const expiresAt = JSON.stringify(
    authInfo.expiresIn * 1000 + new Date().getTime()
  );
  window.localStorage.setItem(storageKeyExpiresAt, expiresAt);
}

export function clearAuthenticationInfo() {
  window.localStorage.removeItem(storageKeyToken);
  window.localStorage.removeItem(storageKeyExpiresAt);
}

export function getAccessToken() {
  return safeGetLocalStorageItem(storageKeyToken);
}

export function hasAuthenticationExpired() {
  const expiresAtUnparsed = safeGetLocalStorageItem(storageKeyExpiresAt);
  if (expiresAtUnparsed === null || expiresAtUnparsed === undefined) {
    return true;
  }

  const expiresAt = JSON.parse(expiresAtUnparsed);
  return new Date().getTime() >= expiresAt;
}

export function logout() {
  // Not swalloing exception here like safeGetLocalStorageItem does
  // User should never be logged in if localStorage disabled
  // and somehow, if they were logout should fail if we try to logout this way
  window.localStorage.removeItem(storageKeyToken);
  window.localStorage.removeItem(storageKeyExpiresAt);
}

function safeGetLocalStorageItem(key: string) {
  try {
    return window.localStorage.getItem(key);
  } catch (err) {
    console.log("error calling localStorage.getItem");
    console.log(err);

    return null;
  }
}
