import React, { useEffect, useRef, useState } from "react";
import {
  components,
  GroupBase,
  OptionProps,
  SelectInstance,
  StylesConfig,
} from "react-select";
import { Spinner } from "reactstrap";
import { getSortedItemsV2 } from "../../../services/sortingService";
import { IInvoiceItem } from "../types/IInvoiceItem";
import WrappedCreatableSelect from "../../input/WrappedCreatableSelect";
import { getNumericString } from "../../typeUtilities/getNumericString";
import { isStringSet } from "../../typeUtilities/isStringSet";
import { DescriptionField, ILineItem } from "./BillableFormLineItem";

type SaveErrorType = "none" | "unknown" | "duplicateName";

export function BillableFormLineItemInvoiceItemField({
  lineItem,
  items,
  onItemAdded,
  onLineItemChanged,
  onClearErrorMessage,
  onItemUpdated,
  setInvoiceItems,
  inputElementId,
  placeHolderText,
  ariaLabel,
  disabled,
  required,
  scrollIntoView,
  labelRef,
  includeDescription,
  descriptionElementId,
}: {
  lineItem: ILineItem;
  items: Array<IInvoiceItem> | null;
  onItemAdded?: (id: string, name: string) => void;
  onLineItemChanged: (updatedItem: ILineItem) => void;
  onItemUpdated: (args: {
    id: string;
    name: string;
    description: string;
    inactive: boolean;
    unitPrice: number | null;
  }) => void;
  onClearErrorMessage: () => void;
  setInvoiceItems: (newValue: Array<IInvoiceItem>) => void;
  inputElementId: string;
  placeHolderText?: string | null;
  ariaLabel?: string;
  disabled: boolean;
  required?: boolean;
  scrollIntoView: boolean;
  labelRef: React.RefObject<HTMLLabelElement> | undefined;
  includeDescription?: boolean;
  descriptionElementId?: string;
}) {
  const selectHeight = "calc(1.5em + 0.75rem + 2px)";
  const selectFontSize = "1rem";

  const isQuickBooksEnabled = false;
  const orderedItems = getOrderedItems(items);

  const { loadingLocalItems, errorLoadingLocalItems, reloadInvoiceItems } =
    useLoadInvoiceItems(items, setInvoiceItems);

  const lineItemSelectRef = useRef<SelectInstance<IInvoiceItem> | null>(null);
  useEffect(() => {
    if (lineItemSelectRef.current?.inputRef) {
      lineItemSelectRef.current.inputRef.setCustomValidity(
        lineItem.itemId || !required ? "" : "Please fill out this field"
      );
    }
  }, [lineItem.itemId, required]);

  const { saveItemErrorType, setSaveItemErrorType, savingItem, saveItem } =
    useSaveItem();

  const saveItemOnComplete = (id: string, name: string) => {
    if (onItemAdded) {
      onItemAdded(id, name);
      onLineItemChanged({
        ...lineItem,
        itemId: id,
        name,
        description: "",
        taxable: false,
      });
    }
  };

  return (
    <div>
      {savingItem ? <Spinner /> : null}
      <div className="mb-3">
        <WrappedCreatableSelect<IInvoiceItem>
          placeholder={placeHolderText}
          styles={getStyles(selectHeight, selectFontSize)}
          selectRef={lineItemSelectRef}
          isDisabled={disabled}
          required={required}
          options={orderedItems.filter(
            (i) => !i.inactive || i.id === lineItem.id
          )}
          isLoading={loadingLocalItems}
          getOptionLabel={(c) => {
            if (
              c.id === lineItem.itemId &&
              isStringSet(lineItem.name ?? null)
            ) {
              return lineItem.name as string;
            }

            return c.name;
          }}
          onMenuOpen={() => {
            if (scrollIntoView) {
              // Call with setTimeout to allow menu to render open
              setTimeout(() => {
                if (labelRef && labelRef.current) {
                  labelRef.current.scrollIntoView({});
                }
              });
            }
          }}
          getOptionValue={(c) => c.id}
          inputId={inputElementId}
          aria-label={ariaLabel}
          onLeaveInputField={(input: string) => {
            let existingItem = getExistingItem(orderedItems, input);
            if (!isQuickBooksEnabled && !existingItem) {
              saveItem({ name: input, onSaveComplete: saveItemOnComplete });
            } else if (existingItem) {
              onLineItemChanged({
                ...lineItem,
                itemId: existingItem.id,
                description: existingItem.name,
                taxable: existingItem.taxable,
              });
            }
          }}
          getNewOptionData={(text) =>
            ({
              name: (text ?? "").trim(),
            } as IInvoiceItem)
          }
          onCreateOption={(input: string) => {
            saveItem({ name: input, onSaveComplete: saveItemOnComplete });
          }}
          isValidNewOption={() => false}
          components={{ Option: OptionComponent }}
          createOptionPosition="first"
          noOptionsMessage={() => "No items exist"}
          isMulti={false}
          onChange={(option) => {
            let id = "";
            let name = "";
            let description = "";
            let taxable = true;
            let amountPerItem = lineItem.amountPerItem;
            if (option) {
              if (Array.isArray(option) && option.length > 0) {
                option = option[0];
              }

              const itemOption = option as IInvoiceItem;
              id = itemOption.id;
              name = itemOption.name;

              if (itemOption.description && itemOption.description.trim()) {
                description = itemOption.description;
              }

              if (!amountPerItem && itemOption.unitPrice) {
                amountPerItem = getNumericString(itemOption.unitPrice);
              }

              taxable = itemOption.taxable;
            }

            onLineItemChanged({
              ...lineItem,
              itemId: id,
              name,
              description,
              taxable,
              amountPerItem,
            });

            onClearErrorMessage();
          }}
          value={orderedItems.find((c) => c.id === lineItem.itemId)}
        />

        {errorLoadingLocalItems ? (
          <>
            <div className="text-danger">
              There was an error loading invoice items, please check your
              Internet connection.
            </div>
            <div>
              <button
                type="button"
                className="btn btn-link p-0"
                onClick={() => reloadInvoiceItems()}
              >
                Try Again
              </button>
            </div>
          </>
        ) : null}

        {includeDescription ? (
          <div className="mt-2">
            <DescriptionField
              lineItem={lineItem}
              onLineItemChanged={(updatedItem) => {
                onLineItemChanged(updatedItem);
              }}
              inputElementId={descriptionElementId ?? "descriptionInput"}
              disabled={disabled}
            />
          </div>
        ) : null}
      </div>

      {saveItemErrorType !== "none" ? (
        <div className="text-danger">
          {saveItemErrorType === "duplicateName"
            ? "The same item was just added by another user. Please reload the items and select the item."
            : "There was an error saving the new item. Please try again."}
          {saveItemErrorType === "duplicateName" ? (
            <div>
              <button
                type="button"
                className="btn btn-link p-0"
                onClick={() => {
                  reloadInvoiceItems();
                  setSaveItemErrorType("none");
                }}
              >
                Reload Items
              </button>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

function useSaveItem() {
  const [saveItemErrorType, setSaveItemErrorType] =
    useState<SaveErrorType>("none");
  const [savingItem, setSavingItem] = useState(false);
  const saveItem = ({
    name,
    onSaveComplete,
  }: {
    name: string;
    onSaveComplete(id: string, name: string): void;
  }) => {
    setSavingItem(true);
    setSaveItemErrorType("none");

    name = name.trim();
  };

  return { setSaveItemErrorType, saveItemErrorType, savingItem, saveItem };
}

function getExistingItem(items: IInvoiceItem[], invoiceItemName: string) {
  return items.find(
    (c) => c.name.trim().toLowerCase() === invoiceItemName.trim().toLowerCase()
  );
}

function useLoadInvoiceItems(
  items: IInvoiceItem[] | null,
  setInvoiceItems: (newValue: Array<IInvoiceItem>) => void
) {
  const [loadingLocalItems, setLoadingLocalItems] = useState(false);
  const [errorLoadingLocalItems, setErrorLoadingLocalItems] = useState(false);
  useEffect(() => {
    if (!items && !loadingLocalItems && !errorLoadingLocalItems) {
      loadInvoiceItems({
        setLoadingLocalItems,
        isQuickBooksEnabled: false,
        setInvoiceItems,
        setErrorLoadingLocalItems,
      });
    }
  }, [items, errorLoadingLocalItems, loadingLocalItems, setInvoiceItems]);

  const reloadInvoiceItems = () => {
    loadInvoiceItems({
      setLoadingLocalItems,
      isQuickBooksEnabled: false,
      setInvoiceItems,
      setErrorLoadingLocalItems,
    });
  };

  return { loadingLocalItems, errorLoadingLocalItems, reloadInvoiceItems };
}

function loadInvoiceItems({
  setLoadingLocalItems,
  isQuickBooksEnabled,
  setInvoiceItems,
  setErrorLoadingLocalItems,
}: {
  setLoadingLocalItems: React.Dispatch<React.SetStateAction<boolean>>;
  isQuickBooksEnabled: boolean;
  setInvoiceItems: (newValue: Array<IInvoiceItem>) => void;
  setErrorLoadingLocalItems: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  setLoadingLocalItems(true);
  setErrorLoadingLocalItems(false);
}

function getOrderedItems(items: IInvoiceItem[] | null) {
  return getSortedItemsV2(items ?? [], ["name"]);
}

function OptionComponent(
  props: OptionProps<IInvoiceItem, false, GroupBase<IInvoiceItem>>
) {
  const invoiceItem = props.data as IInvoiceItem;
  const text = invoiceItem.name;

  return (
    <components.Option {...props}>
      <div>
        {!invoiceItem.id ? <strong>New item: </strong> : null}
        <span data-testid="itemOption">{text}</span>
      </div>
    </components.Option>
  );
}

function getStyles(selectHeight: string, selectFontSize: string) {
  return {
    menu: (provided) => ({
      ...provided,
      zIndex: 50,
    }),

    valueContainer: (provided) => ({
      ...provided,
      height: selectHeight,
      padding: "0 6px",
    }),

    option: (provided) => ({
      ...provided,
      fontSize: selectFontSize,
    }),

    input: (provided) => ({
      ...provided,
      fontSize: "1rem",
      margin: "0px",
    }),

    indicatorSeparator: () => ({
      display: "none",
    }),

    indicatorsContainer: (provided) => ({
      ...provided,
      height: selectHeight,
    }),

    control: (provided) => {
      return {
        ...provided,
        minHeight: selectHeight,
        height: selectHeight,
        padding: 0,
        fontSize: selectFontSize,
        lineHeight: 1.5,
        borderRadius: ".2rem",
      };
    },
  } as StylesConfig<IInvoiceItem>;
}
