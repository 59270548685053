import { ILineItem } from "../components/BillableFormLineItem";
import { IBillableItemForFormLineItem } from "../types/IBillableItemForFormLineItem";

export function getLineItemsForFormData(
  lineItems: Array<Omit<IBillableItemForFormLineItem, "name">>
): Array<ILineItem> {
  return lineItems.length > 0
    ? lineItems.map((li) => ({
        ...li,
        quantity: typeof li.quantity === "number" ? li.quantity.toString() : "",
        amountPerItem:
          typeof li.amount === "number" ? li.amount.toString() : "",
      }))
    : [];
}
