import { Link } from "react-router-dom";
import ISchedule from "../../models/ISchedule";
import { IScheduleIdentifier } from "../../models/IScheduleIdentifier";
import IScheduledJob from "../../models/IScheduleJob";
import { routingBuilders } from "../../services/routing";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faMessage } from "@fortawesome/free-solid-svg-icons";
import { strings } from "../../languages";

interface IProps {
  schedule: ISchedule;
  scheduledJob: IScheduledJob;
  scheduleIdentifier: IScheduleIdentifier;
  showCustomerJobHistory: boolean;
  setShowOptInAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

export const JobHeaderActionButtons: React.FunctionComponent<IProps> = ({
  schedule,
  scheduledJob,
  scheduleIdentifier,
  showCustomerJobHistory,
  setShowOptInAlert,
}) => {
  return (
    <>
      {showCustomerJobHistory ? (
        <Link
          key="jobHistory"
          className="mb-2 mr-1 btn btn-secondary btn-sm"
          data-testid="jobHistoryLink"
          to={routingBuilders.buildCustomerJobHistoryPath({
            scheduleIdentifier: scheduleIdentifier,
            scheduleJobId: scheduledJob.id,
          })}
        >
          <small>
            <FontAwesomeIcon icon={faBook} className="pr-1" fixedWidth />
            {strings.viewJobHistory}
          </small>
        </Link>
      ) : null}
      {schedule.allowOnTheWayNotification && scheduledJob.phoneNumber ? (
        <Link
          key="onTheWayLink"
          className="mb-2 btn btn-secondary btn-sm"
          data-testid="onTheWayLink"
          to={
            scheduledJob.hasOptedIntoSMS
              ? routingBuilders.buildNotifyOnTheWayPath({
                  scheduleIdentifier: scheduleIdentifier,
                  scheduleJobId: scheduledJob.id,
                })
              : "#"
          }
          onClick={() => {
            if (!scheduledJob.hasOptedIntoSMS) {
              setShowOptInAlert(true);
            }
          }}
        >
          <small>
            <FontAwesomeIcon icon={faMessage} className="pr-1" fixedWidth />
            {strings.notifyOnTheWay}
          </small>
        </Link>
      ) : null}
    </>
  );
};
