import * as React from "react";
import dataProvider from "../../../services/DataProvider";
import Spinner from "../../Spinner";
import IScheduledJob from "../../../models/IScheduleJob";
import { IFile } from "../../../models/IFile";
import Alert from "../../Alert";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import deletePhoto from "../../../delete-photo.png";
import {
  isImageByType,
  getFileName,
} from "../../../libraries/formAttachments/services/fileService";
import { getErrorMessage } from "../../../services/errorHandlerService";
import { Language, strings } from "../../../languages";
import EditableTextField from "../../EditableTextField";
import { AutoSaveField } from "../../../models/IAutoSave";
import ISchedule from "../../../models/ISchedule";
import { isStringSet } from "../../../libraries/typeUtilities/isStringSet";
import { parseISO } from "date-fns";
import { formatDateTimeForDateTimeDisplay } from "../../../services/dateTimeService";

interface IProps {
  imagePrefix: string;
  schedule: ISchedule;
  scheduledJob: IScheduledJob;
  file: IFile;
  onFileRemoved: (photoId: string) => void;
  onFileUpdated: (photoId: string, caption: string) => void;
  language: Language;
}

interface IState {
  imageLoaded: boolean;
  saving: boolean;
  showDeleteConfirmation: boolean;
  error: JSX.Element | null;
  editedCaption: string | null;
}

class ExistingFile extends React.Component<IProps, IState> {
  private elRef: React.RefObject<HTMLDivElement>;

  constructor(props: IProps) {
    super(props);

    this.state = {
      imageLoaded: false,
      saving: false,
      showDeleteConfirmation: false,
      error: null,
      editedCaption: null,
    };

    this.updateCaption = this.updateCaption.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.elRef = React.createRef<HTMLDivElement>();
  }

  public render() {
    const { file, language } = this.props;

    let fileElement: JSX.Element;
    if (isImageByType(file.contentType)) {
      const thumbnailKey = 280;
      const thumbnail = dataProvider.getPhotoUrl(
        this.props.imagePrefix,
        thumbnailKey,
        file
      );

      let url: string;
      let width: number | undefined = undefined;
      let height: number | undefined = undefined;
      if (thumbnail) {
        url = thumbnail.url;
        width = thumbnail.width;
        height = thumbnail.height;
      } else {
        return null;
      }

      fileElement = (
        <React.Fragment>
          {/* <a target="_blank" href={dataProvider.getPhotoImageSource(photo.id)}> */}
          <div style={{ position: "relative", display: "inline-block" }}>
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`/photo/${this.props.scheduledJob.id}/${file.id}`}
              >
                <img
                  src={url}
                  height={height}
                  width={width}
                  onLoad={() => this.setState({ imageLoaded: true })}
                  onError={() => this.setState({ imageLoaded: true })}
                  alt=""
                />
              </a>
              {this.state.imageLoaded ? (
                <span
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({
                      showDeleteConfirmation: true,
                    });
                  }}
                >
                  <img src={deletePhoto} alt="" />
                </span>
              ) : null}
            </div>
            {isStringSet(file.timestamp) ? (
              <div data-testid="timestampContainer">
                {formatDateTimeForDateTimeDisplay(
                  parseISO(file.timestamp),
                  language
                )}
              </div>
            ) : null}
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`/photo/${this.props.scheduledJob.id}/${file.id}`}
              >
                {strings.viewFullImage}
              </a>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      fileElement = (
        <React.Fragment>
          <a
            href={`${this.props.imagePrefix}/${file.imagePath}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "underline",
              fontWeight: "bold",
            }}
          >
            {getFileName(file.imagePath)}
          </a>
          {file.caption ? <span> - {file.caption}</span> : null}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {this.state.saving ? <Spinner /> : null}
        {this.state.error !== null ? (
          <Alert
            type="alert-danger"
            message={this.state.error}
            onClose={() => {
              this.setState({
                error: null,
              });
            }}
          />
        ) : null}
        <div
          className="form-group mt-4"
          style={{ textAlign: "center", marginBottom: "0px" }}
        >
          {fileElement}
        </div>

        <EditableTextField
          label={""}
          initialValue={file.caption}
          jobInstanceId={this.props.scheduledJob.id}
          autoSaveField={AutoSaveField.PhotoCaption}
          childId={this.props.file.id}
          onSaveComplete={(value) => {
            this.props.onFileUpdated(this.props.file.id, value);
          }}
          placeholder={`Enter ${
            this.props.file.contentType.startsWith("video") ? "video" : "photo"
          } notes here`}
        />
        <Modal
          isOpen={this.state.showDeleteConfirmation}
          centered={true}
          toggle={() => {
            this.setState({
              showDeleteConfirmation: false,
            });
          }}
        >
          <ModalBody>{strings.deletePhoto}</ModalBody>
          <ModalFooter>
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => {
                this.setState({
                  showDeleteConfirmation: false,
                });
                this.deleteFile();
              }}
            >
              {strings.delete}
            </button>{" "}
            <button
              className="btn btn-secondary"
              onClick={() => {
                this.setState({
                  showDeleteConfirmation: false,
                });
              }}
              type="button"
            >
              {strings.cancel}
            </button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }

  private updateCaption() {
    this.setState({
      saving: true,
    });

    if (typeof this.state.editedCaption === "string") {
      const photoId = this.props.file.id;
      const caption = this.state.editedCaption;
      dataProvider
        .updatePhoto(this.props.scheduledJob.id, photoId, {
          caption,
        })
        .subscribe(
          () => {
            this.setState({ saving: false, editedCaption: null });

            this.props.onFileUpdated(this.props.file.id, caption);
          },
          (error) => {
            const errorMessage = getErrorMessage(error);
            this.setState({
              error: errorMessage,
              saving: false,
            });
          }
        );
    }
  }

  private deleteFile() {
    this.setState({
      saving: true,
    });

    const photoId = this.props.file.id;
    dataProvider.deletePhoto(this.props.scheduledJob.id, photoId).subscribe(
      () => {
        this.setState({ saving: false });

        this.props.onFileRemoved(photoId);
      },
      (error) => {
        console.log(error);

        const errorMessage = getErrorMessage(
          error,
          <span>
            {strings.deleteFailed}
            <br />
            <br />
            {strings.checkInternetConnection}
          </span>
        );

        this.setState({
          error: errorMessage,
          saving: false,
        });
      }
    );
  }
}

export default ExistingFile;
