import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { IconWithCaption } from "./IconWithCaption";

export function FooterButton({
  onClick,
  icon,
  caption,
  color,
  testId,
  className,
  type,
  formId,
}: {
  onClick?(): void;
  icon: IconProp;
  caption: string;
  color: "text-primary" | "text-success" | "text-danger";
  testId?: string;
  className?: string;
  type?: "submit" | "button";
  formId?: string;
}) {
  return (
    <button
      className={`btn ${color} p-0 ${className ?? ""}`}
      onClick={onClick}
      data-testid={testId}
      type={type ?? "button"}
      form={formId}
    >
      <IconWithCaption icon={icon} caption={caption} />
    </button>
  );
}
