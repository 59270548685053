import { useContext } from "react";
import { strings } from "../../../languages";
import { formatCurrency } from "../../../services/currencyFormatter";
import { formatDateForDisplay } from "../../../services/dateTimeService";
import { IInvoiceForPaymentForm } from "../models/IInvoiceForPaymentForm";
import { getInvoicePaymentAmount } from "../services/invoiceService";
import { ConvenienceFeeInUseAlert } from "./ConvenienceFeeInUseAlert";
import { LanguageContext } from "../../../App";

export function DisplayHeader({
  convenienceFeeInUse,
  invoice,
}: {
  convenienceFeeInUse: boolean;
  invoice: IInvoiceForPaymentForm;
}) {
  const languageContext = useContext(LanguageContext);
  const breakpoint = "sm";

  const convenienceFeeContent = convenienceFeeInUse ? (
    <ConvenienceFeeInUseAlert invoice={invoice} />
  ) : null;

  return (
    <div className="d-flex justify-content-between">
      <div>
        <div
          className="d-flex flex-wrap"
          style={{ columnGap: "20px", rowGap: "10px" }}
        >
          <div className="row">
            <div className={`col-12 col-${breakpoint}-6`}>
              <div>
                <label className="text-nowrap" style={{ marginBottom: 0 }}>
                  {strings.paymentAmount}:
                </label>
                <h2 data-testid="paymentAmount">
                  {formatCurrency(
                    getInvoicePaymentAmount(convenienceFeeInUse, invoice)
                  )}
                </h2>
              </div>
              <div className={`d-block d-${breakpoint}-none`}>
                {convenienceFeeContent}
              </div>
            </div>
            {invoice.dueDate ? (
              <div
                className={`col-12 col-${breakpoint}-6 mt-2 mt-${breakpoint}-0`}
                data-testid="dueDate"
              >
                <label className="mb-0">{strings.dueDate}</label>
                <h2>
                  {formatDateForDisplay(
                    invoice.dueDate,
                    languageContext.language
                  )}
                </h2>
              </div>
            ) : null}
          </div>
        </div>
        <div className={`d-none d-${breakpoint}-block`}>
          {convenienceFeeContent}
        </div>
      </div>
      <div className="text-right">
        {invoice.number ? (
          <h4 data-testid="invoiceNumber">
            {strings.invoice} #{invoice.number}
          </h4>
        ) : null}

        {invoice.purchaseOrderNumber ? (
          <h4 data-testid="purchaseOrderNumber">
            PO #{invoice.purchaseOrderNumber}
          </h4>
        ) : null}
      </div>
    </div>
  );
}
