import { IInvoiceForPaymentForm } from "../models/IInvoiceForPaymentForm";
import { IZonedDateTime } from "../models/IZonedDateTime";
import PaymentMethodSelection from "./PaymentMethodSelection";
import InvoicePayFormBankAccount from "./InvoicePayFormBankAccount";
import InvoicePayFormCreditCardFields from "./InvoicePayFormCreditCardFields";
import InvoicePayFormOfflinePayment from "./InvoicePayFormOfflinePayment";
import { IScheduleIdentifier } from "../../../models/IScheduleIdentifier";
import { getParsedPaymentType } from "../enums/paymentType";
import { InvoiceType } from "../../../enums/InvoiceType";

interface IProps {
  invoice: IInvoiceForPaymentForm;
  onSave: (saveResult: {
    transactionDateTime: IZonedDateTime | null;
    convenienceFeePaid: number | null;
    paymentMethodAuthorized: boolean;
  }) => void;
  paymentType: string;
  onPaymentTypeChanged: (newValue: string) => void;
  proposalPaymentMethodCapture?: boolean | null;
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
  invoiceType: InvoiceType;
}

const InvoicePay: React.FunctionComponent<IProps> = ({
  invoice,
  onSave,
  paymentType,
  onPaymentTypeChanged,
  proposalPaymentMethodCapture,
  scheduleIdentifier,
  jobInstanceId,
  invoiceType,
}) => {
  return (
    <>
      <PaymentMethodSelection
        allowAch={invoice.allowAch}
        allowCreditCard={invoice.allowCreditCard}
        bankAccountElement={
          <InvoicePayFormBankAccount
            onSave={onSave}
            scheduleIdentifier={scheduleIdentifier}
            jobInstanceId={jobInstanceId}
            invoice={invoice}
            paymentAmount={invoice.total}
            invoiceType={invoiceType}
          />
        }
        creditCardElement={
          <InvoicePayFormCreditCardFields
            onSave={onSave}
            invoice={invoice}
            paymentAmount={invoice.balanceForCreditCard}
            proposalPaymentMethodCapture={proposalPaymentMethodCapture}
            scheduleIdentifier={scheduleIdentifier}
            jobInstanceId={jobInstanceId}
            invoiceType={invoiceType}
          />
        }
        offlinePaymentElement={
          <InvoicePayFormOfflinePayment
            onSave={onSave}
            scheduleIdentifier={scheduleIdentifier}
            jobInstanceId={jobInstanceId}
            paymentMethodType={getParsedPaymentType(paymentType)}
            invoice={invoice}
            paymentAmount={invoice.total}
            invoiceType={invoiceType}
          />
        }
        paymentType={paymentType}
        onPaymentTypeChanged={onPaymentTypeChanged}
      />
    </>
  );
};

export default InvoicePay;
