import React from "react";
import ITodoItem from "../../models/ITodoItem";
import { strings } from "../../languages";

interface IProps {
  todoItems: Array<ITodoItem>;
  onChecked: (todoItemId: string, checked: boolean) => void;
}

export const TodoItems: React.FunctionComponent<IProps> = ({
  todoItems,
  onChecked,
}) => {
  return todoItems.length > 0 ? (
    <div style={{ marginTop: "20px" }} className="form-group">
      <h4>{strings.checklist}: </h4>
      {todoItems.map((todoItem) => (
        <div
          key={todoItem.id}
          style={{ marginBottom: "25px" }}
          className="custom-control-lg custom-control custom-checkbox"
        >
          <input
            type="checkbox"
            className="custom-control-input"
            id={todoItem.id}
            checked={todoItem.completed}
            onChange={(e) => onChecked(todoItem.id, e.target.checked)}
          />
          <label className="custom-control-label" htmlFor={todoItem.id}>
            {todoItem.text}
          </label>
        </div>
      ))}
    </div>
  ) : null;
};
