export enum SettingsType {
  language,
}

const fallbackStorage: EnumDictionary<SettingsType, string | null> = {
  [SettingsType.language]: null,
};

export function setSetting(settingsType: SettingsType, value: string) {
  try {
    window.localStorage.setItem("language", value);
  } catch (err) {
    console.log(err);
    fallbackStorage[settingsType] = value;
  }
}

export function getSetting(settingsType: SettingsType) {
  try {
    return window.localStorage ? window.localStorage.getItem("language") : null;
  } catch (err) {
    console.log(err);
    return fallbackStorage[settingsType] ?? null;
  }
}

type EnumDictionary<T extends string | symbol | number, U> = {
  [K in T]: U;
};
