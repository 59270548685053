import { round } from "../math/round";
import { getLineItemSubtotal } from "./getLineItemSubtotal";
import { IAmountCalculationLineItem } from "./IAmountCalculationLineItem";

export function getSubtotal(lineItems: Array<IAmountCalculationLineItem>) {
  if (lineItems.length === 0) {
    return 0;
  }

  return round(
    lineItems.reduce((sum, li) => sum + getLineItemSubtotal(li), 0),
    2
  );
}
