import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FooterButton } from "../../../components/FooterButton";
import { logout } from "../services/authentication";
import { routingBuilders } from "../../../services/routing";
import { useHistory } from "react-router-dom";
import { strings } from "../../../languages";
import { useState } from "react";
import Modal from "reactstrap/lib/Modal";
import ModalFooter from "reactstrap/lib/ModalFooter";
import ModalBody from "reactstrap/lib/ModalBody";

export function LogoutFooterButton() {
  const history = useHistory();
  const [showConfirmation, setShowConfirmation] = useState(false);

  return (
    <>
      <FooterButton
        caption={strings.logout}
        icon={faSignOut}
        color="text-primary"
        onClick={() => {
          setShowConfirmation(true);
        }}
      />
      {showConfirmation ? (
        <Modal isOpen>
          <ModalBody>{strings.areYouSureYouWantToLogout}</ModalBody>
          <ModalFooter>
            <button
              className="btn btn-primary mr-2"
              onClick={() => {
                logout();
                history.push(routingBuilders.buildLoginPath());
                setShowConfirmation(false);
              }}
            >
              {strings.yes}
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => {
                setShowConfirmation(false);
              }}
            >
              {strings.no}
            </button>
          </ModalFooter>
        </Modal>
      ) : null}
    </>
  );
}
