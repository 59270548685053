import * as React from "react";
import ISchedule from "../models/ISchedule";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import { es } from "date-fns/locale";
import { useContext } from "react";
import { LanguageContext } from "../App";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { routingBuilders } from "../services/routing";
import { IScheduleIdentifier } from "../models/IScheduleIdentifier";
import { getDayHeader } from "./Container.functions";

interface IProps {
  schedule: ISchedule;
  scheduleIdentifier: IScheduleIdentifier;
  isJobListing: boolean;
  headerOverride?: string;
  hideLanguageToggle?: boolean;
}

const Container: React.FunctionComponent<IProps> = ({
  schedule,
  scheduleIdentifier,
  isJobListing,
  children,
  headerOverride,
  hideLanguageToggle,
}) => {
  const languageContext = useContext(LanguageContext);

  let formatArg: any;
  if (languageContext.language === "es") {
    formatArg = { locale: es };
  }

  const formattedDate = format(
    parseISO(schedule.date),
    "EEEE - M/d",
    formatArg
  );

  const dayHeader = getDayHeader(schedule);

  let headerContents: JSX.Element | string =
    typeof headerOverride === "string" ? (
      <div style={{ whiteSpace: "pre-line" }}>{headerOverride}</div>
    ) : (
      <>
        {schedule.crewName}
        <br />
        {firstCharacterToUppercase(formattedDate)}
        {dayHeader && isJobListing ? (
          <>
            <br />
            <span className="text-danger">{dayHeader}</span>
          </>
        ) : null}
      </>
    );

  const header = (
    <h1 className="text-center" style={{ gridColumnStart: 2 }}>
      {headerContents}
    </h1>
  );

  const allowScheduleNavigation =
    isJobListing &&
    (schedule.allowPreviousDaysScheduleLink ||
      schedule.allowNextDaysScheduleLink ||
      scheduleIdentifier.dayOffset > 0);

  return (
    <div style={{ margin: "15px" }}>
      {!allowScheduleNavigation ? (
        <div>{header}</div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              visibility:
                scheduleIdentifier.dayOffset > 0 ||
                schedule.allowPreviousDaysScheduleLink
                  ? undefined
                  : "hidden",
            }}
          >
            <Link
              to={routingBuilders.buildSchedulePath({
                scheduleIdentifier: {
                  ...scheduleIdentifier,
                  dayOffset: scheduleIdentifier.dayOffset - 1,
                },
              })}
            >
              <button
                type="button"
                className="btn btn-link"
                aria-label="Previous days schedule"
              >
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  size="2x"
                  aria-hidden="true"
                />
              </button>
            </Link>
          </div>
          <div>{header}</div>
          <div
            style={{
              visibility: !schedule.allowNextDaysScheduleLink
                ? "hidden"
                : undefined,
            }}
          >
            <Link
              to={routingBuilders.buildSchedulePath({
                scheduleIdentifier: {
                  ...scheduleIdentifier,
                  dayOffset: scheduleIdentifier.dayOffset + 1,
                },
              })}
            >
              <button
                type="button"
                className="btn btn-link"
                aria-label="Next days schedule"
              >
                <FontAwesomeIcon
                  icon={faChevronRight}
                  size="2x"
                  aria-hidden="true"
                />
              </button>
            </Link>
          </div>
        </div>
      )}
      {!hideLanguageToggle ? (
        <div className="text-center">
          <button
            className="btn btn-link"
            onClick={() => {
              if (languageContext.language === "en") {
                languageContext.setLanguage("es");
              } else {
                languageContext.setLanguage("en");
              }
            }}
          >
            {languageContext.language === "en" ? "Español" : "English"}
          </button>
        </div>
      ) : null}

      {children}
    </div>
  );
};

export default Container;

function firstCharacterToUppercase(input: string) {
  if (!input || input.length < 2) {
    return input;
  }

  return input.substring(0, 1).toLocaleUpperCase() + input.substring(1);
}
