import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import { createEmailAddressRecord } from "./EmailAddresses.functions";
import { IEmailAddressRecord } from "./IEmailAddressRecord";
import { strings } from "../../languages";

export function EmailAddresses({
  label,
  emailAddresses,
  onChange,
  required,
  disabled,
  lastEmailAddressInputRef,
}: {
  label: string;
  emailAddresses: Array<IEmailAddressRecord>;
  onChange(newEmailAddress: Array<IEmailAddressRecord>): void;
  required: boolean;
  disabled?: boolean;
  lastEmailAddressInputRef?: React.MutableRefObject<HTMLInputElement | null>;
}) {
  const lastEmailAddressRef = useRef<HTMLInputElement | null>(null);
  const hasMultipleEmailAddresses = emailAddresses.length > 1;

  return (
    <>
      {emailAddresses.map(({ id, value }, index) => {
        const lastItem = index === emailAddresses.length - 1;
        return (
          <div
            key={`recipientEmailAddress_${id}`}
            className={`input-group ${!lastItem ? "mb-3" : ""}`}
          >
            <input
              aria-label={label}
              data-testid="recipientEmailAddress"
              id="recipientEmailAddress"
              type="email"
              name="recipientEmailAddress"
              value={value}
              className="form-control"
              ref={
                lastItem
                  ? (r) => {
                      lastEmailAddressRef.current = r;
                      if (lastEmailAddressInputRef) {
                        lastEmailAddressInputRef.current = r;
                      }
                    }
                  : null
              }
              required={required && !disabled}
              disabled={disabled}
              onChange={(e) => {
                onChange(
                  emailAddresses.map((emailAddressToMap) => {
                    if (emailAddressToMap.id === id) {
                      return {
                        ...emailAddressToMap,
                        value: e.currentTarget.value,
                      };
                    } else {
                      return emailAddressToMap;
                    }
                  })
                );
              }}
            />
            {hasMultipleEmailAddresses ? (
              <div className="input-group-append">
                <button
                  data-testid="removeRecipientEmailAddress"
                  type="button"
                  className="btn btn-sm text-right pr-0"
                  onClick={() => {
                    onChange(
                      emailAddresses.filter(
                        (emailAddress) => emailAddress.id !== id
                      )
                    );
                  }}
                  style={{ zIndex: 0 }}
                  disabled={disabled}
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    title="Remove email address"
                  />
                </button>
              </div>
            ) : null}
          </div>
        );
      })}
      <div className="text-center">
        {!disabled ? (
          <button
            type="button"
            className="btn btn-link"
            onClick={() => {
              onChange([...emailAddresses, createEmailAddressRecord("")]);

              setTimeout(() => {
                if (lastEmailAddressRef.current) {
                  lastEmailAddressRef.current.focus();
                }
              });
            }}
            style={{ padding: 0 }}
            data-testid="addEmailAddressButton"
          >
            {strings.addEmailAddress}
          </button>
        ) : null}
      </div>
    </>
  );
}
