import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties, useRef } from "react";
import { BillableFormLineItemInvoiceItemField } from "./BillableFormLineItemInvoiceItemField";
import { IInvoiceItem } from "../types/IInvoiceItem";
import ResponsiveGridTable from "../../responsiveGrid/ResponsiveGridTable";
import { getNumericString } from "../../typeUtilities/getNumericString";
import {
  ILineItem,
  QuantityField,
  AmountPerItemField,
  CheckboxField,
  DeleteLineItemButton,
} from "./BillableFormLineItem";
import { strings } from "../../../languages";
import { createNewLineItem } from "../functions/createNewLineItem";

interface IProps {
  lineItems: Array<ILineItem>;
  invoiceItems: Array<IInvoiceItem> | null;
  onClearErrorMessage: () => void;
  elementIdPrefix: string;
  noLineItemMessage?: string;

  setLineItems: (newLineItems: Array<ILineItem>) => void;
  setInvoiceItems: (newValue: Array<IInvoiceItem>) => void;

  allowBlankQuantity?: boolean;
  allowBlankAmountPerItem?: boolean;
  allowZeroLineItems?: boolean;
  allowZeroQuantity: boolean;
  disabled: boolean;
  enableRequiredFields?: boolean;
  hideTaxableField: boolean;
  allowOptionalItems?: boolean;
}

const BillableFormLineItemsFullWidth: React.FunctionComponent<IProps> = ({
  lineItems,
  invoiceItems,
  setLineItems,
  setInvoiceItems,
  onClearErrorMessage,
  elementIdPrefix,
  noLineItemMessage,

  allowBlankAmountPerItem,
  allowBlankQuantity,
  allowZeroLineItems,
  allowZeroQuantity,

  disabled,

  enableRequiredFields,
  hideTaxableField,
  allowOptionalItems,
}) => {
  const buildOnLineItemChanged = (li: ILineItem) => {
    return (newLineItem: ILineItem) => {
      setLineItems(
        lineItems.map((lineItemToUpdate) => {
          if (lineItemToUpdate.id === li.id) {
            return newLineItem;
          } else {
            return lineItemToUpdate;
          }
        })
      );
    };
  };

  const isDeleteAllowed =
    (lineItems.length > 1 || allowZeroLineItems) && !disabled;

  return (
    <>
      <div>
        <h4>{strings.lineItems}</h4>
      </div>

      {lineItems.length > 0 ? (
        <ResponsiveGridTable
          breakpoint="md"
          mobileHeader={(lineItem, index) => {
            return (
              <MobileHeader
                lineItems={lineItems}
                index={index}
                setLineItems={setLineItems}
                isDeleteAllowed={isDeleteAllowed}
                lineItem={lineItem}
                disabled={disabled}
              />
            );
          }}
          columns={[
            {
              key: "reorder",
              header: () => "",
              cell: ({ index, displayType }) =>
                lineItems.length > 1 && displayType === "desktop" ? (
                  <ReorderButtons
                    index={index}
                    lineItems={lineItems}
                    setLineItems={setLineItems}
                  />
                ) : null,
              width: "min-content",
              hidden: disabled,
            },
            {
              header: ({ displayType, index, labelRef }) => (
                <label
                  htmlFor={getInputElementId({
                    property: "item",
                    index: index ?? 0,
                    displayType,
                  })}
                  className="required"
                  ref={labelRef}
                >
                  {strings.productService}
                </label>
              ),
              key: "item",
              width: "auto",
              cell: ({ rowObject: lineItem, index, displayType, labelRef }) => {
                return (
                  <BillableFormLineItemInvoiceItemField
                    lineItem={lineItem}
                    onLineItemChanged={buildOnLineItemChanged(lineItem)}
                    items={invoiceItems}
                    onClearErrorMessage={onClearErrorMessage}
                    setInvoiceItems={setInvoiceItems}
                    disabled={disabled}
                    required={enableRequiredFields}
                    labelRef={labelRef}
                    inputElementId={getInputElementId({
                      property: "item",
                      index: index ?? 0,
                      displayType,
                    })}
                    scrollIntoView={displayType === "mobile"}
                    includeDescription={true}
                    descriptionElementId={getInputElementId({
                      property: "description",
                      index: index ?? 0,
                      displayType,
                    })}
                    onItemAdded={(id, name) => {
                      setInvoiceItems([
                        ...(invoiceItems ?? []),
                        {
                          id,
                          name,
                          description: "",
                          taxable: false,
                          unitPrice: null,
                          inactive: false,
                        },
                      ]);
                    }}
                    onItemUpdated={({
                      id,
                      name,
                      description,
                      inactive,
                      unitPrice,
                    }) => {
                      const originalInvoiceItem = (invoiceItems ?? []).find(
                        (i) => i.id === id
                      );

                      let updatedLineItems = lineItems.map(
                        (lineItemToUpdate) => {
                          if (lineItemToUpdate.itemId === id) {
                            return {
                              ...lineItemToUpdate,
                              name,
                              amountPerItem:
                                (!lineItemToUpdate.amountPerItem &&
                                  unitPrice) ||
                                (originalInvoiceItem?.unitPrice &&
                                  lineItemToUpdate.amountPerItem ===
                                    originalInvoiceItem?.unitPrice.toString())
                                  ? getNumericString(unitPrice)
                                  : lineItemToUpdate.amountPerItem,
                            };
                          }

                          return lineItemToUpdate;
                        }
                      );

                      if (
                        originalInvoiceItem &&
                        originalInvoiceItem?.description !== description
                      ) {
                        updatedLineItems = updatedLineItems.map(
                          (lineItemToUpdate) => {
                            if (
                              lineItemToUpdate.itemId === id &&
                              lineItemToUpdate.description ===
                                originalInvoiceItem.description
                            ) {
                              return {
                                ...lineItemToUpdate,
                                description: description,
                              };
                            }

                            return lineItemToUpdate;
                          }
                        );
                      }

                      setLineItems(updatedLineItems);

                      setInvoiceItems(
                        (invoiceItems ?? []).map((i) => {
                          if (i.id === id) {
                            return {
                              ...i,
                              name,
                              description,
                              inactive,
                              unitPrice,
                            };
                          }

                          return i;
                        })
                      );
                    }}
                  />
                );
              },
            },
            {
              header: ({ displayType, index }) => (
                <label
                  htmlFor={getInputElementId({
                    property: "optional",
                    index: index ?? 0,
                    displayType,
                  })}
                  className="mr-2"
                >
                  {strings.optional}
                </label>
              ),
              key: "optional",
              width: "min-content",
              hidden: !allowOptionalItems,
              cell: ({ rowObject: lineItem, index, displayType }) => {
                return (
                  <CheckboxField
                    property={"optional"}
                    inputElementId={getInputElementId({
                      property: "optional",
                      index: index ?? 0,
                      displayType,
                    })}
                    lineItem={lineItem}
                    onLineItemChanged={buildOnLineItemChanged(lineItem)}
                  />
                );
              },
            },
            {
              header: ({ displayType, index }) => (
                <label
                  htmlFor={getInputElementId({
                    property: "quantity",
                    index: index ?? 0,
                    displayType,
                  })}
                  className={`${allowBlankQuantity ? "" : "required"} mr-2`}
                >
                  {strings.quantity}
                </label>
              ),
              key: "quantity",
              width: "min-content",
              cell: ({ rowObject: lineItem, index, displayType }) => {
                return (
                  <QuantityField
                    allowBlankQuantity={allowBlankQuantity}
                    lineItem={lineItem}
                    onLineItemChanged={buildOnLineItemChanged(lineItem)}
                    inputElementId={getInputElementId({
                      property: "quantity",
                      index: index ?? 0,
                      displayType,
                    })}
                    disabled={disabled}
                    allowZeroQuantity={allowZeroQuantity}
                  />
                );
              },
            },
            {
              header: ({ displayType, index }) => (
                <label
                  htmlFor={getInputElementId({
                    property: "amountPerItem",
                    index: index ?? 0,
                    displayType,
                  })}
                  className={`${
                    allowBlankAmountPerItem ? "" : "required"
                  } mr-2`}
                >
                  {strings.amountPerItem}
                </label>
              ),
              key: "amountPerItem",
              width: "min-content",
              cell: ({ rowObject: lineItem, index, displayType }) => {
                return (
                  <AmountPerItemField
                    allowBlankAmountPerItem={allowBlankAmountPerItem}
                    inputElementId={getInputElementId({
                      property: "amountPerItem",
                      index: index ?? 0,
                      displayType,
                    })}
                    lineItem={lineItem}
                    onLineItemChanged={buildOnLineItemChanged(lineItem)}
                    disabled={disabled}
                  />
                );
              },
            },
            {
              header: ({ displayType, index }) => (
                <label
                  htmlFor={getInputElementId({
                    property: "taxable",
                    index: index ?? 0,
                    displayType,
                  })}
                >
                  {strings.taxable}
                </label>
              ),
              key: "taxable",
              width: "min-content",
              hidden: hideTaxableField,
              cell: ({ rowObject: lineItem, index, displayType }) => {
                return (
                  <CheckboxField
                    property={"taxable"}
                    inputElementId={getInputElementId({
                      property: "taxable",
                      index: index ?? 0,
                      displayType,
                    })}
                    lineItem={lineItem}
                    onLineItemChanged={buildOnLineItemChanged(lineItem)}
                    disabled={disabled}
                  />
                );
              },
            },
            {
              header: () => "",
              key: "delete",
              width: "min-content",
              cell: ({ rowObject: lineItem, displayType }) => {
                return isDeleteAllowed ? (
                  <DeleteLineItemButton
                    lineItem={lineItem}
                    lineItems={lineItems}
                    setLineItems={setLineItems}
                    displayType={displayType}
                  />
                ) : null;
              },
              hidden: !isDeleteAllowed,
              hideOnMobile: true,
            },
          ]}
          rows={lineItems}
          rowKey="id"
        />
      ) : (
        <div className="font-weight-light">
          {noLineItemMessage ?? strings.noLineItemsSet}
        </div>
      )}

      {!disabled ? (
        <div>
          <button
            className="btn btn-secondary btn-sm mt-0 mt-md-3"
            type="button"
            onClick={() => {
              setLineItems([...lineItems, createNewLineItem(1)]);
            }}
          >
            {strings.addLineItem}
          </button>
        </div>
      ) : null}
    </>
  );

  function getInputElementId({
    property,
    index,
    displayType,
  }: {
    property: string;
    index: number;
    displayType: string;
  }) {
    return `${elementIdPrefix}${property}_${index}_${displayType}`;
  }
};

export default BillableFormLineItemsFullWidth;

function MobileHeader({
  lineItems,
  index,
  setLineItems,
  isDeleteAllowed,
  lineItem,
  disabled,
}: {
  lineItems: ILineItem[];
  index: number;
  setLineItems: (newLineItems: Array<ILineItem>) => void;
  isDeleteAllowed: boolean | undefined;
  lineItem: ILineItem;
  disabled?: boolean;
}) {
  const containerRef = useRef<HTMLDivElement>(null);

  const onClick = () => {
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollIntoView();
      }
    });
  };

  const buttonStyleOverrides: Partial<CSSProperties> = {
    margin: undefined,
    padding: undefined,
  };

  return (
    <div className="d-flex justify-content-between" ref={containerRef}>
      <div className="d-flex align-items-center">
        {lineItems.length > 1 && index !== 0 && !disabled ? (
          <MoveUpButton
            index={index}
            lineItems={lineItems}
            setLineItems={setLineItems}
            onClick={onClick}
            buttonStyle={buttonStyleOverrides}
          />
        ) : null}
        <div className="font-weight-bold">Line Item {index + 1}</div>
        {lineItems.length > 1 && index < lineItems.length - 1 && !disabled ? (
          <MoveDownButton
            index={index}
            lineItems={lineItems}
            setLineItems={setLineItems}
            onClick={onClick}
            buttonStyle={buttonStyleOverrides}
          />
        ) : null}
      </div>
      {isDeleteAllowed && !disabled ? (
        <div>
          <DeleteLineItemButton
            lineItem={lineItem}
            lineItems={lineItems}
            setLineItems={setLineItems}
            displayType={"mobile"}
          />
        </div>
      ) : null}
    </div>
  );
}

function ReorderButtons({
  index,
  setLineItems,
  lineItems,
}: {
  index: number;
  setLineItems: (newLineItems: Array<ILineItem>) => void;
  lineItems: ILineItem[];
}) {
  return (
    <div className="mt-1">
      <MoveUpButton
        index={index}
        setLineItems={setLineItems}
        lineItems={lineItems}
        size="sm"
      />
      <MoveDownButton
        index={index}
        setLineItems={setLineItems}
        lineItems={lineItems}
        size="sm"
      />
    </div>
  );
}

function MoveUpButton({
  index,
  setLineItems,
  lineItems,
  size,
  className,
  onClick,
  buttonStyle,
}: {
  index: number;
  setLineItems: (newLineItems: Array<ILineItem>) => void;
  lineItems: ILineItem[];
  size?: SizeProp;
  className?: string;
  onClick?: () => void;
  buttonStyle?: Partial<CSSProperties>;
}) {
  return (
    <ReorderButton
      disabled={index === 0}
      icon={faChevronUp}
      title="Move up"
      onClick={() => {
        if (index === 0) {
          return;
        }

        setLineItems(moveArrayElement(lineItems, index, -1));

        if (onClick) {
          onClick();
        }
      }}
      size={size}
      className={className}
      buttonStyle={buttonStyle}
    />
  );
}

function MoveDownButton({
  index,
  setLineItems,
  lineItems,
  size,
  className,
  onClick,
  buttonStyle,
}: {
  index: number;
  setLineItems: (newLineItems: Array<ILineItem>) => void;
  lineItems: ILineItem[];
  size?: SizeProp;
  className?: string;
  onClick?: () => void;
  buttonStyle?: Partial<CSSProperties>;
}) {
  return (
    <ReorderButton
      disabled={index === lineItems.length - 1}
      icon={faChevronDown}
      title="Move down"
      onClick={() => {
        if (index === lineItems.length - 1) {
          return;
        }

        setLineItems(moveArrayElement(lineItems, index, 1));

        if (onClick) {
          onClick();
        }
      }}
      size={size}
      className={className}
      buttonStyle={buttonStyle}
    />
  );
}

function ReorderButton({
  disabled,
  icon,
  title,
  onClick,
  size,
  className,
  buttonStyle,
}: {
  disabled: boolean;
  icon: IconProp;
  title: string;
  onClick: () => void;
  size?: SizeProp;
  className?: string;
  buttonStyle?: Partial<CSSProperties>;
}) {
  return (
    <button
      className={`btn btn-secondary btn-sm ${className ?? ""}`}
      style={{
        border: 0,
        padding: 0,
        margin: 0,
        backgroundColor: "transparent",
        lineHeight: 0.5,
        display: "block",
        ...(buttonStyle ?? {}),
      }}
      type="button"
      disabled={disabled}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={icon} size={size} title={title} />
    </button>
  );
}

function moveArrayElement(
  lineItems: ILineItem[],
  elementToMove: number,
  offset: number
) {
  const newItems = [...lineItems];
  const element = newItems[elementToMove];
  newItems.splice(elementToMove, 1);
  newItems.splice(elementToMove + offset, 0, element);
  return newItems;
}
