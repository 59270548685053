import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { timeout } from "rxjs/operators";
import PhoneNumberField from "../../../libraries/input/PhoneNumberField";
import LinkButton from "../../../components/LinkButton";
import Spinner from "../../../components/Spinner";
import { getErrorMessageFromError } from "../../../libraries/errorParsing/getErrorMessageFromError";
import { isStringSet } from "../../../libraries/typeUtilities/isStringSet";
import { Observable } from "rxjs";
import {
  getAccessToken,
  hasAuthenticationExpired,
  storeAuthenticationInfo,
} from "../services/authentication";
import { routingBuilders } from "../../../services/routing";
import { AppContainer } from "./AppContainer";
import {
  requestOneTimePassword,
  verifyOneTimePassword,
  VerifyOneTimePasswordResponse,
} from "../services/appDataProvider";
import {
  getUserSettings,
  setUserSettings,
  UserSettingsType,
} from "../../../services/userSettingsService";
import { fullStoryInit } from "../../../services/fullStoryService";
import configuration from "../../../services/configuration";

export function Login() {
  const [screen, setScreen] = useState<"phone" | "code">("phone");
  const [phoneNumber, setPhoneNumber] = useState(
    getUserSettings(UserSettingsType.loginPhoneNumber) ?? ""
  );
  const history = useHistory();

  useEffect(() => {
    if (configuration.fullStoryId) {
      fullStoryInit();
    }
  }, []);

  useEffect(() => {
    if (!hasAuthenticationExpired() && getAccessToken()) {
      history.push(routingBuilders.buildTechAppPath());
    }
  }, [history]);

  let modalScreen: React.ReactNode;
  if (screen === "phone") {
    modalScreen = (
      <PhoneNumberForm
        onMoveToNextStep={() => {
          setUserSettings(UserSettingsType.loginPhoneNumber, phoneNumber);
          setScreen("code");
        }}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
      />
    );
  } else {
    modalScreen = (
      <CodeForm
        phoneNumber={phoneNumber}
        onMoveToPreviousStep={() => setScreen("phone")}
      />
    );
  }

  return <AppContainer>{modalScreen}</AppContainer>;
}

function PhoneNumberForm({
  onMoveToNextStep,
  phoneNumber,
  setPhoneNumber,
}: {
  onMoveToNextStep: () => void;
  phoneNumber: string;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
}) {
  const {
    errorMessageRequestingOneTimePassword,
    requestingOneTimePassword,
    requestOneTimePassword,
  } = useRequestOneTimePassword();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        requestOneTimePassword({ phoneNumber, onSuccess: onMoveToNextStep });
      }}
    >
      {requestingOneTimePassword ? <Spinner /> : null}

      <div className="d-flex justify-content-center mb-3">
        <div>
          <h4>Log in to Crew Control</h4>
          <div className="my-3"></div>
          <div className="form-group">
            <PhoneNumberField
              id={"phoneNumber"}
              className="form-control"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.currentTarget.value)}
              required
              ariaLabel="Phone number"
            />
            <small>
              You will receive a text message to verify your account. Message
              &amp; data rates may apply.
            </small>
          </div>
          <div>
            <button type="submit" className="btn btn-primary btn-block">
              Continue
            </button>
          </div>
          <ErrorMessage errorMessage={errorMessageRequestingOneTimePassword} />
        </div>
      </div>
    </form>
  );
}

const codeRegex = /\d{6}/;

function CodeForm({
  onMoveToPreviousStep,
  phoneNumber,
}: {
  onMoveToPreviousStep: () => void;
  phoneNumber: string;
}) {
  const history = useHistory();
  const [code, setCode] = useState("");
  const abortController = useRef<AbortController | null>(null);
  const codeInputRef = useRef<HTMLInputElement>(null);

  const {
    errorMessageRequestingOneTimePassword,
    requestingOneTimePassword,
    requestOneTimePassword,
  } = useRequestOneTimePassword();

  const {
    errorMessageVerifyingOneTimePassword,
    verifyingOneTimePassword,
    verifyOneTimePassword,
  } = useVerifyOneTimePassword();

  useEffect(() => {
    if (codeInputRef.current) {
      codeInputRef.current.focus();
    }
  }, []);

  const submitForm = useCallback(
    (currentCode: string) => {
      verifyOneTimePassword({
        phoneNumber,
        code: currentCode,
        onSuccess: (authInfo) => {
          storeAuthenticationInfo(authInfo);
          history.push(routingBuilders.buildTechAppPath());
        },
      });
    },
    [phoneNumber, verifyOneTimePassword, history]
  );

  useEffect(() => {
    if ("OTPCredential" in window) {
      console.log("OTPCredential found on window");

      try {
        abortController.current = new AbortController();

        // Typings aren't correct for this so have to ignore a few places
        navigator.credentials
          .get({
            // @ts-ignore
            otp: { transport: ["sms"] },
            signal: abortController.current.signal,
          })
          .then((otp: unknown) => {
            if (
              !!otp &&
              typeof otp === "object" &&
              "code" in otp &&
              typeof otp.code === "string"
            ) {
              const newCode = otp.code;
              setCode(newCode);
              submitForm(newCode);
            } else {
              console.log("unexpected result for otp");
            }
          })
          .catch((err) => {
            console.log("get credentials caught error");
            console.log(err);
          });
      } catch (error) {
        console.error("error in navigator.credentials.get");
        console.error(error);
      }
    } else {
      console.log("OTPCredential not found on window");
    }

    return function cleanup() {
      if (abortController.current) {
        abortController.current.abort("cleanup method call");
      }
    };
  }, [submitForm]);

  const combinedErrorMessage =
    errorMessageVerifyingOneTimePassword ??
    errorMessageRequestingOneTimePassword;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        abortController.current?.abort("form submitted");

        submitForm(code);
      }}
    >
      {requestingOneTimePassword || verifyingOneTimePassword ? (
        <Spinner />
      ) : null}

      <div className="d-flex justify-content-center mb-3">
        <div>
          <h4>Confirm your number</h4>
          <div className="my-3">Enter the code we texted to {phoneNumber}.</div>
          <div className="form-group">
            <input
              type="text"
              inputMode="numeric"
              autoComplete="one-time-code"
              className="form-control"
              maxLength={6}
              required
              value={code}
              onChange={(e) => {
                const newCode = e.currentTarget.value;
                setCode(newCode);

                if (codeInputRef.current) {
                  codeInputRef.current.setCustomValidity(
                    !codeRegex.test(newCode)
                      ? "This code must be a number with six digits."
                      : ""
                  );
                }
              }}
              placeholder="- - - - - -"
              ref={codeInputRef}
              aria-label="Code"
            />
          </div>
          <div className="my-3">
            <small>
              Didn't get a code?{" "}
              <LinkButton
                text={<small>Send again</small>}
                style={{ verticalAlign: "baseline" }}
                onClick={() => {
                  requestOneTimePassword({
                    phoneNumber,
                    onSuccess: () => {},
                  });
                }}
              />
            </small>
          </div>
          <div className="d-flex" style={{ columnGap: "10px" }}>
            <button
              type="button"
              className="btn btn-secondary"
              style={{ flex: "1" }}
              onClick={() => {
                abortController.current?.abort("leaving component");
                onMoveToPreviousStep();
              }}
            >
              Back
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              style={{ flex: "1" }}
            >
              Continue
            </button>
          </div>
          <ErrorMessage errorMessage={combinedErrorMessage} />
        </div>
      </div>
    </form>
  );
}

function ErrorMessage({ errorMessage }: { errorMessage: string | null }) {
  return (
    <>
      {isStringSet(errorMessage) ? (
        <div className="text-danger mt-2" style={{ whiteSpace: "pre-line" }}>
          {errorMessage}
        </div>
      ) : null}
    </>
  );
}

function useRequestOneTimePassword() {
  const { calling, errorMessage, callServer } = useCallServer();

  const requestOneTimePasswordCallback = ({
    phoneNumber,
    onSuccess,
  }: {
    phoneNumber: string;
    onSuccess: () => void;
  }) => {
    callServer({
      onSuccess,
      call: () => requestOneTimePassword({ phoneNumber }),
    });
  };

  return {
    requestingOneTimePassword: calling,
    errorMessageRequestingOneTimePassword: errorMessage,
    requestOneTimePassword: requestOneTimePasswordCallback,
  };
}

function useVerifyOneTimePassword() {
  const { calling, errorMessage, callServer } =
    useCallServer<VerifyOneTimePasswordResponse>();

  const verifyOneTimePasswordCallback = useCallback(
    ({
      phoneNumber,
      code,
      onSuccess,
    }: {
      phoneNumber: string;
      code: string;
      onSuccess: (arg: VerifyOneTimePasswordResponse) => void;
    }) => {
      callServer({
        onSuccess,
        call: () => verifyOneTimePassword({ phoneNumber, code }),
      });
    },
    [callServer]
  );

  return {
    verifyingOneTimePassword: calling,
    errorMessageVerifyingOneTimePassword: errorMessage,
    verifyOneTimePassword: verifyOneTimePasswordCallback,
  };
}

function useCallServer<T>() {
  const [calling, setCalling] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const callServer = useCallback(
    ({
      call,
      onSuccess,
    }: {
      call: () => Observable<T>;
      onSuccess: (result: T) => void;
    }) => {
      setCalling(true);
      setErrorMessage(null);

      call()
        .pipe(timeout(10_000))
        .subscribe({
          next: (result) => {
            setCalling(false);
            onSuccess(result);
          },

          error: (err) => {
            setCalling(false);
            setErrorMessage(getErrorMessageFromError(err));
          },
        });
    },
    []
  );

  return {
    calling,
    errorMessage,
    callServer,
  };
}
