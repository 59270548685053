import { useRef, useState } from "react";
import { round } from "../math/round";

export default function PercentField({
  id,
  value,
  onChange,
  ariaLabel,
  decimalPlacesAllowed,
  disabled,
  required,
  min,
  testId,
}: {
  id: string;
  value: number | null;
  onChange: (newValue: null | number) => void;
  ariaLabel?: string;
  decimalPlacesAllowed: number;
  disabled?: boolean;
  required?: boolean;
  min?: number;
  testId?: string;
}) {
  const [hasFocus, setHasFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>();

  let valueToDisplay: number | string;
  if (value === null) {
    valueToDisplay = "";
  } else {
    const adjustedValue = round(value * 100, 4);
    if (hasFocus) {
      valueToDisplay = adjustedValue;
    } else {
      valueToDisplay = `${adjustedValue}%`;
    }
  }

  return (
    <input
      aria-label={ariaLabel}
      ref={(r) => (inputRef.current = r)}
      id={id}
      className="form-control text-right"
      type={hasFocus ? "number" : undefined}
      step="any"
      value={valueToDisplay}
      onFocus={() => setHasFocus(true)}
      onBlur={() => setHasFocus(false)}
      disabled={disabled}
      required={required}
      data-testid={testId}
      onChange={(e) => {
        if (inputRef.current) {
          inputRef.current.setCustomValidity("");
        }

        let parsedRate = parseFloat(e.currentTarget.value);
        if (isNaN(parsedRate)) {
          onChange(null);
        } else {
          const adjustedValue = round(
            parsedRate / 100,
            2 + decimalPlacesAllowed
          );
          onChange(adjustedValue);

          if (inputRef.current) {
            if (adjustedValue < 0) {
              inputRef.current.setCustomValidity(
                "Value must be greater than or equal to 0%"
              );
            } else if (adjustedValue > 1) {
              inputRef.current.setCustomValidity(
                "Value must be less than or equal to 100%"
              );
            } else if (typeof min === "number" && adjustedValue < min) {
              inputRef.current.setCustomValidity(
                `Value must be greater than or equal to ${min * 100}%`
              );
            }
          }
        }
      }}
      min={min ?? 0}
      max={100}
    />
  );
}
