import React, { useCallback, useRef, useState } from "react";
import {
  Control,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  useForm,
} from "react-hook-form";
import Container from "../../../components/Container";
import { GenericFooter } from "../../../components/JobDetails/GenericFooter";
import Spinner from "../../../components/Spinner";
import { useScheduleJobCache } from "../../../hooks/useScheduleJobCache";
import { createEmailAddressRecord } from "../../../libraries/emailAddresses/EmailAddresses.functions";
import ISchedule from "../../../models/ISchedule";
import { IScheduleIdentifier } from "../../../models/IScheduleIdentifier";
import { routingBuilders, routingPatterns } from "../../../services/routing";
import { PageErrorLoading } from "../../../libraries/commonUi/components/PageErrorLoading";
import { TriggerBillableFormSave } from "../../../libraries/billableForm/types/TriggerBillableFormSave";
import { DiscountType } from "../../../libraries/billableForm/enums/DiscountType";
import { getPageHeader } from "../../../libraries/commonUi/functions/getPageHeader";
import { FloatingIndicator } from "../../../libraries/billableForm/components/FloatingIndicator";
import { useEstimateFormLoad } from "./estimateForm/useEstimateFormLoad";
import { EstimateFormBody } from "./estimateForm/EstimateFormBody";
import { useEstimateFormSave } from "./estimateForm/useEstimateFormSave";
import { IEstimateFormData } from "./estimateForm/IEstimateFormData";
import { usePresentBillableItem } from "../../../libraries/billableForm/hooks/usePresentBillableItem";
import { FooterButton } from "../../../components/FooterButton";
import {
  deleteAcceptForEstimate,
  getOrCreateEstimateForPresentationForm,
} from "../services/estimateDataProvider";
import { faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import {
  Prompt,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { FormLockWarning } from "../../../libraries/billableForm/components/FormLockWarning";
import IScheduledJob from "../../../models/IScheduleJob";
import { strings } from "../../../languages";
import { IInvoiceItem } from "../../../libraries/billableForm/types/IInvoiceItem";
import { ILineItem } from "../../../libraries/billableForm/components/BillableFormLineItem";
import { LineItemFormPageBodyContainer } from "../../../libraries/billableForm/components/LineItemFormPageBodyContainer";
import { useGetFormTitle } from "../../../libraries/billableForm/hooks/useGetFormTitle";
import { getLineItemBackLinkToForm } from "../../../libraries/billableForm/functions/getLineItemBackLinkToForm";
import { IEstimateTemplate } from "../models/IEstimateTemplate";
import { useQuery } from "../../../services/useQuery";
import { billableFormConstants } from "../../../libraries/billableForm/billableFormConstants";
import { useScrollToOriginalPosition } from "../../../libraries/billableForm/hooks/useScrollToOriginalPosition";
import { JobMovedErrorAlert } from "../../../components/JobMovedErrorAlert";
import { LineItemErrorMessages } from "../../../libraries/billableForm/components/BillableFormLineItemsMobile.types";
import { isStringSet } from "../../../libraries/typeUtilities/isStringSet";
import { getMobileCardId } from "../../../libraries/billableForm/components/BillableFormLineItemsMobile.functions";
import { useIsLargeLineItemDisplay } from "../../../libraries/billableForm/hooks/useIsLargeLineItemDisplay";
import { DepositType } from "../enums/DepositType";
import { IAmountAdjustments } from "../../../libraries/billableForm/types/IBillableFormData";

export function EstimateFormPage({
  scheduleJobId,
  scheduleIdentifier,
  schedule,
  onScheduleJobUpdated,
}: {
  scheduleJobId: string;
  scheduleIdentifier: IScheduleIdentifier;
  schedule: ISchedule;
  onScheduleJobUpdated(
    scheduleJobId: string,
    fieldsToUpdate: Partial<IScheduledJob>
  ): void;
}) {
  const { scheduleJob } = useScheduleJobCache(schedule, scheduleJobId ?? "");
  const [isUnlocking, setIsUnlocking] = useState(false);
  const [didUnlockingFail, setDidUnlockingFail] = useState(false);
  const { path } = useRouteMatch();
  const history = useHistory();
  const pageTitle = useGetFormTitle(strings.estimate);
  const query = useQuery();

  const formRef = useRef<HTMLFormElement>(null);

  const { control, getValues, setValue, watch, register } =
    useForm<IEstimateFormData>({
      defaultValues: getEmptyFormData(),
    });
  const lastEmailAddressRef = useRef<HTMLInputElement | null>(null);

  const {
    loading,
    invoiceItems,
    setInvoiceItems,
    errorLoading,
    isEstimateAccepted,
    isDraft,
    retryLoad,
    templates,
    customerTaxExempt,
    taxRateAlreadySet,
    originalPhoneNumber,
    originalPhoneNumberOptedIntoSms,
    defaultDepositItemId,
  } = useEstimateFormLoad({
    scheduleIdentifier,
    scheduleJobId,
    setValue,
  });

  const {
    saving,
    saveError: errorFromFormSave,
    setSaveError: setErrorFromFormSave,
    triggerSave: baseTriggerEstimateFormSave,
    isFormValid,
    hasUnsavedChanges,
  } = useEstimateFormSave({
    scheduleIdentifier,
    jobInstanceId: scheduleJobId,
    getValues,
    formRef,
    invoiceItems,
  });

  const lineItems = watch("lineItems");
  const amountAdjustments = watch("amountAdjustments");
  const lineItemErrorMessages: LineItemErrorMessages = lineItems
    .map((li) => {
      let errorMessage = "";

      if (!isStringSet(li.quantity)) {
        errorMessage = "Quantity must be set";
      } else if (!isStringSet(li.amountPerItem)) {
        errorMessage = "Amount per item must be set";
      }

      return {
        lineItemId: li.id,
        errorMessage,
      };
    })
    .filter((li) => isStringSet(li.errorMessage));

  const {
    presentSaving,
    presentStarted,
    triggerPresentation: baseTriggerPresentInvoice,
    errorSavingForPresentationForm,
    setErrorSavingForPresentationForm,
  } = usePresentBillableItem({
    scheduleIdentifier,
    jobInstanceId: scheduleJob?.id ?? "",
    triggerFormSave: baseTriggerEstimateFormSave,
    skipSave: isEstimateAccepted,
    fullStoryEvent: "Crew: Estimate Present Clicked",
    presentationPagePath: routingBuilders.buildEstimatePresentationPath({
      scheduleIdentifier: scheduleIdentifier,
      scheduleJobId: scheduleJob?.id ?? "",
    }),
    getOrCreateCall: getOrCreateEstimateForPresentationForm,
    lastEmailAddressInputRef: lastEmailAddressRef,
  });

  const triggerEstimateFormSave: TriggerBillableFormSave = (arg) => {
    setErrorSavingForPresentationForm(null);
    baseTriggerEstimateFormSave(arg);
  };

  const isLargeLineItemDisplay = useIsLargeLineItemDisplay();
  const triggerPresentation = () => {
    if (!isLargeLineItemDisplay) {
      if (lineItemErrorMessages.length > 0) {
        const firstLineItemId = lineItemErrorMessages[0].lineItemId;
        const cardId = getMobileCardId(firstLineItemId);
        const cardElement = document.getElementById(cardId);
        if (cardElement) {
          cardElement.scrollIntoView();
          return;
        }
      }
    }

    baseTriggerPresentInvoice();
  };

  const setLineItems = useCallback((v) => setValue("lineItems", v), [setValue]);
  const getLineItems = useCallback(() => getValues("lineItems"), [getValues]);

  if (!scheduleJob) {
    return <JobMovedErrorAlert scheduleIdentifier={scheduleIdentifier} />;
  }

  return (
    <Container
      isJobListing={false}
      schedule={schedule}
      headerOverride={getPageHeader({
        schedule,
        scheduleJob,
        pageTitle,
      })}
      scheduleIdentifier={scheduleIdentifier}
    >
      {loading || presentSaving || isUnlocking ? <Spinner /> : null}

      {!loading && errorLoading ? (
        <>
          <PageErrorLoading
            retryLoad={retryLoad}
            errorDetails={errorLoading.message}
            disableRetry={errorLoading.was400Error}
          />
          <GenericFooter
            backLink={routingBuilders.buildScheduleJobPath({
              scheduleIdentifier,
              scheduleJobId: scheduleJobId,
            })}
          />
        </>
      ) : null}

      {!loading && !errorLoading ? (
        <>
          {isEstimateAccepted ? (
            <FormLockWarning
              message={strings.voidAcceptanceWarning}
              onClear={() => {
                setIsUnlocking(true);
                setDidUnlockingFail(false);

                deleteAcceptForEstimate({
                  scheduleIdentifier,
                  jobInstanceId: scheduleJobId,
                }).subscribe({
                  next: () => {
                    setIsUnlocking(false);
                    retryLoad();

                    onScheduleJobUpdated(scheduleJobId, {
                      proposalAccepted: false,
                    });
                  },

                  error: () => {
                    setIsUnlocking(false);
                    setDidUnlockingFail(true);
                  },
                });
              }}
            />
          ) : null}

          <Switch>
            <Route
              path={routingPatterns.getLineItemFormPath(path)}
              render={(props) => {
                const lineItemId = props.match.params.lineItemId;
                return (
                  <LineItemFormPageBodyContainer
                    invoiceItems={invoiceItems}
                    setInvoiceItems={setInvoiceItems}
                    lineItemId={lineItemId ?? null}
                    setLineItems={setLineItems}
                    getLineItems={getLineItems}
                    disabled={isEstimateAccepted}
                    allowOptionalItems={true}
                    allowZeroQuantity={true}
                    getLineItemBackLink={() =>
                      getLineItemBackLink({
                        scheduleIdentifier,
                        scheduleJobId,
                        originalScrollPosition: query.get(
                          billableFormConstants.originalScrollPosition
                        ),
                      })
                    }
                    onSaveComplete={(id) => {
                      history.push(
                        getLineItemBackLink({
                          scheduleIdentifier,
                          scheduleJobId,
                          originalScrollPosition: query.get(
                            billableFormConstants.originalScrollPosition
                          ),
                        })
                      );

                      triggerEstimateFormSave({});
                    }}
                  />
                );
              }}
            />
            <Route
              render={() => {
                return (
                  <>
                    <Prompt
                      message={strings.unsavedChangesWarning}
                      when={hasUnsavedChanges}
                    />
                    <EstimateFormContainer
                      formRef={formRef}
                      control={control}
                      register={register}
                      setValue={setValue}
                      getValues={getValues}
                      triggerEstimateFormSave={triggerEstimateFormSave}
                      invoiceItems={invoiceItems}
                      setInvoiceItems={setInvoiceItems}
                      lineItems={lineItems}
                      amountAdjustments={amountAdjustments}
                      scheduleIdentifier={scheduleIdentifier}
                      schedule={schedule}
                      isEstimateAccepted={isEstimateAccepted}
                      isDraft={isDraft}
                      presentStarted={presentStarted}
                      scheduleJob={scheduleJob}
                      templates={templates}
                      scheduleJobId={scheduleJobId}
                      triggerPresentation={triggerPresentation}
                      customerTaxExempt={customerTaxExempt}
                      taxRateAlreadySet={taxRateAlreadySet}
                      originalPhoneNumber={originalPhoneNumber}
                      originalPhoneNumberOptedIntoSms={
                        originalPhoneNumberOptedIntoSms
                      }
                      lastEmailAddressInputRef={lastEmailAddressRef}
                      lineItemErrorMessages={lineItemErrorMessages}
                      defaultDepositItemId={defaultDepositItemId}
                    />

                    <FloatingIndicator
                      // presentSaving will have a top-level saving so avoid double spinner
                      saving={saving && !presentSaving}
                      errorFromFormSave={errorFromFormSave}
                      setErrorFromFormSave={setErrorFromFormSave}
                      triggerInvoiceFormSave={triggerEstimateFormSave}
                      isFormValid={isFormValid}
                      formRef={formRef}
                      errorSavingForPaymentForm={errorSavingForPresentationForm}
                      setErrorSavingForPaymentForm={
                        setErrorSavingForPresentationForm
                      }
                      triggerPresentInvoice={triggerPresentation}
                      didUnlockingFail={didUnlockingFail}
                      setDidUnlockingFail={setDidUnlockingFail}
                    />
                  </>
                );
              }}
            />
          </Switch>
        </>
      ) : null}
    </Container>
  );
}

function EstimateFormContainer({
  formRef,
  control,
  register,
  setValue,
  getValues,
  triggerEstimateFormSave,
  invoiceItems,
  setInvoiceItems,
  lineItems,
  amountAdjustments,
  scheduleIdentifier,
  schedule,
  isEstimateAccepted,
  isDraft,
  presentStarted,
  scheduleJob,
  templates,
  scheduleJobId,
  triggerPresentation,
  customerTaxExempt,
  taxRateAlreadySet,
  originalPhoneNumber,
  originalPhoneNumberOptedIntoSms,
  lastEmailAddressInputRef,
  lineItemErrorMessages,
  defaultDepositItemId,
}: {
  formRef: React.RefObject<HTMLFormElement>;
  control: Control<IEstimateFormData, any>;
  register: UseFormRegister<IEstimateFormData>;
  setValue: UseFormSetValue<IEstimateFormData>;
  getValues: UseFormGetValues<IEstimateFormData>;
  triggerEstimateFormSave: TriggerBillableFormSave;
  invoiceItems: IInvoiceItem[];
  setInvoiceItems: React.Dispatch<React.SetStateAction<Array<IInvoiceItem>>>;
  lineItems: ILineItem[];
  amountAdjustments: IAmountAdjustments;
  scheduleIdentifier: IScheduleIdentifier;
  schedule: ISchedule;
  isEstimateAccepted: boolean;
  isDraft: boolean;
  presentStarted: boolean;
  scheduleJob: IScheduledJob;
  templates: Array<IEstimateTemplate>;
  scheduleJobId: string;
  triggerPresentation: () => void;
  customerTaxExempt: boolean;
  taxRateAlreadySet: boolean;
  originalPhoneNumber: string;
  originalPhoneNumberOptedIntoSms: boolean;
  lastEmailAddressInputRef: React.MutableRefObject<HTMLInputElement | null>;
  lineItemErrorMessages: LineItemErrorMessages;
  defaultDepositItemId: string | null;
}) {
  useScrollToOriginalPosition();

  return (
    <>
      <EstimateFormBody
        formRef={formRef}
        control={control}
        register={register}
        setValue={setValue}
        getValues={getValues}
        triggerSave={triggerEstimateFormSave}
        invoiceItems={invoiceItems}
        setInvoiceItems={setInvoiceItems}
        lineItems={lineItems}
        amountAdjustments={amountAdjustments}
        scheduleIdentifier={scheduleIdentifier}
        schedule={schedule}
        isEstimateAccepted={isEstimateAccepted}
        isDraft={isDraft}
        presentStarted={presentStarted}
        scheduleJob={scheduleJob}
        templates={templates}
        scheduleJobId={scheduleJobId}
        customerTaxExempt={customerTaxExempt}
        taxRateAlreadySet={taxRateAlreadySet}
        originalPhoneNumber={originalPhoneNumber}
        originalPhoneNumberOptedIntoSms={originalPhoneNumberOptedIntoSms}
        lastEmailAddressInputRef={lastEmailAddressInputRef}
        lineItemErrorMessages={lineItemErrorMessages}
        defaultDepositItemId={defaultDepositItemId}
      />

      <GenericFooter
        backLink={routingBuilders.buildScheduleJobPath({
          scheduleIdentifier,
          scheduleJobId: scheduleJobId,
        })}
      >
        <div className="text-center">
          <FooterButton
            icon={faFileInvoice}
            caption={strings.present}
            color="text-primary"
            testId="presentLink"
            onClick={() => {
              triggerPresentation();
            }}
          />
        </div>
      </GenericFooter>
    </>
  );
}

function getLineItemBackLink({
  scheduleIdentifier,
  scheduleJobId,
  originalScrollPosition,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  scheduleJobId: string;
  originalScrollPosition: string | null;
}): string {
  return getLineItemBackLinkToForm({
    formPath: routingBuilders.buildEstimateFormPath({
      scheduleIdentifier,
      scheduleJobId,
    }),
    originalScrollPosition,
  });
}

function getEmptyFormData(): IEstimateFormData {
  return {
    phoneNumberWithOptInStatus: {
      phoneNumber: "",
      optedIn: false,
    },
    emails: [createEmailAddressRecord("")],
    amountAdjustments: {
      discount: {
        type: DiscountType.amount,
        amount: null,
        percent: null,
      },
      taxRate: null,
    },
    files: [],
    lineItems: [],
    summary: "",
    daysValid: "",
    proposalTemplateId: null,
    proposalDate: "",
    validUntilDate: "",
    subject: null,
    depositSettings: {
      amount: null,
      percent: null,
      type: DepositType.percent,
      depositItemId: null,
      depositItemName: null,
      depositItemDescription: null,
    },
  };
}
