import CurrencyInput from "../../../../libraries/input/CurrencyInput";
import PercentField from "../../../../libraries/input/PercentField";
import { DepositType } from "../../enums/DepositType";
import { DepositSettingsFormData } from "./IEstimateFormData";

type IProps = {
  depositSettings: DepositSettingsFormData | null;
  setDepositSettings: (value: DepositSettingsFormData | null) => void;
  disabled: boolean;
};

const DepositRequiredFields: React.FunctionComponent<IProps> = ({
  depositSettings,
  setDepositSettings,
  disabled,
}) => {
  return (
    <>
      <>
        <div className="form-row">
          <div className="col-12 col-sm-6 form-group">
            <select
              className="form-control"
              aria-label="Deposit type"
              data-testid="depositType"
              disabled={disabled}
              value={
                depositSettings?.type?.toString() ??
                DepositType.percent.toString()
              }
              onChange={(e) => {
                let type = parseInt(e.currentTarget.value) as DepositType;

                if (
                  type === DepositType.percent ||
                  type === DepositType.amount
                ) {
                  setDepositSettings({
                    ...depositSettings,
                    type,
                  } as DepositSettingsFormData);
                } else {
                  setDepositSettings(null);
                }
              }}
            >
              <option value={DepositType.percent.toString()}>
                Required deposit percent
              </option>
              <option value={DepositType.amount.toString()}>
                Required deposit amount
              </option>
            </select>
          </div>
          <div className={`col-12 col-sm-6 form-group`}>
            {depositSettings?.type === DepositType.amount ? (
              <CurrencyInput
                aria-label="Deposit settings amount"
                id="depositSettingsAmount"
                className="form-control"
                onValueChange={(newValue) => {
                  const parsedNewValue = parseFloat(newValue);
                  setDepositSettings({
                    ...depositSettings,
                    type: DepositType.amount,
                    amount:
                      (parsedNewValue ?? 0) === 0 || isNaN(parsedNewValue)
                        ? null
                        : newValue,
                  } as DepositSettingsFormData);
                }}
                value={depositSettings?.amount ?? ""}
                disabled={disabled}
              />
            ) : (
              <PercentField
                id="depositSettingsPercent"
                ariaLabel="Deposit settings percent"
                onChange={(newValue) =>
                  setDepositSettings({
                    ...depositSettings,
                    type: DepositType.percent,
                    percent: (newValue ?? 0) === 0 ? null : newValue,
                  } as DepositSettingsFormData)
                }
                decimalPlacesAllowed={0}
                value={depositSettings?.percent ?? null}
                disabled={disabled}
              />
            )}
          </div>
        </div>
      </>
    </>
  );
};

export default DepositRequiredFields;
