import { differenceInDays, format, parseISO } from "date-fns";
import { strings } from "../languages";
import ISchedule from "../models/ISchedule";
import { getCurrentDate } from "../services/getCurrentDate";

export function getDayHeader(schedule: ISchedule) {
  const dayDifference = differenceInDays(
    parseISO(schedule.date),
    removeTimeComponents(getCurrentDate())
  );

  let dayHeader: string = "";
  if (dayDifference < 0) {
    dayHeader = strings.pastDay;
  } else if (dayDifference > 0) {
    if (dayDifference > 1) {
      dayHeader = strings.futureDay;
    } else {
      dayHeader = strings.nextDay;
    }
  }
  return dayHeader;
}

function removeTimeComponents(input: Date) {
  return parseISO(format(input, "yyyy-MM-dd"));
}
