import React, { useState } from "react";
import Modal from "reactstrap/lib/Modal";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalBody from "reactstrap/lib/ModalBody";
import { strings } from "../languages";
import { ITimeRange } from "../models/ITimeRange";
import dataProvider from "../services/DataProvider";
import Spinner from "./Spinner";
import { finalize } from "rxjs/operators";
import { getErrorMessageFromError } from "../libraries/errorParsing/getErrorMessageFromError";

enum CloseState {
  cancelled,
  completed,
  notCompleted,
}

interface IProps {
  show: boolean;
  onAttemptedCompleteWithoutRequiredTodoItemsDone: (
    uncompletedTodoItems: any
  ) => void;
  onCloseWithoutSave: (closeState: CloseState) => void;
  onCloseWithSave: (
    updatedTimeRanges: Array<ITimeRange>,
    updatedCompleted: boolean
  ) => void;
  scheduleJobId: string;
  timeRanges: Array<ITimeRange>;
}

const verticalMargin = "35px";
export const ClockStopModal: React.FunctionComponent<IProps> = ({
  show,
  scheduleJobId,
  timeRanges,
  onAttemptedCompleteWithoutRequiredTodoItemsDone,
  onCloseWithSave,
  onCloseWithoutSave,
}) => {
  const [saving, setSaving] = useState(false);
  const [savingErrorMessage, setErrorSaving] = useState("");

  function saveClockStop(closeState: CloseState) {
    setSaving(true);
    setErrorSaving("");

    const timeRangeToEnd = getTimeRangeToEnd(timeRanges);
    dataProvider
      .endClock(
        scheduleJobId,
        new Date(),
        timeRangeToEnd,
        closeState === CloseState.completed
      )
      .pipe(finalize(() => setSaving(false)))
      .subscribe(
        (result) => {
          onCloseWithSave(result.updatedTimeRanges, result.updatedCompleted);
        },
        (error) => {
          if (
            error.response &&
            error.response.errorCode ===
              "CrewCompletingJobWithoutRequiredTodoItemsDone"
          ) {
            onAttemptedCompleteWithoutRequiredTodoItemsDone(
              error.response.additionalData.uncompletedTodoItems
            );
          } else {
            setErrorSaving(getErrorMessageFromError(error, strings.saveFailed));
          }
        }
      );
  }

  return (
    <>
      {saving && <Spinner />}
      <Modal
        isOpen={show}
        centered={true}
        toggle={() => {
          onCloseWithoutSave(CloseState.cancelled);
        }}
      >
        <ModalHeader
          toggle={() => {
            onCloseWithoutSave(CloseState.cancelled);
          }}
        >
          {strings.stopClock}
        </ModalHeader>
        <ModalBody>
          {savingErrorMessage && (
            <div className="alert alert-danger" role="alert">
              {savingErrorMessage}
            </div>
          )}
          <div>
            <button
              className="btn btn-success btn-block btn-lg"
              type="button"
              style={{
                marginTop: verticalMargin,
                marginBottom: verticalMargin,
              }}
              onClick={() => {
                saveClockStop(CloseState.completed);
              }}
            >
              {strings.complete}
            </button>{" "}
            <button
              className="btn btn-primary btn-block btn-lg"
              style={{
                marginTop: verticalMargin,
                marginBottom: verticalMargin,
              }}
              onClick={() => {
                saveClockStop(CloseState.notCompleted);
              }}
              type="button"
            >
              {strings.pause}
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

function getTimeRangeToEnd(timeRanges: ITimeRange[]) {
  return timeRanges.reduce((acc, tr) => {
    if (tr.startTime && !tr.endTime) {
      return tr.id;
    }
    return acc;
  }, "");
}
