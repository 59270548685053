import { useCallback } from "react";
import { UseFormGetValues } from "react-hook-form";
import { getNumberOrNull } from "../../../../libraries/typeUtilities/getNumber";
import { IScheduleIdentifier } from "../../../../models/IScheduleIdentifier";
import {
  UpdateEstimatePayload,
  updateEstimateForForm,
} from "../../services/estimateDataProvider";
import { IEstimateFormData } from "./IEstimateFormData";
import { IInvoiceItem } from "../../../../libraries/billableForm/types/IInvoiceItem";
import { useBillableItemFormSave } from "../../../../libraries/billableForm/hooks/useBillableItemFormSave";
import { mapLineItemForSave } from "../../../../libraries/billableForm/functions/mapLineItemForSave";

export function useEstimateFormSave({
  scheduleIdentifier,
  jobInstanceId,
  getValues,
  formRef,
  invoiceItems,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
  getValues: UseFormGetValues<IEstimateFormData>;
  formRef: React.RefObject<HTMLFormElement>;
  invoiceItems: Array<IInvoiceItem>;
}) {
  const mapFormDataToPayload = useCallback(
    (values: IEstimateFormData) => {
      return {
        customerEmailAddresses: values.emails.map((e) => ({
          id: e.id,
          emailAddress: e.value,
        })),
        discount: values.amountAdjustments.discount,
        taxRate: values.amountAdjustments.taxRate,
        summary: values.summary,
        files: values.files,
        daysValid: getNumberOrNull(values.daysValid),
        proposalTemplateId: values.proposalTemplateId,
        proposalDate: values.proposalDate,
        validUntilDate: values.validUntilDate,
        lineItems: values.lineItems.map((l) =>
          mapLineItemForSave(l, invoiceItems)
        ),
        recipientPhoneNumber: values.phoneNumberWithOptInStatus.phoneNumber,
        recipientPhoneNumberOptedIntoSms:
          values.phoneNumberWithOptInStatus.optedIn,
        subject: values.subject,
        depositSettings: values.depositSettings,
        depositItemId: values.depositSettings?.depositItemId ?? null,
        depositItemName: values.depositSettings?.depositItemName ?? null,
        depositItemDescription:
          values.depositSettings?.depositItemDescription ?? null,
      } as UpdateEstimatePayload;
    },
    [invoiceItems]
  );

  return useBillableItemFormSave<IEstimateFormData, UpdateEstimatePayload>({
    scheduleIdentifier,
    jobInstanceId,
    getValues,
    formRef,
    invoiceItems,
    save: updateEstimateForForm,
    mapFormDataToPayload,
    shouldFailTriggerSave: () => false,
  });
}
