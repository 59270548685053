import { strings } from "../../languages";
import { isStringSet } from "../typeUtilities/isStringSet";
import PhoneNumberField from "./PhoneNumberField";

export type PhoneNumberWithOptInValue = {
  phoneNumber: string;
  optedIn: boolean;
};

export function PhoneNumberWithOptIn({
  value,
  onChange,
  originalPhoneNumber,
  originalPhoneNumberOptedIntoSms,
  disabled,
}: {
  value: PhoneNumberWithOptInValue;
  onChange(v: PhoneNumberWithOptInValue): void;
  originalPhoneNumber: string;
  originalPhoneNumberOptedIntoSms: boolean;
  disabled?: boolean;
}) {
  return (
    <>
      <div className="form-group">
        <label htmlFor="customerPhoneNumber">
          {strings.customerPhoneNumber}
        </label>
        <PhoneNumberField
          id="customerPhoneNumber"
          className="form-control"
          value={value.phoneNumber}
          disabled={disabled}
          onChange={(newValue) => {
            const newPhoneNumber = newValue.target.value;
            let newOptedIn = value.optedIn;

            if (newPhoneNumber === originalPhoneNumber) {
              newOptedIn = originalPhoneNumberOptedIntoSms;
            } else {
              newOptedIn = false;
            }

            onChange({
              phoneNumber: newPhoneNumber,
              optedIn: newOptedIn,
            });
          }}
        />
      </div>

      {isStringSet(value.phoneNumber) &&
      (value.phoneNumber !== originalPhoneNumber ||
        !originalPhoneNumberOptedIntoSms) ? (
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="optin"
              name="optin"
              disabled={disabled}
              checked={value.optedIn}
              onChange={(e) =>
                onChange({
                  ...value,
                  optedIn: e.currentTarget.checked,
                })
              }
              required={false}
            />
            <label className="custom-control-label" htmlFor="optin">
              {strings.customerPhoneNumberOptedIn}
            </label>
          </div>
          <div>
            <small className="font-weight-light">
              <ul>
                <li>{strings.messageDataRatesMayApply}</li>
                <li>{strings.messagesMaySendEveryDay}</li>
                <li>{strings.canReplyWithHelpStop}</li>
              </ul>
            </small>
          </div>
        </div>
      ) : null}
    </>
  );
}
