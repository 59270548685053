import React, { useRef } from "react";

export type ResponsiveGridTableDisplayType = "mobile" | "desktop";

type Column<T> = {
  key: string;
  header: ({
    displayType,
    index,
  }: {
    displayType: ResponsiveGridTableDisplayType;
    index?: number;
    labelRef?: React.RefObject<HTMLLabelElement>;
  }) => string | React.ReactNode;
  cell:
    | keyof T
    | (({
        rowObject,
        index,
        displayType,
      }: {
        rowObject: T;
        index: number;
        displayType: ResponsiveGridTableDisplayType;
        labelRef?: React.RefObject<HTMLLabelElement>;
      }) => React.ReactNode | string);
  hidden?: boolean;
  width: string;
  className?: string;
  headerClassName?: string;
  contentClassName?: string;
  cellTestId?: string;
  hideOnMobile?: boolean;
};

type ResponseGridTableColumns<T> = Array<Column<T>>;

export default function ResponsiveGridTable<T>({
  rows,
  columns,
  breakpoint,
  rowKey,
  mobileHeader,
  rowGap,
  columnGap,
  showRowIndex,
}: {
  rows: Array<T>;
  columns: ResponseGridTableColumns<T>;
  breakpoint?: string;
  rowKey: keyof T;
  mobileHeader?: (arg: T, index: number) => React.ReactNode;
  rowGap?: string;
  columnGap?: string;
  showRowIndex?: boolean;
}) {
  breakpoint = breakpoint ?? "md";
  const visibleColumns = columns.filter((column) => !column.hidden);

  let gridTemplateColumns = visibleColumns.map((c) => c.width).join(" ");
  if (showRowIndex) {
    gridTemplateColumns = "min-content " + gridTemplateColumns;
  }

  return (
    <>
      <div className={`d-none d-${breakpoint}-block`}>
        <div
          style={{
            display: "grid",
            gap: `${rowGap ?? "5px"} ${columnGap ?? "5px"}`,
            gridTemplateColumns,
          }}
        >
          {showRowIndex ? <div></div> : null}

          {visibleColumns.map((column) => (
            <div
              className={`text-nowrap ${column.className ?? ""} ${
                column.headerClassName ?? ""
              }`}
              key={column.key}
            >
              {column.header({ displayType: "desktop" })}
            </div>
          ))}

          {rows.map((row, rowIndex) => {
            return (
              <React.Fragment key={`${row[rowKey]}`}>
                {showRowIndex ? (
                  <div style={{ marginRight: "-15px" }}>#{rowIndex + 1}</div>
                ) : null}

                {visibleColumns.map((column) => (
                  <DesktopElement
                    key={`${row[rowKey]}_${column.key}`}
                    column={column}
                    rowIndex={rowIndex}
                    row={row}
                  />
                ))}
              </React.Fragment>
            );
          })}
        </div>
      </div>
      <div className={`d-block d-${breakpoint}-none`}>
        {rows.map((row, rowIndex) => (
          <React.Fragment key={row[rowKey] as unknown as string}>
            {typeof mobileHeader === "function"
              ? mobileHeader(row, rowIndex)
              : null}

            {visibleColumns
              .filter((c) => !c.hideOnMobile)
              .map((column) => (
                <MobileElement
                  key={column.key}
                  column={column}
                  row={row}
                  rowIndex={rowIndex}
                />
              ))}
            {rowIndex < rows.length - 1 ? (
              <hr data-testid="smallRowSeparator" />
            ) : null}
          </React.Fragment>
        ))}
      </div>
    </>
  );
}

function DesktopElement<T>({
  row,
  column,
  rowIndex,
}: {
  row: T;
  column: Column<T>;
  rowIndex: number;
}) {
  return (
    <>
      <div
        className={`${column.className ?? ""} ${column.contentClassName ?? ""}`}
        data-testid={column.cellTestId}
      >
        {getCellContents<T>(row, column, rowIndex, "desktop")}
      </div>
    </>
  );
}

function MobileElement<T>({
  row,
  column,
  rowIndex,
}: {
  row: T;
  column: Column<T>;
  rowIndex: number;
}) {
  const labelRef = useRef<HTMLLabelElement>(null);
  return (
    <div className="mb-3">
      <div className={column.headerClassName}>
        {column.header({ displayType: "mobile", index: rowIndex, labelRef })}
      </div>
      <div>{getCellContents<T>(row, column, rowIndex, "mobile", labelRef)}</div>
    </div>
  );
}

function getCellContents<T>(
  row: T,
  column: Column<T>,
  index: number,
  displayType: ResponsiveGridTableDisplayType,
  labelRef?: React.RefObject<HTMLLabelElement>
): React.ReactNode {
  const keyOrFunction = column.cell;

  if (typeof keyOrFunction === "function") {
    return keyOrFunction({ rowObject: row, index, displayType, labelRef });
  } else {
    return row[keyOrFunction];
  }
}
