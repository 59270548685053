import React, { CSSProperties, useContext, useState } from "react";
import { LanguageContext } from "../App";
import { PayrollGetCrewMembersMode } from "../enums/PayrollGetCrewMembersMode";
import { strings } from "../languages";
import { IScheduleIdentifier } from "../models/IScheduleIdentifier";
import PayrollClockInOutModal from "./PayrollClockInOutModal";

interface IProps {
  scheduleIdentifier: IScheduleIdentifier;
}

const PayrollClockInOutButtons: React.FunctionComponent<IProps> = ({
  scheduleIdentifier,
}) => {
  const [modalMode, setModalMode] = useState<PayrollGetCrewMembersMode | null>(
    null
  );

  const buttonStyles: Partial<CSSProperties> = {
    fontSize: "x-large",
    height: "100px",
  };

  const languageContext = useContext(LanguageContext);

  return (
    <>
      <div className="row" style={{ marginTop: "10px", marginBottom: "20px" }}>
        <div className="col text-center">
          <button
            className={
              languageContext.language === "en"
                ? "btn btn-block btn-success"
                : "btn btn-block mb-3 btn-success"
            }
            style={buttonStyles}
            onClick={() => setModalMode(PayrollGetCrewMembersMode.clockIn)}
          >
            {strings.clockInUpper}
          </button>
        </div>
        <div className="col text-center">
          <button
            className="btn btn-block btn-danger"
            style={buttonStyles}
            onClick={() => setModalMode(PayrollGetCrewMembersMode.clockOut)}
          >
            {strings.clockOutUpper}
          </button>
        </div>
      </div>
      {modalMode !== null ? (
        <PayrollClockInOutModal
          scheduleIdentifier={scheduleIdentifier}
          mode={modalMode}
          onClose={() => setModalMode(null)}
        />
      ) : null}
    </>
  );
};

export default PayrollClockInOutButtons;
