export function PageErrorLoading({
  retryLoad,
  headerOverride,
  errorDetails,
  disableRetry = false,
}: {
  retryLoad(): void;
  headerOverride?: string;
  errorDetails?: string;
  disableRetry?: boolean;
}) {
  return (
    <>
      <div>
        <div className="font-weight-bold">
          {headerOverride ?? "Form Could Not Load"}
        </div>
        <div>
          {errorDetails ??
            "Please check your Internet connection and try again."}
        </div>

        {!disableRetry ? (
          <button
            type="button"
            className="btn btn-secondary mt-3"
            onClick={retryLoad}
            data-testid="tryAgainButton"
          >
            Try Again
          </button>
        ) : null}
      </div>
    </>
  );
}
