import { Observable, of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { map, catchError } from "rxjs/operators";
import ISchedule from "../models/ISchedule";
import IScheduledJob from "../models/IScheduleJob";
import configuration from "./configuration";
import IFileUploadProperties from "../libraries/formAttachments/models/IFileUploadProperties";
import { IFile } from "../models/IFile";
import format from "date-fns/format";
import { IClockStartResponse } from "../models/IClockStartResponse";
import { IClockEndResponse } from "../models/IClockEndResponse";
import { strings } from "../languages";
import { IGetEstimatedJobTimesRequest } from "../models/IGetEstimatedJobTimesRequest";
import { IGetEstimatedJobTimesResponse } from "../models/IGetEstimatedJobTimesResponse";
import {
  IAutoSaveRequest,
  IAutoSaveResponse,
  AutoSaveResponseStatus,
} from "../models/IAutoSave";
import { getFileComponents } from "../libraries/formAttachments/services/fileService";
import { IScheduledJobUpdateResponse } from "../models/IScheduledJobUpdateResponse";
import { IPhotoGetResponses } from "../models/IPhotoGetResponses";
import { PayrollGetCrewMembersMode } from "../enums/PayrollGetCrewMembersMode";
import { IPayrollCrewMember } from "../models/IPayrollCrewMember";
import { IScheduleIdentifier } from "../models/IScheduleIdentifier";
import {
  getAccessToken,
  hasAuthenticationExpired,
} from "../slices/app/services/authentication";

export const buildFullUrl = (path: string) =>
  `${configuration.urlBase}/crewapi/${path}`;

export const buildFullUrlWithDayOffset = (path: string, dayOffset: number) => {
  return `${configuration.urlBase}/crewapi/${path}?dayOffset=${dayOffset}`;
};

export function getHeaders() {
  const language = strings.getLanguage();
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    // Pass in country code so correct currency symbols used.
    "Accept-Language": `${language}-US`,
  };

  const accessToken = getAccessToken();
  if (!hasAuthenticationExpired() && accessToken) {
    return {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    };
  } else {
    return headers;
  }
}

const formatTimeForSave = (input: Date) => format(input, "HH:mm") + ":00";

const dataProvider = {
  getSchedule: (
    scheduleIdentifier: IScheduleIdentifier
  ): Observable<ISchedule> => {
    let headers: object = getHeaders();

    return ajax
      .get(
        buildFullUrlWithDayOffset(
          `crew/${scheduleIdentifier.linkedDayScheduleId}`,
          scheduleIdentifier.dayOffset
        ),
        headers
      )
      .pipe(
        map((response) => {
          // TODO: Handle errors
          return {
            ...response.response,
            id: scheduleIdentifier.linkedDayScheduleId,
          } as ISchedule;
        })
      );
  },

  getEstimatedJobTimes: (
    request: IGetEstimatedJobTimesRequest
  ): Observable<IGetEstimatedJobTimesResponse> => {
    return ajax
      .post(buildFullUrl("crew/GetEstimatedTimes"), request, getHeaders())
      .pipe(
        map((response) => {
          // TODO: Handle errors
          return response.response as IGetEstimatedJobTimesResponse;
        })
      );
  },

  getFileUploadProperties: (
    scheduleIdentifier: IScheduleIdentifier
  ): Observable<IFileUploadProperties> => {
    return ajax
      .get(
        buildFullUrlWithDayOffset(
          `crew/${scheduleIdentifier.linkedDayScheduleId}/sastoken`,
          scheduleIdentifier.dayOffset
        ),
        getHeaders()
      )
      .pipe(map((r) => r.response as IFileUploadProperties));
  },

  uploadImage: (scheduleIdentifier: IScheduleIdentifier, file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    const headers: any = getHeaders();
    delete headers["Content-Type"];

    return ajax
      .post(
        buildFullUrlWithDayOffset(
          `crew/${scheduleIdentifier.linkedDayScheduleId}/imageUpload`,
          scheduleIdentifier.dayOffset
        ),
        formData,
        headers
      )
      .pipe(
        map(
          (response) =>
            response.response as {
              imagePath: string;
              actualWidth: number;
              actualHeight: number;
              thumbnails: Array<{
                imagePath: string;
                thumbnailKey: number;
                actualWidth: number;
                actualHeight: number;
              }>;
            }
        )
      );
  },

  updateScheduleJob: (
    id: string,
    updatedFields: Partial<IScheduledJob>
  ): Observable<IScheduledJobUpdateResponse> => {
    return ajax
      .patch(
        buildFullUrl(`scheduledjob/${id}`),
        JSON.stringify({
          ...updatedFields,
          time: formatTimeForSave(new Date()),
        }),
        getHeaders()
      )
      .pipe(
        map((result) => {
          return result.response as IScheduledJobUpdateResponse;
        })
      );
  },

  autoSave: (
    id: string,
    request: IAutoSaveRequest
  ): Observable<IAutoSaveResponse> => {
    return ajax
      .post(
        buildFullUrl(`scheduledjob/${id}/autosave`),
        JSON.stringify(request),
        getHeaders()
      )
      .pipe(
        map(() => {
          return {
            newValue: request.newValue,
            status: AutoSaveResponseStatus.OK,
          } as IAutoSaveResponse;
        }),
        catchError((err) => {
          let status = AutoSaveResponseStatus.UnknownError;
          let errorMessage =
            "An error occurred while saving.  Please verify your Internet connection.";
          if (err && err.response) {
            if (err.response.errorCode === "AutoSaveNotesConcurrency") {
              status = AutoSaveResponseStatus.ConcurrencyError;
            }

            if (typeof err.response === "string") {
              errorMessage = err.response;
            } else if (typeof err.response?.message) {
              errorMessage = err.response.message;
            }
          }

          return of({
            newValue: request.newValue,
            status,
            errorMessage,
          } as IAutoSaveResponse);
        })
      );
  },

  getPhotoUrl(
    imagePrefix: string,
    thumbnailKey: number,
    photo: IFile
  ): { url: string; width: number; height: number } | null {
    const matchingItem = photo.thumbnails.find(
      (t) => t.thumbnailKey === thumbnailKey
    );
    if (!matchingItem) {
      console.log("matchingItem not found");
      return null;
    }

    const fileComponents = getFileComponents(matchingItem.imagePath);
    const path = fileComponents.success
      ? fileComponents.linkUrl
      : matchingItem.imagePath;

    return {
      url: `${imagePrefix}/${path}`,
      height: matchingItem.actualHeight,
      width: matchingItem.actualWidth,
    };
  },

  notifyOnTheWay({
    scheduleIdentifier,
    scheduleJobId,
    crewMemberName,
    crewMemberPhoneNumber,
    approximateTimeMinutes,
  }: {
    scheduleIdentifier: IScheduleIdentifier;
    scheduleJobId: string;
    crewMemberName: string;
    crewMemberPhoneNumber: string;
    approximateTimeMinutes: number | null;
  }) {
    return ajax.post(
      buildFullUrl(
        `scheduledjob/${scheduleIdentifier.linkedDayScheduleId}/${scheduleJobId}/notifyontheway?dayOffset=${scheduleIdentifier.dayOffset}`
      ),
      {
        crewMemberName: crewMemberName,
        crewMemberPhoneNumber: crewMemberPhoneNumber,
        approximateTimeMinutes: approximateTimeMinutes,
      },
      getHeaders()
    );
  },

  addPhoto: (
    id: string,
    imagePath: string,
    contentType: string,
    caption: string,
    actualWidth: number | null,
    actualHeight: number | null,
    thumbnails: Array<{
      imagePath: string;
      thumbnailKey: number;
      actualWidth: number;
      actualHeight: number;
    }>,
    timestamp: string | null
  ): Observable<IFile> => {
    return ajax
      .post(
        buildFullUrl(`scheduledjob/${id}/photo`),
        JSON.stringify({
          imagePath,
          caption,
          contentType,
          thumbnails,
          actualWidth,
          actualHeight,
          timestamp,
        }),
        getHeaders()
      )
      .pipe(
        map((result) => {
          return result.response as IFile;
        })
      );
  },

  updatePhoto: (
    scheduledJobId: string,
    photoId: string,
    update: Partial<IFile>
  ): Observable<{}> => {
    return ajax.patch(
      buildFullUrl(`scheduledjob/${scheduledJobId}/photo/${photoId}`),
      JSON.stringify(update),
      getHeaders()
    );
  },

  getPhoto: (
    scheduledJobId: string,
    photoId: string
  ): Observable<IPhotoGetResponses> => {
    return ajax
      .get(
        buildFullUrl(`scheduledjob/${scheduledJobId}/photo/${photoId}`),
        getHeaders()
      )
      .pipe(
        map((result) => {
          return result.response as IPhotoGetResponses;
        })
      );
  },

  deletePhoto: (scheduledJobId: string, photoId: string): Observable<{}> => {
    return ajax.delete(
      buildFullUrl(`scheduledjob/${scheduledJobId}/photo/${photoId}`),
      getHeaders()
    );
  },

  startClock: (
    scheduledJobId: string,
    time: Date,
    scheduleIdentifier: IScheduleIdentifier
  ): Observable<IClockStartResponse> => {
    return ajax
      .post(
        buildFullUrl(`scheduledjob/${scheduledJobId}/clockstartV2`),
        JSON.stringify({
          time: formatTimeForSave(time),
          originalScheduleLookupId: scheduleIdentifier.linkedDayScheduleId,
          viewedScheduleDayOffset: scheduleIdentifier.dayOffset,
        }),
        getHeaders()
      )
      .pipe(
        map((result) => {
          return result.response as IClockStartResponse;
        })
      );
  },

  endClock: (
    scheduledJobId: string,
    time: Date,
    timeRangeIdToComplete: string,
    completed: boolean
  ): Observable<IClockEndResponse> => {
    return ajax
      .post(
        buildFullUrl(`scheduledjob/${scheduledJobId}/clockend`),
        JSON.stringify({
          time: formatTimeForSave(time),
          timeRangeIdToComplete,
          completed,
        }),
        getHeaders()
      )
      .pipe(
        map((result) => {
          return result.response as IClockEndResponse;
        })
      );
  },

  shopClockIn: (scheduleIdentifier: IScheduleIdentifier, time: Date) => {
    const formattedTime = formatTimeForSave(time);
    return ajax
      .post(
        buildFullUrlWithDayOffset(
          `crew/${scheduleIdentifier.linkedDayScheduleId}/shopclockin`,
          scheduleIdentifier.dayOffset
        ),
        JSON.stringify({
          time: formattedTime,
        }),
        getHeaders()
      )
      .pipe(
        map(() => {
          return {
            formattedTime,
          };
        })
      );
  },

  shopClockOut: (scheduleIdentifier: IScheduleIdentifier, time: Date) => {
    const formattedTime = formatTimeForSave(time);
    return ajax
      .post(
        buildFullUrlWithDayOffset(
          `crew/${scheduleIdentifier.linkedDayScheduleId}/shopclockout`,
          scheduleIdentifier.dayOffset
        ),
        JSON.stringify({
          time: formattedTime,
        }),
        getHeaders()
      )
      .pipe(
        map(() => {
          return {
            formattedTime,
          };
        })
      );
  },

  getPayrollCrewMembers: (
    scheduleIdentifier: IScheduleIdentifier,
    mode: PayrollGetCrewMembersMode
  ) => {
    return ajax
      .get(
        buildFullUrlWithDayOffset(
          `crew/${scheduleIdentifier.linkedDayScheduleId}/PayrollCrewMembers`,
          scheduleIdentifier.dayOffset
        ) + `&mode=${mode}`,
        getHeaders()
      )
      .pipe(
        map((result) => {
          return result.response.crewMembers as Array<IPayrollCrewMember>;
        })
      );
  },

  payrollClockIn: (
    scheduleIdentifier: IScheduleIdentifier,
    crewMemberIds: Array<string>
  ) => {
    return ajax.post(
      buildFullUrlWithDayOffset(
        `crew/${scheduleIdentifier.linkedDayScheduleId}/PayrollClockIn`,
        scheduleIdentifier.dayOffset
      ),
      { crewMemberIds },
      getHeaders()
    );
  },

  payrollClockOut: (
    scheduleIdentifier: IScheduleIdentifier,
    crewMemberIds: Array<string>
  ) => {
    return ajax.post(
      buildFullUrlWithDayOffset(
        `crew/${scheduleIdentifier.linkedDayScheduleId}/PayrollClockOut`,
        scheduleIdentifier.dayOffset
      ),
      { crewMemberIds },
      getHeaders()
    );
  },
};

export default dataProvider;
