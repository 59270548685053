import uuidv4 from "uuid/v4";
import {
  AnonymousCredential,
  StorageURL,
  ServiceURL,
  ContainerURL,
  BlockBlobURL,
  uploadBrowserDataToBlockBlob,
  Aborter,
  IUploadToBlockBlobOptions,
} from "@azure/storage-blob";
import { from, Observable, Observer, forkJoin, of, concat } from "rxjs";
import IFileUploadProperties from "../models/IFileUploadProperties";
import {
  catchError,
  flatMap,
  map,
  mergeMap,
  retry,
  tap,
  timeout,
  toArray,
} from "rxjs/operators";
import { Profiler } from "./profiler";
import { PicaService } from "./image-resize/pica.service";
import dataProvider from "../../../services/DataProvider";
import { IScheduleIdentifier } from "../../../models/IScheduleIdentifier";
import { exifParser } from "./exifParser";

const pica = new PicaService();

function getSasToken(scheduleIdentifier: IScheduleIdentifier) {
  return dataProvider
    .getFileUploadProperties(scheduleIdentifier)
    .pipe(timeout(2000), retry(2));
}

function resizeAndUploadImage(
  scheduleIdentifier: IScheduleIdentifier,
  file: File
): Observable<Array<IUploadResult & { timestamp: string | null }>> {
  return forkJoin({
    uploadProperties: getSasToken(scheduleIdentifier),
    resizedImages: convertFileToImage(file).pipe(
      map((r) => r.img),
      flatMap((img) =>
        resizeImage(img, file.name, file.type, 1920).pipe(
          map((r) => ({
            ...r,
            // Set to null to indicate full size image
            thumbnailKey: null,
          })),
          flatMap((r) =>
            forkJoin(
              exifParser(img).pipe(
                catchError((err) => {
                  console.log(err);
                  console.log("error getting exif data");
                  return of({ timestamp: null });
                })
              ),
              of(r as IResizeImageResult),
              getThumbnails(r, file)
            )
          ),
          map(([exifData, fullSize, thumbnails]) => {
            return {
              timestamp: exifData.timestamp,
              images: [fullSize, ...thumbnails],
            };
          })
        )
      )
    ),
  }).pipe(
    flatMap(({ uploadProperties, resizedImages }) => {
      return forkJoin(
        resizedImages.images.map((result) =>
          uploadImage(result.profiler, uploadProperties, file, result).pipe(
            tap((r) => console.log(r.profiler.getOutput())),
            map((r) => ({
              ...r,
              timestamp: resizedImages.timestamp,
            }))
          )
        )
      );
    })
  );
}

function uploadFile(scheduleIdentifier: IScheduleIdentifier, file: File) {
  return getSasToken(scheduleIdentifier).pipe(
    flatMap((uploadProperties) => {
      const uploadResult = uploadFileInternal(
        uploadProperties,
        file,
        file,
        null
      );

      return uploadResult.uploadObservable.pipe(
        map(() => ({
          imagePath: uploadResult.imagePath,
        }))
      );
    })
  );
}

function uploadSignature(
  scheduleIdentifier: IScheduleIdentifier,
  fileData: Blob
) {
  return getSasToken(scheduleIdentifier).pipe(
    mergeMap((uploadProperties) => {
      const filePrefix = uuidv4();
      const imagePath = `${filePrefix}/signature.png`;
      const blobUrl = getBlobUrl(uploadProperties, imagePath);

      return forkJoin([
        from(uploadBrowserDataToBlockBlob(Aborter.none, fileData, blobUrl, {})),
        of(imagePath),
      ]);
    }),
    map(([_, imagePath]) => imagePath)
  );
}

function convertFileToImage(
  blob: Blob
): Observable<{ img: HTMLImageElement; profiler: Profiler }> {
  var profiler = new Profiler("convertFileToImage");
  profiler.logEntry("start convertFileToImage");

  return Observable.create(
    (observer: Observer<{ img: HTMLImageElement; profiler: Profiler }>) => {
      const img = new Image();
      img.onload = () => {
        profiler.logEntry("img loaded");
        observer.next({ img, profiler });
        observer.complete();
      };
      img.onerror = (error) => {
        observer.error(error);
      };
      img.src = window.URL.createObjectURL(blob);
    }
  );
}

export {
  resizeAndUploadImage,
  uploadFile,
  uploadSignature,
  convertFileToImage,
};

function uploadFileInternal(
  uploadProperies: IFileUploadProperties,
  fileForName: File,
  fileToUpload: Blob,
  suffix: number | null
) {
  const filePrefix = uuidv4();
  const imagePath = getBlobName(filePrefix, fileForName, suffix);
  const blobUrl = getBlobUrl(uploadProperies, imagePath);

  const options: IUploadToBlockBlobOptions = {
    blobHTTPHeaders: {
      blobContentType: fileForName.type,
    },
  };

  return {
    uploadObservable: from(
      uploadBrowserDataToBlockBlob(Aborter.none, fileToUpload, blobUrl, options)
    ),
    imagePath,
  };
}

function uploadImage(
  profiler: Profiler,
  uploadProperies: IFileUploadProperties,
  file: File,
  fileToUpload: IResizeImageResult
): Observable<IUploadResult> {
  profiler.logEntry("start uploadImage");

  const uploadFileResult = uploadFileInternal(
    uploadProperies,
    file,
    fileToUpload.blob,
    fileToUpload.thumbnailKey
  );

  return uploadFileResult.uploadObservable.pipe(
    tap(() => profiler.logEntry("end uploadImage")),
    map(() => ({
      ...fileToUpload,
      imagePath: uploadFileResult.imagePath,
    }))
  );
}

function getThumbnails(
  source: IResizeImageResult,
  originalFile: File
): Observable<Array<IResizeImageResult>> {
  return convertFileToImage(source.blob).pipe(
    flatMap((r) =>
      concat(
        resizeImage(r.img, originalFile.name, originalFile.type, 280),
        resizeImage(r.img, originalFile.name, originalFile.type, 350)
      ).pipe(toArray())
    ),
    tap((r) => {
      window.URL.revokeObjectURL(r[0].sourceImage.src);
    })
  );
}

function resizeImage(
  image: HTMLImageElement,
  fileName: string,
  fileType: string,
  width: number,
  height?: number
): Observable<IResizeImageResult> {
  const profiler = new Profiler(`width: ${width}`);
  profiler.logEntry("start resize");

  return pica
    .resize(image, fileName, fileType, width, height ?? 1920, true)
    .pipe(
      tap(() => {
        profiler.logEntry("end resize");
      }),
      flatMap((f) =>
        convertBlobToArrayBuffer({
          profiler,
          blob: f.file,
          width: f.width,
          height: f.height,
        })
      ),
      map((r) => ({
        sourceImage: image,
        profiler,
        blob: r.blob,
        buffer: r.buffer,
        thumbnailKey: width,
        actualHeight: r.height,
        actualWidth: r.width,
      }))
    );
}

function convertBlobToArrayBuffer(result: {
  profiler: Profiler;
  blob: Blob;
  width: number;
  height: number;
}): Observable<{
  blob: Blob;
  buffer: ArrayBuffer;
  width: number;
  height: number;
}> {
  return Observable.create(
    (
      observer: Observer<{
        blob: Blob;
        buffer: ArrayBuffer;
        width: number;
        height: number;
      }>
    ) => {
      const reader = new FileReader();
      result.profiler.logEntry("start convertBlobToArrayBuffer");

      reader.onload = () => {
        result.profiler.logEntry("complete convertBlobToArrayBuffer");

        const arrayBuffer = reader.result as ArrayBuffer;
        observer.next({
          blob: result.blob,
          buffer: arrayBuffer,
          width: result.width,
          height: result.height,
        });
        observer.complete();
      };

      reader.onerror = () => {
        observer.error(reader.error);
      };

      reader.readAsArrayBuffer(result.blob);
    }
  );
}

function getBlobUrl(result: IFileUploadProperties, blobName: string) {
  const anonymousCredential = new AnonymousCredential();
  const pipeline = StorageURL.newPipeline(anonymousCredential);
  const serviceUrl = new ServiceURL(`${result.uri}${result.token}`, pipeline);
  const containerUrl = ContainerURL.fromServiceURL(
    serviceUrl,
    result.containerName
  );
  const blobUrl = BlockBlobURL.fromContainerURL(containerUrl, blobName);
  return blobUrl;
}

function getBlobName(
  filePrefix: string,
  file: File,
  thumbnailKey: number | null
) {
  let fileNameToSave: string;
  if (typeof thumbnailKey === "number") {
    if (file.name.indexOf(".") !== -1) {
      const parts = file.name.split(".");

      const nonExtensionParts = parts.slice(0, parts.length - 1);
      const extension = parts.slice(-1);

      fileNameToSave = `${nonExtensionParts.join(
        "."
      )}_${thumbnailKey}.${extension}`;
    } else {
      fileNameToSave = `${file.name}_${thumbnailKey}`;
    }
  } else {
    fileNameToSave = file.name;
  }

  return `${filePrefix}/${fileNameToSave}`;
}

interface IImageVersion {
  profiler: Profiler;

  thumbnailKey: number | null;

  actualWidth: number;
  actualHeight: number;
}

export interface IUploadResult extends IImageVersion {
  imagePath: string;
}

interface IResizeImageResult extends IImageVersion {
  buffer: ArrayBuffer;
  blob: Blob;
  sourceImage: HTMLImageElement;
}
