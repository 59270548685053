import React from "react";

interface IProps {
  errorHeader: string;
  errorDetails: string;
}

const Error: React.FunctionComponent<IProps> = ({
  errorHeader,
  errorDetails,
}) => (
  <div className="container">
    <div className="jumbotron bg-secondary my-5">
      <h6 className="display-4">{errorHeader}</h6>
      <div className="lead">{errorDetails}</div>
    </div>
  </div>
);

export default Error;
