import {
  faCheck,
  faExclamationTriangle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState, useEffect, useReducer } from "react";
import { Observable, Subject, interval, merge } from "rxjs";
import {
  debounce,
  switchMap,
  delay,
  tap,
  distinctUntilChanged,
} from "rxjs/operators";
import { AutoSaveResponseStatus, IAutoSaveResponse } from "../models/IAutoSave";
import {
  fullStoryLogError,
  fullStoryLogInfo,
} from "../services/fullStoryService";

interface IProps {
  initialValue: string;

  saveChange(args: {
    value: string;
    oldValues: Array<string>;
  }): Observable<IAutoSaveResponse>;
  onSaveComplete(newValue: string): void;

  renderLabel: () => JSX.Element;
  renderInputField(args: {
    value: string;
    onChange: (newValue: string) => void;
    onFocus: () => void;
    onBlur: () => void;
  }): JSX.Element;
}

enum SaveState {
  noAction,
  saving,
  saveComplete,
  error,
}

function saveStateReducer(state: SaveState, action: SaveState) {
  if (action === SaveState.noAction && state === SaveState.error) {
    return state;
  }

  return action;
}

const EditableFieldBase: React.FunctionComponent<IProps> = ({
  renderLabel,
  onSaveComplete,
  initialValue,
  renderInputField,
  saveChange,
}) => {
  const elRef = useRef<HTMLDivElement>(null);
  const previousInitialValueForAutoSave = useRef([""]);
  const [value, setValue] = useState("");
  const [hasFocus, setHasFocus] = useState(false);
  const [lastSavedValue, setLastSavedValue] = useState("");

  const previousInitialValue = useRef<string | null>(null);
  useEffect(() => {
    if (!hasFocus && initialValue !== previousInitialValue.current) {
      const normalizedInitialValue = initialValue || "";

      setValue(normalizedInitialValue);
      setLastSavedValue(normalizedInitialValue);

      previousInitialValueForAutoSave.current = [normalizedInitialValue];

      fullStoryLogInfo(
        `Crew: EditableTextField changed initial value: ${initialValue}`
      );

      previousInitialValue.current = initialValue;
    }
  }, [initialValue, hasFocus]);

  const {
    saveSubject,
    changeSubject,
    retrySave,
    saveErrorMessage,
    saveState,
    saveStateDispatch,
    showSaveButton,
  } = useSetupDebouncedSaving({
    setLastSavedValue,
    previousInitialValueForAutoSave,
    saveChange,
    onSaveComplete,
  });

  return (
    <div ref={elRef}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <div>{renderLabel()}</div>
        {saveState !== SaveState.noAction ? (
          <div>
            <span>
              {saveState === SaveState.saving ? (
                <>
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    fixedWidth
                    data-testid="savingIndicator"
                  />
                </>
              ) : saveState === SaveState.saveComplete ? (
                <FontAwesomeIcon
                  icon={faCheck}
                  data-testid="saveConfirmIndicator"
                />
              ) : (
                <FontAwesomeIcon
                  data-testid="errorIndicator"
                  icon={faExclamationTriangle}
                  className="text-danger"
                ></FontAwesomeIcon>
              )}
            </span>
          </div>
        ) : null}
      </div>

      {renderInputField({
        value: value,

        onChange: (newValue) => {
          setValue(newValue);
          changeSubject.current.next(newValue);
        },

        onFocus: () => {
          if (elRef.current) {
            elRef.current.scrollIntoView(true);
          }
          setHasFocus(true);
          saveStateDispatch(SaveState.noAction);
        },

        onBlur: () => {
          if (lastSavedValue !== value) {
            saveSubject.current.next(value);
          }

          setHasFocus(false);
        },
      })}

      {saveErrorMessage || showSaveButton ? (
        <div>
          {saveErrorMessage ? (
            <div data-testid="errorMessage" className="text-danger">
              {saveErrorMessage}
            </div>
          ) : null}
          {showSaveButton ? (
            <button
              type="button"
              className="btn btn-primary btn-block"
              onClick={() => {
                retrySave.current.next(value);
              }}
              style={{ marginTop: "10px" }}
            >
              Retry Save
            </button>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default EditableFieldBase;

function useSetupDebouncedSaving({
  setLastSavedValue,
  previousInitialValueForAutoSave,
  saveChange,
  onSaveComplete,
}: {
  setLastSavedValue: React.Dispatch<React.SetStateAction<string>>;
  previousInitialValueForAutoSave: React.MutableRefObject<string[]>;
  saveChange: (args: {
    value: string;
    oldValues: Array<string>;
  }) => Observable<IAutoSaveResponse>;
  onSaveComplete: (newValue: string) => void;
}) {
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [saveErrorMessage, setSaveErrorMessage] = useState("");
  const [saveState, saveStateDispatch] = useReducer(
    saveStateReducer,
    SaveState.noAction
  );

  const changeSubject = useRef(new Subject<string>());
  const saveSubject = useRef(new Subject<string>());
  const retrySave = useRef(new Subject<string>());

  // Store the latest copy of the prop functions
  // so the useEffect uses the latest copy rather than the
  // captured value when the useEffect ran.
  // Ideally would use https://react.dev/reference/react/experimental_useEffectEvent
  // but it isn't released yet.
  const onSaveCompleteLatestPropValue = useRef(onSaveComplete);
  onSaveCompleteLatestPropValue.current = onSaveComplete;

  const saveChangeLatestPropValue = useRef(saveChange);
  saveChangeLatestPropValue.current = saveChange;

  useEffect(() => {
    const eventStream = changeSubject.current.pipe(
      debounce(() => interval(750)),
      distinctUntilChanged(),
      tap(() => setShowSaveButton(false))
    );

    const subscription = merge(
      merge(eventStream, saveSubject.current).pipe(distinctUntilChanged()),
      retrySave.current
    )
      .pipe(
        tap((value) => {
          saveStateDispatch(SaveState.saving);

          setLastSavedValue(value);
        }),
        switchMap((value) => {
          const originalPreviousValues =
            previousInitialValueForAutoSave.current;

          previousInitialValueForAutoSave.current = [
            ...previousInitialValueForAutoSave.current,
            value,
          ];

          return saveChangeLatestPropValue.current({
            value,
            oldValues: originalPreviousValues,
          });
        }),
        tap((response) => {
          if (response.status === AutoSaveResponseStatus.OK) {
            saveStateDispatch(SaveState.saveComplete);
            onSaveCompleteLatestPropValue.current(response.newValue);

            setSaveErrorMessage("");
          } else {
            saveStateDispatch(SaveState.error);
            setSaveErrorMessage(response.errorMessage || "");
          }
          setShowSaveButton(
            response.status === AutoSaveResponseStatus.UnknownError
          );

          if (response.status === AutoSaveResponseStatus.ConcurrencyError) {
            fullStoryLogError("Crew: Concurrency error in autosave");
          }
        }),
        delay(3000)
      )
      .subscribe(() => {
        saveStateDispatch(SaveState.noAction);
      });

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, [previousInitialValueForAutoSave, setLastSavedValue]);

  return {
    changeSubject,
    saveSubject,
    retrySave,
    saveErrorMessage,
    saveState,
    saveStateDispatch,
    showSaveButton,
  };
}
