import React, { useEffect, useState } from "react";
import Modal from "reactstrap/lib/Modal";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalFooter from "reactstrap/lib/ModalFooter";
import ModalBody from "reactstrap/lib/ModalBody";
import { PayrollGetCrewMembersMode } from "../enums/PayrollGetCrewMembersMode";
import { IPayrollCrewMember } from "../models/IPayrollCrewMember";
import Spinner from "./Spinner";
import dataProvider from "../services/DataProvider";
import Select from "react-select";
import { getSortedItemsV2 } from "../services/sortingService";
import { Observable } from "rxjs";
import { AjaxResponse } from "rxjs/ajax";
import { strings } from "../languages";
import { IScheduleIdentifier } from "../models/IScheduleIdentifier";

interface IProps {
  scheduleIdentifier: IScheduleIdentifier;
  mode: PayrollGetCrewMembersMode;
  onClose: () => void;
}

const PayrollClockInOutModal: React.FunctionComponent<IProps> = ({
  scheduleIdentifier,
  mode,
  onClose,
}) => {
  const [availableCrewMembers, setAvailableCrewMembers] = useState<
    Array<IPayrollCrewMember>
  >([]);
  const [selectedCrewMemberIds, setSelectedCrewMemberIds] = useState<
    Array<string>
  >([]);
  const [loadingOrSaving, setLoadingOrSaving] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setLoadingOrSaving(true);
    setErrorLoading(false);

    const subscription = dataProvider
      .getPayrollCrewMembers(scheduleIdentifier, mode)
      .subscribe(
        (result) => {
          setLoadingOrSaving(false);
          setAvailableCrewMembers(getSortedItemsV2(result, ["name"]));

          if (mode === PayrollGetCrewMembersMode.clockOut) {
            // Default all active crew members to clock out
            setSelectedCrewMemberIds(result.map((r) => r.id));
          } else {
            // Default all crew members in the crew to clock in
            setSelectedCrewMemberIds(
              result.filter((r) => r.memberOfCrewRoster).map((r) => r.id)
            );
          }
        },
        (err) => {
          setLoadingOrSaving(false);
          setErrorLoading(true);

          if (err.response) {
            setErrorMessage(err.response);
          }
        }
      );

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, [
    scheduleIdentifier,
    mode,
    setLoadingOrSaving,
    setErrorLoading,
    setAvailableCrewMembers,
  ]);

  return (
    <>
      {loadingOrSaving && <Spinner />}
      <Modal toggle={onClose} isOpen={true}>
        <ModalHeader>
          {mode === PayrollGetCrewMembersMode.clockIn
            ? strings.clockIn
            : strings.clockOut}
        </ModalHeader>
        <ModalBody>
          {!errorLoading ? (
            <>
              <div>
                <label htmlFor="crewMembers">{strings.chooseEmployees}</label>
              </div>
              <div>
                <Select
                  inputId="crewMembers"
                  value={availableCrewMembers.filter((cm) =>
                    selectedCrewMemberIds.includes(cm.id)
                  )}
                  onChange={(selectedOptions) => {
                    setErrorMessage("");
                    if (selectedOptions) {
                      setSelectedCrewMemberIds(
                        (selectedOptions as Array<IPayrollCrewMember>).map(
                          (c) => c.id
                        )
                      );
                    } else {
                      setSelectedCrewMemberIds([]);
                    }
                  }}
                  placeholder={`${strings.search}...`}
                  noOptionsMessage={() => strings.noAvailableCrewMembers}
                  options={availableCrewMembers}
                  isMulti={true}
                  getOptionLabel={(cm) => cm.name}
                  getOptionValue={(cm) => cm.id}
                />
              </div>
              {errorMessage ? (
                <div className="text-danger">{errorMessage}</div>
              ) : null}
            </>
          ) : (
            <div>
              {errorMessage.length > 0
                ? errorMessage
                : strings.errorLoadingCrewMembers}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          {!errorLoading ? (
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => {
                if (selectedCrewMemberIds.length === 0) {
                  setErrorMessage(strings.mustSelectCrewMember);
                  return;
                }

                setLoadingOrSaving(true);
                setErrorMessage("");

                let saveObservable: Observable<AjaxResponse>;
                if (mode === PayrollGetCrewMembersMode.clockIn) {
                  saveObservable = dataProvider.payrollClockIn(
                    scheduleIdentifier,
                    selectedCrewMemberIds
                  );
                } else {
                  saveObservable = dataProvider.payrollClockOut(
                    scheduleIdentifier,
                    selectedCrewMemberIds
                  );
                }

                saveObservable.subscribe(
                  () => {
                    setLoadingOrSaving(false);
                    onClose();
                  },
                  () => {
                    setLoadingOrSaving(false);
                    setErrorMessage(
                      mode === PayrollGetCrewMembersMode.clockIn
                        ? strings.errorClockingIn
                        : strings.errorClockingOut
                    );
                  }
                );
              }}
            >
              {mode === PayrollGetCrewMembersMode.clockIn
                ? strings.clockIn
                : strings.clockOut}
            </button>
          ) : null}
          <button className="btn btn-secondary" type="button" onClick={onClose}>
            {strings.cancel}
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PayrollClockInOutModal;
