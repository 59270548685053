import { useIsLargeLineItemDisplay } from "../hooks/useIsLargeLineItemDisplay";
import { IInvoiceItem } from "../types/IInvoiceItem";
import { ILineItem } from "./BillableFormLineItem";
import BillableFormLineItemsFullWidth from "./BillableFormLineItemsFullWidth";
import BillableFormLineItemsMobile from "./BillableFormLineItemsMobile";
import { LineItemErrorMessages } from "./BillableFormLineItemsMobile.types";
import { BuildLineItemLink } from "./BillableFormLineItemsMobile.types";

interface IProps {
  lineItems: Array<ILineItem>;
  invoiceItems: Array<IInvoiceItem> | null;
  onClearErrorMessage: () => void;
  elementIdPrefix: string;
  noLineItemMessage?: string;

  setLineItems: (newLineItems: Array<ILineItem>) => void;
  setInvoiceItems: (newValue: Array<IInvoiceItem>) => void;

  allowBlankQuantity?: boolean;
  allowBlankAmountPerItem?: boolean;
  allowZeroLineItems?: boolean;
  disabled: boolean;
  enableRequiredFields?: boolean;
  buildLineItemLink: BuildLineItemLink;
  hideTaxableField: boolean;
  allowOptionalItems: boolean;
  allowZeroQuantity: boolean;
  errorMessages: LineItemErrorMessages;
}

const BillableLineItems: React.FunctionComponent<IProps> = ({
  lineItems,
  invoiceItems,
  setLineItems,
  setInvoiceItems,
  onClearErrorMessage,
  elementIdPrefix,
  noLineItemMessage,

  allowBlankAmountPerItem,
  allowBlankQuantity,
  allowZeroLineItems,

  disabled,

  enableRequiredFields,
  buildLineItemLink,
  hideTaxableField,
  allowOptionalItems,
  allowZeroQuantity,
  errorMessages,
}) => {
  const isLargeLineItemDisplay = useIsLargeLineItemDisplay();
  return (
    <>
      {isLargeLineItemDisplay ? (
        <div>
          <BillableFormLineItemsFullWidth
            lineItems={lineItems}
            invoiceItems={invoiceItems}
            onClearErrorMessage={onClearErrorMessage}
            elementIdPrefix={elementIdPrefix}
            setLineItems={setLineItems}
            setInvoiceItems={setInvoiceItems}
            noLineItemMessage={noLineItemMessage}
            allowBlankAmountPerItem={allowBlankAmountPerItem}
            allowBlankQuantity={allowBlankQuantity}
            allowZeroLineItems={allowZeroLineItems}
            enableRequiredFields={enableRequiredFields}
            disabled={disabled}
            hideTaxableField={hideTaxableField}
            allowOptionalItems={allowOptionalItems}
            allowZeroQuantity={allowZeroQuantity}
          />
        </div>
      ) : (
        <div>
          <BillableFormLineItemsMobile
            lineItems={lineItems}
            invoiceItems={invoiceItems ?? []}
            setLineItems={setLineItems}
            noLineItemMessage={noLineItemMessage}
            allowZeroLineItems={allowZeroLineItems}
            disabled={disabled}
            buildLineItemLink={buildLineItemLink}
            hideTaxableField={hideTaxableField}
            errorMessages={errorMessages}
          />
        </div>
      )}
    </>
  );
};

export default BillableLineItems;
