import React, { useState } from "react";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import { timeout } from "rxjs/operators";
import { IScheduleIdentifier } from "../../../models/IScheduleIdentifier";
import Spinner from "../../../components/Spinner";
import { applyToJob } from "../services/estimateDataProvider";
import { JobToApplyProposalTo } from "../enums/JobToApplyProposalTo";
import LinkButton from "../../../components/LinkButton";
import { strings } from "../../../languages";

interface IProps {
  show: boolean;
  scheduleIdentifier: IScheduleIdentifier;
  scheduleJobId: string;
  onSave: (jobToApplyTo: JobToApplyProposalTo) => void;
  onCancel: () => void;
}

const verticalMargin = "35px";
export const ApplyToCurrentJobModal: React.FunctionComponent<IProps> = ({
  show,
  scheduleJobId,
  scheduleIdentifier,
  onSave,
  onCancel,
}) => {
  const [saving, setSaving] = useState(false);
  const [errorSaving, setErrorSaving] = useState<boolean>(false);

  const saveApplyToJob = (jobToApplyTo: JobToApplyProposalTo) => {
    setSaving(true);
    setErrorSaving(false);
    applyToJob({
      scheduleIdentifier,
      jobInstanceId: scheduleJobId,
      payload: {
        jobToApplyTo,
      },
    })
      .pipe(timeout(10000))
      .subscribe({
        next: () => {
          setSaving(false);
          onSave(jobToApplyTo);
        },

        error: () => {
          setSaving(false);
          setErrorSaving(true);
        },
      });
  };

  return (
    <>
      {saving && <Spinner />}
      <Modal isOpen={show} centered={true}>
        <ModalBody>
          {strings.wouldYouLikeToApplyEstimateToCurrentJob}
          <div>
            <button
              className="btn btn-secondary btn-block btn-lg"
              type="button"
              style={{
                marginTop: verticalMargin,
                marginBottom: verticalMargin,
              }}
              onClick={() => {
                saveApplyToJob(JobToApplyProposalTo.currentJob);
              }}
              data-testid="applyToCurrentJob"
            >
              {strings.yesApplyToCurrentJob}
            </button>{" "}
            <button
              className="btn btn-secondary btn-block btn-lg"
              style={{
                marginTop: verticalMargin,
                marginBottom: verticalMargin,
              }}
              onClick={() => {
                saveApplyToJob(JobToApplyProposalTo.futureJob);
              }}
              type="button"
              data-testid="applyToFutureJob"
            >
              {strings.noWorkWillBeDoneLater}
            </button>
            {errorSaving ? (
              <div className="text-danger" data-testid="errorContainer">
                Oops, something went wrong. Need to get back to your schedule?{" "}
                <LinkButton
                  onClick={onCancel}
                  text="Close"
                  additionalClassNames="text-danger"
                  style={{ verticalAlign: "baseline" }}
                  testId="closeModal"
                />
              </div>
            ) : null}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
