import { useEffect } from "react";
import ISchedule from "../models/ISchedule";
import IScheduledJob from "../models/IScheduleJob";

type JobCache = { [key: string]: IScheduledJob };

const cachedJobDetails: JobCache = {};

export function useScheduleJobCache(
  schedule: ISchedule,
  scheduleJobId: string
) {
  useEffect(() => {
    let scheduledJobToCache = schedule.scheduledJobs.find((ji) =>
      isScheduleJob(ji, scheduleJobId)
    );
    if (scheduledJobToCache) {
      cachedJobDetails[scheduleJobId] = scheduledJobToCache;
    }
  }, [schedule, scheduleJobId]);

  const scheduleJobIndex = schedule.scheduledJobs.findIndex((ji) =>
    isScheduleJob(ji, scheduleJobId)
  );
  let scheduleJob: IScheduledJob | null = null;

  if (scheduleJobIndex !== -1) {
    scheduleJob = schedule.scheduledJobs[scheduleJobIndex];
  }

  if (!scheduleJob && typeof cachedJobDetails[scheduleJobId] === "object") {
    scheduleJob = cachedJobDetails[scheduleJobId];
  }

  return {
    scheduleJob,
    scheduleJobIndex,
    updatedCachedScheduleJob: function updatedCachedScheduleJob(
      updatedJobId: string,
      fieldsToUpdate: Partial<IScheduledJob>
    ) {
      if (cachedJobDetails[updatedJobId]) {
        cachedJobDetails[updatedJobId] = {
          ...cachedJobDetails[updatedJobId],
          ...fieldsToUpdate,
        };
      }
    },
  };
}

function isScheduleJob(ji: IScheduledJob, scheduleJobId: string): boolean {
  return ji.id === scheduleJobId;
}
