import { strings } from "../../languages";

export interface IUncompletedItem {
  todoText: string;
  id: string;
}

export function CompleteJobErrorRequiredItems({
  uncompletedItems,
}: {
  uncompletedItems: Array<IUncompletedItem>;
}) {
  return (
    <>
      <div>
        {strings.jobCannotBeCompletedUntilFollowingItemsDone}
        <ul>
          {uncompletedItems.map((item) => {
            return <li key={item.id}>{item.todoText}</li>;
          })}
        </ul>
      </div>
    </>
  );
}
