import { ajax } from "rxjs/ajax";
import { catchError, map } from "rxjs/operators";
import { IScheduleIdentifier } from "../../../models/IScheduleIdentifier";
import { buildFullUrl, getHeaders } from "../../../services/DataProvider";
import { IWorksheet } from "../models/IWorksheet";
import {
  AutoSaveResponseStatus,
  IAutoSaveResponse,
} from "../../../models/IAutoSave";
import { of } from "rxjs";

export function getWorksheet({
  scheduleIdentifier,
  jobInstanceId,
  worksheetForJobId,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
  worksheetForJobId: string;
}) {
  return ajax
    .get(
      buildFullUrl(
        `Worksheet/${scheduleIdentifier.linkedDayScheduleId}/${jobInstanceId}/${worksheetForJobId}?dayOffset=${scheduleIdentifier.dayOffset}`
      ),
      getHeaders()
    )
    .pipe(
      map((response) => {
        return response.response as IWorksheet;
      })
    );
}

export function completeWorksheet({
  scheduleIdentifier,
  jobInstanceId,
  worksheetForJobId,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
  worksheetForJobId: string;
}) {
  return ajax.post(
    buildFullUrl(
      `Worksheet/${scheduleIdentifier.linkedDayScheduleId}/${jobInstanceId}/${worksheetForJobId}/complete?dayOffset=${scheduleIdentifier.dayOffset}`
    ),
    {},
    getHeaders()
  );
}

export function saveWorksheetResponse({
  scheduleIdentifier,
  jobInstanceId,
  worksheetForJobInstanceContentItemId,
  newValue,
  oldValues,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
  worksheetForJobInstanceContentItemId: string;
  newValue: string;
  oldValues: Array<string>;
}) {
  return ajax
    .post(
      buildFullUrl(
        `Worksheet/${scheduleIdentifier.linkedDayScheduleId}/${jobInstanceId}/${worksheetForJobInstanceContentItemId}/response?dayOffset=${scheduleIdentifier.dayOffset}`
      ),
      {
        newValue,
        oldValues,
      },
      getHeaders()
    )
    .pipe(
      map(() => {
        return {
          newValue: newValue,
          status: AutoSaveResponseStatus.OK,
        } as IAutoSaveResponse;
      }),
      catchError((err) => {
        let status = AutoSaveResponseStatus.UnknownError;
        let errorMessage =
          "An error occurred while saving.  Please verify your Internet connection.";
        if (err && err.response) {
          if (err.response.errorCode === "AutoSaveNotesConcurrency") {
            status = AutoSaveResponseStatus.ConcurrencyError;
          }

          if (typeof err.response === "string") {
            errorMessage = err.response;
          } else if (typeof err.response?.message) {
            errorMessage = err.response.message;
          }
        }

        return of({
          newValue,
          status,
          errorMessage,
        } as IAutoSaveResponse);
      })
    );
}
