import LinkButton from "../../../components/LinkButton";
import { FloatingAlert } from "./FloatingAlert";
import { TriggerBillableFormSave } from "../../../libraries/billableForm/types/TriggerBillableFormSave";

export function FloatingErrorSavingIndicator({
  errorMessage,
  clearErrorMessage,
  triggerSave,
  showRetry,
}: {
  errorMessage: string;
  clearErrorMessage: () => void;
  triggerSave: TriggerBillableFormSave;
  showRetry: boolean;
}) {
  return (
    <FloatingAlert
      type="danger"
      showClose
      onClose={() => clearErrorMessage()}
      allowWrap={!showRetry}
    >
      <div className={showRetry ? "text-nowrap" : ""}>
        {errorMessage}{" "}
        {showRetry ? (
          <LinkButton
            text="Retry"
            testId="billableFormSaveRetry"
            additionalClassNames="text-dark"
            style={{ verticalAlign: "baseline" }}
            onClick={() => {
              triggerSave({
                immediateSave: true,
              });
            }}
          />
        ) : null}
      </div>
    </FloatingAlert>
  );
}
