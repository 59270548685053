export interface IAutoSaveRequest {
  newValue: string;
  oldValues: Array<string>;
  autoSaveField: AutoSaveField;
  childId?: string;
}

export interface IAutoSaveResponse {
  newValue: string;
  errorMessage?: string;
  status: AutoSaveResponseStatus;
}

export enum AutoSaveField {
  NoteFromCrew = 0,
  CustomCrewQuestion = 1,
  PhotoCaption = 2,
}

export enum AutoSaveResponseStatus {
  OK,
  ConcurrencyError,
  UnknownError,
}
