import { ajax } from "rxjs/ajax";
import { buildFullUrl, getHeaders } from "../../../services/DataProvider";
import { map } from "rxjs/operators";

export type VerifyOneTimePasswordResponse = {
  accessToken: string;
  expiresIn: number;
};

export type GetAvailableSchedulesResponse = {
  tenants: Array<{
    id: string;
    name: string;
    crews: Array<{
      id: string;
      name: string;
    }>;
  }>;
};

export function getAvailableSchedules({
  accessToken,
}: {
  accessToken: string;
}) {
  return ajax
    .get(buildFullUrl(`app/GetAvailableSchedules`), {
      ...getHeaders(),
      Authorization: `Bearer ${accessToken}`,
    })
    .pipe(
      map((response) => response.response as GetAvailableSchedulesResponse)
    );
}

export function getScheduleLinkId({
  crewId,
  accessToken,
}: {
  crewId: string;
  accessToken: string;
}) {
  return ajax
    .get(buildFullUrl(`app/${crewId}/GetScheduleLinkId`), {
      ...getHeaders(),
      Authorization: `Bearer ${accessToken}`,
    })
    .pipe(
      map(
        (response) =>
          response.response as {
            dayScheduleLinkId: string;
            scheduleDate: string;
          }
      )
    );
}

export function requestOneTimePassword(body: { phoneNumber: string }) {
  return ajax.post(buildFullUrl(`app/RequestOneTimePassword`), body, {
    ...getHeaders(),
  });
}

export function verifyOneTimePassword(body: {
  phoneNumber: string;
  code: string;
}) {
  return ajax
    .post(buildFullUrl(`app/VerifyOneTimePassword`), body, {
      ...getHeaders(),
    })
    .pipe(
      map((response) => {
        return response.response as VerifyOneTimePasswordResponse;
      })
    );
}
