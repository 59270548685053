let applicationInsights: any | null = null;

enum SeverityLevel {
  Verbose = 0,
  Information = 1,
  Warning = 2,
  Error = 3,
  Critical = 4,
}

export function identifyApplicationInsightsUser(tenantName: string) {
  applicationInsights = getAppInsights();
  if (applicationInsights) {
    applicationInsights.setAuthenticatedUserContext(tenantName);
    applicationInsights.addTelemetryInitializer((envelope: any) => {
      if (envelope.data) {
        envelope.data.tenantName = tenantName;
      }
    });
  }
}

function getAppInsights() {
  const winAny = window as any;
  if (winAny.appInsights) {
    return winAny.appInsights as {
      setAuthenticatedUserContext: (v: string) => void;
      addTelemetryInitializer: (envelope: any) => void;
      trackTrace: (data: { message: string; severityLevel: number }) => void;
    };
  } else {
    return null;
  }
}

export function trackTrace(
  errorMessage: string,
  properties?: { [key: string]: unknown }
) {
  if (applicationInsights) {
    applicationInsights.trackTrace({
      message: errorMessage,
      severityLevel: SeverityLevel.Error,
      properties,
    });
  }
}

export function trackDependencyData(payload: IDependencyTelemetry) {
  if (applicationInsights) {
    applicationInsights.trackDependencyData(payload);
  }
}

export interface IDependencyTelemetry {
  id: string;
  name?: string;
  duration?: number;
  success?: boolean;
  startTime?: Date;
  responseCode: number;
  correlationContext?: string;
  type?: string;
  data?: string;
  target?: string;
  iKey?: string;
  properties?: { [key: string]: unknown };
}
