import React, { useMemo, useState } from "react";
import JobCard from "./JobCard";
import Container from "./Container";
import ISchedule from "../models/ISchedule";
import { IGetEstimatedJobTimesResponse } from "../models/IGetEstimatedJobTimesResponse";
import { IJobTimeEstimates } from "../models/IGetEstimatedJobTimesResponseJobInstance";
import { estimatedJobTimesLoadingState } from "../App";
import { getEstimatedStartAndEndTimes } from "../services/estimationService";
import dataProvider from "../services/DataProvider";
import { strings } from "../languages";
import PayrollClockInOutButtons from "./PayrollClockInOutButtons";
import ShopClockInOutButton from "./ShopClockInOutButton";
import { IScheduleIdentifier } from "../models/IScheduleIdentifier";

interface IProps {
  schedule: ISchedule;
  scheduleIdentifier: IScheduleIdentifier;
  estimatedJobTimes: IGetEstimatedJobTimesResponse | null;
  estimatedJobTimesLoading: estimatedJobTimesLoadingState;
  onJobDetailsLinkClicked: () => void;
  onScheduleUpdated(fieldsToUpdate: Partial<ISchedule>): void;
  footerElement: React.ReactNode | null;
}

const JobListing: React.FunctionComponent<IProps> = ({
  schedule,
  scheduleIdentifier,
  estimatedJobTimesLoading,
  estimatedJobTimes,
  onJobDetailsLinkClicked,
  onScheduleUpdated,
  footerElement,
}) => {
  const calculatedJobTimes: Array<IJobTimeEstimates> = useMemo(() => {
    return getEstimatedStartAndEndTimes(
      schedule.showCrewEstimatedTimes,
      schedule.typicalStartTime,
      schedule.typicalLunchStartTime,
      schedule.typicalLunchEndTime,
      schedule.scheduledJobs,
      estimatedJobTimes
    );
  }, [
    schedule.showCrewEstimatedTimes,
    schedule.typicalStartTime,
    schedule.typicalLunchStartTime,
    schedule.typicalLunchEndTime,
    schedule.scheduledJobs,
    estimatedJobTimes,
  ]);
  const [shopClockInError, setShopClockInError] = useState("");
  const [shopClockOutError, setShopClockOutError] = useState("");

  const isOriginalSchedule = scheduleIdentifier.dayOffset === 0;

  const crewNotesForCrewExists = (schedule.crewNotesForCrew || "").trim();
  const dayScheduleNotesForCrewExists = (
    schedule.dayScheduleNotesForCrew || ""
  ).trim();

  return (
    <Container
      schedule={schedule}
      isJobListing={true}
      scheduleIdentifier={scheduleIdentifier}
    >
      <div
        style={{ marginBottom: footerElement !== null ? "100px" : undefined }}
      >
        {schedule.showPayrollTimeTracking && isOriginalSchedule ? (
          <PayrollClockInOutButtons scheduleIdentifier={scheduleIdentifier} />
        ) : null}
        {schedule.showDayClockInClockOut &&
        !schedule.shopClockIn &&
        isOriginalSchedule ? (
          <ShopClockInOutButton
            buttonText={strings.leavingShop}
            buttonClass="btn-success"
            containingDivStyle={{ marginBottom: "20px" }}
            errorMessage={shopClockInError}
            onClick={() => {
              dataProvider
                .shopClockIn(scheduleIdentifier, new Date())
                .subscribe(
                  (result) => {
                    onScheduleUpdated({
                      shopClockIn: result.formattedTime,
                    });
                  },
                  () => {
                    setShopClockInError(
                      "Unable to start the day.  Please make sure you have a working Internet connection."
                    );
                  }
                );
            }}
          />
        ) : null}
        {crewNotesForCrewExists || dayScheduleNotesForCrewExists ? (
          <div>
            <div>
              <h4 style={{ whiteSpace: "pre-wrap" }}>{strings.notes}: </h4>
            </div>
            <div>
              {crewNotesForCrewExists ? (
                <div>
                  <h4 style={{ whiteSpace: "pre-wrap" }}>
                    {schedule.crewNotesForCrew}
                  </h4>
                </div>
              ) : null}

              {dayScheduleNotesForCrewExists ? (
                <div>
                  <h4 style={{ whiteSpace: "pre-wrap" }}>
                    {schedule.dayScheduleNotesForCrew}
                  </h4>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
        <React.Fragment>
          {schedule.scheduledJobs.length === 0 ? (
            <div className="alert alert-info mt-2">
              There are no jobs are schedule on this day.
            </div>
          ) : null}

          {schedule.scheduledJobs.map((scheduledJob, index) => {
            let estimatedJobTime: IJobTimeEstimates | null = null;
            if (estimatedJobTimes !== null) {
              estimatedJobTime =
                calculatedJobTimes.find((t) => t.id === scheduledJob.id) ||
                null;
            }

            return (
              <JobCard
                scheduleIdentifier={scheduleIdentifier}
                key={scheduledJob.id}
                showCrewEstimatedTimes={schedule.showCrewEstimatedTimes}
                showScheduledTimes={schedule.showScheduledTimes}
                estimatedJobTimesLoading={estimatedJobTimesLoading}
                scheduledJob={scheduledJob}
                estimatedJobTime={estimatedJobTime}
                index={index}
                onJobDetailsLinkClicked={onJobDetailsLinkClicked}
              />
            );
          })}
        </React.Fragment>
        {schedule.showDayClockInClockOut &&
        schedule.shopClockIn &&
        !schedule.shopClockOut &&
        isOriginalSchedule ? (
          <ShopClockInOutButton
            buttonText={strings.returnToShop}
            errorMessage={shopClockOutError}
            buttonClass="btn-danger"
            containingDivStyle={{ marginTop: "20px" }}
            onClick={() => {
              dataProvider
                .shopClockOut(scheduleIdentifier, new Date())
                .subscribe(
                  (result) => {
                    onScheduleUpdated({
                      shopClockOut: result.formattedTime,
                    });
                  },
                  () => {
                    setShopClockOutError(
                      "Unable to end the day.  Please make sure you have a working Internet connection."
                    );
                  }
                );
            }}
          />
        ) : null}
      </div>

      {footerElement}
    </Container>
  );
};

export default JobListing;
