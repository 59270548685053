import { useEffect, useRef } from "react";
import { useQuery } from "../../../services/useQuery";
import { billableFormConstants } from "../billableFormConstants";

export function useScrollToOriginalPosition() {
  const query = useQuery();
  const originalScrollPosition = query.get(
    billableFormConstants.originalScrollPosition
  );
  const hasScroll = useRef(false);
  useEffect(() => {
    const parsedValue = parseInt(originalScrollPosition ?? "");
    if (!isNaN(parsedValue) && !hasScroll.current) {
      window.scrollTo({
        top: parsedValue,
      });

      hasScroll.current = true;
    }
  }, [originalScrollPosition]);
}
