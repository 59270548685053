import {
  Controller,
  Control,
  UseFormRegister,
  UseFormSetValue,
  UseFormGetValues,
} from "react-hook-form";
import { EmailAddresses } from "../../../../libraries/emailAddresses/EmailAddresses";
import Files from "../../../../libraries/formAttachments/components/Index";
import { isPhoto } from "../../../../libraries/formAttachments/services/fileService";
import ISchedule from "../../../../models/ISchedule";
import { IScheduleIdentifier } from "../../../../models/IScheduleIdentifier";
import { TriggerBillableFormSave } from "../../../../libraries/billableForm/types/TriggerBillableFormSave";
import IScheduledJob from "../../../../models/IScheduleJob";
import BillableFormAmounts from "../../../../libraries/billableForm/components/BillableFormAmounts";
import { ILineItem } from "../../../../libraries/billableForm/components/BillableFormLineItem";
import BillableLineItems from "../../../../libraries/billableForm/components/BillableFormLineItems";
import { BillableFormSummaryField } from "../../../../libraries/billableForm/components/BillableFormSummaryField";
import { IInvoiceItem } from "../../../../libraries/billableForm/types/IInvoiceItem";
import { IEstimateFormData } from "./IEstimateFormData";
import { IEstimateTemplate } from "../../models/IEstimateTemplate";
import { getSortedItemsV2 } from "../../../../services/sortingService";
import { getNumericString } from "../../../../libraries/typeUtilities/getNumericString";
import { getLineItemsForFormData } from "../../../../libraries/billableForm/functions/getLineItemsForFormData";
import { strings } from "../../../../languages";
import { routingBuilders } from "../../../../services/routing";
import IPhoto from "../../../../libraries/formAttachments/models/IPhoto";
import { CopyJobFilesLink } from "../../../../libraries/formAttachments/components/CopyJobFilesLink";
import { PhoneNumberWithOptIn } from "../../../../libraries/input/PhoneNumberWithOptIn";
import { LineItemErrorMessages } from "../../../../libraries/billableForm/components/BillableFormLineItemsMobile.types";
import DepositFields from "./DepositFields";
import { IAmountAdjustments } from "../../../../libraries/billableForm/types/IBillableFormData";
import { DepositType } from "../../enums/DepositType";

export function EstimateFormBody({
  formRef,
  control,
  register,
  setValue,
  getValues,
  triggerSave,
  invoiceItems,
  setInvoiceItems,
  lineItems,
  amountAdjustments,
  scheduleIdentifier,
  schedule,
  isEstimateAccepted,
  isDraft,
  presentStarted,
  scheduleJob,
  templates,
  scheduleJobId,
  customerTaxExempt,
  taxRateAlreadySet,
  originalPhoneNumber,
  originalPhoneNumberOptedIntoSms,
  lastEmailAddressInputRef,
  lineItemErrorMessages,
  defaultDepositItemId,
}: {
  formRef: React.RefObject<HTMLFormElement>;
  control: Control<IEstimateFormData, any>;
  register: UseFormRegister<IEstimateFormData>;
  setValue: UseFormSetValue<IEstimateFormData>;
  getValues: UseFormGetValues<IEstimateFormData>;
  triggerSave: TriggerBillableFormSave;
  invoiceItems: IInvoiceItem[];
  setInvoiceItems: React.Dispatch<React.SetStateAction<Array<IInvoiceItem>>>;
  lineItems: ILineItem[];
  amountAdjustments: IAmountAdjustments;
  scheduleIdentifier: IScheduleIdentifier;
  schedule: ISchedule;
  isEstimateAccepted: boolean;
  isDraft: boolean;
  presentStarted: boolean;
  scheduleJob: IScheduledJob;
  templates: Array<IEstimateTemplate>;
  scheduleJobId: string;
  customerTaxExempt: boolean;
  taxRateAlreadySet: boolean;
  jobAttachments?: Array<IPhoto>;
  originalPhoneNumber: string;
  originalPhoneNumberOptedIntoSms: boolean;
  lastEmailAddressInputRef: React.MutableRefObject<HTMLInputElement | null>;
  lineItemErrorMessages: LineItemErrorMessages;
  defaultDepositItemId: string | null;
}) {
  const isFormDisabled = isEstimateAccepted;
  const areNonDraftFieldsRequired = !isDraft || presentStarted;
  const taxRateDisabled = customerTaxExempt && !taxRateAlreadySet;

  return (
    <form ref={formRef}>
      <div style={{ marginBottom: "160px" }}>
        <Controller
          control={control}
          name="phoneNumberWithOptInStatus"
          render={({ field }) => (
            <PhoneNumberWithOptIn
              originalPhoneNumber={originalPhoneNumber}
              originalPhoneNumberOptedIntoSms={originalPhoneNumberOptedIntoSms}
              value={field.value}
              onChange={(newValue) => {
                field.onChange(newValue);
                triggerSave({});
              }}
              disabled={isFormDisabled}
            />
          )}
        />

        <label>{strings.customerEmailAddresses}</label>

        <Controller
          control={control}
          name="emails"
          render={({ field }) => (
            <EmailAddresses
              label={strings.customerEmailAddresses}
              emailAddresses={field.value}
              onChange={(newValue) => {
                field.onChange(newValue);
                triggerSave({});
              }}
              required={false}
              disabled={isFormDisabled}
              lastEmailAddressInputRef={lastEmailAddressInputRef}
            />
          )}
        />

        <div className="mt-3 form-row">
          {templates.length > 0 ? (
            <div className={`form-group ${isDraft ? "col-md-6" : "col-md-12"}`}>
              <label htmlFor="proposalTemplateId">{strings.template}</label>
              <select
                className="form-control"
                id="proposalTemplateId"
                disabled={isFormDisabled}
                {...register("proposalTemplateId", {
                  onChange: (e) => {
                    const template = templates.find(
                      (t) => t.id === e.currentTarget.value
                    );
                    if (template) {
                      if (template.daysValid) {
                        setValue(
                          "daysValid",
                          getNumericString(template.daysValid)
                        );
                      }

                      setValue("subject", template.subject);

                      setValue("depositSettings", {
                        depositItemId: getValues(
                          "depositSettings.depositItemId"
                        ),
                        depositItemName: getValues(
                          "depositSettings.depositItemName"
                        ),
                        depositItemDescription: getValues(
                          "depositSettings.depositItemDescription"
                        ),
                        type: DepositType.percent,
                        amount: null,
                        percent: null,
                        ...template.depositSettings,
                      });

                      setValue(
                        "lineItems",
                        getLineItemsForFormData(
                          template.lineItems.map((li) => ({
                            ...li,
                            // Tech estimates don't support hidden line items
                            hide: false,
                          }))
                        )
                      );
                    }

                    triggerSave({});
                  },
                })}
              >
                <option value=""></option>
                {getSortedItemsV2(templates, [
                  (t) => (t.isPrimaryTemplate ? 0 : 1),
                  "name",
                ]).map((t) => (
                  <option key={t.id} value={t.id}>
                    {t.name}
                  </option>
                ))}
              </select>
            </div>
          ) : null}

          {isDraft ? (
            <div className="form-group col-md-6">
              <label htmlFor={"daysValid"} className="required">
                {strings.numberOfDaysValid}
              </label>

              <input
                type="number"
                id="daysValid"
                className="form-control"
                pattern="[0-9]"
                required={areNonDraftFieldsRequired}
                step={1}
                disabled={isFormDisabled}
                {...register("daysValid", {
                  onChange: () => {
                    triggerSave({});
                  },
                })}
                min={0}
                max={10000}
              />
            </div>
          ) : null}
        </div>

        {!isDraft ? (
          <div className="form-row">
            <div className="form-group col-12 col-sm-6">
              <label htmlFor="proposalDate" className="required">
                {strings.estimateDate}
              </label>
              <input
                type="date"
                id="proposalDate"
                data-testid="proposalDate"
                className="form-control"
                required
                disabled={isFormDisabled}
                {...register("proposalDate", {
                  onChange: () => {
                    triggerSave({});
                  },
                })}
              />
            </div>
            <div className="form-group col-12 col-sm-6">
              <label htmlFor="validUntilDate" className="required">
                {strings.validUntil}
              </label>
              <input
                type="date"
                id="validUntilDate"
                data-testid="validUntilDate"
                className="form-control"
                required
                disabled={isFormDisabled}
                {...register("validUntilDate", {
                  onChange: () => {
                    triggerSave({});
                  },
                })}
              />
            </div>
          </div>
        ) : null}

        <div className="mt-3">
          <Controller
            control={control}
            name="lineItems"
            render={({ field }) => (
              <BillableLineItems
                hideTaxableField={taxRateDisabled}
                lineItems={field.value}
                invoiceItems={invoiceItems}
                onClearErrorMessage={() => {}}
                elementIdPrefix={""}
                allowOptionalItems={true}
                errorMessages={lineItemErrorMessages}
                setLineItems={(newLineItems) => {
                  field.onChange(newLineItems);
                  triggerSave({});
                }}
                setInvoiceItems={(newInvoiceItems) => {
                  setInvoiceItems(newInvoiceItems);
                }}
                disabled={isFormDisabled}
                enableRequiredFields={areNonDraftFieldsRequired}
                allowBlankAmountPerItem={!areNonDraftFieldsRequired}
                allowBlankQuantity={!areNonDraftFieldsRequired}
                allowZeroQuantity={true}
                buildLineItemLink={(id) =>
                  routingBuilders.buildEstimateLineItemPath({
                    scheduleIdentifier,
                    scheduleJobId,
                    lineItemId: id,
                  })
                }
              />
            )}
          />
        </div>

        <div className={isFormDisabled ? "mt-4" : ""}>
          <Controller
            control={control}
            name="amountAdjustments"
            render={({ field }) => (
              <BillableFormAmounts
                taxRateDisabled={taxRateDisabled}
                lineItems={lineItems.map((li) => ({
                  ...li,
                  // For calculating the sub-total on the form,
                  // we don't want to treat an item as selected
                  selected: false,
                }))}
                disabled={isFormDisabled}
                taxRate={field.value.taxRate}
                requireTaxRate={
                  areNonDraftFieldsRequired &&
                  lineItems.some((li) => li.taxable)
                }
                onTaxRateChange={(newValue) => {
                  field.onChange({
                    ...field.value,
                    taxRate: newValue,
                  });
                  triggerSave({});
                }}
                discountFields={{
                  discount: field.value.discount,
                  onChange: (newValue) => {
                    field.onChange({
                      ...field.value,
                      discount: newValue,
                    });
                    triggerSave({});
                  },
                  amountOnly: false,
                }}
              />
            )}
          />
        </div>

        <Controller
          control={control}
          name="depositSettings"
          render={({ field }) => (
            <DepositFields
              depositSettings={field.value}
              onDepositSettingsChanged={(newValue) => {
                field.onChange(newValue);

                triggerSave({});
              }}
              lineItems={lineItems}
              amountAdjustments={amountAdjustments}
              invoiceItems={invoiceItems}
              defaultDepositItemId={defaultDepositItemId}
              disabled={isFormDisabled}
              areNonDraftFieldsRequired={areNonDraftFieldsRequired}
            />
          )}
        />

        <Controller
          control={control}
          name="files"
          render={({ field }) => (
            <Files
              scheduleIdentifier={scheduleIdentifier}
              files={field.value}
              imagePrefix={schedule.imagePrefix}
              header={"Files"}
              tenantId={schedule.tenantId}
              disabled={isFormDisabled}
              onFileAdded={(newFile) => {
                field.onChange([...field.value, newFile]);
                triggerSave({});
              }}
              onFileRemoved={(fileId, imagePath) => {
                field.onChange(
                  field.value.filter((f) => !isPhoto(fileId, imagePath, f))
                );
                triggerSave({});
              }}
              onFileUpdated={(fileId, imagePath, caption) => {
                field.onChange(
                  field.value.map((f) => {
                    if (isPhoto(fileId, imagePath, f)) {
                      return {
                        ...f,
                        caption,
                      };
                    } else {
                      return f;
                    }
                  })
                );
                triggerSave({});
              }}
              onFileUploadingStatusChange={() => {}}
              addAdditionalFiles={
                <CopyJobFilesLink
                  jobPhotos={scheduleJob.photos}
                  existingFiles={field.value}
                  setFiles={(newValue) => {
                    setValue("files", newValue);
                  }}
                  triggerSave={triggerSave}
                />
              }
            />
          )}
        />

        <div className="form-group">
          <Controller
            control={control}
            name="summary"
            render={({ field }) => (
              <BillableFormSummaryField
                value={field.value}
                notesFromCrew={scheduleJob.notesFromCrew ?? ""}
                disabled={isFormDisabled}
                onChange={(newValue) => {
                  field.onChange(newValue);
                  triggerSave({});
                }}
              />
            )}
          />
        </div>
      </div>
    </form>
  );
}
