import React from "react";
import Modal from "reactstrap/lib/Modal";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import {
  CompleteJobErrorRequiredItems,
  IUncompletedItem,
} from "./CompleteJobErrorRequiredItems";

interface IProps {
  show: boolean;
  uncompletedItems: Array<IUncompletedItem>;
  onClose: () => void;
}

export const CompleteJobErrorModal: React.FunctionComponent<IProps> = ({
  show,
  onClose,
  uncompletedItems,
}) => (
  <Modal
    isOpen={show}
    centered={true}
    toggle={() => {
      onClose();
    }}
  >
    <ModalHeader>Job Cannot Be Completed</ModalHeader>
    <ModalBody>
      <CompleteJobErrorRequiredItems uncompletedItems={uncompletedItems} />
    </ModalBody>
    <ModalFooter>
      <button
        className="btn btn-primary"
        type="button"
        onClick={() => {
          onClose();
        }}
      >
        OK
      </button>
    </ModalFooter>
  </Modal>
);
