import React, { useCallback } from "react";
import { IScheduleIdentifier } from "../../../models/IScheduleIdentifier";
import { IInvoiceForPaymentForm } from "../models/IInvoiceForPaymentForm";

import { IZonedDateTime } from "../models/IZonedDateTime";
import { saveCreditCardPayment } from "../services/invoicePaymentFormDataProvider";
import CreditCard, { SaveCallbackArgs } from "./CreditCard";
import { InvoiceType } from "../../../enums/InvoiceType";

interface IProps {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
  invoice: IInvoiceForPaymentForm;
  onSave: (saveResult: {
    transactionDateTime: IZonedDateTime | null;
    convenienceFeePaid: number | null;
    paymentMethodAuthorized: boolean;
  }) => void;
  paymentAmount: number;
  proposalPaymentMethodCapture?: boolean | null;
  invoiceType: InvoiceType;
}

const InvoicePayFormCreditCardFields: React.FunctionComponent<IProps> = ({
  invoice,
  scheduleIdentifier,
  jobInstanceId,
  onSave,
  paymentAmount,
  invoiceType,
}) => {
  const saveCreditCardFn = useCallback(
    ({ token, partialNumber, referenceInformation }: SaveCallbackArgs) => {
      return saveCreditCardPayment({
        scheduleIdentifier: {
          linkedDayScheduleId: scheduleIdentifier.linkedDayScheduleId,
          dayOffset: scheduleIdentifier.dayOffset,
          techAppCrewId: scheduleIdentifier.techAppCrewId,
        },
        jobInstanceId,
        partialNumber: partialNumber,
        paymentReferenceInformation: referenceInformation,
        payrixToken: token,
        version: invoice.version,
        invoiceType,
      });
    },
    [
      scheduleIdentifier.linkedDayScheduleId,
      scheduleIdentifier.dayOffset,
      scheduleIdentifier.techAppCrewId,
      jobInstanceId,
      invoice.version,
      invoiceType,
    ]
  );

  return (
    <CreditCard
      isNonProductionEnvironment={invoice.isNonProductionEnvironment}
      payrixKey={invoice.payrixKey}
      merchantAccountId={invoice.merchantAccountId}
      paymentAmount={paymentAmount}
      onSave={onSave}
      saveCall={saveCreditCardFn}
      errorLogMsg={`Error saving payment to crew control. Job instance id '${jobInstanceId}'`}
    />
  );
};

export default InvoicePayFormCreditCardFields;
