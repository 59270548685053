import uuidv4 from "uuid/v4";

export function createNewLineItem(quantity: number | string) {
  return {
    id: uuidv4(),
    amountPerItem: "",
    name: "",
    description: "",
    itemId: "",
    quantity: typeof quantity === "number" ? quantity.toString() : quantity,
    serviceDate: undefined,
  };
}
