import { useLocation } from "react-router-dom";
import { strings } from "../../../languages";

export function useGetFormTitle(baseTitle: string) {
  const location = useLocation();

  if (location.pathname.endsWith("/lineitem/")) {
    return strings.newLineItem;
  } else if (location.pathname.indexOf("/lineitem/") !== -1) {
    return strings.editLineItem;
  } else {
    return baseTitle;
  }
}
