import pica from "pica";
import { ImgExifService } from "./img-exif.service";
import { Observable } from "rxjs/internal/Observable";
import { from } from "rxjs";

const windowAny = window as any;

export interface ResizeCanvasOptions {
  quality?: number;
  alpha?: boolean;
  unsharpAmount?: number;
  unsharpRadius?: number;
  unsharpThreshold?: number;
}

export interface ResizeBufferOptions {
  src: Uint8Array;
  width: number;
  height: number;
  toWidth: number;
  toHeight: number;
  quality?: number;
  alpha?: boolean;
  unsharpAmount?: number;
  unsharpRadius?: number;
  unsharpThreshold?: number;
}

export class PicaService {
  private imageExifService: ImgExifService;

  constructor() {
    this.imageExifService = new ImgExifService();
  }

  public getExifService(): ImgExifService {
    return this.imageExifService;
  }

  public resize(
    image: HTMLImageElement,
    fileName: string,
    fileType: string,
    width: number,
    height: number,
    keepAspectRatio: boolean = false
  ): Observable<{ file: File; width: number; height: number }> {
    return from(
      this.resizeFile(image, fileName, fileType, width, height, keepAspectRatio)
    );
  }

  public resizeCanvas(
    from: HTMLCanvasElement,
    to: HTMLCanvasElement,
    options: ResizeCanvasOptions
  ): Promise<HTMLCanvasElement> {
    let result: Promise<HTMLCanvasElement> = new Promise((resolve, reject) => {
      let curPica = new pica();
      if (!curPica || !curPica.resize) {
        curPica = new windowAny.pica();
      }
      curPica.resize(from, to, options).then(
        (response: any) => {
          resolve(response);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
    return result;
  }
  public resizeBuffer(options: ResizeBufferOptions): Promise<Uint8Array> {
    let result: Promise<Uint8Array> = new Promise((resolve, reject) => {
      let curPica = new pica();
      if (!curPica || !curPica.resizeBuffer) {
        curPica = new windowAny.pica();
      }
      curPica.resizeBuffer(options).then(
        (response: any) => {
          resolve(response);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
    return result;
  }

  private resizeFile(
    orientedImg: HTMLImageElement,
    fileName: string,
    fileType: string,
    width: number,
    height: number,
    keepAspectRatio: boolean = false
  ): Promise<{ file: File; width: number; height: number }> {
    let result: Promise<{
      file: File;
      width: number;
      height: number;
    }> = new Promise((resolve, reject) => {
      let fromCanvas: HTMLCanvasElement = document.createElement("canvas");
      let ctx = fromCanvas.getContext("2d") as any;

      fromCanvas.width = orientedImg.width;
      fromCanvas.height = orientedImg.height;
      ctx.drawImage(orientedImg, 0, 0);
      let imageData = ctx.getImageData(
        0,
        0,
        orientedImg.width,
        orientedImg.height
      );
      if (keepAspectRatio) {
        let ratio = Math.min(
          width / imageData.width,
          height / imageData.height
        );
        width = Math.round(imageData.width * ratio);
        height = Math.round(imageData.height * ratio);
      }
      let useAlpha = true;
      if (
        fileType === "image/jpeg" ||
        (fileType === "image/png" && !this.isImgUsingAlpha(imageData))
      ) {
        //image without alpha
        useAlpha = false;
        ctx = fromCanvas.getContext("2d", { alpha: false });
        ctx.drawImage(orientedImg, 0, 0);
      }

      // Don't enlarge picture
      if (fromCanvas.width <= width && fromCanvas.height <= height) {
        fromCanvas.toBlob(blob => {
          const newFile: File = this.generateResultFile(
            blob as Blob,
            fileName,
            fileType,
            new Date().getTime()
          );

          resolve({
            file: newFile,
            width: fromCanvas.width,
            height: fromCanvas.height
          });
        });
      } else {
        let toCanvas: HTMLCanvasElement = document.createElement("canvas");
        toCanvas.width = width;
        toCanvas.height = height;
        this.resizeCanvas(fromCanvas, toCanvas, { alpha: useAlpha })
          .then((resizedCanvas: HTMLCanvasElement) => {
            resizedCanvas.toBlob(blob => {
              let newFile: File = this.generateResultFile(
                blob as Blob,
                fileName,
                fileType,
                new Date().getTime()
              );
              resolve({
                file: newFile,
                width: toCanvas.width,
                height: toCanvas.height
              });
            }, fileType);
          })
          .catch(error => {
            reject(error);
          });
      }
    });
    return result;
  }
  private isImgUsingAlpha(imageData: any): boolean {
    for (var i = 0; i < imageData.data.length; i += 4) {
      if (imageData.data[i + 3] !== 255) {
        return true;
      }
    }
    return false;
  }
  private generateResultFile(
    blob: Blob,
    name: string,
    type: string,
    lastModified: number
  ): File {
    let resultFile = new Blob([blob], { type: type });
    return this.blobToFile(resultFile, name, lastModified);
  }
  private blobToFile(blob: Blob, name: string, lastModified: number): File {
    let file: any = blob;
    file.name = name;
    file.lastModified = lastModified;

    //Cast to a File() type
    return file as File;
  }
}
