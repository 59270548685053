import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Observable } from "rxjs";
import { IScheduleIdentifier } from "../../../models/IScheduleIdentifier";
import { fullStoryTrack } from "../../../services/fullStoryService";
import { getErrorMessageFromError } from "../../errorParsing/getErrorMessageFromError";
import { ErrorResponse } from "../types/ErrorResponse";
import { TriggerBillableFormSave } from "../types/TriggerBillableFormSave";
import { AjaxResponse } from "rxjs/ajax";

export function usePresentBillableItem({
  scheduleIdentifier,
  jobInstanceId,
  triggerFormSave,
  skipSave,
  fullStoryEvent,
  presentationPagePath,
  getOrCreateCall,
  lastEmailAddressInputRef,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
  triggerFormSave: TriggerBillableFormSave;
  skipSave: boolean;
  fullStoryEvent: string;
  presentationPagePath: string;
  getOrCreateCall: ({
    scheduleIdentifier,
    jobInstanceId,
  }: {
    scheduleIdentifier: IScheduleIdentifier;
    jobInstanceId: string;
  }) => Observable<{}>;
  lastEmailAddressInputRef: React.MutableRefObject<HTMLInputElement | null>;
}) {
  const [presentStarted, setPresentStarted] = useState(false);
  const [presentSaving, setPresentSaving] = useState(false);
  const [errorSavingForPresentationForm, setErrorSavingForPresentationForm] =
    useState<ErrorResponse | null>(null);

  const history = useHistory();

  const redirectToPresentationPage = () => history.push(presentationPagePath);

  const triggerPresentation = () => {
    fullStoryTrack(fullStoryEvent);

    if (skipSave) {
      redirectToPresentationPage();
    } else {
      setPresentSaving(true);
      setErrorSavingForPresentationForm(null);
      setPresentStarted(true);

      setTimeout(() => {
        triggerFormSave({
          immediateSave: true,
          checkValidityOnImmediate: true,
          successCallback: () => {
            // Note: This will ensure the form is valid before sending
            getOrCreateCall({
              scheduleIdentifier,
              jobInstanceId,
            }).subscribe({
              next: () => {
                setPresentSaving(false);

                redirectToPresentationPage();
              },

              error: (err: AjaxResponse) => {
                if (
                  err.response?.errorCode ===
                  "CrewBillableItemEmailOrPhoneRequired"
                ) {
                  if (lastEmailAddressInputRef?.current) {
                    lastEmailAddressInputRef.current.focus();
                  }
                }

                setPresentSaving(false);
                setErrorSavingForPresentationForm({
                  message: getErrorMessageFromError(err),
                  was400Error: err.status === 400,
                });
              },
            });
          },
          failureCallback: () => {
            setPresentSaving(false);
          },
        });
      });
    }
  };

  return {
    presentStarted,
    presentSaving,
    errorSavingForPresentationForm,
    triggerPresentation,
    setErrorSavingForPresentationForm,
  };
}
