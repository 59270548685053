import { Observable, race, timer } from "rxjs";
import { mergeMap, timeout } from "rxjs/operators";

export function loadWithRetries<T>(loadData: () => Observable<T>) {
  return race(
    loadData(),
    timer(4000).pipe(mergeMap(() => loadData())),
    timer(8000).pipe(mergeMap(() => loadData())),
    timer(16000).pipe(mergeMap(() => loadData())),
    timer(60000).pipe(mergeMap(() => loadData()))
  ).pipe(timeout(70000));
}
