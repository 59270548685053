import React from "react";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import { strings } from "../languages";

interface IProps {
  show: boolean;
  onClose: (reset: boolean) => void;
}

export const ResetTimesConfirmation: React.SFC<IProps> = ({ show, onClose }) =>
  show ? (
    <Modal
      isOpen={show}
      centered={true}
      toggle={() => {
        onClose(false);
      }}
    >
      <ModalBody>
        <p>{strings.areYouSureResetTimes}</p>
      </ModalBody>
      <ModalFooter>
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => {
            onClose(true);
          }}
        >
          {strings.resetJobTimes}
        </button>{" "}
        <button
          className="btn btn-secondary"
          onClick={() => {
            onClose(false);
          }}
          type="button"
        >
          {strings.no}
        </button>
      </ModalFooter>
    </Modal>
  ) : null;
