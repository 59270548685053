import { Link } from "react-router-dom";
import { ErrorResponse } from "../types/ErrorResponse";

export function PresentationFormLoadFailureAlert({
  loadError,
  backLinkText,
  backLinkPath,
  onTryAgain,
}: {
  loadError: ErrorResponse;
  backLinkText: string;
  backLinkPath: string;
  onTryAgain: () => void;
}) {
  return (
    <div>
      <div className="font-weight-bold">Form Could Not Load</div>
      <div>{loadError.message}</div>

      {loadError.was400Error ? (
        <div>
          Please go to the <Link to={backLinkPath}>{backLinkText}</Link> to
          correct the information.
        </div>
      ) : (
        <button
          type="button"
          className="btn btn-secondary mt-3"
          onClick={() => {
            onTryAgain();
          }}
          data-testid="paymentFormTryAgainButton"
        >
          Try Again
        </button>
      )}
    </div>
  );
}
