import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Container from "../../../components/Container";
import { GenericFooter } from "../../../components/JobDetails/GenericFooter";
import Spinner from "../../../components/Spinner";
import { getErrorMessageFromError } from "../../../libraries/errorParsing/getErrorMessageFromError";
import ISchedule from "../../../models/ISchedule";
import { IScheduleIdentifier } from "../../../models/IScheduleIdentifier";
import { routingBuilders } from "../../../services/routing";
import { getPageHeader } from "../../../libraries/commonUi/functions/getPageHeader";
import { emailInvoice, textInvoice } from "../services/invoiceDataProvider";
import {
  faCreditCard,
  faEnvelope,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fullStoryTrack } from "../../../services/fullStoryService";
import { FloatingAlert } from "../../../libraries/billableForm/components/FloatingAlert";
import {
  loadInvoiceForPaymentForm,
  useLoadInvoiceForPaymentForm,
} from "../hooks/useLoadInvoiceForPaymentForm";
import InvoicePresentation from "../../../libraries/invoicePaymentForm/components/InvoicePresentation";
import { PresentationFormLoadFailureAlert } from "../../../libraries/billableForm/components/PresentationFormLoadFailureAlert";
import { FloatingErrorSavingIndicator } from "../../../libraries/billableForm/components/FloatingErrorSavingIndicator";
import { ErrorResponse } from "../../../libraries/billableForm/types/ErrorResponse";
import { strings } from "../../../languages";
import IScheduledJob from "../../../models/IScheduleJob";
import { JobMovedErrorAlert } from "../../../components/JobMovedErrorAlert";
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap/lib";

export function InvoicePresentationPage({
  scheduleJobId,
  scheduleIdentifier,
  schedule,
  imagePrefix,
  hasMovedFromSignaturePad,
  onScheduleJobUpdated,
}: {
  scheduleJobId: string;
  scheduleIdentifier: IScheduleIdentifier;
  schedule: ISchedule;
  imagePrefix: string;
  hasMovedFromSignaturePad: boolean;
  onScheduleJobUpdated(
    scheduleJobId: string,
    fieldsToUpdate: Partial<IScheduledJob>
  ): void;
}) {
  const {
    loading,
    invoice,
    loadError,
    invoicePaid,
    scheduleJob,
    setInvoice,
    setInvoicePaid,
    setLoading,
    setLoadError,
  } = useLoadInvoiceForPaymentForm({
    schedule,
    scheduleIdentifier,
    scheduleJobId,
  });

  const {
    triggerEmailInvoice,
    triggerTextInvoice,
    sendInvoiceSaving,
    showSendInvoiceSuccess,
    setShowSendInvoiceSuccess,
    errorSendingInvoice,
    setErrorSendingInvoice,
  } = useSendInvoice({
    scheduleIdentifier,
    jobInstanceId: scheduleJob?.id ?? "",
  });

  useSetInvoicePaidOnJob({ invoicePaid, onScheduleJobUpdated, scheduleJobId });

  const [isSendDropdownOpen, setIsSendDropDownOpen] = useState(false);

  let floatingAlertOrIndicator: JSX.Element | null = null;
  if (errorSendingInvoice) {
    floatingAlertOrIndicator = (
      <FloatingErrorSavingIndicator
        errorMessage={errorSendingInvoice.message}
        clearErrorMessage={() => setErrorSendingInvoice(null)}
        triggerSave={() => triggerEmailInvoice()}
        showRetry={!errorSendingInvoice.was400Error}
      />
    );
  } else if (showSendInvoiceSuccess) {
    floatingAlertOrIndicator = (
      <FloatingAlert
        type="success"
        showClose
        onClose={() => setShowSendInvoiceSuccess(false)}
        testId="messageSentConfirmation"
      >
        {strings.messageSent}
      </FloatingAlert>
    );
  }

  if (!scheduleJob) {
    return <JobMovedErrorAlert scheduleIdentifier={scheduleIdentifier} />;
  }

  return (
    <Container
      isJobListing={false}
      schedule={schedule}
      headerOverride={getPageHeader({
        schedule,
        scheduleJob,
        pageTitle: strings.invoice,
      })}
      scheduleIdentifier={scheduleIdentifier}
    >
      {loading || sendInvoiceSaving ? <Spinner /> : null}

      <div style={{ marginBottom: "100px" }}>
        {loadError ? (
          <PresentationFormLoadFailureAlert
            loadError={loadError}
            backLinkPath={routingBuilders.buildInvoiceFormPath({
              scheduleIdentifier,
              scheduleJobId,
            })}
            backLinkText="Invoice Form"
            onTryAgain={() => {
              loadInvoiceForPaymentForm({
                setLoading,
                setLoadError,
                linkedDayScheduleId: scheduleIdentifier.linkedDayScheduleId,
                dayOffset: scheduleIdentifier.dayOffset,
                techAppCrewId: scheduleIdentifier.techAppCrewId,
                scheduleJobId,
                setInvoice,
                setInvoicePaid,
              });
            }}
          />
        ) : null}

        {invoice ? (
          <InvoicePresentation
            invoice={invoice}
            imagePrefix={imagePrefix}
            signatureLinkPath={routingBuilders.buildInvoiceSignaturePath({
              scheduleIdentifier,
              scheduleJobId,
            })}
            hasMovedFromSignaturePad={hasMovedFromSignaturePad}
          />
        ) : null}
      </div>

      {floatingAlertOrIndicator}

      <GenericFooter
        backLink={
          invoicePaid
            ? routingBuilders.buildScheduleJobPath({
                scheduleIdentifier: scheduleIdentifier,
                scheduleJobId: scheduleJob.id,
              })
            : routingBuilders.buildInvoiceFormPath({
                scheduleIdentifier: scheduleIdentifier,
                scheduleJobId: scheduleJob.id,
              })
        }
      >
        {invoice?.lookupId || invoice?.canEmail || invoice?.canText ? (
          <div>
            <Dropdown
              isOpen={isSendDropdownOpen}
              toggle={() => setIsSendDropDownOpen((v) => !v)}
              direction="up"
            >
              <DropdownToggle className="text-primary p-0 bg-transparent border-0 text-center">
                <FontAwesomeIcon icon={faEnvelope} size="3x" fixedWidth />
                <div>{strings.send}</div>
              </DropdownToggle>
              <DropdownMenu
                style={{
                  borderRadius: ".75rem",
                  border: "2px solid rgba(0, 0, 0, 0.15)",
                }}
              >
                {!invoice?.paid ? (
                  <>
                    {invoice?.canEmail ? (
                      <>
                        <DropdownItem
                          onClick={() => {
                            triggerEmailInvoice();
                          }}
                          data-testid="emailInvoice"
                          className="text-primary font-weight-bold"
                        >
                          {strings.email}
                        </DropdownItem>
                        <DropdownItem divider />
                      </>
                    ) : null}

                    {invoice?.canText ? (
                      <>
                        <DropdownItem
                          onClick={() => {
                            triggerTextInvoice();
                          }}
                          data-testid="textInvoice"
                          className="text-primary font-weight-bold"
                        >
                          {strings.text}
                        </DropdownItem>
                        <DropdownItem divider />
                      </>
                    ) : null}
                  </>
                ) : null}

                {invoice?.lookupId ? (
                  <>
                    <DropdownItem
                      tag={"a"}
                      data-testid="printPage"
                      href={`${schedule.customerUrlRoot}/print/invoice/${invoice.lookupId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary font-weight-bold"
                    >
                      {strings.print}
                    </DropdownItem>
                  </>
                ) : null}
              </DropdownMenu>
            </Dropdown>
          </div>
        ) : null}

        {invoice?.paid && invoice.total === 0 ? (
          <div className={`text-center`}>
            <Link
              to={routingBuilders.buildInvoiceFormPath({
                scheduleIdentifier,
                scheduleJobId,
              })}
              data-testid="editLink"
            >
              <FontAwesomeIcon icon={faPenToSquare} size="3x" fixedWidth />
              <div>{strings.edit}</div>
            </Link>
          </div>
        ) : null}

        {invoice ? (
          <div className={`text-center`}>
            <Link
              to={routingBuilders.buildInvoicePaymentPath({
                scheduleIdentifier,
                scheduleJobId,
              })}
              data-testid="payLink"
              className={invoice?.paid ? "text-success" : ""}
              style={{
                paddingTop: "10px",
                pointerEvents: invoice?.paid ? "none" : undefined,
              }}
              onClick={() => {
                fullStoryTrack("Crew: Pay Invoice Clicked");
              }}
            >
              <FontAwesomeIcon icon={faCreditCard} size="3x" fixedWidth />
              <div>{invoice?.paid ? strings.paid : strings.pay}</div>
            </Link>
          </div>
        ) : null}
      </GenericFooter>
    </Container>
  );
}

function useSetInvoicePaidOnJob({
  invoicePaid,
  onScheduleJobUpdated,
  scheduleJobId,
}: {
  invoicePaid: boolean;
  onScheduleJobUpdated: (
    scheduleJobId: string,
    fieldsToUpdate: Partial<IScheduledJob>
  ) => void;
  scheduleJobId: string;
}) {
  const invoicePaidUpdatedRef = useRef(false);
  useEffect(() => {
    if (invoicePaid && !invoicePaidUpdatedRef.current) {
      onScheduleJobUpdated(scheduleJobId, {
        invoicePaid: true,
      });
      invoicePaidUpdatedRef.current = true;
    }
  }, [onScheduleJobUpdated, scheduleJobId, invoicePaid]);
}

function useSendInvoice({
  scheduleIdentifier,
  jobInstanceId,
}: {
  scheduleIdentifier: IScheduleIdentifier;
  jobInstanceId: string;
}) {
  const [sendInvoiceSaving, setSendInvoiceSaving] = useState(false);
  const [showSendInvoiceSuccess, setShowSendInvoiceSuccess] = useState(false);
  const [errorSendingInvoice, setErrorSendingInvoice] =
    useState<ErrorResponse | null>(null);

  const clearConfirmationTimeoutId = useRef<NodeJS.Timeout | null>(null);

  // If component is disposed, remove the timeout
  useEffect(() => {
    return function cleanup() {
      if (clearConfirmationTimeoutId.current) {
        clearTimeout(clearConfirmationTimeoutId.current);
      }
    };
  }, []);

  const triggerEmailInvoice = () => {
    fullStoryTrack("Crew: Email Invoice Clicked");

    setSendInvoiceSaving(true);
    setShowSendInvoiceSuccess(false);
    setErrorSendingInvoice(null);

    emailInvoice({
      scheduleIdentifier,
      jobInstanceId,
    }).subscribe({
      next: () => {
        setSendInvoiceSaving(false);
        setShowSendInvoiceSuccess(true);

        if (clearConfirmationTimeoutId.current) {
          clearTimeout(clearConfirmationTimeoutId.current);
        }

        clearConfirmationTimeoutId.current = setTimeout(() => {
          setShowSendInvoiceSuccess(false);
        }, 5000);
      },

      error: (err) => {
        setSendInvoiceSaving(false);
        setErrorSendingInvoice({
          message: getErrorMessageFromError(err),
          was400Error: err.status === 400,
        });
      },
    });
  };

  const triggerTextInvoice = () => {
    fullStoryTrack("Crew: Text Invoice Clicked");

    setSendInvoiceSaving(true);
    setShowSendInvoiceSuccess(false);
    setErrorSendingInvoice(null);

    textInvoice({
      scheduleIdentifier,
      jobInstanceId,
    }).subscribe({
      next: () => {
        setSendInvoiceSaving(false);
        setShowSendInvoiceSuccess(true);

        if (clearConfirmationTimeoutId.current) {
          clearTimeout(clearConfirmationTimeoutId.current);
        }

        clearConfirmationTimeoutId.current = setTimeout(() => {
          setShowSendInvoiceSuccess(false);
        }, 5000);
      },

      error: (err) => {
        setSendInvoiceSaving(false);
        setErrorSendingInvoice({
          message: getErrorMessageFromError(err),
          was400Error: err.status === 400,
        });
      },
    });
  };

  return {
    sendInvoiceSaving,
    showSendInvoiceSuccess,
    errorSendingInvoice,
    triggerEmailInvoice,
    triggerTextInvoice,
    setShowSendInvoiceSuccess,
    setErrorSendingInvoice,
  };
}
