import React, { useRef, useState } from "react";
import IScheduledJob from "../models/IScheduleJob";
import { Route, useRouteMatch } from "react-router-dom";
import JobListing from "./JobListing";
import ISchedule from "../models/ISchedule";
import ScrollToTop from "./ScrollToTop";
import { IGetEstimatedJobTimesResponse } from "../models/IGetEstimatedJobTimesResponse";
import { estimatedJobTimesLoadingState } from "../App";
import JobDetailsWithCache from "./JobDetailsWithCache";
import { InvoiceFormPage } from "../slices/billing/components/InvoiceFormPage";
import { routingBuilders, routingPatterns } from "../services/routing";
import { InvoicePresentationPage } from "../slices/billing/components/InvoicePresentationPage";
import { IScheduleIdentifier } from "../models/IScheduleIdentifier";
import { PaymentPage } from "../slices/billing/components/PaymentPage";
import { CaptureSignaturePad } from "../libraries/signature/components/CaptureSignaturePad";
import { EstimateFormPage } from "../slices/sales/components/EstimateFormPage";
import { CustomerJobHistory } from "../slices/scheduling/components/CustomerJobHistory";
import { EstimatePresentationPage } from "../slices/sales/components/EstimatePresentationPage";
import { acceptEstimate } from "../slices/sales/services/estimateDataProvider";
import { updateInvoiceSignature } from "../slices/billing/services/invoiceDataProvider";
import NotifyOnTheWay from "../slices/scheduling/components/NotifyOnTheWay";
import { DepositPaymentPage } from "../slices/sales/components/DepositPaymentPage";
import { WorksheetPage } from "../slices/worksheets/components/WorksheetPage";

interface IProps {
  schedule: ISchedule;
  estimatedJobTimesLoading: estimatedJobTimesLoadingState;
  estimatedJobTimes: IGetEstimatedJobTimesResponse | null;
  onScheduleJobUpdated(
    scheduleJobId: string,
    fieldsToUpdate: Partial<IScheduledJob>
  ): void;
  onScheduleUpdated(fieldsToUpdate: Partial<ISchedule>): void;
  scheduleIdentifier: IScheduleIdentifier;
  reloadSchedule: () => void;
  jobListingFooterElement: React.ReactNode | null;
}

const CrewJobs: React.FunctionComponent<IProps> = ({
  schedule,
  estimatedJobTimes,
  estimatedJobTimesLoading,
  onScheduleJobUpdated,
  onScheduleUpdated,
  scheduleIdentifier,
  reloadSchedule,
  jobListingFooterElement,
}) => {
  const jobListingPosition = useRef(0);
  const { path } = useRouteMatch();
  const [hasMovedFromSignaturePad, setHasMovedFromSignaturePad] =
    useState(false);
  const [showNotifiedOnTheWayAlert, setShowNotifiedOnTheWayAlert] =
    useState(false);

  return (
    <React.Fragment>
      <ScrollToTop
        onMovedToJobDetails={() => {
          setHasMovedFromSignaturePad(false);
          window.setTimeout(() => {
            window.scrollTo(0, 0);
          }, 0);
        }}
        onMovedToJobListing={() => {
          setHasMovedFromSignaturePad(false);
          window.setTimeout(() => {
            window.scrollTo(0, jobListingPosition.current || 0);
          }, 0);
        }}
        onMoveFromSignature={() => {
          setHasMovedFromSignaturePad(true);
        }}
      >
        <Route
          path={path}
          exact={true}
          render={() => {
            return (
              <JobListing
                schedule={schedule}
                estimatedJobTimes={estimatedJobTimes}
                estimatedJobTimesLoading={estimatedJobTimesLoading}
                onJobDetailsLinkClicked={() => {
                  jobListingPosition.current = window.scrollY;
                }}
                onScheduleUpdated={onScheduleUpdated}
                scheduleIdentifier={scheduleIdentifier}
                footerElement={jobListingFooterElement}
              />
            );
          }}
        />

        <Route
          path={routingPatterns.getInvoiceSignaturePath(path)}
          render={(props) => {
            const redirectToPage = () => {
              props.history.push(
                routingBuilders.buildInvoicePresentation({
                  scheduleIdentifier,
                  scheduleJobId: props.match.params.scheduleJobId,
                })
              );
            };
            return (
              <CaptureSignaturePad
                scheduleIdentifier={scheduleIdentifier}
                scheduleJobId={props.match.params.scheduleJobId}
                onCancel={redirectToPage}
                saveMethod={updateInvoiceSignature}
                onSaveCompleted={() => {
                  redirectToPage();
                }}
              />
            );
          }}
        />

        <Route
          path={routingPatterns.getEstimateSignaturePath(path)}
          render={(props) => {
            const redirectToPage = () => {
              props.history.push(
                routingBuilders.buildEstimatePresentationPath({
                  scheduleIdentifier,
                  scheduleJobId: props.match.params.scheduleJobId,
                })
              );
            };
            return (
              <CaptureSignaturePad
                scheduleIdentifier={scheduleIdentifier}
                scheduleJobId={props.match.params.scheduleJobId}
                onCancel={redirectToPage}
                saveMethod={acceptEstimate}
                onSaveCompleted={() => {
                  onScheduleJobUpdated(props.match.params.scheduleJobId, {
                    proposalAccepted: true,
                  });
                  redirectToPage();
                }}
              />
            );
          }}
        />

        <Route
          path={routingPatterns.getInvoicePresentationPath(path)}
          exact={true}
          render={(props) => {
            const scheduleJobId = props.match.params.scheduleJobId;
            return (
              <InvoicePresentationPage
                scheduleJobId={scheduleJobId}
                schedule={schedule}
                scheduleIdentifier={scheduleIdentifier}
                imagePrefix={schedule.imagePrefix}
                hasMovedFromSignaturePad={hasMovedFromSignaturePad}
                onScheduleJobUpdated={onScheduleJobUpdated}
              />
            );
          }}
        />

        <Route
          path={routingPatterns.getInvoicePaymentPath(path)}
          exact={true}
          render={(props) => {
            const scheduleJobId = props.match.params.scheduleJobId;
            return (
              <PaymentPage
                scheduleJobId={scheduleJobId}
                schedule={schedule}
                scheduleIdentifier={scheduleIdentifier}
                onScheduleJobUpdated={onScheduleJobUpdated}
              />
            );
          }}
        />

        <Route
          path={routingPatterns.getEstimateDepositInvoicePaymentPath(path)}
          exact={true}
          render={(props) => {
            const scheduleJobId = props.match.params.scheduleJobId;
            return (
              <DepositPaymentPage
                scheduleJobId={scheduleJobId}
                schedule={schedule}
                scheduleIdentifier={scheduleIdentifier}
                onScheduleJobUpdated={onScheduleJobUpdated}
              />
            );
          }}
        />

        <Route
          path={routingPatterns.getInvoiceFormPath(path)}
          render={(props) => {
            const scheduleJobId = props.match.params.scheduleJobId;
            return (
              <InvoiceFormPage
                scheduleJobId={scheduleJobId}
                schedule={schedule}
                scheduleIdentifier={scheduleIdentifier}
              />
            );
          }}
        />

        <Route
          path={routingPatterns.getEstimateFormPath(path)}
          render={(props) => {
            const scheduleJobId = props.match.params.scheduleJobId;
            return (
              <EstimateFormPage
                scheduleJobId={scheduleJobId}
                schedule={schedule}
                scheduleIdentifier={scheduleIdentifier}
                onScheduleJobUpdated={onScheduleJobUpdated}
              />
            );
          }}
        />

        <Route
          path={routingPatterns.getEstimatePresentationPath(path)}
          exact={true}
          render={(props) => {
            const scheduleJobId = props.match.params.scheduleJobId;
            return (
              <EstimatePresentationPage
                scheduleJobId={scheduleJobId}
                schedule={schedule}
                scheduleIdentifier={scheduleIdentifier}
                imagePrefix={schedule.imagePrefix}
                hasMovedFromSignaturePad={hasMovedFromSignaturePad}
                reloadSchedule={reloadSchedule}
              />
            );
          }}
        />
        <Route
          path={routingPatterns.getCustomerJobHistoryPath(path)}
          exact={true}
          render={(props) => {
            const scheduleJobId = props.match.params.scheduleJobId;
            return (
              <CustomerJobHistory
                scheduleJobId={scheduleJobId}
                scheduleIdentifier={scheduleIdentifier}
                schedule={schedule}
                filePrefix={schedule.imagePrefix}
              />
            );
          }}
        />
        <Route
          path={routingPatterns.getNotifyOnTheWayPath(path)}
          exact={true}
          render={(props) => {
            const scheduleJobId = props.match.params.scheduleJobId;
            const redirectToPage = () => {
              props.history.push(
                routingBuilders.buildScheduleJobPath({
                  scheduleIdentifier,
                  scheduleJobId: scheduleJobId,
                })
              );
            };
            return (
              <NotifyOnTheWay
                scheduleIdentifier={scheduleIdentifier}
                scheduleJobId={scheduleJobId}
                schedule={schedule}
                onSaveCompleted={() => {
                  setShowNotifiedOnTheWayAlert(true);
                  redirectToPage();
                }}
              />
            );
          }}
        />
        <Route
          path={routingPatterns.getScheduleJobPath(path)}
          exact={true}
          render={(props) => {
            const scheduleJobId = props.match.params.scheduleJobId as string;
            return (
              <React.Fragment>
                <div>
                  <JobDetailsWithCache
                    schedule={schedule}
                    filePrefix={schedule.imagePrefix}
                    tenantId={schedule.tenantId}
                    key={scheduleJobId}
                    scheduleJobId={scheduleJobId}
                    onScheduleJobUpdated={onScheduleJobUpdated}
                    customCrewQuestions={schedule.customCrewQuestions}
                    hideCustomerNameOnCrewView={
                      schedule.hideCustomerNameOnCrewView
                    }
                    hideCustomerPhoneNumberOnCrewView={
                      schedule.hideCustomerPhoneNumberOnCrewView
                    }
                    showCustomerEmailAddressOnCrewView={
                      schedule.showCustomerEmailAddressOnCrewView
                    }
                    scheduleIdentifier={scheduleIdentifier}
                    showNotifiedOnTheWayAlert={showNotifiedOnTheWayAlert}
                    setShowNotifiedOnTheWayAlert={setShowNotifiedOnTheWayAlert}
                  />
                </div>
              </React.Fragment>
            );
          }}
        />
        <Route
          path={routingPatterns.getWorksheetPath(path)}
          exact={true}
          render={(props) => {
            const scheduleJobId = props.match.params.scheduleJobId as string;
            const worksheetForJobId = props.match.params
              .worksheetForJobId as string;
            return (
              <WorksheetPage
                schedule={schedule}
                scheduleIdentifier={scheduleIdentifier}
                scheduleJobId={scheduleJobId}
                worksheetForJobId={worksheetForJobId}
                onCompleted={() => {
                  const job = schedule.scheduledJobs.find(
                    (j) => j.id === scheduleJobId
                  );
                  if (!!job) {
                    const updatedWorksheets = job.worksheets.map((w) => {
                      if (w.id === worksheetForJobId) {
                        return {
                          ...w,
                          completed: true,
                        };
                      } else {
                        return w;
                      }
                    });

                    onScheduleJobUpdated(scheduleJobId, {
                      worksheets: updatedWorksheets,
                    });
                  }
                }}
              />
            );
          }}
        />
      </ScrollToTop>
    </React.Fragment>
  );
};

export default CrewJobs;
